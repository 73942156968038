import { Component, OnInit, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-preview',
  templateUrl: './master-preview.component.html',
  styleUrls: ['./master-preview.component.styl']
})
export class MasterPreviewComponent implements OnInit {
  admin;
  currentRoute;
  @Input() master: any;
  @Input() textColor;
  @Input() noName;
  @Input() small;
  @Input() isLink = false;
  @Input() lastName = false;
  @Input() wrap;
  constructor(private userService: UserService, private router: Router) { }

  ngOnInit() {
    if (this.userService.userData) {
      this.admin = this.userService.userData.role === 'ADMIN' || this.userService.userData.role === 'SUPER_ADMIN';
    }
    this.currentRoute = this.router.url.split('?')[0];
  }

  getMastersName(admin) {
    if (!this.master.first_name && !this.master.last_name) {
      return 'Пользователь';
    }
    let result;
    if (admin) {
      result = (this.lastName ? this.master.last_name  || '' : '') + ' ' + (this.master.first_name || this.master.name || '');
    } else {
      result = (this.master.first_name || this.master.name || '') + ' ' + (this.lastName ? this.master.last_name  || '' : '');
    }
    return result;
  }

}
