import { Pipe } from '@angular/core';

@Pipe({
  name: 'removeExtention'
})
export class RemoveExtentionPipe {

  transform(value: any, args?: any): any {
    let arr = value.split('.');
    if (arr.length > 1) {
      arr.pop();
      return arr.join('.');
    } else {
      return value;
    }
  }

}
