import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-master-select',
  templateUrl: './master-select.component.html',
  styleUrls: ['./master-select.component.styl']
})
export class MasterSelectComponent implements OnInit {
  @Input() control;
  @Input() masters: any[];
  @Input() disabled: boolean;
  @Input() loading;
  @Output() selectEmitter = new EventEmitter();
  pickerOpened;

  constructor() { }

  ngOnInit() {
  }

  selectMaster(master) {
    setTimeout(() => {
      this.closePicker();
    }, 0);
    if (this.control) {
      this.control.setValue(master);
      this.control.markAsTouched();
    }
    this.selectEmitter.emit();
  }

  openPicker() {
    if (!this.disabled) {
      this.pickerOpened = true;
    }
  }

  closePicker() {
    if (this.pickerOpened) {
      this.pickerOpened = false;
      if (this.control) {
        this.control.markAsTouched();
      }
    }
  }

}
