import { OnInit, Directive, ElementRef, Input } from '@angular/core';
import { IsBrowserService } from '../services/is-browser.service';
@Directive({
  selector: '[appAutoFocus]'
})
export class AutoFocusDirective implements OnInit {
  @Input() disableAutoFocus;
  constructor(private el: ElementRef,
              private isBrowserService: IsBrowserService) {
   
              }
  ngOnInit() {
    if (this.isBrowserService.isBrowser && this.el.nativeElement.focus && !this.disableAutoFocus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      })
    }
  }
}
