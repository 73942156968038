import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-course-dates',
  templateUrl: './course-dates.component.html',
  styleUrls: ['./course-dates.component.styl']
})
export class CourseDatesComponent implements OnInit {
  @Input() dates;
  // @Input() mapMethod;
  parsedDates;
  constructor() { }

  ngOnInit() {
    this.parsedDates = this.parseDates(this.dates.map(item => item.start_date ? item.start_date : item));
  }

  parseDates(dates) {
    function sortDates(a, b) {
      return +(new Date(a) > new Date(b)) * 2 - 1;
    }
    function groupByMonth(arr) {
      function dropDay(date) {
        return new Date(new Date(date).setDate(1)).setHours(0,0,0,0);
      }
      let result = [];
      let currentMonth = dropDay(arr[0]);
      result.push([arr[0]]);
      if (arr.length > 1) {
        for (let i = 1; i < arr.length; i++) {
          if (dropDay(arr[i]) === currentMonth) {
            +arr[i] !== +arr[i-1] && result[result.length - 1].push(arr[i]);
          } else {
            currentMonth = dropDay(arr[i])
            result.push([arr[i]]);
          }
        }
      }
      return result;
    }

    if (dates.sort) {
      let sorted = dates.sort(sortDates);
      // console.log(groupByMonth(sorted))
      return groupByMonth(sorted);
    } else {
      return null;
    }

  }

}
