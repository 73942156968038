import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-confirm-popup',
  templateUrl: './confirm-popup.component.html',
  styleUrls: ['./confirm-popup.component.styl']
})
export class ConfirmPopupComponent implements OnInit {
  @Input() confirmData;
  @Output() confirmEmitter = new EventEmitter();
  @Output() rejectEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  confirm() {
    this.confirmEmitter.emit();
  }

  reject() {
    this.rejectEmitter.emit();
  }

}
