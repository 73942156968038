import { Pipe } from '@angular/core';

@Pipe({
  name: 'storageFilter'
})
export class StorageFilterPipe {

  transform(items: any[], filter: string) {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item) => {
      return !!([item.owner.first_name || '', item.owner.last_name || ''].join(' ').toLowerCase().indexOf(filter.toLowerCase()) + 1);
    });
  }

}
