import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-seo-block',
  templateUrl: './seo-block.component.html',
  styleUrls: ['./seo-block.component.styl']
})
export class SeoBlockComponent implements OnInit {
  @Input() text;
  constructor() { }

  ngOnInit() {

  }

}
