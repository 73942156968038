import { Pipe } from '@angular/core';

@Pipe({
  name: 'consultationsSort'
})
export class ConsultationsSortPipe {

  transform(items: any[], param: string, direction: boolean) {
    let callback;
    function timeSortCallback(a, b) {
      let result = new Date(a.start_time).getHours() * 60 + new Date(a.start_time).getMinutes() - new Date(b.start_time).getHours() * 60 + new Date(b.start_time).getMinutes();
      return direction ? result : -result;
    }

    function userSortCallback(userType, a, b) {
      if (!a[userType].last_name && !a[userType].first_name) {
        return 1;
      }
      if (!b[userType].last_name && !b[userType].first_name) {
        return -1;
      }
      if (`${(a[userType].last_name || '').toLowerCase()} ${(a[userType].first_name || '').toLowerCase()}`.trim() > `${(b[userType].last_name || '').toLowerCase()} ${(b[userType].first_name || '').toLowerCase()}`.trim()) {
        return direction ? 1 : -1;
      }
      if (`${(a[userType].last_name || '').toLowerCase()} ${(a[userType].first_name || '').toLowerCase()}`.trim() < `${(b[userType].last_name || '').toLowerCase()} ${(b[userType].first_name || '').toLowerCase()}`.trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }

    function paidSortCallback(a, b) {
      let result = +a.paid - +b.paid;
      return direction ? result : -result;
    }

    function attendedSortCallback(a, b) {
      let result = +a.attended - +b.attended;
      return direction ? result : -result;
    }

    function defaultSortCallback(a, b) {
      return 0
    }

    if (!items) {
      return items;
    }
    switch(param) {
      case 'time':
        callback = timeSortCallback;
        break;
      case 'user':
        callback = userSortCallback.bind(null, 'user');
        break;
      case 'master':
        callback = userSortCallback.bind(null, 'master');
        break;
      case 'paid':
        callback = paidSortCallback;
        break;
      case 'attended':
        callback = attendedSortCallback;
        break;
      default:
        callback = defaultSortCallback;
        break;
      }

    return items.sort(callback);
  }

}
