import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { EquipmentsService } from '../../services/equipments.service';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-modal-quantity-of-breakdown',
  templateUrl: './modal-quantity-of-breakdown.component.html',
  styleUrls: ['./modal-quantity-of-breakdown.component.styl']
})
export class ModalQuantityOfBreakdownComponent implements OnInit {

  repairDate;
  breaksList;
  todayBreaksList;
  isLoading;
  error;
  equipments = [
    {
      name: "Фрезер 1,2кВт POF1200 AE Bosch",
      breakdown_date: 1525868308094,
      repair_date: 1525868308094,
      provider: "ООО «Роберт Бош», 8 (495) 123-47-82 ",
      replaced: "Фрезер Makita RP2301FCX",
      comment: "Работы по замене до 30 сентября, Максим 89162322232"
    }
  ]
  constructor(private fb: FormBuilder,
              private modalService: ModalService,
              private equipmentsService: EquipmentsService,
              private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.updatesBreaksList();
  }

  updatesBreaksList() {
    this.isLoading = true;
    this.notificationsService.updateEmitter();
    this.equipmentsService.getBrokenList().subscribe((data) => {
      let list = data.result.map(item => {
        return {
          ...item,
          repairDate: this.fb.group({
            date: new Date(item.repair_date)
          })
        }
      }).sort((a, b) => {
        return new Date(a.repair_date).getTime() - new Date(b.repair_date).getTime();
      });
      this.todayBreaksList = list.filter(item => {
        return new Date().setHours(0,0,0,0) === new Date(item.repair_date).setHours(0,0,0,0);
      })
      this.breaksList =list.filter(item => {
        return new Date().setHours(0,0,0,0) !== new Date(item.repair_date).setHours(0,0,0,0);
      })
      this.isLoading = false;
    }, () => {this.error = true; this.isLoading = false})
  }

  // initForm() {
  //   this.repairDate = this.fb.group({
  //     date: new Date()
  //   });
  // }

  back() {
    this.modalService.back();
  }

}
