import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ElementRef } from '@angular/core';

@Component({
  selector: 'app-lf-input-number',
  templateUrl: './lf-input-number.component.html',
  styleUrls: ['./lf-input-number.component.styl']
})
export class LfInputNumberComponent implements OnInit {
  @ViewChild('input') input: ElementRef;
  @Input() minValue:number = 0;
  @Input() showPlus:boolean;
  @Input() value:number;
  @Output() onChange = new EventEmitter();
  amount;
  inputValue;
  reg = new RegExp(/^[+]{0,1}[0-9]*$/);
  numReg = new RegExp(/[0-9]{1,}/ig);
  constructor() { }

  ngOnInit() {
    if (!this.value) {
      this.amount = this.minValue;
    } else {
      this.amount = this.value;
    }
    this.updateInputValue();
  }

  plus() {
    this.amount++;
    this.updateInputValue()
  }

  minus() {
    this.amount--;
    this.updateInputValue()
  }

  updateInputValue() {
    this.inputValue = this.amount;
    if (this.showPlus) {
      this.inputValue = '+' + this.inputValue;
    }
    this.input.nativeElement.value = this.inputValue;
    this.onChange.emit(this.amount);
  }

  onBlur() {
    this.updateInputValue();
  }

  onInput() {
    if (this.reg.test(this.inputValue)) {
      let newVal;
      if (this.inputValue.match(this.numReg) && this.inputValue.match(this.numReg)[0]) {
        newVal = +this.inputValue.match(this.numReg)[0];
      }
      if (newVal || newVal === 0) {
        this.amount = Math.max(newVal, this.minValue);
      }
    } else {
      this.updateInputValue();
    }
  }

}
