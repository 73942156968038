import { Component, OnInit, PLATFORM_ID, Inject, Input, Output, EventEmitter } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from '../../services/user.service';
import { StorageService } from '../../services/storage.service';
import { getDatesDiff } from '../../utils';
declare var $;

@Component({
  selector: 'app-storage-diagram',
  templateUrl: './storage-diagram.component.html',
  styleUrls: ['./storage-diagram.component.styl']
})
export class StorageDiagramComponent implements OnInit {
  @Input() user;
  @Input() blockId = 'circles-2';
  @Output() initEmitter = new EventEmitter();
  currentUserId;
  val2 = 5;
  maxVal2 = 10;
  isBrowser;
  w: any;
  getDatesDiff = getDatesDiff;
  isEmpty;
  currentValue;
  constructor(@Inject(PLATFORM_ID) platformId: string,
              private userService: UserService,
              private storageService: StorageService) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.w = window;
    }
  }

  ngOnInit() {
    if (!this.user) {
      this.currentUserId = this.userService.userId;
    } else {
      this.currentUserId = this.user.id;
    }
    this.initDiagram();
    this.userService.updateSubject.subscribe(() => {
      this.initDiagram();
    })
    this.storageService.updateSubject.subscribe(() => {
      this.initDiagram();
    })

  }

  initDiagram() {
    this.storageService.getUsersStorageSessions(this.currentUserId).subscribe((data) => {
      let isEmpty = !data.result.length;
      this.isEmpty = isEmpty;
      let now = new Date().getTime();
      let sorted = data.result.sort((a, b) => {
        return (a.end_date) - (b.end_date);
      })
      let closestCell;
      let max;
      let current;
      let overdue;
      if (!isEmpty) {
        closestCell = sorted[0];
        overdue = new Date(closestCell.end_date).setHours(0,0,0,0) < new Date().setHours(0,0,0,0);
        current = getDatesDiff(new Date(), closestCell.end_date, true)
        if (overdue) {
          current = -current;
        }
        max = getDatesDiff(closestCell.start_date, closestCell.end_date, true)

        this.currentValue = current;
      }
      this.initEmitter.emit(data.result);
      this.initCircles(isEmpty, current, max);
    })
  }

  // ngAfterViewInit() {
  //
  // }

  initCircles(empty?, current?, max?) {
    let value;
    if (current < 0) {
      value = -current;
    } else {
      if (empty) {
        value = 1;
      } else {
        value = max - current;
      }
    }
    let text;
    if (current < 0) {
      text = this.circleCallback2.bind(this, max, true)
    } else {
      text = this.circleCallback2.bind(this, max, false)
    }
    if (this.isBrowser) {
      $('#'+this.blockId).html('');
      this.w.Circles.create({
        id:           this.blockId,
        radius:       46,
        value:        value,
        maxValue:     empty || (current < 0) ? value || 1 : max,
        width:        9.8,
        text:         empty ? '' : text,
        colors:       [empty ? 'transparent' : '#b0c4da', value ? '#edeef0' : 'transparent'],
        duration:     empty ? 0 : 600,
        wrpClass:     'circles-wrp',
        textClass:    empty ? 'circles-text-2 circles-text-2-empty' : 'circles-text-2',
        styleWrapper: true,
        styleText:    true
      })
    }
  }

    private circleCallback2 = function(maxval, minus, val) {
      let v;
      if (minus) {
        v = -val;
      } else {
        v = maxval - val;
      }
      function declOfNum(number, titles) {
        let cases = [2, 0, 1, 1, 1, 2];
        return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
      }
      return Math.round(v) + ' ' + declOfNum(Math.abs(Math.round(v)), ['день', 'дня', 'дней']);
    }

}
