import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Inject } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { FileUploadService } from '../../services/file-upload.service';
import { EventsService } from '../../services/events.service';
import { CurrentEventService } from '../../services/current-event.service';
import { CreationService } from '../../services/creation.service';
import { getApiTime, phoneMask } from '../../utils';
import { Subject } from 'rxjs';
import { first, debounceTime } from 'rxjs/operators';
import { IsBrowserService } from '../../services/is-browser.service';
import { TranslitService } from '../../services/translit.service';
import { HistoryService } from '../../services/history.service';
import { RoutesDataService } from '../../services/routes-data.service';

import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-event-creation',
  templateUrl: './event-creation.component.html',
  styleUrls: ['./event-creation.component.styl']
})
export class EventCreationComponent implements OnInit {
  @ViewChild('priceInput') priceInput: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('mainPhotoPicker') mainPhotoPicker: ElementRef;
  editionMode;
  loadingState;
  errorMessage;
  deleteLoading;
  deleted;
  confirmType;
  creationForm;
  isEdition;
  eventLoading;
  isBrowser;
  loadedSubject$;
  defaultSchedule;
  scheduleEdited;
  currentRoute;
  phoneMask = phoneMask;

  constructor(private router: Router,
              private fb: FormBuilder,
              private _ref: ChangeDetectorRef,
              private eventsService: EventsService,
              private creationService: CreationService,
              private currentEventService: CurrentEventService,
              private activatedRoute: ActivatedRoute,
              private fileUploadService: FileUploadService,
              private isBrowserService: IsBrowserService,
              private translitService: TranslitService,
              private historyService: HistoryService,
              private routesDataService: RoutesDataService,
              @Inject('HOSTNAME') private hostName: string) { this.isBrowser = this.isBrowserService.isBrowser }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    this.loadedSubject$ = new Subject();
    if (this.activatedRoute.snapshot.queryParams.editevent) {
      this.eventLoading = true;
      this.isEdition = true;
      this.eventsService.getEvent(this.activatedRoute.snapshot.queryParams.editevent).pipe(first()).subscribe((data) => {
        this.eventLoading = false;
        this.editionMode = data.result;
        this.initEventEdition();
        this.wrapper.nativeElement.scrollTop = 0;
        this.loadedSubject$.next();
        this.defaultSchedule = {
          time: moment(data.result.start_time).format('HH:mm') + '-' + moment(data.result.end_time).format('HH:mm'),
          start_date: data.result.start_date,
          end_date: data.result.end_date
        }
      })
    } else if (this.activatedRoute.snapshot.queryParams.creation && this.activatedRoute.snapshot.queryParams.creation === 'event') {
      this.initEventCreation();
    }
    if (this.activatedRoute.snapshot.queryParams.field && this.isBrowser) {
      if (this.activatedRoute.snapshot.queryParams.field === 'schedule') {
        this.loadedSubject$.pipe(first(), debounceTime(100)).subscribe(() => {
          this._ref.detectChanges();
          $(this.wrapper.nativeElement).animate({
            scrollTop: document.getElementById('schedule-section').getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
          }, 1000);
        })
      }
    }
  }

  initEventCreation() {
    this.creationForm = this.fb.group({
      title: ['', [Validators.required]],
      mainImage: [null, [Validators.required]],
      leroyOrg: ['true', [Validators.required]],
      organizers: this.fb.array([]),
      shortDescription: ['', [Validators.required]],
      mainDescription: ['', [Validators.required]],
      program: [''],
      eventPageUrl: ['', [Validators.pattern(/^(https?|ftp|file):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]/)]],
      leroyPlace: ['true', [Validators.required]],
      placeAddress: [''],
      isFree: ['true', [Validators.required]],
      price: [''],
      needRegistration: ['true'],
      maxCapacity: [''],
      registrationUrl: [''],
      age: ['0', [Validators.required]],
      albumUrl: ['', [Validators.pattern(/^(https?:\/\/)?(www\.)?(vk|facebook)\.com\/.{1,}$/)]],
      address: ['', [Validators.required]],
      phone: ['', [Validators.required]],
      email: ['', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)]],
      importantEvent: [''],
      dateStart: ['', [Validators.required]],
      dateEnd: ['', [Validators.required]],
      time: ['', [Validators.required]],
      socials: this.fb.array([]),
      mainPageImage: [null],
      gallery: this.fb.array([
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null)
      ])
    });
    this.addOrganizer();
    this.onRegistrationChange();
  }

  initEventEdition() {
    this.creationForm = this.fb.group({
      title: [this.editionMode.name || '', [Validators.required]],
      mainImage: [this.editionMode.photo ? {
        old: true,
        fileId: this.editionMode.photo.id,
        fileUrl: this.editionMode.photo.url
      } : '', [Validators.required]],
      leroyOrg: [this.editionMode.leroy_fabric_organizer ? 'true' : 'false' , [Validators.required]],
      organizers: this.fb.array([]),
      shortDescription: [this.encode(this.editionMode.short_description) || '', [Validators.required]],
      mainDescription: [this.encode(this.editionMode.description) || '', [Validators.required]],
      program: [this.editionMode.program || ''],
      eventPageUrl: [this.editionMode.schedule_link || '', [Validators.pattern(/^(https?|ftp|file):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]/)]],
      leroyPlace: [this.editionMode.leroy_fabric_place ? 'true' : 'false', [Validators.required]],
      placeAddress: [this.editionMode.address || ''],
      isFree: [this.editionMode.cost == 0 ? 'true' : 'false', [Validators.required]],
      price: [this.editionMode.cost || ''],
      needRegistration: [this.editionMode.need_registration || ''],
      maxCapacity: [this.editionMode.places_amount || ''],
      registrationUrl: [this.editionMode.registration_link || ''],
      age: [this.editionMode.age_limit + '' || '0', [Validators.required]],
      albumUrl: [this.editionMode.photo_album_link || '', [Validators.pattern(/^(https?:\/\/)?(www\.)?(vk|facebook)\.com\/.{1,}$/)]],
      address: [this.editionMode.administration.address || '', [Validators.required]],
      phone: [this.editionMode.administration.phone || '', [Validators.required]],
      email: [this.editionMode.administration.email || '', [Validators.required, Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)]],
      importantEvent: [!!this.editionMode.important || ''],
      dateStart: [new Date(this.editionMode.start_date).getTime() || '', [Validators.required]],
      dateEnd: [new Date(this.editionMode.end_date).getTime() || '', [Validators.required]],
      time: [moment(this.editionMode.start_time).format('HH:mm') + '-' + moment(this.editionMode.end_time).format('HH:mm'), [Validators.required]],
      socials: this.fb.array([]),
      mainPageImage: [this.editionMode.main_photo ? {
        old: true,
        fileId: this.editionMode.main_photo.id,
        fileUrl: this.editionMode.main_photo.url
      } : ''],
      gallery: this.fb.array([
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null)
      ])
    });
    if (!this.editionMode.leroy_fabric_organizer) {
      this.editionMode.organizers.forEach(item => {
        this.addOrganizer(item);
      })
    }
    for (let key in this.editionMode.administration) {
      if (key === 'facebook' && this.editionMode.administration.facebook) {
        this.addSocial(key, this.editionMode.administration.facebook);
      }
      if (key === 'vk' && this.editionMode.administration.vk) {
        this.addSocial(key, this.editionMode.administration.vk);
      }
      if (key === 'twitter' && this.editionMode.administration.twitter) {
        this.addSocial(key, this.editionMode.administration.twitter);
      }
      if (key === 'google_plus' && this.editionMode.administration.google_plus) {
        this.addSocial(key, this.editionMode.administration.google_plus);
      }
      if (key === 'whatsapp' && this.editionMode.administration.whatsapp) {
        this.addSocial(key, this.editionMode.administration.whatsapp.replace('https://api.whatsapp.com/send?phone=', '+'));
      }
      if (key === 'telegram' && this.editionMode.administration.telegram) {
        this.addSocial(key, this.editionMode.administration.telegram.replace('https://telegram.me/', ''));
      }
      if (key === 'viber' && this.editionMode.administration.viber) {
        this.addSocial(key, this.editionMode.administration.viber.replace('viber://add?number=', '+'));
      }
    }
    this.editionMode.attachments.forEach((item, index) => {
      this.creationForm.controls.gallery.controls[index].setValue({
        old: true,
        fileId: item.id,
        fileUrl: item.url
      })
    })
    this.onImportantChange();
    this.onRegistrationChange();
  }

  addOrganizer(org:any = {}) {
    let oldFile;
    if (org.logo) {
      oldFile = {
        fileId: org.logo.id,
        fileUrl: org.logo.url,
        old: true
      }
    }
    let group = this.fb.group({
      name: [org.name || ''],
      url: [org.url || ''],
      file: [oldFile || null]
    })
    this.creationForm.controls.organizers.push(group)
  }

  addSocial(type, contact) {
    let social = this.fb.group({
      type: [type],
      contact: [contact, [Validators.required]]
    })
    if (type === 'whatsapp' || type === 'viber') {
      social.controls.contact.setValidators([Validators.required, Validators.pattern(/8|\+7\d{10}/)]);
    } else {
      social.controls.contact.setValidators([Validators.required]);
    }
    this.creationForm.controls.socials.push(social);
  }

  removeOrganizer(index) {
    this.creationForm.controls.organizers.removeAt(index);
    if (!this.creationForm.controls.organizers.controls.length) {
      this.addOrganizer();
    }
  }

  closeModal() {
    this.creationService.closeCreationModal();
  }

  onPriceFocus() {
    this.creationForm.controls.isFree.setValue('false');
    this.onIsFreeChange();
  }

  onOrgChange() {
    if (this.creationForm.controls.leroyOrg.value === 'false') {
      if (!this.creationForm.controls.organizers.controls.length) {
        this.addOrganizer();
      }
      this.creationForm.controls.organizers.controls.forEach(item => {
        item.controls.file.setValidators(Validators.required);
        this.safeReset(item.controls.file);
        item.controls.name.setValidators(Validators.required);
        this.safeReset(item.controls.name);
        item.controls.url.setValidators([this.urlValidator, Validators.required]);
        this.safeReset(item.controls.url);
      })
    } else {
      this.creationForm.controls.organizers.controls.forEach(item => {
        item.controls.file.setValidators();
        this.safeReset(item.controls.file);
        item.controls.name.setValidators();
        this.safeReset(item.controls.name);
        item.controls.url.setValidators();
        this.safeReset(item.controls.url);
      })
    }
  }

  onIsFreeChange() {
    if (this.creationForm.controls.isFree.value === 'false') {
      this.priceInput.nativeElement.focus();
      this.creationForm.controls.price.setValidators([this.getNumberValidator(1), Validators.required]);
      this.safeReset(this.creationForm.controls.price);
    } else {
      this.creationForm.controls.price.setValidators()
      this.safeReset(this.creationForm.controls.price);
    }
  }

  onPlaceChange() {
    if (this.creationForm.controls.leroyPlace.value === 'false') {
      this.creationForm.controls.placeAddress.setValidators(Validators.required);
      this.creationForm.controls.placeAddress.reset();
    } else {
      this.creationForm.controls.placeAddress.setValidators()
      this.safeReset(this.creationForm.controls.placeAddress);
    }
  }

  onRegistrationChange() {
    if (this.creationForm.controls.needRegistration.value) {
      this.creationForm.controls.maxCapacity.setValidators([Validators.required, this.getNumberValidator(this.editionMode ? (this.editionMode.places_filled || 1) : 1)]);
      this.creationForm.controls.registrationUrl.setValidators([Validators.required, this.urlValidator]);
      this.safeReset(this.creationForm.controls.maxCapacity);
      this.safeReset(this.creationForm.controls.registrationUrl);
    } else {
      this.creationForm.controls.maxCapacity.setValidators();
      this.creationForm.controls.registrationUrl.setValidators();
      this.safeReset(this.creationForm.controls.maxCapacity);
      this.safeReset(this.creationForm.controls.registrationUrl);
    }
  }

  onImportantChange() {
    if (this.creationForm.controls.importantEvent.value) {
      this.creationForm.controls.mainPageImage.setValidators(Validators.required);
      this.safeReset(this.creationForm.controls.mainPageImage);
    } else {
      this.creationForm.controls.mainPageImage.setValidators();
      this.safeReset(this.creationForm.controls.mainPageImage);
    }
  }

  onTimeBlur(evt) {
    this.compareTimings();
    setTimeout(() => {
      this.creationForm.controls.time.setValue(evt.target.value)
    }, 0);
  }

  uploadFiles(eventName?) {
    this.errorMessage = '';
    this.loadingState = 1;
    let that = this;
    function* generator() {
      if (that.creationForm.controls.mainImage.value) {
        yield 'main'
      }
      if (that.creationForm.controls.mainPageImage.value && that.creationForm.controls.importantEvent.value) {
        yield 'mainPage'
      }
      for (let n = 0; n < that.creationForm.controls.gallery.controls.length; n++) {
        if (that.creationForm.controls.gallery.controls[n].value) {
          yield n;
        }
      }
      for (let m = 0; m < that.creationForm.controls.organizers.length; m++) {
        if (that.creationForm.controls.organizers.controls[m].controls.file.value) {
          yield m;
        }
      }
      return that.submitForm();
    }
    let gen = generator();
    gen.next();
    this.uploadImage(that.creationForm.controls.mainImage, gen, eventName);
    if (that.creationForm.controls.mainPageImage.value && that.creationForm.controls.importantEvent.value) {
      this.uploadImage(that.creationForm.controls.mainPageImage, gen, eventName);
    }
    that.creationForm.controls.gallery.controls.forEach(item => {
      if (item.value) {
        this.uploadImage(item, gen, eventName);
      }
    })
    that.creationForm.controls.organizers.controls.forEach(item => {
      if (item.controls.file.value) {
        this.uploadImage(item.controls.file, gen, eventName);
      }
    })
  }

  private uploadImage = function(file, gen, eventName?) {
    if (file.value) {
      if (file.value.old) {
        file.loaded = true;
        gen.next();
      } else if (file.loaded) {
        gen.next();
      } else {
        file.loading = true;
        file.hasErr = false;
        this.fileUploadService.uploadFile(file.value, 'EVENTS', eventName).pipe(first()).subscribe((data) => {
          file.value.fileUrl = data.result.url;
          file.value.fileId = data.result.id;
          file.loaded = true;
          file.loading = false;
          gen.next();
        }, () => {
          file.hasErr = true;
          file.loading = false;
          this.loadingState = 'error'
          this.errorMessage = 'Ошибка загрузки файла';
        });
      }
    }
  }

  submitForm() {
    this.loadingState = 2;
    if (this.editionMode) {
      this.eventsService.edit(this.editionMode.id, this.getEventCreationForm()).pipe(first()).subscribe((data) => {
        this.loadingState = 3;
        // this.closeModal();
        let redirectUrl;
        let redirectQueryParams;
        let isEventPage = this.router.url.indexOf('/sobytiya') + 1;
        if (isEventPage) {
          // if (data.result.new_entity_id) {
          //   let queryParams = this.router.url.split('?')[1];
          //   if (queryParams) {
          //     queryParams = '?' + queryParams;
          //   }
          //   console.log(this.hostName + 'sobytiya/' + this.translitService.getHumanReadableId(data.result.name, data.result.new_entity_id) + queryParams);
          //   window.history.replaceState({}, '', this.hostName + 'sobytiya/' + this.translitService.getHumanReadableId(data.result.name, data.result.new_entity_id) + queryParams);
          // }
          redirectUrl = '/sobytiya/' + this.translitService.getHumanReadableId(data.result.name, data.result.new_entity_id || this.editionMode.id);
          try {
            this.historyService.redirect404 = true;
            this.historyService.currentState = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url].previous.url;
            let pageData:any = {};
            pageData[redirectUrl] = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url]
            console.log(pageData);
            this.historyService.addPageData('event', pageData);
          } catch {
            this.historyService.redirect404 = false;
          }
        } else {
          redirectUrl = this.router.url.split('?')[0];
        }
        // redirectUrl = this.router.url.split('?')[0];
        // console.log(redirectUrl);
        if (data.result.notification_id) {
          redirectQueryParams = { popup: 'notify-move-event', notification: data.result.notification_id };
        } else {
          redirectQueryParams = {};
        }

        this.router.navigate([redirectUrl], { queryParams: redirectQueryParams });
        setTimeout(() => {
          this.currentEventService.updateEmitter(data.result.new_entity_id);
        })
      }, (e) => {
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка публикации';
      });
    } else {
      this.eventsService.create(this.getEventCreationForm()).pipe(first()).subscribe((data) => {
        this.loadingState = 3;
        this.closeModal();
        this.currentEventService.updateEmitter();
      }, (e) => {
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка публикации';
      });
    }

  }


  onSubmit() {
    if (this.creationForm.invalid) {
      this.markInvalidFields();
      this._ref.detectChanges();
      this.scrollToInvalidField();
    } else {
      let eventName = this.creationForm.value.title;
      this.uploadFiles(eventName);
    }
  }

  openConfirmDeletion() {
    this.confirmType = 'delete';
    this.wrapper.nativeElement.style.overflow = 'hidden';
  }

  closeConfirm() {
    this.confirmType = '';
    this.wrapper.nativeElement.style.overflow = 'auto';
  }


  deleteEvent() {
    this.confirmType = '';
    this.loadingState = 0;
    if (this.editionMode.id) {
      this.deleteLoading = true;
      this.eventsService.deleteEvent(this.editionMode.id).pipe(first()).subscribe((data) => {
        this.deleteLoading = false;
        this.deleted = true;
        this.closeModal();
        if (this.router.url.indexOf('sobytiya/') + 1) {
          this.router.navigate(['/sobytiya']);
        }
      }, () => {
        this.deleteLoading = false;
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка удаления';
        this.closeConfirm();
      })
    }
  }

  private scrollToInvalidField = function() {
    let elem;
    if (!this.creationForm.value.mainImage) {
      let elem = this.mainPhotoPicker.nativeElement;
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
    } else {
      if ($('.ng-invalid')[0].tagName === 'FORM' && $('.ng-invalid')[1]) {
        elem = $('.ng-invalid')[1].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-touched.ng-invalid')[1]
        }
      } else {
        elem = $('.ng-invalid')[0].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-invalid')[0]
        }
      }
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
    }
  }

  markInvalidFields() {
    const controls = this.creationForm.controls;
    Object.keys(controls)
    .forEach(controlName => controls[controlName].markAsTouched());
    this.creationForm.controls.organizers.controls.forEach(control => {
      Object.keys(control.controls)
      .forEach(controlName => {
        control.controls[controlName].markAsTouched();
      });
    });
    this.creationForm.controls.mainImage.markAsTouched();
  }

  private getNumberValidator = function(minValue) {
    let numberValidator: ValidatorFn = function(control: FormControl) {
      return +control.value >= minValue || (control.value === null || control.value === '') ? null : {
        incorrect: 'true'
      }
    }.bind(this)
    return numberValidator;
  }.bind(this)

  private urlValidator: ValidatorFn = function(control: FormControl) {
    return /^(https?|ftp|file):\/\/[-a-zA-Z0-9+&@#/%?=~_|!:,.;]*[-a-zA-Z0-9+&@#/%=~_|]/.test(control.value) || (control.value === null || control.value === '') ? null : {
      incorrect: 'true'
    }
  }.bind(this)

  private safeReset(control) {
    let val = control.value;
    control.reset();
    control.setValue(val);
  }

  compareTimings() {
    if (this.isEdition) {
      if (this.defaultSchedule.time === this.creationForm.value.time && new Date(this.defaultSchedule.start_date).setHours(0,0,0,0) === new Date(this.creationForm.value.dateStart).setHours(0,0,0,0) && new Date(this.defaultSchedule.end_date).setHours(0,0,0,0) === new Date(this.creationForm.value.dateEnd).setHours(0,0,0,0)) {
        this.scheduleEdited = false;
      } else {
        this.scheduleEdited = true;
      }
    }
  }

  private getEventCreationForm = function() {
    let form:any = {};
    form.name = this.creationForm.value.title;
    form.leroy_fabric_organizer = this.creationForm.value.leroyOrg === 'true';
    if (!form.leroy_fabric_organizer) {
      form.organizers = this.creationForm.value.organizers.map(item => {
        return {
          logo_id: item.file.fileId,
          name: item.name,
          url: item.url
        }
      })
    }
    form.short_description = this.escape(this.creationForm.value.shortDescription);
    form.description = this.escape(this.creationForm.value.mainDescription);
    if (this.creationForm.value.program) {
      form.program = this.escape(this.creationForm.value.program);
    }
    form.start_date = this.parseDate(this.creationForm.value.dateStart);
    form.end_date = this.parseDate(this.creationForm.value.dateEnd);
    form.start_time = this.parseTime(this.creationForm.value.time, 0);
    form.end_time = this.parseTime(this.creationForm.value.time, 1);
    form.leroy_fabric_place = this.creationForm.value.leroyPlace === 'true';
    if (!form.leroy_fabric_place) {
      form.address = this.creationForm.value.placeAddress;
    }
    if (this.creationForm.value.isFree === 'true') {
      form.cost = 0;
    } else {
      form.cost = this.creationForm.value.price;
    }
    form.need_registration = !!this.creationForm.value.needRegistration;
    if (form.need_registration) {
      form.places_amount = this.creationForm.value.maxCapacity;
      form.registration_link = this.creationForm.value.registrationUrl;
    }
    form.age_limit = this.creationForm.value.age;
    form.attachments = this.creationForm.controls.gallery.controls.filter(item => item.value).map((item, index) => {
      return {
        file_id: item.value.fileId,
        attachment_url: item.value.fileUrl,
        file_item_type: 'IMAGE',
        order: index + 1
      }
    })
    if (this.creationForm.value.albumUrl) {
      form.photo_album_link = this.creationForm.value.albumUrl;
    }
    let administration: any = {};
    if (this.creationForm.value.address) {
      administration.address = this.creationForm.value.address;
    }
    if (this.creationForm.value.phone) {
      administration.phone = this.creationForm.value.phone;
    }
    if (this.creationForm.value.email) {
      administration.email = this.creationForm.value.email;
    }
    this.creationForm.value.socials.forEach(item => {
      if (item.type === 'facebook' || item.type === 'vk' || item.type === 'twitter' || item.type === 'google_plus') {
        administration[item.type] = item.contact;
      } else if (item.type === 'whatsapp') {
        let result = item.contact;

        if (item.contact[0] === '8') {
          result = '7' + result.substring(1);
        }
        administration.whatsapp = 'https://api.whatsapp.com/send?phone=' + result.replace('+', '');
      } else if (item.type === 'telegram') {
        administration.telegram = 'https://telegram.me/' + item.contact;
      } else if (item.type === 'viber') {
        let result = item.contact;
        if (item.contact[0] === '8') {
          result = '7' + result.substring(1);
        }
        administration.viber = 'viber://add?number=' + result.replace('+', '');
      }
    });
    form.administration = administration;
    if (this.creationForm.value.mainPageImage && this.creationForm.value.importantEvent) {
      form.main_photo_id = this.creationForm.value.mainPageImage.fileId;
    } else {
      form.main_photo_id = null;
    }
    form.photo_id = this.creationForm.value.mainImage.fileId;
    if (this.creationForm.value.eventPageUrl) {
      form.schedule_link = this.creationForm.value.eventPageUrl;
    }
    return form;
  }

  private parseDate = function(date) {
    return moment(date).format('YYYY-MM-DD');
  }

  private parseTime = function(str, index) {
    return getApiTime(str.split('-')[index]);
  }

  private escape = function (string) {
    let escaped = $("<div>").text(string).html();
    return escaped;
  }

  private encode = function (string) {
    let encoded = $("<div>").html(string).text();
    return encoded;
  }
}
