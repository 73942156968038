import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class EventsService {
  constructor(private api: ApiService) {

  }

  create(model) {
    return this.api.post('v1/events/new', JSON.stringify(model));
  }

  edit(eventId, model) {
    return this.api.post(`v1/events/${eventId}/edit`, JSON.stringify(model));
  }

  deleteEvent(eventId) {
    return this.api.delete(`v1/events/${eventId}/delete`);
  }

  getEventsList(filter) {
    return this.api.post('v2/events/list', JSON.stringify(filter));
  }

  getAdditional(ids) {
    return this.api.get('v1/events/list/additional', {eventIds: ids});
  }

  getEvent(eventId) {
    return this.api.get(`v1/events/${eventId}`);
  }

  getComments(eventId) {
    return this.api.get(`v1/events/${eventId}/comments`);
  }

  sendComment(eventId, model) {
    return this.api.post(`v1/events/${eventId}/comments`, JSON.stringify(model));
  }

  sendReply(eventId, commentId, model) {
    return this.api.post(`v1/events/${eventId}/comments/${commentId}/reply`, JSON.stringify(model));
  }

  deleteComment(eventId, commentId) {
    return this.api.delete(`v1/events/${eventId}/comments/${commentId}`)
  }

  likeComment(eventId, commentId, value) {
    return this.api.post(`v1/events/${eventId}/comments/${commentId}/like`, undefined, { favorite: value });
  }

  archive(eventId, archive) {
    return this.api.delete(`v1/events/${eventId}/archive`, { archive })
  }

  register(eventId, model) {
    return this.api.post(`v1/events/${eventId}/register`, JSON.stringify(model));
  }

  getRegisteredUsers(eventId) {
    return this.api.get(`v1/events/${eventId}/users`);
  }

  paid(eventId, userId, paid) {
    return this.api.post(`v1/events/${eventId}/users/paid`, undefined, { userId, paid });
  }

  attend(eventId, userId, attended) {
    return this.api.post(`v1/events/${eventId}/users/attend`, undefined, { userId, attended });
  }

  cancelEvent(eventId, model) {
    return this.api.post(`v1/events/${eventId}/cancel`, model);
  }

}
