import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { SubscriptionsService } from '../../services/subscriptions.service';
import { ActivatedRoute } from "@angular/router";
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-modal-payment-options',
  templateUrl: './modal-payment-options.component.html',
  styleUrls: ['./modal-payment-options.component.styl']
})
export class ModalPaymentOptionsComponent implements OnInit {
  @Input() type: 'subscription' | 'reservation';
  @Input() subscriptionParams: any;
  fabricHoursLeft = 0;
  isLoading;
  isError;
  constructor (
    public modalService: ModalService,
    private subscriptionsService: SubscriptionsService,
    private userService: UserService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    if (this.type === 'subscription') {
      if (!this.subscriptionParams) {
        this.subscriptionParams = {
          subscriptionTime: this.route.snapshot.queryParams.subscriptionTime,
          subscriptionTimeUnit: this.route.snapshot.queryParams.subscriptionTimeUnit,
          subscriptionPrice: this.route.snapshot.queryParams.subscriptionPrice
        }
      }
    }
  }

  orderSubscription(payNow) {
    let model = {
      amount: this.subscriptionParams.subscriptionTime,
      subscription_type: this.subscriptionParams.subscriptionTimeUnit === 'hours' ? 'HOURLY' : 'DAILY',
      payment_type: payNow ? 'ONLINE' : 'ON_THE_SPOT'
    }
    this.isLoading = true;
    this.isError = false;
    this.subscriptionsService.newSubscription(model).subscribe((data) => {
      this.isLoading = false;
      this.userService.addSubscription(data.result.amount, data.result.subscription_type === 'HOURLY' ? 'hours' : 'days');
      this.modalService.nextPage({
        subscriptionPaid: payNow,
        subscriptionParams: this.subscriptionParams
      }, null);
    }, () => {
      this.isError = true;
      this.isLoading = false;
    })
  }
}
