import { Component, OnInit, Input } from '@angular/core';
import { CommentsService } from '../../services/comments.service';
import { first } from 'rxjs/operators';
import { TranslitService } from '../../services/translit.service';

@Component({
  selector: 'app-profile-comments',
  templateUrl: './profile-comments.component.html',
  styleUrls: ['./profile-comments.component.styl']
})
export class ProfileCommentsComponent implements OnInit {
  @Input() userId;
  newComments;
  oldComments;
  showNewComments = true;
  isLoading;
  isError;
  commentsList;
  sortFilterParam = [
    {
      title: `новые`,
      value: 'new'
    },
    {
      title: 'прочитанные',
      value: 'old'
    }
  ];
  searchText;

  constructor(private commentsService: CommentsService,
              public translitService: TranslitService) { }

  ngOnInit() {
    this.filterChange('new');
  }

  getComments() {
    this.isLoading = true;
    this.commentsService.getCommentForMaster(this.showNewComments, this.userId).pipe(first()).subscribe((data) => {
      if (this.showNewComments) {
        this.newComments =  data.result;
        this.newComments.sort((a, b) => b.created_date - a.created_date);
        this.filterOwner(this.newComments);
        this.commentsList = this.newComments;
        this.sortFilterParam[0].title = `новые (${this.newComments.length})`;
      } else {
        this.oldComments = data.result;
        this.oldComments.sort((a, b) => b.created_date - a.created_date);
        this.filterOwner(this.oldComments);
        this.commentsList = this.oldComments;
      }
      this.isLoading = false;

    }, () => {
      this.isError = true;
      this.isLoading = false;
    });
  }

  filterOwner(comments) {
    for (let i = 0; i < comments.length; i++ ) {
      if (!comments[i].owner) {
        comments[i].owner = {id: null};
      }
    }
  }

  filterChange(e) {
    this.searchText = null;
    this.showNewComments = e !== 'old';
    this.getComments();
  }

  onSearch(evt) {
    this.searchText = evt || '';
    let comments = this.showNewComments ? this.newComments : this.oldComments;
    this.commentsList = comments.filter(item => this.filterCheck(item.text, this.searchText));
  }

  filterCheck(word, evt) {
    return !evt || !!(word.toLowerCase().indexOf(evt.toLowerCase())+1);
  }

  clearSearchQuery() {
    this.onSearch('');
  }


}
