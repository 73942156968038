import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-add-social-item',
  templateUrl: './add-social-item.component.html',
  styleUrls: ['./add-social-item.component.styl']
})
export class AddSocialItemComponent implements OnInit {
  @Input() control;
  @Input() socialTypes;
  @Output() removeEmitter = new EventEmitter();
  @Output() changeEmitter = new EventEmitter();
  pickerOpened;
  placeholders: any = {
    facebook: 'https://facebook.com/...',
    vk: 'https://vk.com/...',
    whatsapp: '+7 (000) 123 45 67',
    telegram: 'Username',
    viber: '+7 (000) 123 45 67',
    google_plus: 'https://plus.google.com/...',
    twitter: 'https://twitter.com/...'
  }
  constructor() { }

  ngOnInit() {
  }

  openPicker() {
    if (this.socialTypes && this.socialTypes.length) {
      this.pickerOpened = true;
    }
  }

  closePicker() {
    this.pickerOpened = false;
  }

  togglePicker() {
    if (this.pickerOpened) {
      this.closePicker();
    } else {
      this.openPicker();
    }
  }

  changeSocialType(type) {
    this.control.type.setValue(type);
    this.closePicker();
    this.changeEmitter.emit();
    this.control.contact.reset();
  }

  removeSocial() {
    this.removeEmitter.emit();
  }

}
