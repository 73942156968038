import { Injectable } from '@angular/core';
import { ModalService } from './modal.service';

@Injectable({
  providedIn: 'root'
})
export class ClientPopupService {
  popupOpened;
  currentClient;
  constructor(private modalService: ModalService) { }

  openClientModal(clientId) {
    this.modalService.freezBody();
    this.popupOpened = true;
    this.currentClient = clientId;
    // this.creationSubject = subject;
  }

  closeClientModal(noRedirects?) {
    this.modalService.unfreezBody();
    this.popupOpened = false;
    this.currentClient = null;
    if (!noRedirects) {
      this.modalService.onClose();
    } else {
      this.modalService.isModalOpened = false;
    }
  }

}
