import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { getDatesDiff } from '../../utils';

@Component({
  selector: 'app-storage-products-table',
  templateUrl: './storage-products-table.component.html',
  styleUrls: ['./storage-products-table.component.styl']
})
export class StorageProductsTableComponent implements OnInit {
  @Output() updateEmitter = new EventEmitter();
  tabsActive = 1;
  today = new Date();
  overdueList;
  occupiedList;
  pausedList;
  isLoading;
  error;
  searchQuery;
  getDatesDiff = getDatesDiff;
  confirmData: any;
  confirmOpened;
  constructor(private storageService: StorageService) { }

  ngOnInit() {
    this.updateProducts();
  }

  updateProducts() {
    this.isLoading = true;
    this.storageService.getStorageList().subscribe((data) => {
      this.overdueList = data.result.filter(item => {
        return item.status === 'RUNNING' && item.overdue && item.slot_identifier;
      })
      this.occupiedList = data.result.filter(item => {
        return item.status === 'RUNNING' && !item.overdue && item.slot_identifier;
      }).sort((a, b) => {
        return (b.no_paid_sum || 0) - (a.no_paid_sum || 0);
      })
      this.pausedList = data.result.filter(item => {
        return item.status === 'PAUSED';
      })
      this.isLoading = false;
      this.updateEmitter.emit();
    }, () => {this.error = true; this.isLoading = false})
  }

  switchTab(num) {
    this.tabsActive = num;
  }

  onSearchInput(query) {
    this.searchQuery = query;
  }

  onSearchClose() {
    this.searchQuery = '';
  }

  onExtend(days, item) {
    this.isLoading = true;
    this.storageService.prolongSession([{
      slot_identifier: item.slot_identifier,
      days: days
    }], item.owner.id).subscribe(() => {
      this.updateProducts();
    }, () => {this.error = true; this.isLoading = false})
  }

  onComment(comment, item) {
    this.isLoading = true;
    this.storageService.phoned(true, item.slot_identifier, comment).subscribe(() => {
      this.updateProducts();
    }, () => {this.error = true; this.isLoading = false})
  }

  pauseSession(item) {
    this.isLoading = true;
    this.storageService.pauseSession(item.id).subscribe(() => {
      this.updateProducts();
    }, () => {this.error = true; this.isLoading = false})
  }

  restartSession(item, form) {
    this.isLoading = true;
    this.storageService.restartSession(item.id, form.cell, form.duration).subscribe(() => {
      this.updateProducts();
    }, () => {this.error = true; this.isLoading = false})
  }

  sessionPaid(item) {
    this.storageService.paid(item.id, true).subscribe((data) => {
      item.paid = data.result;
    })
  }

  finishSession(item) {
    this.isLoading = true;
    this.storageService.finishSession(item.id).subscribe(() => {
      this.updateProducts();
    }, () => {this.error = true; this.isLoading = false})
  }

  initConfirmData(data) {
    this.confirmData = {};
    this.confirmData.title = 'Подтверждение оплаты';
    this.confirmData.confirmText = 'ПОДТВЕРДИТЬ';
    this.confirmData.rejectText = 'ОТМЕНА';
    this.confirmData.htmlContent = `Вы освобождаете ячейку раньше запланированного срока. Пожалуйста, подтвердите получение от клиента оплаты за использованное время хранения <b>(${'2 дня'}).</b>
                                    <br><br>
                                    <b>Ячейка ${123}</b>
                                    <br>
                                    Клиент: ${'Клара'}
                                    <br>
                                    Оплата: <b>${'100'}</b> ₽`;
  }


}
