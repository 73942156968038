import { Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { phoneMask, parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';
import { UserService } from '../../services/user.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IsBrowserService } from '../../services/is-browser.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-phone-confirm',
  templateUrl: './phone-confirm.component.html',
  styleUrls: ['./phone-confirm.component.styl']
})
export class PhoneConfirmComponent implements OnInit {
  @Input() isChange;
  @Output() onSuccess = new EventEmitter();
  phoneForm: FormGroup;
  titleText: string;
  isReg: boolean;
  phoneMask = phoneMask;
  setCode: boolean;
  error:boolean;
  phone;
  user;
  confirmationRequestLoading;
  confirmationRequestSent;
  confirmationLoading;
  incorrectCode;
  isError;
  isLoading;
  errorMessage;
  codeMask = [/\d/, /\d/, /\d/, /\d/];
  tooManyRequests;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private messagesService: MessagesService,
              private isBrowserService: IsBrowserService,
              private _ref: ChangeDetectorRef,
              private authService: AuthService) { }

  ngOnInit() {
    this.user = this.userService.userData;
    if (this.user && this.user.phone && !this.isChange) {
      this.phone = this.user.phone;
      if (this.isBrowserService.isBrowser) {
        this.getConfirmationCode();
      }
    } else {
      if (this.isBrowserService.isBrowser) {
        setTimeout(() => {
          document.getElementById('confirm-phone-input').focus();
        });
      }
    }
    this.initForm();
  }

  initForm() {
    this.phoneForm = this.fb.group({
      phone: ['', [Validators.required]],
      agree: [false, [Validators.requiredTrue]],
      code: ['', [Validators.required, Validators.pattern('[0-9]{4}')]]
    });
  }

  changePhone() {
    console.log('change phone');
    this.phone = null;
    this.confirmationRequestSent = false;
    this.phoneForm.controls.code.reset();
    this.onFormChange();
    this.tooManyRequests = false;
    this._ref.detectChanges();
    setTimeout(() => {
      document.getElementById('confirm-phone-input').focus();
    });
  }

  submitPhone() {
    this.phone = this.phoneForm.value.phone.split(' ').join('').trim();
    this.getConfirmationCode();
  }

  getConfirmationCode() {
    this.isLoading = true;
    this.userService.getPhoneConfirmationCode(this.phone).pipe(first()).subscribe(data => {
      this.confirmationRequestSent = true;
      this.isLoading = false;
      this._ref.detectChanges();
      setTimeout(() => {
        document.getElementById('confirm-code-input').focus();
      }, 100);
    }, () => {
      this.isLoading = false;
      this.isError = false;
      this.tooManyRequests = true;
    })
  }

  submitCode() {
    let model = {
      phone: this.phone,
      confirmationCode: this.phoneForm.value.code
    }
    this.isLoading = true;
    this.userService.confirmPhone(model).pipe(first()).subscribe((data) => {
      this.onSuccess.emit();
      this.isLoading = false;
    }, (e) => {
      this.isLoading = false;
      let error = parseApiError(e);
      if (error === 'Invalid phone or confirmation code') {
        this.isError = true;
        this.errorMessage = 'Это неверный код. Пожалуйста, введите код, который мы только что выслали.';
      } else if (error.code === 'duplicate-phone') {
        this.isError = true;
        this.errorMessage = 'Этот телефон уже используется.';
      } else {
        this.isError = true;
        this.errorMessage = 'Ошибка.';
      }
    })
  }

  onFormChange() {
    this.incorrectCode = false;
    this.isError = false;
  }

  submitForm(evt) {
    console.log(evt);
    evt.preventDefault();
    evt.stopPropagation();
  }
}
