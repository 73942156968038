import { Pipe } from '@angular/core';

@Pipe({
  name: 'clientsFilter'
})
export class ClientsFilterPipe {

  transform(items: any[], filter: string) {
    if (!items || !filter) {
      return items;
    }
    let result;

    switch(filter) {
      case 'withsubscription':
        result = items.filter((item) => {
          return item.subscription_days || item.subscription_hours;
        });
        break;
      case 'withoutsubscription':
        result = items.filter((item) => {
          return !item.subscription_days && !item.subscription_hours;
        });
        break;
      default:
        result = items;
        break;
      }
    return result;
  }

}
