import { Component, OnInit, Input, ViewChild, ElementRef, HostListener } from '@angular/core';

@Component({
  selector: 'app-multiple-string-ellipsis',
  templateUrl: './multiple-string-ellipsis.component.html',
  styleUrls: ['./multiple-string-ellipsis.component.styl']
})
export class MultipleStringEllipsisComponent implements OnInit {
  @Input() text: string;
  @Input() strings: number = 2;
  @Input() stringHeight: number = 24;
  @ViewChild('firststring') firstString: ElementRef;
  @ViewChild('content') content: ElementRef;
  textHidden: boolean = false;
  string1: string = '';
  string2: string = '';
  finalString1: string = '';
  constructor() { }

  @HostListener('window:resize') onResize() {
    if (this.text) {
      this.splitByStrings(this.text, this.strings);
    }
  }

  ngOnInit() {
    if (this.text) {
      this.splitByStrings(this.text, this.strings);
    }
  }

  ngAfterViewInit() {
    if (!this.text) {
      this.text = this.content.nativeElement.textContent.trim();
    }
    setTimeout(() => {
      this.splitByStrings(this.text, this.strings);
    })
  }

  private splitByStrings = function(text, stringsNumber) {
    let arr = text.trim().split(' ');
    arr = arr.map((word, index) => {
      if (index < arr.length - 1) {
        return word + ' ';
      } else return word
    })
    let newArr = [];
    arr.forEach(item => {
      if (item.indexOf('-') + 1) {
        let parts = item.split('-');
        parts = parts.map((part, index) => {
          if (index < parts.length - 1) {
            return part + '-';
          } else return part;
        })
        newArr = [...newArr, ...parts];
      } else newArr.push(item);
    })
    arr = newArr;
    this.string1 = '';
    let previousVal;
    let stringHeight;
    let stop = false;
    let currentNumber;
    this.firstString.nativeElement.style.lineHeight = this.stringHeight + 'px';
    arr.forEach((item, index) => {
      if (!stop) {
        previousVal = this.string1;
        this.string1 += item;
        this.firstString.nativeElement.innerText = this.string1;
      }
      if (index === 0) {
        stringHeight = this.stringHeight;
        currentNumber = 1;
      }
      if (stringHeight < this.firstString.nativeElement.offsetHeight) {
        while (stringHeight < this.firstString.nativeElement.offsetHeight) {
          currentNumber++;
          stringHeight = currentNumber * this.stringHeight;
        }
      }
      if (!stop && (currentNumber >= stringsNumber)) {
        stop = true;
        this.string1 = previousVal;
        if (this.string1 != this.finalString1) {
          this.finalString1 = " " + this.string1;
          this.firstString.nativeElement.innerText = previousVal;
          let arr2 = arr.slice(index, arr.length);
          setTimeout(() => {
            this.string2 = arr2.join('');
          }, 0)
        }
      }
    })
  }

}
