import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-toggle-came',
  templateUrl: './toggle-came.component.html',
  styleUrls: ['./toggle-came.component.styl']
})
export class ToggleCameComponent implements OnInit {
  @Input() active = false;
  @Input() type;
  @Output() toggleEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  toggle() {
    this.toggleEmitter.emit();
  }


}
