import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-schedule-picker',
  templateUrl: './schedule-picker.component.html',
  styleUrls: ['./schedule-picker.component.styl']
})
export class SchedulePickerComponent implements OnInit {
  constructor() { }
  @Input() file;
  @Output() addingFileEmitter = new EventEmitter();
  // @Output() addingFileEmitter = new EventEmitter();
  limit = 1;
  uploader;

  public hasDropZoneOver:boolean = false;

  public fileOver(e:any):void {
    this.hasDropZoneOver = e;
  }
  ngOnInit() {
    this.uploader = new FileUploader({});
    this.uploader.onAfterAddingFile = (item:any, response:any, status:any, headers:any) => {
      if ((this.limit && this.uploader.queue.length > this.limit) || this.uploader.queue[0].file.type !== 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
        this.uploader.queue.pop();
      } else {
        this.addingFileEmitter.emit({file: this.uploader.queue[0].file, replace: !!this.file.file});
        this.uploader.clearQueue();
      }
    };
  }

  removeFile(index) {
    this.uploader.queue.splice(index, 1);
  }
}
