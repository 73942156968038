import { Pipe } from '@angular/core';

@Pipe({
  name: 'mastersSort'
})
export class MastersSortPipe {

  transform(items: any[], param: string, direction: boolean) {
    let callback;
    function masterSortCallback(a, b) {
      if (!a.master.last_name && !a.master.first_name) {
        return 1;
      }
      if (!b.master.last_name && !b.master.first_name) {
        return -1;
      }
      if (`${(a.master.last_name || '').toLowerCase()} ${(a.master.first_name || '').toLowerCase()}`.trim() > `${(b.master.last_name || '').toLowerCase()} ${(b.master.first_name || '').toLowerCase()}`.trim()) {
        return direction ? 1 : -1;
      }
      if (`${(a.master.last_name || '').toLowerCase()} ${(a.master.first_name || '').toLowerCase()}`.trim() < `${(b.master.last_name || '').toLowerCase()} ${(b.master.first_name || '').toLowerCase()}`.trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }
    function ideasSortCallback(a, b) {
      let result = a.ideas_count - b.ideas_count;
      return direction ? result : -result;
    }
    function coursesSortCallback(a, b) {
      let result = a.courses_count - b.courses_count;
      return direction ? result : -result;
    }
    function consultationsSortCallback(a, b) {
      let result = a.consultations_count - b.consultations_count;
      return direction ? result : -result;
    }
    function priceSortCallback(a, b) {
      let result = a.total_sum - b.total_sum;
      return direction ? result : -result;
    }
    function defaultSortCallback(a, b) {
      return 0;
    }

    if (!items) {
      return items;
    }

    switch(param) {
      case 'master':
        callback = masterSortCallback;
        break;
      case 'ideas':
        callback = ideasSortCallback;
        break;
      case 'courses':
        callback = coursesSortCallback;
        break;
      case 'consults':
        callback = consultationsSortCallback;
        break;
      case 'price':
        callback = priceSortCallback;
        break;
      default:
        callback = defaultSortCallback;
        break;
      }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.sort(callback);
  }

}
