import { Pipe } from '@angular/core';

@Pipe({
  name: 'sortByDates'
})
export class SortByDatesPipe {
  private sortByDates = function(events) {
    function groupByDay(arr) {
      function dropTime(date) {
        return new Date(date).setHours(0,0,0,0);
      }
      let result = [];
      let currentDay = dropTime(new Date(arr[0].start_date));
      result.push([arr[0]]);
      if (arr.length > 1) {
        for (let i = 1; i < arr.length; i++) {
          if (dropTime(new Date(arr[i].start_date)) === currentDay) {
            result[result.length - 1].push(arr[i]);
          } else {
            currentDay = dropTime(new Date(arr[i].start_date));
            result.push([arr[i]]);
          }
        }
      }
      return result;
    }
    if (events && events.length) {
      let sorted = events.sort((a, b) => {
        return +(new Date(a.start_date) > new Date(b.start_date)) * 2 - 1;
      })
      return groupByDay(sorted);
    } else {
      return [];
    }

  }

  transform(items: any[], param: string) {
    if (!items) {
      return items;
    }
    if (!param) {
      param = 'future';
    }

    // filter items array, items which match and return true will be kept, false will be filtered out
    return this.sortByDates(items);
  }
}
