import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-storage-chart',
  templateUrl: './storage-chart.component.html',
  styleUrls: ['./storage-chart.component.styl']
})
export class StorageChartComponent implements OnInit {
  @Input() data;
  constructor() { }

  ngOnInit() {
  }

}
