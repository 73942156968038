import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextMaskModule } from 'angular2-text-mask';
import { PipesModule } from '../pipes/pipes.module';
import { FileUploadModule } from 'ng2-file-upload';
import { HttpClientModule } from '@angular/common/http';

// components
import { UserShortComponent } from '../../shared/user-short/user-short.component';
import { MasterPreviewComponent } from '../../shared/master-preview/master-preview.component';
import { ShareButtonComponent } from '../../shared/share-button/share-button.component';
import { ReserveInstrumentComponent } from '../../shared/reserve-instrument/reserve-instrument.component';
import { CalendarComponent } from '../../shared/calendar/calendar.component';
import { AreaIconComponent } from '../../shared/area-icon/area-icon.component';
import { LfInputNumberComponent } from '../../shared/lf-input-number/lf-input-number.component';
import { ProfileCardComponent } from '../../shared/profile-card/profile-card.component';
import { AdminUserComponent } from '../../shared/admin-user/admin-user.component';
import { AdminMasterComponent } from '../../shared/admin-master/admin-master.component';
import { ProfileImageComponent } from '../../shared/profile-image/profile-image.component';
import { ProfileInfoItemComponent } from '../../shared/profile-info-item/profile-info-item.component';
import { RegisterUserToComponent } from '../../shared/register-user-to/register-user-to.component';
import { SortingFilterComponent } from '../../shared/sorting-filter/sorting-filter.component';
import { SelectMonthWithPopupComponent } from '../../shared/select-month-with-popup/select-month-with-popup.component';
import { AdminCalendarItemComponent } from '../../shared/admin-calendar-item/admin-calendar-item.component';
import { EventTimeComponent } from '../../shared/event-time/event-time.component';
import { SessionControlPanelComponent } from '../../shared/session-control-panel/session-control-panel.component';
import { SubscriptionDiagramComponent } from '../../shared/subscription-diagram/subscription-diagram.component';
import { StorageDiagramComponent } from '../../shared/storage-diagram/storage-diagram.component';
import { ToReturnComponent } from '../../shared/to-return/to-return.component';
import { ProfileMasterCalendarComponent } from '../../shared/profile-master-calendar/profile-master-calendar.component';
import { ProfileMasterHistoryComponent } from '../../shared/profile-master-history/profile-master-history.component';
import { ProfileMasterIdeasComponent } from '../../shared/profile-master-ideas/profile-master-ideas.component';
import { TogglePaidComponent } from '../../shared/toggle-paid/toggle-paid.component';
import { ToggleCameComponent } from '../../shared/toggle-came/toggle-came.component';
import { SortingFilterWithIconsComponent } from '../../shared/sorting-filter-with-icons/sorting-filter-with-icons.component';
import { MasterCalendarItemComponent } from '../../shared/master-calendar-item/master-calendar-item.component';
import { TextSearchComponent } from '../../shared/text-search/text-search.component';
import { TextSearchMobileComponent } from '../../shared/text-search-mobile/text-search-mobile.component';
import { EventCalendarItemComponent } from '../../shared/event-calendar-item/event-calendar-item.component';
import { IdeaCardComponent } from '../../shared/idea-card/idea-card.component';
import { CoursesListOptionComponent } from '../../shared/courses-list-option/courses-list-option.component';
import { ConsultationsListOptionComponent } from '../../shared/consultations-list-option/consultations-list-option.component';
import { EquipmentsListOptionsComponent } from '../../shared/equipments-list-options/equipments-list-options.component';
import { EventsListOptionsComponent } from '../../shared/events-list-options/events-list-options.component';
import { LikeButtonComponent } from '../../shared/like-button/like-button.component';
import { DoneButtonComponent } from '../../shared/done-button/done-button.component';
import { MultipleStringEllipsisComponent } from '../../shared/multiple-string-ellipsis/multiple-string-ellipsis.component';
import { ConsultDatePickerComponent } from '../../shared/consult-date-picker/consult-date-picker.component';
import { AdminSearchComponent } from '../../shared/admin-search/admin-search.component';
import { AdminSearchResultItemComponent } from '../../shared/admin-search-result-item/admin-search-result-item.component';
import { StorageChartComponent } from '../../shared/storage-chart/storage-chart.component';
import { LineChartComponent } from '../../shared/line-chart/line-chart.component';
import { StorageProductsCommentComponent } from '../../shared/storage-products-comment/storage-products-comment.component';
import { StorageProductsExtendComponent } from '../../shared/storage-products-extend/storage-products-extend.component';
import { StorageProductsPlayComponent } from '../../shared/storage-products-play/storage-products-play.component';
import { StorageProductsTableComponent } from '../../shared/storage-products-table/storage-products-table.component';
import { ConfirmPopupComponent } from '../../shared/confirm-popup/confirm-popup.component';
import { RadioFilterComponent } from '../../shared/radio-filter/radio-filter.component';
import { CheckboxSelectComponent } from '../../shared/checkbox-select/checkbox-select.component';
import { SelectMonthComponent } from '../../shared/select-month/select-month.component';
import { CourseDatesComponent } from '../../shared/course-dates/course-dates.component';
import { SubsriptionsCardComponent } from '../../shared/subsriptions-card/subsriptions-card.component';
import { FileLinkComponent } from '../../shared/file-link/file-link.component';
import { SubscriptionCardsComponent } from '../../shared/subscription-cards/subscription-cards.component';
import { SubscriptionServicesPricesComponent } from '../../shared/subscription-services-prices/subscription-services-prices.component';
import { SubscriptionServicesPriceComponent } from '../../shared/subscription-services-price/subscription-services-price.component';
import { ModalRemoveClientComponent } from '../../shared/modal-remove-client/modal-remove-client.component';
import { SeoBlockComponent } from '../../shared/seo-block/seo-block.component';
import { NativeVideoPreviewComponent } from '../../shared/native-video-preview/native-video-preview.component';
import { PhoneConfirmComponent } from '../../shared/phone-confirm/phone-confirm.component';
import { NameSurnameConfirmComponent } from '../../shared/name-surname-confirm/name-surname-confirm.component';
import { ProfileCommentsComponent } from '../../shared/profile-comments/profile-comments.component';
// directives
import { AutosizeDirective } from '../../directives/autosize.directive';
import { ImagePreviewDirective } from '../../directives/image-preview.directive';
import { LmCustomSelectDirective } from '../../directives/lm-custom-select.directive';
import { LmInputDirective } from '../../directives/lm-input.directive';
import { LmTextareaDirective } from '../../directives/lm-textarea.directive';
import { TextPopupDirective } from '../../directives/text-popup.directive';
import { TimeAutocompleteDirective } from '../../directives/time-autocomplete.directive';
import { VideoPreviewDirective } from '../../directives/video-preview.directive';
import { ScrollUpDirective } from '../../directives/scroll-up.directive';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { InitActionDirective } from '../../directives/init-action.directive';
import { CommentsComponent } from '../../shared/comments/comments.component';
import { CommentAuthorComponent } from '../../shared/comment-author/comment-author.component';
import { CommentItemComponent } from '../../shared/comment-item/comment-item.component';
import { WriteCommentComponent } from '../../shared/write-comment/write-comment.component';
import { NgxImageGalleryModule } from 'ngx-image-gallery';
import { MasterAddMenuComponent } from '../../shared/master-add-menu/master-add-menu.component';
import { AutoFocusDirective } from '../../directives/auto-focus.directive';
import { SortButtonComponent } from '../../shared/sort-button/sort-button.component';

const sharedModules = [
  // components
  MasterAddMenuComponent,
  CommentsComponent,
  CommentAuthorComponent,
  CommentItemComponent,
  WriteCommentComponent,
  ProfileCommentsComponent,
  MasterPreviewComponent,
  UserShortComponent,
  ShareButtonComponent,
  ReserveInstrumentComponent,
  CalendarComponent,
  AreaIconComponent,
  LfInputNumberComponent,
  ProfileCardComponent,
  AdminUserComponent,
  AdminMasterComponent,
  ProfileImageComponent,
  ProfileInfoItemComponent,
  RegisterUserToComponent,
  SortingFilterComponent,
  SelectMonthWithPopupComponent,
  AdminCalendarItemComponent,
  EventTimeComponent,
  SessionControlPanelComponent,
  SubscriptionDiagramComponent,
  StorageDiagramComponent,
  ToReturnComponent,
  ProfileMasterCalendarComponent,
  ProfileMasterHistoryComponent,
  ProfileMasterIdeasComponent,
  TogglePaidComponent,
  ToggleCameComponent,
  SortingFilterWithIconsComponent,
  MasterCalendarItemComponent,
  TextSearchComponent,
  TextSearchMobileComponent,
  EventCalendarItemComponent,
  IdeaCardComponent,
  CoursesListOptionComponent,
  ConsultationsListOptionComponent,
  EquipmentsListOptionsComponent,
  EventsListOptionsComponent,
  LikeButtonComponent,
  DoneButtonComponent,
  MultipleStringEllipsisComponent,
  ConsultDatePickerComponent,
  AdminSearchComponent,
  AdminSearchResultItemComponent,
  StorageChartComponent,
  LineChartComponent,
  StorageProductsCommentComponent,
  StorageProductsExtendComponent,
  StorageProductsPlayComponent,
  StorageProductsTableComponent,
  ConfirmPopupComponent,
  RadioFilterComponent,
  CheckboxSelectComponent,
  SelectMonthComponent,
  CourseDatesComponent,
  SubsriptionsCardComponent,
  FileLinkComponent,
  SubscriptionCardsComponent,
  SubscriptionServicesPricesComponent,
  SubscriptionServicesPriceComponent,
  ModalRemoveClientComponent,
  SeoBlockComponent,
  NativeVideoPreviewComponent,
  PhoneConfirmComponent,
  NameSurnameConfirmComponent,
  SortButtonComponent,
  // directives
  AutosizeDirective,
  ImagePreviewDirective,
  LmCustomSelectDirective,
  LmInputDirective,
  LmTextareaDirective,
  TextPopupDirective,
  TimeAutocompleteDirective,
  VideoPreviewDirective,
  ScrollUpDirective,
  ClickOutsideDirective,
  InitActionDirective,
  AutoFocusDirective
]

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TextMaskModule, PipesModule, FileUploadModule, HttpClientModule, NgxImageGalleryModule ],
  declarations: sharedModules,
  exports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TextMaskModule, PipesModule, FileUploadModule, HttpClientModule, NgxImageGalleryModule, ...sharedModules]
})

export class GlobalModule {}
