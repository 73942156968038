import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { EquipmentsService } from '../../services/equipments.service';
import { getGreenwichDate } from '../../utils';
import { NotificationsService} from '../../services/notifications.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-modal-breakdown-equipment',
  templateUrl: './modal-breakdown-equipment.component.html',
  styleUrls: ['./modal-breakdown-equipment.component.styl']
})
export class ModalBreakdownEquipmentComponent implements OnInit {
  currentStep: number = 0;
  state:any = {};
  equipmentId;
  isLoading;
  error;
  errorMessage;
  descriptionForm;
  registerLoading;

  constructor(private router: Router,
              private modalService: ModalService,
              private equipmentsService: EquipmentsService,
              private activatedRoute: ActivatedRoute,
              private notificationsService: NotificationsService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.equipment) {
      this.equipmentId = this.activatedRoute.snapshot.queryParams.equipment;
      this.selectEquipment(this.equipmentId);
    }
  }

  selectEquipment(equipmentId) {
    this.isLoading = true;
    this.equipmentsService.getEquipment(equipmentId).pipe(first()).subscribe((data) => {
      if (data.result.broken) {
        this.error = true;
        this.errorMessage = 'Поломка уже зарегистрирована.';
      } else {
        this.nextStep({equipment: data.result});
      }
    }, () => this.error = true, () => this.isLoading = false);
  }

  onBreakdownDescriptionChange(evt) {
    this.descriptionForm = evt;
  }

  regBreakdown() {
    let model: any = {
      broken_equipment_id: this.state.equipment.id,
      repair_date: getGreenwichDate(this.descriptionForm.value.date),
      replacement_exists: this.descriptionForm.value.replacement
    }
    if (this.descriptionForm.value.comment) {
      model.comment = this.descriptionForm.value.comment;
    }
    if (model.replacement_exists && this.descriptionForm.value.equipment.equipment_id) {
      model.replacing_euqipment_id = this.descriptionForm.value.equipment.equipment_id;
    }
    this.registerLoading = true;
    this.equipmentsService.registerBreakdown(model).pipe(first()).subscribe((data) => {
      this.notificationsService.updateEmitter();
      if (data.result.notification_id) {
        this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'notify-equipment-break', notification: data.result.notification_id}});
      } else {
        this.modalService.nextPage(null, null);
      }
    }, () => {this.error = true; this.registerLoading = false})
  }

  cancel() {
    this.modalService.onClose();
  }

  nextStep(state) {
    this.currentStep++;
    this.state = {
      ...this.state,
      ...state
    };
  }

}
