import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { IdeasService } from '../../services/ideas.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { Subject } from 'rxjs';
import { debounceTime, tap, switchMap } from 'rxjs/operators';
import { UserService } from '../../services/user.service';

const USER_MASTER_IDEAS_KEY = makeStateKey('user-master-ideas');
const USER_MASTER_IDEAS_PAGE_KEY = makeStateKey('user-master-ideas-page');
@Component({
  selector: 'app-profile-master-ideas',
  templateUrl: './profile-master-ideas.component.html',
  styleUrls: ['./profile-master-ideas.component.styl']
})
export class ProfileMasterIdeasComponent implements OnInit {

  sortParams: any[] = [
    {
      title: 'избранные',
      value: 'only_my_favorite',
      iconClass: 'liked'
    },{
      title: 'сделанные',
      value: 'only_realized_by_me',
      iconClass: 'done'
    },{
      title: 'мои',
      value: 'only_invented_by_me',
      iconClass: 'my-ideas'
    }
  ];
  @Input() userId;
  ideasList;
  filterModel: any = {
    only_favorite: true,
    only_realized: false,
    page: 1
  };
  ideasLoading;
  searchQuery;
  ideasSubject$;
  lastPage;
  isMe;
  constructor(private ideasService: IdeasService,
              private state: TransferState,
              private userService: UserService) { }

  ngOnInit() {
    if (this.state.get(USER_MASTER_IDEAS_PAGE_KEY, null as any)) {
      this.filterModel.page = this.state.get(USER_MASTER_IDEAS_PAGE_KEY, null as any);
    }
    if (this.userId) {
      this.filterModel.user_id = this.userId;
      this.isMe = this.userId == this.userService.userId;
    } else {
      this.isMe = true;
    }
    this.ideasSubject$ = new Subject();
    this.ideasSubject$.pipe(debounceTime(100), tap(() => { this.ideasLoading = true }), switchMap(model => this.ideasService.getIdeasList(model)))
      .subscribe(data => {
          this.filterModel.page++;
          this.state.set(USER_MASTER_IDEAS_PAGE_KEY, this.filterModel.page as any);
          if (!data.result.data.length || data.result.data.length < 20) {
            this.lastPage = true;
          }
          if (!this.ideasList) {
            this.state.set(USER_MASTER_IDEAS_KEY, data.result as any);
          }
          if (this.ideasList) {
            this.ideasList = [...this.ideasList, ...data.result.data];
          } else {
            this.ideasList = data.result.data;
          }
          this.ideasLoading = false;
        }
      )
    if (!this.ideasList) {
      this.ideasSubject$.next(this.filterModel);
    }
  }

  ngOnDestroy() {
    this.state.set(USER_MASTER_IDEAS_KEY, null as any);
    this.state.set(USER_MASTER_IDEAS_PAGE_KEY, null as any);
  }

  updateIdeas(nextPage?) {
    if (!nextPage) {
      this.ideasList = null;
      this.filterModel.page = 1;
      this.lastPage = false;
    }
    this.ideasSubject$.next(this.filterModel);
  }

  nextPage() {
    this.updateIdeas(true);
  }


  filterChange(filter) {
    if (filter) {
      if (filter.paramName === 'query') {
        if (filter.paramValue) {
          this.filterModel.query_str = filter.paramValue;
        } else {
          delete this.filterModel.query_str;
        }
      }
    }
    this.updateIdeas();
  }

  sortingChange(val) {
    if (val === 'only_my_favorite') {
      this.filterModel.only_favorite = true;
      this.filterModel.only_invented = false;
      this.filterModel.only_realized = false;
    } else if (val === 'only_realized_by_me') {
      this.filterModel.only_favorite = false;
      this.filterModel.only_realized = true;
      this.filterModel.only_invented = false;
    } else if (val === 'only_invented_by_me') {
      this.filterModel.only_favorite = false;
      this.filterModel.only_realized = false;
      this.filterModel.only_invented = true;
    }
    this.filterChange(null)
  }

  onSearch(evt) {
    this.filterChange({paramName: 'query', paramValue: evt});
    this.searchQuery = evt;
  }

  clearSearchQuery() {
    this.onSearch('');
  }

  onScroll() {
    if (!this.ideasLoading && !this.lastPage) {
      this.nextPage();
    }
  }

}
