import { Directive, OnInit, Inject, Input, PLATFORM_ID, ElementRef } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
declare var $;
@Directive({
  selector: '[appLmCustomSelect]'
})
export class LmCustomSelectDirective {
  @Input()
  set values(newValues) {
    setTimeout(() => {
      this.initCustomSelect();
    });
  }

  isBrowser;
  constructor(private el: ElementRef, @Inject(PLATFORM_ID) platformId: string) {
                this.isBrowser = isPlatformBrowser(platformId);
              }
  ngOnInit() {
    this.initCustomSelect();
  }

  initCustomSelect() {
    if (this.isBrowser) {
      var $this = $(this.el.nativeElement), numberOfOptions = $this.children('option').length;
      $this.addClass('lm-custom-select-hidden');
      $this.wrap('<div class="lm-custom-select"></div>');
      $this.after('<div class="lm-custom-select-styled"></div>');

      var $styledSelect = $this.next('div.lm-custom-select-styled');
      if ($this.children('option[selected="selected"]').length) {
        $styledSelect.text($this.children('option[selected="selected"]').eq(0).text());
      } else {
        $styledSelect.text($this.children('option').eq(0).text());
      }

      var $list = $('<ul />', {
          'class': 'lm-custom-select-options'
      }).insertAfter($styledSelect);
      for (var i = 0; i < numberOfOptions; i++) {
        $('<li />', {
            text: $this.children('option').eq(i).text(),
            rel: $this.children('option').eq(i).val()
        }).appendTo($list);
      }
      var $listItems = $list.children('li');

      $styledSelect.click(function(e) {
          e.stopPropagation();
          $('div.lm-custom-select-styled.lm-custom-select-active').not(this).each(function(){
              $(this).removeClass('lm-custom-select-active').next('ul.select-options').hide();
          });
          $(this).toggleClass('lm-custom-select-active').next('ul.lm-custom-select-options').toggle();
      });

      $listItems.click(function(e) {
          e.stopPropagation();
          $styledSelect.text($(this).text()).removeClass('lm-custom-select-active');
          $this.val($(this).attr('rel'));
          $this.trigger('change');
          $list.hide();

      });

      $(document).click(function() {
          $styledSelect.removeClass('lm-custom-select-active');
          $list.hide()
      });
    }

  }

}
