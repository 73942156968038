import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-event-time',
  templateUrl: './event-time.component.html',
  styleUrls: ['./event-time.component.styl']
})
export class EventTimeComponent implements OnInit {
  @Input() dateStart;
  @Input() dateEnd;
  @Input() timeStart;
  @Input() timeEnd;
  @Input() noTime;
  @Input() eventPastStatus;
  month;
  constructor() { }

  ngOnInit() {
    this.dateStart = new Date(new Date(this.dateStart).setHours(0,0,0,0));
    if (this.dateEnd) {
      this.dateEnd = new Date(new Date(this.dateEnd).setHours(0,0,0,0));
    }
    this.month = (new Date(this.dateStart)).getMonth();
  }

}
