import { Component, OnInit, Input } from '@angular/core';
import { RefundService } from '../../services/refund.service';

@Component({
  selector: 'app-to-return',
  templateUrl: './to-return.component.html',
  styleUrls: ['./to-return.component.styl']
})
export class ToReturnComponent implements OnInit {
  @Input() userId;
  @Input() returns;
  refunds;
  isLoading;
  error;
  constructor(private refundService: RefundService) { }

  ngOnInit() {
    this.updateRefund();
  }

  updateRefund() {
    this.isLoading = true;
    this.refundService.getRefunds(this.userId).subscribe((data) => {
      this.refunds = data.result;
      this.isLoading = false;
    }, () => this.error = true)
  }

  refund(refundId) {
    this.refunds = null;
    this.isLoading = true;
    this.refundService.setReturned(refundId, true).subscribe((data) => {
      this.updateRefund();
    }, () => this.error = true);
  }

}
