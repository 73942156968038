import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})

export class ServicesService {

  constructor() {
  }

  get() {
    return [
      {
        type: 'consultation',
        price: 700,
        priceType: 'hours'
      }
    ];
  }
}
