import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

const timeZoneOffset = new Date().getTimezoneOffset() * 60 * 1000;

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.styl']
})
export class DatePickerComponent implements OnInit {
  pickerOpened = false;
  @Input() control: any;
  @Input() minDate: any;
  @Input() maxDate: any;
  @Input() disabled: boolean;
  @Input() disablePast: boolean;
  @Input() todayRed: boolean;
  @Output() setDateEmitter = new EventEmitter();
  date;
  isToday;
  constructor() { }

  ngOnInit() {
    this.checkToday();
  }

  selectDate(evt) {
    this.date = evt - timeZoneOffset;
    setTimeout(() => {
      this.closePicker();
    });
    if (this.control) {
      this.control.setValue(this.date);
      this.control.markAsTouched();
    }
    this.checkToday();
    this.setDateEmitter.emit(this.date);
  }

  openPicker() {
    if (!this.disabled) {
      this.pickerOpened = true;
    }
  }

  closePicker() {
    if (this.pickerOpened) {
      this.pickerOpened = false;
      this.control.markAsTouched();
    }
  }

  checkToday() {
    if (this.control) {
      this.isToday = new Date(this.control.value).setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
    } else if (this.date) {
      this.isToday = new Date(this.date).setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
    }
  }

}
