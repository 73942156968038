import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class IdeasService {

  constructor(private api: ApiService) { }

  create(model) {
    return this.api.post('v1/ideas/new', JSON.stringify(model));
  }

  edit(ideaId, model) {
    return this.api.post(`v1/ideas/${ideaId}/edit`, JSON.stringify(model));
  }

  deleteIdea(ideaId) {
    return this.api.delete(`v1/ideas/${ideaId}/delete`);
  }

  putDownloadsCount(ideaId) {
    return this.api.put(`v1/ideas/${ideaId}/downloads`, null);
  }

  getIdeasList(filter) {
    return this.api.post('v2/ideas/list', JSON.stringify(filter));
  }

  getAdditional(ids) {
    return this.api.get('v1/ideas/list/additional', {ideaIds: ids});
  }

  getIdea(ideaId) {
    return this.api.get(`v1/ideas/${ideaId}`);
  }

  getComments(ideaId) {
    return this.api.get(`v1/ideas/${ideaId}/comments`);
  }

  sendComment(ideaId, model) {
    return this.api.post(`v1/ideas/${ideaId}/comments`, JSON.stringify(model));
  }

  sendReply(ideaId, commentId, model) {
    return this.api.post(`v1/ideas/${ideaId}/comments/${commentId}/reply`, JSON.stringify(model));
  }

  deleteComment(ideaId, commentId) {
    return this.api.delete(`v1/ideas/${ideaId}/comments/${commentId}`)
  }

  done(ideaId, value) {
    return this.api.post(`v1/ideas/${ideaId}/realized`, undefined, { realized: value });
  }

  like(ideaId, value) {
    return this.api.post(`v1/ideas/${ideaId}/like`, undefined, { favorite: value });
  }

  likeComment(ideaId, commentId, value) {
    return this.api.post(`v1/ideas/${ideaId}/comments/${commentId}/like`, undefined, { favorite: value });
  }

  getIdeaCourses(ideaId) {
    return this.api.get(`v1/ideas/${ideaId}/courses`);
  }

  convertPageToPdf(url, title) {
    return this.api.get(`v1/pdfconvert/directly`, { url, title });
  }

}
