import { Component, OnInit, Output, Input, EventEmitter } from '@angular/core';
import { EquipmentsService } from '../../services/equipments.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-equipment-search-consult-item',
  templateUrl: './equipment-search-consult-item.component.html',
  styleUrls: ['./equipment-search-consult-item.component.styl']
})
export class EquipmentSearchConsultItemComponent implements OnInit {
  @Input() item;
  nearestReserveDate;
  @Output() clickEmitter = new EventEmitter();
  currentRoute;

  constructor(private equipmentsService: EquipmentsService,
              private router: Router) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    if (!this.item.free_access && !this.item.broken) {
      this.equipmentsService.getNearestReservationDate(this.item.id).subscribe((data) => {
        this.nearestReserveDate = data.result.nearest_reservation_date_time_ms;
      })
    }
  }

  getReserveDateType(date) {
    if (new Date(date).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
      return 'today';
    } else if (new Date(date).setHours(0,0,0,0) === new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0)) {
      return 'tomorrow';
    } else return null;
  }

}
