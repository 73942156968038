import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FileUploadService } from '../../services/file-upload.service';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-profile-image',
  templateUrl: './profile-image.component.html',
  styleUrls: ['./profile-image.component.styl']
})
export class ProfileImageComponent implements OnInit {
  @Input() image;
  @Input() cantEdit;
  @ViewChild('image') userPic: ElementRef;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('remove') remove: ElementRef;
  @ViewChild('input') input: ElementRef;
  paramLoading: boolean = false;
  actionText;
  constructor(private userService: UserService,
              private fileUploadService: FileUploadService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.renderImage();
    this.userPic.nativeElement.onload = () => {
      let prop = this.userPic.nativeElement.offsetWidth / this.userPic.nativeElement.offsetHeight;
      if (prop <= 1) {
        this.userPic.nativeElement.style.height = 'auto';
        this.userPic.nativeElement.style.left = '0';
        this.userPic.nativeElement.style.width = '100%';
        this.userPic.nativeElement.style.top = -this.userPic.nativeElement.parentNode.offsetWidth * (1 - prop) / 2 + 'px';
      } else {
        this.userPic.nativeElement.style.width = 'auto';
        this.userPic.nativeElement.style.top = '0';
        this.userPic.nativeElement.style.height = '100%';
        this.userPic.nativeElement.style.left = -this.userPic.nativeElement.parentNode.offsetWidth * (prop - 1) / 2 + 'px';
      }
    }
  }


  uploadPhoto() {
    if((this.input.nativeElement.files[0].type.indexOf('image/')+1)) {
      this.paramLoading = true;
      this.fileUploadService.uploadFile(this.input.nativeElement.files[0], 'PROFILE').subscribe(data => {
        this.paramLoading = false;
        this.userService.updateUserData({
          imageProfileId: data.result.id
        }).subscribe(data => {
          this.image = data.result.image.url;
          this.userService.setUserData(data.result);
          this.renderImage();
          this.userService.updateImg();
          this.messagesService.showMessage('Фотография профиля успешно установлена');
        });
      }, () => {
        this.messagesService.showMessage('Ошибка при обновлении данных пользователя');
      });
    }

  }

  deletePhoto() {
    this.paramLoading = true;
    this.userService.deleteUserPhoto().subscribe((data) => {
      this.paramLoading = false;
      this.image = null;
      this.renderImage();
      this.userService.setUserData(data.result);
      this.messagesService.showMessage('Фотография профиля успешно удалена');
      this.userService.updateImg();
    }, () => {
      this.paramLoading = false;
      this.messagesService.showMessage('Ошибка при обновлении данных пользователя');
    })
  }


  renderImage() {
    if (!this.image) {
      this.userPic.nativeElement.src = '/assets/icons/no-profile-image.svg';
      if (this.remove) {
        this.remove.nativeElement.style.display = 'none';
      }
      this.actionText = 'Добавить';
    } else {
      this.userPic.nativeElement.src = '' + this.image;
      if (this.remove) {
        this.remove.nativeElement.style.display = 'unset';
      }
      this.actionText = 'Изменить';
    }

  }
}
