import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MetaService } from './meta.service';
import { Subject, of, BehaviorSubject } from 'rxjs';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class SeoService {
  currentPageMetaData;
  constructor(private api: ApiService,
              private router: Router) { }

  getSeoInfo(url) {
    return this.api.get('v1/seo/info', { url });
  }

  getSuperadminSeoFiles() {
    return this.api.get('v1/superadmin/seo/files');
  }

  uploadSeoFile(file, fileType) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.api.post('v1/superadmin/seo/upload', formData, { fileType }, { });
  }

  deleteSeoFile(fileType) {
    return this.api.delete('v1/superadmin/seo/delete', { fileType });
  }

  // updateCurrnetPageDate(data) {
  //   this.currentPageMetaData = data;
  // }
  updatePageSeo(url, onSuccess, metadata?) {
    let result$ = new Subject();
    if (!metadata) {
      this.getSeoInfo(url).pipe(first()).subscribe((data) => {
        if (data.result && data.result.redirect) {
          this.router.navigate([data.result.redirect]);
        } else {
          onSuccess(data.result);
          result$.next();
        }
      }, () => {
        onSuccess({}),
        result$.next();
      })
      return result$;
    } else {
      if (metadata && metadata.redirect) {
        this.router.navigate([metadata.redirect]);
      } else {
        onSuccess(metadata);
        return new BehaviorSubject(true);
      }
    }
  }

  initSeoVars(seoData, vars) {
    let result = {};
    if (vars) {
      Object.keys(vars).forEach(key => {
        let reg = new RegExp('#{' + key + '}', 'g');
        Object.keys(seoData).forEach(item => {
          if (seoData[item]) {
            result[item] = seoData[item].replace(reg, vars[key]);
          } else {
            result[item] = seoData[item];
          }
        })
      })
    } else {
      result = seoData;
    }
    return result;
  }
}