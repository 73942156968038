import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { StorageService } from '../../services/storage.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-modal-prolongate-storage-period',
  templateUrl: './modal-prolongate-storage-period.component.html',
  styleUrls: ['./modal-prolongate-storage-period.component.styl']
})
export class ModalProlongateStoragePeriodComponent implements OnInit {
  cellsList;
  isLoading;
  error;
  cellsForm;
  totalDuration;
  currentUserId;
  loadingMessage = 'Загрузка данных по хранению...';
  constructor(private modalService: ModalService,
              private userService: UserService,
              private storageService: StorageService,
              private fb: FormBuilder) { }

  ngOnInit() {
    this.currentUserId = this.userService.userId;
    this.updateCells();
  }

  updateCells() {
    this.isLoading = true;
    this.storageService.getUsersStorageSessions(this.currentUserId).subscribe((data) => {
      this.cellsList = this.initForm(data.result);
      this.updateValues();
      this.isLoading = false;
    }, () => {this.error = true; this.isLoading = false})
  }

  initForm(cells) {
    this.cellsForm = this.fb.group({
      cells: this.fb.array([])
    })
    cells.forEach(item => {
      let group = this.fb.group({
        slot_identifier: [item.slot_identifier, Validators.required],
        duration: [0, Validators.required],
        new_end_date: [null]
      })
      this.cellsForm.controls.cells.push(group);
      item.control = group;
    })
    return cells;
  }

  updateValues() {
    this.totalDuration = this.cellsForm.value.cells.reduce((previous, current) => {
      return previous + current.duration;
    }, 0)
  }

  submit() {
    let slots = this.cellsForm.value.cells.filter(item => {
      return item.duration;
    }).map(item => {
      return {
        slot_identifier: item.slot_identifier,
        days: item.duration
      }
    });
    let slotsIds = slots.map(item => item.slot_identifier);
    this.loadingMessage = 'Продление срока хранения...';
    this.isLoading = true;
    this.storageService.prolongSession(slots, this.currentUserId).subscribe((data) => {
      this.isLoading = false;
      this.modalService.nextPage({prolongateStoragePeriodData: data.result.filter(item => slotsIds.includes(item.slot_identifier)).map(item => {
        return {
          cell: item.slot_identifier,
          date: item.end_date
        }
      })}, null);
    }, () => {this.error = true; this.isLoading = false})
  }

  back() {
    this.modalService.back();
  }

}
