import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-modal-remove-client',
  templateUrl: './modal-remove-client.component.html',
  styleUrls: ['./modal-remove-client.component.styl']
})
export class ModalRemoveClientComponent implements OnInit {

  @Output() remove = new EventEmitter<boolean>();
  @Output() close = new EventEmitter<boolean>();
  @Input() client;
  @Input() type;
  @Input() entity = 'user';
  @Input() fileName;
  constructor() { }

  ngOnInit() {
    if (!this.type && this.client) {
      if (this.client.role === 'CLIENT' || this.client.role === 'SUPER_ADMIN') {
        this.type = 'client';
      }
      if (this.client.role === 'ADMIN') {
        this.type = 'master';
      }
    }
  }

  removeClient() {
    this.remove.emit(true);
  }
  closeModal() {
    this.close.emit(true);
  }

}
