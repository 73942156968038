import { Component, OnInit, Inject, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CopyTextService } from '../../services/copy-text.service';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { sameYear, compressTimings } from '../../utils';
import { RESERVE_START_TIME, RESERVE_END_TIME } from '../../config';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { TranslitService } from '../../services/translit.service';

@Component({
  selector: 'app-modal-payment-success',
  templateUrl: './modal-payment-success.component.html',
  styleUrls: ['./modal-payment-success.component.styl']
})
export class ModalPaymentSuccessComponent implements OnInit {
  @Input() subscriptionPaid;
  @Input() subscriptionParams;
  @Input() extSubscriptionPaid;
  @Input() extSubscriptionParams;
  @Input() eventPaid;
  @Input() eventParams;
  @Input() coursePaid;
  @Input() courseParams;
  @Input() courseTimeParams;
  @Input() isMoving;
  @Input() reserveParams;
  @Input() reserveSettings;
  @Input() reserveForClient;
  @Input() appointForCoursePaid;
  @Input() appointForCourseParam;
  @Input() consultPaid;
  @Input() consultParams;
  @Input() appointForEventParam;
  @Input() appointForEventPaid;
  @Input() storageCellParam;
  @Input() storageCellPaid;
  @Input() prolongateStoragePeriodData;
  @Input() clientProlongateStoragePeriodData;
  @Input() hasSert;

  isBrowser;
  currentType;
  currentId;
  currentName;
  sameYear;
  compressTimings = compressTimings;
  balanceAfterWork: any;
  workTimeStart = RESERVE_START_TIME;
  workTimeEnd = RESERVE_END_TIME;
  componentDestroyed$ = new Subject();
  constructor(
    public modalService: ModalService,
    private copyTextService: CopyTextService,
    private activatedRoute: ActivatedRoute,
    private userService: UserService,
    private translitService: TranslitService
  ) {}

  ngOnInit() {
    if (this.reserveSettings && this.reserveParams) {
      if (this.userService.userId == this.reserveParams.user_id) {
        let userSubscription = {
          hours: +this.userService.userData.subscription_hours,
          days: +this.userService.userData.subscription_days
        }
        if (this.reserveSettings.sessions.length <= userSubscription.hours) {
          this.balanceAfterWork = {
            hours: userSubscription.hours - this.reserveSettings.sessions.length,
            days: userSubscription.days
          }
        } else if (userSubscription.days) {
          this.balanceAfterWork = {
            hours: userSubscription.hours,
            days: userSubscription.days - 1
          }
        }
      }
    }
    this.sameYear = sameYear;
    this.activatedRoute.queryParams.pipe(takeUntil(this.componentDestroyed$)).subscribe(params => {
      if (params.event) {
        this.currentType = 'event';
        this.currentId = params.event;
        this.currentName = this.eventParams.name;
      }
      if (params.course) {
        this.currentType = 'course';
        this.currentId = params.course;
        this.currentName = this.courseParams.name;
      }
      if (params.instrument) {
        this.currentType = 'equipment';
        this.currentId = params.instrument;
        this.currentName = this.reserveSettings.equipmentName
      }
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  copyUrl() {
    if (this.currentType && this.currentId) {
      let id = this.translitService.getHumanReadableId(this.currentName, this.currentId);
      this.copyTextService.copyUrl(this.currentType, id);
    }
  }


}
