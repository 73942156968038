import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  constructor(private api: ApiService,
              private router: Router) { }

  userSearch(query) {
    return this.api.post('v1/users/search', null, { query });
  }

  superAdminSearch(query) {
    return this.api.post('v1/admin/search', null, { query });
  }

  startWork(model) {
    return this.api.post('v1/works/start', null, model);
  }

  finishWork(userId) {
    return this.api.post('v1/works/finish', null, { userId });
  }

  addCertificateToSession(model) {
    return this.api.put('v1/works/certificate', null, model);
  }

  removeCertificateFromSession(userId) {
    return this.api.delete('v1/works/certificate/delete', { userId });
  }

  getScheduleList() {
    return this.api.get('v1/admin/schedule/list/actual');
  }

  getLastUploadScheduleDate() {
    return this.api.get('v1/admin/schedule/date');
  }

  updateSchedule(id, file, zone) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.api.post('v1/admin/update/schedule/' + id, formData, { module: 'SCHEDULE', timeZone: zone}, { });
  }

  uploadSchedule(file, date, zone) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    return this.api.post('v1/admin/upload/schedule', formData, { module: 'SCHEDULE', date: date, timeZone: zone}, { });
  }

  createAccount(model) {
    return this.api.post('v1/admin/users/new', model);
  }

  editAccount(userId, model) {
    return this.api.post(`v1/admin/users/${userId}/edit`, model);
  }

  cancelCourseCallResult(model) {
    return this.api.post(`v1/admin/warnings/courses/cancel`, model);
  }

  moveCourseCallResult(model) {
    return this.api.post(`v1/admin/warnings/courses/reschedule`, model);
  }

  cancelEventCallResult(model) {
    return this.api.post(`v1/admin/warnings/events/cancel`, model);
  }

  moveEventCallResult(model) {
    return this.api.post(`v1/admin/warnings/events/reschedule`, model);
  }

  getCancelCourseNotificationInfo(notificationId) {
    return this.api.get(`v1/admin/warnings/courses/cancel/info`, { notificationId });
  }

  getMoveCourseNotificationInfo(notificationId) {
    return this.api.get(`v1/admin/warnings/courses/reschedule/info`, { notificationId });
  }

  getCancelEventNotificationInfo(notificationId) {
    return this.api.get(`v1/admin/warnings/events/cancel/info`, { notificationId });
  }

  getMoveEventNotificationInfo(notificationId) {
    return this.api.get(`v1/admin/warnings/events/reschedule/info`, { notificationId });
  }

  getWorkingUsersList() {
    return this.api.get('v1/works/users');
  }

  getShortWorkingData() {
    return this.api.get('v1/works/users/statistic');
  }

  getEntitiesInfoForDeletingMaster(masterId) {
    return this.api.get(`v1/admin/masters/${masterId}/delete/info`);
  }

  openEntitiesInfoForDeletingMaster(userId) {
    let currentRoute = this.router.url.split('?')[0];
    this.getEntitiesInfoForDeletingMaster(userId).subscribe(data => {
        if (data.result.consultations && data.result.consultations.length || data.result.courses && data.result.courses.length) {
          this.router.navigate([currentRoute], {
            queryParams: {
              popup: 'entities-info-deleting-master',
              masterId: userId
            }
          });
        }
      });
  }
}
