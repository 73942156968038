import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { CertificatesService } from '../../services/certificates.service';
import { IsBrowserService } from '../../services/is-browser.service';
import { UserService } from '../../services/user.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-modal-client-certs',
  templateUrl: './modal-client-certs.component.html',
  styleUrls: ['./modal-client-certs.component.styl']
})
export class ModalClientCertsComponent implements OnInit {
  isLoading;
  isError;
  hoursCertificates;
  coursesCertificates;
  activeTab: number = 0;
  userId: number;
  certs;
  user;
  sortType;
  sortDirection;

  constructor(private activatedRoute: ActivatedRoute,
              private certificatesService: CertificatesService,
              private userService: UserService,
              private isBrowserService: IsBrowserService) { }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.queryParams.user;
    if (this.isBrowserService.isBrowser) {
      this.getCertificates();
    }
  }

  setTab(index: number) {
    this.activeTab = index;
  }

  getCertificates() {
    this.isLoading = true;
    forkJoin(this.certificatesService.getUserCertificates(this.userId, { type: 'WORK_SESSION' }), this.certificatesService.getUserCertificates(this.userId, { type: 'COURSE' }), this.userService.getUserData(this.userId)).pipe(first()).subscribe((data) => {
      this.hoursCertificates = data[0].result;
      this.coursesCertificates = data[1].result;
      if (this.coursesCertificates.length && !this.hoursCertificates.length) {
        this.setTab(1);
      }
      this.user = data[2].result;
      this.certs = [this.hoursCertificates, this.coursesCertificates];
      this.isLoading = false;
    }, () => {
      this.isLoading = false;
      this.isError = true;
    })
  }

  setSortType(type) {
    if (type === this.sortType) {
      this.sortDirection = !this.sortDirection;
    } else {
      this.sortType = type;
      this.sortDirection = true;
    }
  }
}
