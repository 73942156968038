import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ConsultationsService } from '../../services/consultations.service';
import { ActivatedRoute } from '@angular/router';
import { spaces, parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-modal-order-consultation',
  templateUrl: './modal-order-consultation.component.html',
  styleUrls: ['./modal-order-consultation.component.styl']
})
export class ModalOrderConsultationComponent implements OnInit {
  @Input() isMoving;
  consultParams;
  isLoading;
  spaces = spaces;
  orderingLoading;
  constructor(public modalService: ModalService,
              private consultService: ConsultationsService,
              private activatedRoute: ActivatedRoute,
              private messagesService: MessagesService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.consultation) {
      this.isLoading = true;
      this.consultService.getConsultationById(this.activatedRoute.snapshot.queryParams.consultation).subscribe((data) => {
        this.consultParams = data.result;
        this.isLoading = false;
      }, () => {
        this.isLoading = false
      })
    }
  }

  orderConsultation(payNow) {
    this.isLoading = true;
    this.orderingLoading = true;
    this.consultService.order(this.consultParams.id, payNow ? 'ONLINE' : 'ON_THE_SPOT').subscribe((data) => {
      this.isLoading = false;
      this.orderingLoading = false;
      this.consultService.onOrder();
      this.modalService.nextPage({consultPaid: payNow, consultParams: this.consultParams}, null);
    }, (e) => {
      this.isLoading = false;
      this.orderingLoading = false;
      this.messagesService.showMessage('У вас уже есть запись на это время', false, false, true);
    })
  }
}
