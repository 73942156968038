import { Component, OnInit, ViewChild, ElementRef, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-text-search',
  templateUrl: './text-search.component.html',
  styleUrls: ['./text-search.component.styl']
})
export class TextSearchComponent implements OnInit {
  @Input() mirror;
  @Input() local;
  @Input() placeholder = 'Поиск...';
  @ViewChild('input') input: ElementRef;
  @Output() searchEmitter = new EventEmitter();
  @Output() inputEmitter = new EventEmitter();
  @Output() closeEmitter = new EventEmitter();
  searchOpened: boolean = false;

  constructor() { }

  ngOnInit() {
  }

  openSearch() {
    this.searchOpened = true;
    setTimeout(() => {
      this.input.nativeElement.focus();
    }, 100)
    if (this.local) {
      this.input.nativeElement.value = '';
    }
  }

  closeSearch() {
    this.onClose();

    this.input.nativeElement.blur();
    this.searchOpened = false;

  }

  onSubmit() {
    this.searchEmitter.emit(this.input.nativeElement.value);
    this.closeSearch();
    this.input.nativeElement.value = '';
  }

  clearField() {
    this.input.nativeElement.value = '';
    this.closeSearch();
  }

  onInput() {
    this.inputEmitter.emit(this.input.nativeElement.value);
  }

  onClose() {
    this.closeEmitter.emit();
  }

  onClickOutside() {
    if (!this.local) {
      this.closeSearch();
    }
  }

}
