import { Component, OnInit, Input, ChangeDetectorRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-social-picker',
  templateUrl: './social-picker.component.html',
  styleUrls: ['./social-picker.component.styl']
})
export class SocialPickerComponent implements OnInit {
  @Input() socials;
  pickerOpened = false;
  allSocialTypes = [
    {
      name: 'facebook'
    },{
      name: 'vk'
    }, {
    //   name: 'whatsapp'
    // }, {
    //   name: 'telegram'
    // }, {
    //   name: 'viber'
    // }, {
      name: 'google_plus'
    }, {
      name: 'twitter'
    }
  ]
  socialTypes = [];
  constructor(private fb: FormBuilder, private _ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.initSocialTypes();
  }

  addSocial(type) {
    let validators = [Validators.required];
    let social = this.fb.group({
      type: [type],
      contact: ['', [Validators.required]]
    })
    if (type === 'whatsapp' || type === 'viber') {
      social.controls.contact.setValidators([Validators.required, Validators.pattern(/8|\+7\d{10}/)]);
    } else {
      social.controls.contact.setValidators([Validators.required]);
    }
    this.socials.push(social);
    this.closePicker();
    this.initSocialTypes();
  }

  openPicker() {
    this.pickerOpened = true;
  }

  closePicker() {
    this.pickerOpened = false;
  }

  togglePicker() {
    if (this.pickerOpened) {
      this.closePicker();
    } else {
      this.openPicker();
    }
  }

  removeSocial(index) {
    this.socials.removeAt(index);
    this._ref.detectChanges();
    this.initSocialTypes();
  }

  initSocialTypes() {
    this.socialTypes = this.allSocialTypes.filter((item) => {
      return !this.socials.controls.some(elem => elem.controls.type.value === item.name);
    })
  }


}
