import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { CoursesService } from '../../services/courses.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { MessagesService } from '../../services/messages.service';
import { debounceTime } from 'rxjs/operators';

@Component({
  selector: 'app-modal-appointment-for-course',
  templateUrl: './modal-appointment-for-course.component.html',
  styleUrls: ['./modal-appointment-for-course.component.styl']
})
export class ModalAppointmentForCourseComponent implements OnInit {
  finalStep = 2;
  currentStep:number = 0;
  state:any = {};
  isLoading;
  error;
  registerLoading;
  courseId;
  courseTimingId;
  errorMessage = 'Ошибка';
  userId;
  constructor(private modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private messagesService: MessagesService,
              private coursesService: CoursesService,
              private currentCourseService: CurrentCourseService) { }

  ngOnInit() {
    this.userId = +this.activatedRoute.snapshot.queryParams.user;
    if (this.userId) {
      this.selectUser({user: {id: this.userId}});
    }
    this.courseId = this.activatedRoute.snapshot.queryParams.course
    this.courseTimingId = this.activatedRoute.snapshot.queryParams.timing
  }

  selectUser(state) {
    this.isLoading = true;
    this.userService.getUserData(state.user.id).subscribe((data) => {
      if (data.result) {
        this.isLoading = false;
        this.nextStep({
          user: data.result
        });
      } else {
        this.error = true;
      }
    }, () => this.error = true)
  }

  selectCourse(courseId, timingId) {
    this.isLoading = true;
    this.coursesService.getCourse(courseId).subscribe((data) => {
      this.isLoading = false;
      let timing = data.result.course_timings.filter(item => item.id == timingId)[0];
      if (timing) {
        this.nextStep({
          course: {
            name: data.result.name,
            cost: data.result.cost,
            ...timing
          }
        });
      }
    }, () => {
      this.error = true;
      this.errorMessage = 'Данный мастер-класс недоступен.'
    })
  }

  nextStep(state) {
    let checkStep = () => {
      this.currentStep++;
      this.state = {
        ...this.state,
        ...state
      };
      if (state.course && state.course.status === 'PASSED') {
        this.error = true;
        this.errorMessage = 'Этот мастер-класс уже прошёл.';
      } else if (state.event && state.event.places_amonut && !state.event.places_left) {
        this.error = true;
        this.errorMessage = 'На этот мастер-класс нет свободных мест.';
      } else if (state.course && state.course.status === 'CANCELLED') {
        this.error = true;
        this.errorMessage = 'Этот мастер-класс отменен.';
      }
      if (this.currentStep > this.finalStep) {
        this.submit();
      }
      if (this.currentStep === 1 && this.courseId && this.courseTimingId) {
        this.selectCourse(this.courseId, this.courseTimingId);
      }
      if (state.user) {
        this.userId = state.user.id;
      }
    };

    if (this.currentStep === 1 && state.course || state.user && this.courseId && this.currentStep === 0) {
      this.coursesService.checkUserRegistered( +this.courseId || state.course.course_id, this.userId || state.user.id).subscribe(data => {
        if (data.result) {
          this.messagesService.showMessage('Клент уже записан на этот мастер класс');
        } else {
          checkStep();
        }
      });
    } else {
      checkStep();
    }
  }

  submit(params?) {
    let model = {
      course_timing_id: this.state.course.id,
      user_id: this.state.user.id,
      payment_type: this.state.userPaid ? 'ONLINE' : 'ON_THE_SPOT'
    }
    this.registerLoading = true;
    this.coursesService.register(this.state.course.course_id, model).subscribe((data) => {
      if (this.state.userPaid) {
        let payModel:any = {
          userId: this.state.user.id,
          paid: true
        }
        if (params && params.certificateNumber) {
          payModel.certificateNumber = params.certificateNumber;
        }
        this.coursesService.paid(this.state.course.course_id, this.state.course.id, payModel).subscribe((paid) => {
          this.currentCourseService.updateEmitter();
          this.onSuccess(data, paid.result);
        }, () => this.error = true)
      } else {
        this.currentCourseService.updateEmitter();
        this.onSuccess(data, false);
      }
    }, () => this.error = true)
  }

  onSuccess(data, paid) {
    this.registerLoading = false
    this.modalService.nextPage({
      appointForCoursePaid: paid,
      appointForCourseParam: {
        name: this.state.user.name,
        course_name: this.state.course.name,
        cost: this.state.course.cost,
        start_date: data.result.start_date,
        start_time: data.result.start_time,
        end_time: data.result.end_time
      }
    }, null);
  }

  back() {
    this.modalService.back();
  }

}
