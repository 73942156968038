import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { switchMap, debounceTime, tap, map } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { EquipmentsService } from '../../services/equipments.service';
import { areaTypesEquipmentsShort } from '../../utils';

@Component({
  selector: 'app-equipment-search-consult',
  templateUrl: './equipment-search-consult.component.html',
  styleUrls: ['./equipment-search-consult.component.styl']
})
export class EquipmentSearchConsultComponent implements OnInit {
  @Input() filter: 'reserve';
  @Input() searchParams;
  @Output() onSelect = new EventEmitter();

  focused;
  searchQuery;
  equipmentsList: any[] = [];
  equipmentsSubject$;
  equipmentsLoading;
  error;

  areas: any[] = areaTypesEquipmentsShort;
  filterModel: any = {
    page: 1,
    area: this.areas[0].value,
    limit: 5,
    query_str: '',
    hide_broken: true
  };

  constructor(private equipmentsService: EquipmentsService) { }

  ngOnInit() {
    if (this.searchParams) {
      this.filterModel = {
        ...this.filterModel,
        ...this.searchParams
      }
    }
    this.equipmentsSubject$ = new Subject();
    this.equipmentsSubject$.pipe(debounceTime(100), tap(() => {
      this.equipmentsList = [];
      this.equipmentsLoading = true;
    }), switchMap((model:any) => this.equipmentsService.getEquipmentsList(model)))
      .subscribe(data => {
          this.error = false;
          this.equipmentsList = data.result.data;
          this.equipmentsLoading = false;
        }, () => this.error = true, () => this.equipmentsLoading = false
      )
    this.updateEquipments();
  }

  selectEquipment(event) {
    this.onSelect.emit(event);
  }

  filterChange(filter) {
    if (filter.paramName === 'query') {
      if (filter.paramValue) {
        this.filterModel.query_str = filter.paramValue;
      } else {
        delete this.filterModel.query_str;
      }
    }
    if (filter.paramName === 'area') {
      this.filterModel.area = filter.paramValue;
    }
    if (!filter.stop) {
      this.updateEquipments();
    }
  }

  clearField() {
    this.searchQuery = '';
    this.onInput();
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  onInput() {
    this.filterChange({paramName: 'query', paramValue: this.searchQuery});
  }

  updateEquipments() {
    this.equipmentsSubject$.next(this.filterModel);
  }

}
