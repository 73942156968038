import { Injectable, Inject } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class MetaService {

  constructor(private title: Title,
              private meta: Meta,
              @Inject(DOCUMENT) private dom,
              @Inject('HOSTNAME') private hostname) { }

  setMeta(metaObject) {
    if (metaObject.title) {
      this.title.setTitle(metaObject.title);
    } else {
      this.title.setTitle('Фабрика идей Леруа Мерлен');
    }
    if (metaObject.description) {
      this.meta.updateTag({ name: 'description', content: metaObject.description || ''});
    }
    if (metaObject.image) {
      this.meta.updateTag({ name: 'og:image', content: metaObject.image || ''});
    }
    if (metaObject.keywords) {
      this.meta.updateTag({ name: 'keywords', content: metaObject.keywords || ''});
    }
  }

  createCanonicalURL(url) {
    let oldLink = this.dom.querySelector('[rel="canonical"]');
    if (oldLink) {
      oldLink.remove();
    }
    let link: HTMLLinkElement = this.dom.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.dom.head.appendChild(link);
    link.setAttribute('href', `${this.hostname}${url}`);
 }
}
