//Сервис для передачи данных из компоненты конкретного события в компоненту редактирования

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentEventService {
  currentEvent;
  updateSubject;
  enrollSubject;
  constructor() {
    this.updateSubject = new Subject();
    this.enrollSubject = new Subject();
  }

  updateEmitter(data?) {
    this.updateSubject.next(data);
  }

  enrollEmitter(id) {
    this.enrollSubject.next(id);
  }

}
