import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-line-chart',
  templateUrl: './line-chart.component.html',
  styleUrls: ['./line-chart.component.styl']
})
export class LineChartComponent implements OnInit {
  @Input() data;
  constructor() { }

  ngOnInit() {
  }

}
