import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-radio-filter',
  templateUrl: './radio-filter.component.html',
  styleUrls: ['./radio-filter.component.styl']
})
export class RadioFilterComponent implements OnInit {
  @Input() short;
  @Input() params: any[];
  @Input() noDefaultSelect: boolean;
  @Input() dropFiltersSubject;
  @Input() setValueSubject;

  @Output() onChangeEmitter = new EventEmitter();
  selectedValue;
  popupOpened;
  currentValue;
  radioFilterForm;

  constructor(private fb: FormBuilder) {
    this.radioFilterForm = this.fb.group({
      param: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.setDefaultValue();
    if (this.dropFiltersSubject) {
      this.dropFiltersSubject.subscribe(() => this.setDefaultValue())
    }
    if (this.setValueSubject) {
      this.setValueSubject.subscribe((value) => this.setValue(value))
    }
  }

  onChange(evt) {
    this.selectedValue = this.radioFilterForm.value.param;
    this.onChangeEmitter.next(this.radioFilterForm.value.param);
    let param = this.params.filter(item => item.value === this.radioFilterForm.value.param)[0];
    if (param) {
      this.currentValue = param.title;
    }
    this.closePopup();
  }

  setDefaultValue() {
    if (!this.noDefaultSelect) {
      this.radioFilterForm.controls.param.setValue(this.params[0].value);
      this.currentValue = this.params[0].title;
    }
  }

  setValue(value) {
    let param = this.params.filter(item => item.value === value)[0];
    if (param) {
      this.radioFilterForm.controls.param.setValue(param.value);
      this.currentValue = param.title;
    } else {
      this.setDefaultValue();
    }
  }

  openPopup() {
    this.popupOpened = true;
  }

  closePopup() {
    this.popupOpened = false;
  }

  togglePopup() {
    if (this.popupOpened) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

}
