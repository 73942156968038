import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { StorageService } from '../../services/storage.service';
import { getDatesDiff } from '../../utils';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-modal-notify-passed-storage',
  templateUrl: './modal-notify-passed-storage.component.html',
  styleUrls: ['./modal-notify-passed-storage.component.styl']
})
export class ModalNotifyPassedStorageComponent implements OnInit {
  overdueList;
  isError;
  isLoading;
  getDatesDiff = getDatesDiff;
  today = new Date();
  searchQuery;
  currentNotificationId;
  constructor(private modalService: ModalService,
              private storageService: StorageService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.currentNotificationId = this.activatedRoute.snapshot.queryParams.notification;
    if (this.currentNotificationId) {
      this.updateProducts();
    } else {
      this.isError = false;
    }
  }

  closeModal() {
    this.modalService.onClose();
  }

  updateProducts(disableLoader?) {
    if (!disableLoader) {
      this.isLoading = true;
    }
    this.storageService.getOverdueSessionsByNotificationId(this.currentNotificationId).pipe(first()).subscribe((data) => {
      this.overdueList = data.result;
      this.isLoading = false;
    }, () => {this.isError = true; this.isLoading = false})
  }

  onExtend(days, item) {
    // this.isLoading = true;
    this.storageService.prolongSession([{
      slot_identifier: item.slot_identifier,
      days: days
    }], item.owner.id).pipe(first()).subscribe(() => {
      this.updateProducts(true);
    }, () => {this.isError = true; this.isLoading = false})
  }

  onComment(comment, item) {
    this.storageService.phoned(true, item.slot_identifier, comment).pipe(first()).subscribe(() => {
      this.updateProducts(true);
    }, () => {this.isError = true; this.isLoading = false})
  }

  finishSession(item) {
    // this.isLoading = true;
    this.storageService.finishSession(item.storage_session_id).pipe(first()).subscribe(() => {
      this.updateProducts(true);
    }, () => {this.isError = true; this.isLoading = false})
  }

}
