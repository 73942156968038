import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-modal-multistep-item',
  templateUrl: './modal-multistep-item.component.html',
  styleUrls: ['./modal-multistep-item.component.styl']
})
export class ModalMultistepItemComponent implements OnInit {
  @Input() step: number;
  @Input() activeTitle: string;
  @Input() passedTitle: string;
  @Input() passedValue: string;
  @Input() isActive: boolean;
  @Input() isPassed: boolean;

  constructor() { }

  ngOnInit() {
  }

}
