import { Pipe } from '@angular/core';

@Pipe({
  name: 'sorting'
})
export class SortingPipe {

  transform(items: any[], param: string) {
    if (!items) {
      return items;
    }
    if (!param) {
      param = 'start_time';
    }

    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.sort ? items.sort((a, b) => {
      return a[param] - b[param]
    }) : null;
  }

}
