import { Injectable, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { ApiService } from './api.service';
import { TokenService } from './token.service';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { SocketService } from './socket.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  updateEmitter;
  private updateObs;
  constructor(private api: ApiService,
              private router: Router,
              private tokenService: TokenService,
              private userService: UserService,
              private socketService: SocketService) {
                this.updateEmitter = Observable.create((obs) => this.updateObs = obs);
              }


  logIn(login, password) {
    return this.api.postForm('v1/users/login', { login, password });
  }

  logOut(afterLogout?) {
    this.tokenService.deleteToken();
    this.userService.deleteUser();
    this.router.navigate(['/']);
    this.socketService.disconnectClient();
    this.socketService.disconnectAdmin();
  }

  register(login, password, phone) {
    return this.api.postForm('v1/users/register', { login, password, phone });
  }

  getCurrentUserId() {
    return this.userService.userId;
  }

  initUser(userId, token) {
    this.tokenService.setToken({authToken: token});
    let initSubject$ = new Subject();
    setTimeout(() => {
      // if (!this.userService.userData) {
        this.userService.getUserData(userId)
          .subscribe((user) => {
            this.userService.setUser(userId, user.result);
            initSubject$.next();
          }, (e) => {
            this.tokenService.deleteToken();
            initSubject$.next(true);
          })
      // }

    }, 50);
    return initSubject$;
  }

  

  public update = function() {
    this.updateObs.next(true);
  }.bind(this);
}
