import { Injectable } from '@angular/core';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})

export class PreviousStateService {
  currentState;
  history = [];
  constructor(private router: Router) {

  }

  setPrevState(url) {

    let uri = decodeURI(url);
    // console.log(uri);
    if (!(uri.indexOf('popup=') + 1) && !(uri.indexOf('/print') + 1) && !(uri.indexOf('creation=') + 1) && !(uri.indexOf('editidea=') + 1) && !(uri.indexOf('editcourse=') + 1) && !(uri.indexOf('editevent=') + 1)  && !(uri.indexOf('editequipment=') + 1) && uri !== this.currentState) {
      this.history.push(decodeURI(uri));
      this.currentState = uri;
    }
    // console.log(this.history);

  }

}
