import { Pipe } from '@angular/core';

@Pipe({
  name: 'rusDifficulty'
})
export class RusDifficultyPipe {

  transform(value: any, args?: any): any {
    if (value === 'EASY') {
      return 'просто'
    }
    if (value === 'MEDIUM') {
      return 'средне'
    }
    if (value === 'HARD') {
      return 'сложно'
    }
  }

}
