import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { sameYear } from '../../utils';

@Component({
  selector: 'app-modal-move-consult-success',
  templateUrl: './modal-move-consult-success.component.html',
  styleUrls: ['./modal-move-consult-success.component.styl']
})
export class ModalMoveConsultSuccessComponent implements OnInit {
  @Input() consultParams;
  @Input() consultInstrument;
  @Input() deletion;
  sameYear;
  constructor(private modalService: ModalService) { }

  ngOnInit() {
    this.sameYear = sameYear;
  }

  closeModal() {
    this.modalService.nextPage(null, null);
  }

}
