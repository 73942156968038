import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ClientPopupService } from '../../services/client-popup.service';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-modal-client-popup',
  templateUrl: './modal-client-popup.component.html',
  styleUrls: ['./modal-client-popup.component.styl']
})
export class ModalClientPopupComponent implements OnInit {
  userId;
  client;
  removeModalStatus;
  canDelete;
  userRole;
  currentRoute;
  constructor(private clientPopupService: ClientPopupService,
              private modalService: ModalService,
              private router: Router,
              private adminService: AdminService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.user) {
      this.userId = this.activatedRoute.snapshot.queryParams.user;
    }
    this.currentRoute = this.router.url.split('?')[0];
    this.canDelete = this.userService.userData.role === 'SUPER_ADMIN';
  }

  closeModal() {
    this.modalService.onClose();
  }

  openRemoveModal() {
    this.removeModalStatus = true;
    this.userService.getUserData(this.userId).subscribe(data => {
      this.client = data.result;
    });
  }

  closeRemoveModal() {
    this.removeModalStatus = false;
  }

  removeClient() {
    this.closeRemoveModal();
    this.userService.removeUser(this.userId).subscribe(() => {
      if (this.userRole !== 'CLIENT') {
        this.adminService.openEntitiesInfoForDeletingMaster(this.userId);
      } else {
        this.closeModal();
      }
      this.userService.deleteEmitter();
    });
  }

  initUser(user) {
    this.userRole = user.role;
  }
}
