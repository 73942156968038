import {Component, Input, OnInit} from '@angular/core';
import {SubscriptionsService} from '../../services/subscriptions.service';
import {ModalService} from '../../services/modal.service';
import {EquipmentsService} from '../../services/equipments.service';
import {CurrentEquipmentService} from '../../services/current-equipment.service';
import {UserService} from '../../services/user.service';
import {FABRIC_WORK_HOURS} from '../../config';

@Component({
  selector: 'app-modal-reserve-instrument-subscription',
  templateUrl: './modal-reserve-instrument-subscription.component.html',
  styleUrls: ['./modal-reserve-instrument-subscription.component.styl']
})
export class ModalReserveInstrumentSubscriptionComponent implements OnInit {
  @Input() reserveParams: any;
  @Input() reserveSettings: any;
  subCase = 'absent';
  userSubscriptionHours;
  reservationHours;
  isLoading;
  error;
  cards: any[] = this.subscriptionsService.buildCards();

  constructor(private modalService: ModalService,
              private equipmentsService: EquipmentsService,
              private currentEquipmentService: CurrentEquipmentService,
              private userService: UserService,
              private subscriptionsService: SubscriptionsService) {
  }

  ngOnInit() {
    this.initUserSubscription();
    if (+this.userSubscriptionHours >= +this.reservationHours) {
      // setTimeout(() => {
        this.modalService.nextPage(null, null);
        this.modalService.nextPage({reserveParams: this.reserveParams, reserveSettings: this.reserveSettings}, null);
      // })
    } else {
      this.modalService.steps = [{name: 'Вход', breakPoint: 0},
                    {name: 'Выбор времени', breakPoint: 1},
                    {name: 'Абонемент', breakPoint: 3},
                    {name: 'Подтверждение', breakPoint: 5}];
      this.cards[0].minHours = this.reservationHours - this.userSubscriptionHours;
    }
  }

  showRules() {
    this.modalService.showRules();
  }

  closeModal() {
    this.modalService.onClose();
  }

  skipSubscription() {
    this.isLoading = true;
    this.modalService.nextPage(null, null);
    this.modalService.nextPage(null, null);
  }

  initUserSubscription() {
    this.userSubscriptionHours = this.userService.userData.subscription_days * FABRIC_WORK_HOURS + this.userService.userData.subscription_hours;
    this.reservationHours = this.reserveSettings.sessions.length;
  }

}
