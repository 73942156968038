import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, filter, tap } from 'rxjs/operators';

@Component({
  selector: 'app-events-search',
  templateUrl: './events-search.component.html',
  styleUrls: ['./events-search.component.styl']
})
export class EventsSearchComponent implements OnInit {
  @Output() onSelect = new EventEmitter();
  filterModel: any = {
    actual: true,
    page: 1,
    limit: 5,
    query_str: ''
  };
  focused;
  searchQuery;
  eventsList: any[] = [];
  eventsSubject$;
  eventsLoading;
  error;
  constructor(private eventsService: EventsService) { }

  ngOnInit() {
    this.eventsSubject$ = new Subject();
    this.eventsSubject$.pipe(debounceTime(300), tap(() => {
      this.eventsList = [];
      this.eventsLoading = true;
    }), switchMap((model:any) => this.eventsService.getEventsList(model)))
      .subscribe(data => {
          this.error = false;
          this.eventsList = data.result.data;
          this.eventsLoading = false;
        }, () => this.error = true, () => this.eventsLoading = false
      )
    this.updateEvents();
  }

  selectEvent(event) {
    this.onSelect.emit(event);
  }

  filterChange(filter) {
    if (filter.paramName === 'query') {
      if (filter.paramValue) {
        this.filterModel.query_str = filter.paramValue;
      } else {
        delete this.filterModel.query_str;
      }
    }
    if (!filter.stop) {
      this.updateEvents();
    }
  }

  clearField() {
    this.searchQuery = '';
    this.onInput();
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  onInput() {
    this.filterChange({paramName: 'query', paramValue: this.searchQuery});
  }

  updateEvents() {
    this.eventsSubject$.next(this.filterModel);
  }

}
