import { Pipe } from '@angular/core';

@Pipe({
  name: 'coursesSort'
})
export class CoursesSortPipe {

  transform(items: any[], param: string, direction: boolean) {
    let callback;
    function organizerSortCallback(a, b) {
      if (!a.organisation_name) {
        return 1;
      }
      if (!b.organisation_name) {
        return -1;
      }
      if (a.organisation_name.toLowerCase().trim() > b.organisation_name.toLowerCase().trim()) {
        return direction ? 1 : -1;
      }
      if (a.organisation_name.toLowerCase().trim() < b.organisation_name.toLowerCase().trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }
    function masterSortCallback(a, b) {
      if (!a.master.last_name && !a.master.first_name) {
        return 1;
      }
      if (!b.master.last_name && !b.master.first_name) {
        return -1;
      }
      if (`${(a.master.last_name || '').toLowerCase()} ${(a.master.first_name || '').toLowerCase()}`.trim() > `${(b.master.last_name || '').toLowerCase()} ${(b.master.first_name || '').toLowerCase()}`.trim()) {
        return direction ? 1 : -1;
      }
      if (`${(a.master.last_name || '').toLowerCase()} ${(a.master.first_name || '').toLowerCase()}`.trim() < `${(b.master.last_name || '').toLowerCase()} ${(b.master.first_name || '').toLowerCase()}`.trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }
    function courseSortCallback(a, b) {
      if ((a.name || '').toLowerCase().trim() > (b.name || '').toLowerCase().trim()) {
        return direction ? 1 : -1;
      }
      if ((a.name || '').toLowerCase().trim() < (b.name || '').toLowerCase().trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }
    function dateSortCallback(a, b) {
      let result = new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
      return direction ? result : -result;
    }
    function visitedSortCallback(a, b) {
      let result;
      if (a.status === 'POSTPONED' || a.status === 'PLANNED' || a.status === 'CANCELED') {
        result = -1;
      } else if (b.status === 'POSTPONED' || b.status === 'PLANNED' || b.status === 'CANCELED') {
        result = 1;
      } else {
        result = a.visited_users - b.visited_users;
      }
      return direction ? result : -result;
    }
    function paramsSortCallback(param, a, b) {
      let result = a[param] - b[param];
      return direction ? result : -result;
    }
    function defaultSortCallback(a, b) {
      return 0;
    }

    if (!items) {
      return items;
    }
    switch(param) {
      case 'master':
        callback = masterSortCallback;
        break;
      case 'organizer':
        callback = organizerSortCallback;
        break;
      case 'course':
        callback = courseSortCallback;
        break;
      case 'date':
        callback = dateSortCallback;
        break;
      case 'enrolled':
        callback = paramsSortCallback.bind(null, 'places_filled');
        break;
      case 'attended':
        callback = visitedSortCallback;
        break;
      case 'price':
        callback = paramsSortCallback.bind(null, 'cost');
        break;
      case 'sert':
        callback = paramsSortCallback.bind(null, 'certificates_count');
        break;
      default:
        callback = defaultSortCallback;
        break;
      }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.sort(callback);
  }
}
