import { Injectable } from '@angular/core';
import { pipe, Subject } from 'rxjs';
import { filter, startWith, map, mergeMap } from 'rxjs/operators';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class RoutesDataService {
  updateDataSubject$ = new Subject();
  currentData;
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute) {
                this.router.events
                  .pipe(
                    filter(event => event instanceof NavigationEnd),
                    startWith(this.activatedRoute),
                    map(() => this.activatedRoute),
                    map(route => {
                      while (route.firstChild) {
                        route = route.firstChild;
                      }
                      return route;
                    }),
                    mergeMap(route => route.data)
                  )
                  .subscribe((data:any) => {
                    this.currentData = data;
                    this.currentData.url = this.router.url.split('?')[0];
                    // this.currentData.id = this.router.url.split('?')[0];
                    this.updateDataSubject$.next(data);
                  });
              }


}
