import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessagesService } from '../../services/messages.service';
import { Router } from '@angular/router';
import { EventsService } from '../../services/events.service';
import { CalendarService } from '../../services/calendar.service';
import { CoursesService } from '../../services/courses.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { ModalService } from '../../services/modal.service';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { areaTypesShort,  areaTypesEquipmentsShort } from '../../utils';
import { UserService } from '../../services/user.service';
import { TranslitService } from '../../services/translit.service';

@Component({
  selector: 'app-event-calendar-item',
  templateUrl: './event-calendar-item.component.html',
  styleUrls: ['./event-calendar-item.component.styl']
})
export class EventCalendarItemComponent implements OnInit {
  @Input() event: any;
  @Input() hideDate: any;
  @Input() past: boolean;
  @Input() history: boolean;
  @Output() updateEmitter = new EventEmitter();
  modalStatus;
  modalOpen;
  currentRoute;
  spaces: any = {};
  unregisterLoading;
  admin;
  constructor(private router: Router,
              private messagesService: MessagesService,
              private calendarService: CalendarService,
              private eventsService: EventsService,
              private coursesService: CoursesService,
              private userService: UserService,
              private currentCourseService: CurrentCourseService,
              private modalService: ModalService,
              private cancelRegistrationService: CancelRegistrationService,
              private translitService: TranslitService) { }

  ngOnInit() {
    areaTypesEquipmentsShort.forEach(item => {
      this.spaces[item.value.toLowerCase()] = item.title;
    })
    this.currentRoute = this.router.url.split('?')[0];
    this.admin = this.userService.userData.role === 'SUPER_ADMIN' ||  this.userService.userData.role === 'ADMIN';
    if (Array.isArray(this.event.mini_courses)) {
      this.event.mini_courses.sort((a, b) => a.start_time - b.start_time);
    }

  }

  getDescription() {
    let result = [];
    if (this.event.areas && this.event.areas.length) {

      if (+this.event.areas.length === 1) {
        result.push('Пространство «' + this.spaces[this.event.areas[0].toLowerCase()] + '»');
      } else if (!this.event.areas.length) {
        result.push('Пространство «' + this.spaces[this.event.areas.toLowerCase()] + '»');
      } else {
        let str = '';
        this.event.areas.forEach((item, index) => {
          if (index === 0) {

            str += `«${this.spaces[item.toLowerCase()]}»`;
          } else if (index === this.event.areas.length - 1) {
            str += ` и «${this.spaces[item.toLowerCase()]}»`;
          } else {
            str += `, «${this.spaces[item.toLowerCase()]}»`;
          }
        });
        result.push('Пространства ' + str);
      }
    } else {
      return '';
    }
    if (this.event.master && this.event.master.first_name) {
      result.push('мастер ' + this.event.master.first_name);
    }
    return result.join(', ');
  }

  moveCourse(event) {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'move-course', course: event.entity_second_id}});
  }

  cancelCourse() {
    this.cancelRegistrationService.cancelCourse(this.event);
  }

  cancelEvent() {
    this.cancelRegistrationService.cancelEvent(this.event);
  }


  cancelConsult(id) {
    this.cancelRegistrationService.cancelConsult(this.event);
  }

  cancelReservation() {
    this.cancelRegistrationService.cancelReservation(this.event.entity_id, this.event.equipment_timing_ids, this.event.user_id, this.event)
  }



  modalClose() {
    this.modalService.unfreezBody();
    this.modalOpen = false;
  }

  routeToPage() {
    if (this.event.entity_type === 'COURSE') {
      this.router.navigate(['/master-klassy/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_second_id || this.event.entity_id)])
    } else if (this.event.entity_type === 'EVENT') {
      this.router.navigate(['/sobytiya/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_id)])
    }
  }

  getRoute() {
    if (this.event.entity_type === 'COURSE') {
      return ['/master-klassy/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_second_id || this.event.entity_id)];
    } else if (this.event.entity_type === 'EVENT') {
      return ['/sobytiya/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_id)];
    } else if (this.event.entity_type === 'EQUIPMENT') {
      return ['/oborudovanie/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_id)];
    }
  }
}
