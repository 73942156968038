import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessagesService {
  type;
  text;
  iconType;
  smallSize;
  messageVisible;
  messageFadeOut;
  func;
  action;
  timer1;
  timer2;
  timer3
  constructor() { }

  showMessage(text, action?, iconType?, smallSize?) {
    clearTimeout(this.timer1);
    clearTimeout(this.timer2);
    clearTimeout(this.timer3);
    if (this.messageVisible) {
      this.hideMessage();
      this.timer1 = setTimeout(() => {
        this.showMessage(text, action, iconType, smallSize);
      }, 500);
    } else {
      if (action) {
        this.action = action;
      } else {
        this.action = null;
      }
      if (iconType && iconType !== 'smile') this.iconType = 'check'
      else this.iconType = iconType;
      this.smallSize = !!smallSize;
      this.text = text;
      this.messageVisible = true;
      this.messageFadeOut = false;
      this.timer2 = setTimeout(() => {
        this.hideMessage();
      }, 5000)
    }
  }

  hideMessage() {
    this.messageFadeOut = true;
    this.timer3 = setTimeout(() => {
      this.messageVisible = false;
    }, 500);
  }
}
