import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { EventsService } from '../../services/events.service';
import { CurrentEventService } from '../../services/current-event.service';
import { parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-modal-appointment-for-event',
  templateUrl: './modal-appointment-for-event.component.html',
  styleUrls: ['./modal-appointment-for-event.component.styl']
})
export class ModalAppointmentForEventComponent implements OnInit {
  finalStep = 2;
  currentStep:number = 0;
  state:any = {};
  isLoading;
  error;
  registerLoading;
  errorMessage;
  eventId;
  constructor(private modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private eventsService: EventsService,
              private messagesService: MessagesService,
              private currentEventService: CurrentEventService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.user) {
      this.selectUser({user: {id: this.activatedRoute.snapshot.queryParams.user}});
    }
    if (this.activatedRoute.snapshot.queryParams.event) {
      this.eventId = this.activatedRoute.snapshot.queryParams.event;
    }
  }

  selectUser(state) {
    this.isLoading = true;
    this.userService.getUserData(state.user.id).subscribe((data) => {
      if (data.result) {
        this.isLoading = false;
        this.nextStep({
          user: data.result
        });
      } else {
        this.error = true;
      }
    }, () => this.error = true)
  }

  selectEvent(eventId) {
    this.isLoading = true;
    this.eventsService.getEvent(eventId).subscribe((data) => {
      this.isLoading = false;
      this.nextStep({
        event: data.result
      });
    }, () => {
      this.error = true;
      this.errorMessage = 'Данное событие недоступно.'
    })
  }

  nextStep(state) {

    let checkStep = () => {
      this.currentStep++;
      this.state = {
        ...this.state,
        ...state
      };
      if (state.event && state.event.status === 'PASSED') {
        this.error = true;
        this.errorMessage = 'Это событие уже прошло.'
      } else if (state.event && !state.event.need_registration) {
        this.error = true;
        this.errorMessage = 'На это событие не нужна регистрация.'
      } else if (state.event && (state.event.places_amonut - state.event.places_filled <= 0)) {
        this.error = true;
        this.errorMessage = 'На это событие нет свободных мест.'
      } else if (state.event && state.event.status === 'CANCELLED') {
        this.error = true;
        this.errorMessage = 'Это событие отменено.'
      }
      if (this.currentStep > this.finalStep) {
        this.submit();
      }
      if (this.currentStep === 1 && this.eventId) {
        this.selectEvent(this.eventId);
      }
    }

    if (this.currentStep === 1 && state.event || state.user && this.eventId && this.currentStep === 0) {
      this.eventsService.getRegisteredUsers( +this.eventId || state.event.id).subscribe(data => {
        let currentUserId = this.state.user && this.state.user.id || state.user && state.user.id;
        if (data.result.length && data.result.some(eventItem => eventItem.user.id === currentUserId)) {
          this.messagesService.showMessage('Клент уже записан на это событие');
        } else {
          checkStep();
        }
      });
    } else {
      checkStep();
    }
  }

  submit() {
    let model = {
      register: true,
      user_id: this.state.user.id,
      payment_type: this.state.userPaid ? 'ONLINE' : 'ON_THE_SPOT'
    }
    this.registerLoading = true;
    this.eventsService.register(this.state.event.id, model).subscribe((data) => {
      if (this.state.userPaid) {
        this.eventsService.paid(this.state.event.id, this.state.user.id, true).subscribe((paid) => {
          this.currentEventService.updateEmitter();
          this.onSuccess({result: this.state.event}, paid.result);
        }, () => {this.error = true; this.registerLoading = false})
      } else {
        this.currentEventService.updateEmitter();
        this.onSuccess({result: this.state.event}, false);
      }
    }, (err) => {
      let error = parseApiError(err);
      if (error[0].field === 'already_registered') {
        this.errorMessage = 'Пользователь уже зарегистрирован на это событие'
      }
      this.error = true;
      this.registerLoading = false;
    })
  }

  onSuccess(data, paid) {
    this.registerLoading = false
    this.modalService.nextPage({
      appointForEventPaid: paid,
      appointForEventParam: {
        name: this.state.user.name,
        event_name: this.state.event.name,
        cost: this.state.event.cost,
        start_date: data.result.start_date,
        end_date: data.result.end_date,
        start_time: data.result.start_time,
        end_time: data.result.end_time
      }
    }, null);
  }

  back() {
    this.modalService.back();
  }

}
