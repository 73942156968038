import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { ConsultationsService } from '../../services/consultations.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { spaces, parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-modal-appointment-for-consult',
  templateUrl: './modal-appointment-for-consult.component.html',
  styleUrls: ['./modal-appointment-for-consult.component.styl']
})
export class ModalAppointmentForConsultComponent implements OnInit {
  finalStep = 2;
  currentStep:number = 0;
  state:any = {};
  isLoading;
  error;
  registerLoading;
  showOrderBtn;
  selectedConsult;
  selectedArea;
  constructor(private modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private consultationsService: ConsultationsService,
              private currentCourseService: CurrentCourseService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.user) {
      this.selectUser({user: {id: this.activatedRoute.snapshot.queryParams.user}});
    }
  }

  selectUser(state) {
    this.isLoading = true;
    this.userService.getUserData(state.user.id).subscribe((data) => {
      if (data.result) {
        this.isLoading = false;
        this.nextStep({
          user: data.result
        });
      } else {
        this.error = true;
      }
    }, () => this.error = true)
  }

  nextStep(state) {
    this.currentStep++;
    this.state = {
      ...this.state,
      ...state
    };
    if (this.currentStep > this.finalStep) {
      this.submit();
    }
  }

  selectArea(area) {
    this.selectedArea = area;
  }

  submit() {
    let model = {
      available_consultation_id: this.state.consult.id,
      user_id: this.state.user.id,
      payment_type: this.state.userPaid ? 'ONLINE' : 'ON_THE_SPOT'
    }
    this.registerLoading = true;
    this.consultationsService.orderClient(model).subscribe((data) => {
      if (this.state.userPaid) {
        this.consultationsService.paid(data.result.id, true).subscribe((paid) => {
          this.currentCourseService.updateEmitter();
          this.onSuccess(data, paid.result);
        }, () => this.error = true)
      } else {
        this.currentCourseService.updateEmitter();
        this.onSuccess(data, false);
      }
    }, (e) => {
      this.registerLoading = false
      let error = parseApiError(e);
      this.selectedConsult = null;
      this.selectedArea = null;
      this.currentStep = 1;
      if (error === 'already_registered') {
        this.messagesService.showMessage('У пользователя уже есть запись на это время.', false, false, true);
      } else {
        this.messagesService.showMessage('Ошибка при записи пользователя на консультацию.', false, false, true);
      }
    })
  }

  onSuccess(data, paid) {
    this.registerLoading = false
    this.modalService.nextPage({
      consultPaid: paid,
      consultParams: {
        name: this.state.user.name,
        master: this.state.consult.maker.first_name,
        area: spaces[data.result.area.toLowerCase()],
        cost: data.result.cost,
        start_date: data.result.start_time,
        start_time: data.result.start_time,
        end_time: data.result.end_time
      }
    }, null);
  }

  back() {
    this.modalService.back();
  }

  selectConsult(evt) {
    this.selectedConsult = evt;
  }

  orderConsult() {
    this.currentStep++;
  }

  clientPaid(payNow) {
    this.modalService.nextPage({consultPaid: payNow, consultParams:
      {
        name: 'Всеволод',
        master: 'Андрей',
        area: 'Дерево',
        cost: 4000,
        start_date: 1526342400000,
        start_time: 18000000,
        end_time: 25200000
      }}, null);
  }

  closeModal() {
    this.modalService.onClose();
  }

}
