import { Directive, ElementRef, Input, Renderer, OnChanges, SimpleChanges, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { encodeBase64 } from '../utils';
declare var $;

@Directive({
  selector: '[appImagePreview]'
})
export class ImagePreviewDirective {
  @Input() image: any;
  @Input() useImgTag: boolean;
  @Input() exif: boolean;
  isBrowser;
  constructor(private el: ElementRef, private renderer: Renderer, @Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.isBrowser) {
      let reader = new FileReader();
      let el = this.el;
      el.nativeElement.style.backgroundSize = 'cover';
      el.nativeElement.style.backgroundPosition = 'center';
      let that = this;
      if (this.isBrowser)
      if (!this.exif) {
        reader.onloadend = function (e) {
          if (!that.useImgTag) {
            el.nativeElement.style.backgroundImage = `url(${reader.result})`;
          } else {
            let imgElem = $(`<img src="${reader.result}">`).css('height', '100%')
            imgElem.css('maxHeight', '100%')
            imgElem.css('maxWidth', '100%')
            $(el.nativeElement).append(imgElem);
          }
        };

        if (this.image) {
           return reader.readAsDataURL(this.image);
        } else {
          el.nativeElement.style.backgroundImage = 'none';
        }
      } else {
       var rotation = {
         1: 'rotate(0deg)',
         3: 'rotate(180deg)',
         6: 'rotate(90deg)',
         8: 'rotate(270deg)'
       };
       var _arrayBufferToBase64 = function(buffer) {
         var binary = ''
         var bytes = new Uint8Array(buffer)
         var len = bytes.byteLength;
         for (var i = 0; i < len; i++) {
           binary += String.fromCharCode(bytes[i])
         }
         return encodeBase64(binary);
       }
        var orientation = function(file, callback) {
          var fileReader:any = new FileReader();
          fileReader.onloadend = function() {
            var base64img = "data:" + file.type + ";base64," + _arrayBufferToBase64(fileReader.result);
            var scanner = new DataView(fileReader.result);
            var idx = 0;
            var value = 1; // Non-rotated is the default
            if (fileReader.result.length < 2 || scanner.getUint16(idx) != 0xFFD8) {
              // Not a JPEG
              if (callback) {
                callback(base64img, value);
              }
              return;
            }
            idx += 2;
            var maxBytes = scanner.byteLength;
            while (idx < maxBytes - 2) {
              var uint16 = scanner.getUint16(idx);
              idx += 2;
              switch (uint16) {
                case 0xFFE1: // Start of EXIF
                  var exifLength = scanner.getUint16(idx);
                  maxBytes = exifLength - idx;
                  idx += 2;
                  break;
                case 0x0112: // Orientation tag
                  // Read the value, its 6 bytes further out
                  // See page 102 at the following URL
                  // http://www.kodak.com/global/plugins/acrobat/en/service/digCam/exifStandard2.pdf
                  value = scanner.getUint16(idx + 6, false);
                  maxBytes = 0; // Stop scanning
                  break;
              }
            }
            if (callback) {
              callback(base64img, value);
            }
          }
          fileReader.readAsArrayBuffer(file);
        };
        orientation(this.image, function(base64img, value) {
          // $('#placeholder1').attr('src', base64img);
          let imgElem = $(`<img src="${base64img}">`)
          imgElem.css('maxHeight', '100%')
          imgElem.css('maxWidth', '100%')
          $(el.nativeElement).append(imgElem);
          if (value) {
            imgElem.css('transform', rotation[value]);
          }
        });
      }
    }
  }
}
