import { Component, OnInit, OnDestroy, AfterViewInit, Input, Output, EventEmitter } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-toggle-paid',
  templateUrl: './toggle-paid.component.html',
  styleUrls: ['./toggle-paid.component.styl']
})
export class TogglePaidComponent implements OnInit {
  @Input() isCourse;
  @Input() active;
  @Input() user;
  @Input() event;
  @Input() calendarItem;
  @Input() withConfirm;
  @Input() sert: boolean;
  @Input() outerPopup;
  @Output() toggleEmitter = new EventEmitter();
  @Output() openPopupEmitter = new EventEmitter();
  loaded;

  confirmOpened;
  componentDestroyed$ = new Subject();
  confirmPaid$;

  constructor(private modalService: ModalService) { }

  ngOnInit() {

  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  ngAfterViewInit() {
    this.loaded = true;
  }

  toggle() {
    if (!this.active && this.withConfirm) {
      // this.confirmOpened = true;
      this.componentDestroyed$.next();
      let confirm$ = new Subject();
      confirm$.pipe(first(), takeUntil(this.componentDestroyed$)).subscribe((data) => {
        this.toggleEmitter.emit(data);
      });
      let config = {
        event: this.event,
        user: this.user,FormData,
        sert: this.sert,
        isCourse: this.isCourse,
        confirm$: confirm$
      }
      this.modalService.setConfirmPaid(config);
    }
  }

  // reject() {
  //   this.confirmOpened = false;
  // }

  // confirm () {
  //   if (this.isCourse) {
  //     if (this.paidForm.value.cause == 3 && this.showSertNumber && this.paidForm.value.number) {
  //       this.toggleEmitter.emit({ certificateNumber: this.paidForm.value.number });
  //     } else if (this.paidForm.value.cause == 2) {
  //       this.toggleEmitter.emit();
  //     }
  //   } else {
  //     this.toggleEmitter.emit();
  //   }
  //   this.reject();
  // }

}