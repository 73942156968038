import { Pipe} from '@angular/core';
import { FABRIC_WORK_HOURS } from '../config';
@Pipe({
  name: 'clientsSort'
})
export class ClientsSortPipe {

  transform(items: any[], param: string, direction: boolean) {
    let callback;
    function nameSortCallback(a, b) {
      if (!a.client.last_name && !a.client.first_name) {
        return 1;
      }
      if (!b.client.last_name && !b.client.first_name) {
        return -1;
      }
      if (`${(a.client.last_name || '').toLowerCase()} ${(a.client.first_name || '').toLowerCase()}`.trim() > `${(b.client.last_name || '').toLowerCase()} ${(b.client.first_name || '').toLowerCase()}`.trim()) {
        return direction ? 1 : -1;
      }
      if (`${(a.client.last_name || '').toLowerCase()} ${(a.client.first_name || '').toLowerCase()}`.trim() < `${(b.client.last_name || '').toLowerCase()} ${(b.client.first_name || '').toLowerCase()}`.trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }
    function subscriptionSortCallback(a, b) {
      let result = (a.subscription_days * FABRIC_WORK_HOURS + a.subscription_hours) - (b.subscription_days * FABRIC_WORK_HOURS + b.subscription_hours);
      return direction ? result : -result;
    }
    function paramsSortCallback(param, a, b) {
      let result = a[param] - b[param];
      return direction ? result : -result;
    }
    function defaultSortCallback(a, b) {
      return 0
    }

    if (!items) {
      return items;
    }
    switch(param) {
      case 'name':
        callback = nameSortCallback;
        break;
      case 'subscription':
        callback = subscriptionSortCallback;
        break;
      case 'worktime':
        callback = paramsSortCallback.bind(null, 'hours_in_fabric');
        break;
      case 'coursescount':
        callback = paramsSortCallback.bind(null, 'courses_count');
        break;
      case 'worktimeCert':
        callback = paramsSortCallback.bind(null, 'hours_by_certificates');
        break;
      case 'coursescountCert':
        callback = paramsSortCallback.bind(null, 'courses_certificates_count');
        break;
      case 'consultscount':
        callback = paramsSortCallback.bind(null, 'consultations_count');
        break;
      default:
        callback = defaultSortCallback;
        break;
      }

    return items.sort(callback);
  }


}
