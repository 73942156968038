import { Directive, Input, Output, EventEmitter, OnInit, OnDestroy, ElementRef, ChangeDetectorRef } from '@angular/core';
import { IsBrowserService } from '../services/is-browser.service';
import { first } from 'rxjs/operators';
@Directive({
  selector: '[appClickOutside]'
})
export class ClickOutsideDirective implements OnDestroy {
  @Input() always;
  @Input() exclude;
  @Output() onClickOutside = new EventEmitter();
  subscriptions = [];
  constructor(private isBrowserSrevice: IsBrowserService,
              private _ref: ChangeDetectorRef,
              private elem: ElementRef) {

  }

  ngOnInit() {
    if (this.always) {
      this.enable();
    }
  }

  enable() {
    setTimeout(() => {
      this.subscriptions.push(this.isBrowserSrevice.windowClickSubject$.subscribe((e:any) => {
        this.checkClick(e);
      }));
    })
  }

  enableOnce() {
    setTimeout(() => {
      this.isBrowserSrevice.windowClickSubject$.pipe(first()).subscribe((e:any) => {
        this.checkClick(e);
      })
    })
  }

  disable() {
    this.clearSubscriptions();
  }

  clearSubscriptions() {
    this.subscriptions.forEach(item => {
      if (item && item.unsubscribe) {
        item.unsubscribe();
      }
    })
  }

  checkClick(e) {
    if (!this.isDescendant(this.elem.nativeElement, e.target)) {
      this.onClickOutside.emit();
      this._ref.detectChanges();
    }
  }

  ngOnDestroy() {
    this.clearSubscriptions();
  }

  private isDescendant = (parent, child) => {
    var node = child;
    while (node != null) {
        if (this.exclude && this.checkExclude(node)) {
          return true
        }
        if (node == parent) {
            return true;
        }
        node = node.parentNode;
    }
      return false;
  }

  checkExclude(node) {
    return this.exclude.split(',').map(q => document.querySelector(q.trim())).some(el => el == node);
  }

}
