import { Injectable } from '@angular/core';
import { IsBrowserService } from './is-browser.service';
import { RoutesDataService } from './routes-data.service';

const STORAGE_KEY = 'lm_local_pages_data';

@Injectable({
  providedIn: 'root'
})
export class HistoryService {
  currentState;
  previousState;
  currentDepth;
  previousDepth;
  usedPopState;
  usedBackBtn;
  redirect404;
  history = [];
  pagesData: any = {};
  w;
  constructor(private isBrowserService: IsBrowserService,
              private routesDataService: RoutesDataService) {
    if (this.isBrowserService.isBrowser) {
      this.w = window;
      let key = this.w.localStorage.getItem(STORAGE_KEY);
      this.pagesData = key ? JSON.parse(key) : {};
      window.onpopstate = function( e ) {
        this.usedPopState = true;
      }.bind(this);

    }
  }

  setPrevState(url) {
    // alert(url);
    let uri = decodeURI(url);
    if (!this.currentState || this.currentState.split('?')[0] !== uri.split('?')[0]) {
      this.previousState = this.currentState;
      this.currentState = uri;
      this.previousDepth = this.currentDepth;
      this.currentDepth = this.routesDataService.currentData.depth;
    } else {
      this.currentState = uri;
      this.currentDepth = this.routesDataService.currentData.depth;
    }
  }

  updatePagesData(pageName, data) {
    this.pagesData[pageName] = data;
    if (this.w) {
      this.w.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.pagesData));
    }
  }

  addPageData(pageName, data) {
    this.pagesData[pageName] = { ...this.pagesData[pageName], ...data };
    if (this.w) {
      this.w.localStorage.setItem(STORAGE_KEY, JSON.stringify(this.pagesData));
    }
  }

  clearPagesData() {
    if (this.w) {
      this.w.localStorage.removeItem(STORAGE_KEY);
    }
  }
}
