import { Component, OnInit, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { ModalService } from './services/modal.service';
import { UserService } from './services/user.service';
import { TokenService } from './services/token.service';
import { MessagesService } from './services/messages.service';
import { CreationService } from './services/creation.service';
import { ClientPopupService } from './services/client-popup.service';
import { Router, NavigationEnd, NavigationStart } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { PreviousStateService } from './services/previous-state.service';
import { CancelRegistrationService } from './services/cancel-registration.service';
import { AuthService } from './services/auth.service';
import { Subject } from 'rxjs';
import { RoutesDataService } from './services/routes-data.service';
import { takeUntil, first } from 'rxjs/operators';
import { IsBrowserService } from './services/is-browser.service';
import { SeoService } from './services/seo.service';
import { HistoryService } from './services/history.service';

const USER_KEY = makeStateKey('user');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.styl']
})
export class AppComponent implements OnInit, OnDestroy {
  rout: any;
  isBrowser: any;
  showHeader = true;
  queryParamsChecked;
  componentDestroyed$ = new Subject();
  w: any;
  constructor(
    private http: HttpClient,
    private state: TransferState,
    public modalService: ModalService,
    public userService: UserService,
    public tokenService: TokenService,
    public messagesService: MessagesService,
    public creationService: CreationService,
    public clientPopupService: ClientPopupService,
    public cancelRegistrationService: CancelRegistrationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private previousStateSetvice: PreviousStateService,
    private authService: AuthService,
    private routesDataService: RoutesDataService,
    private _ref: ChangeDetectorRef,
    private isBrowserService: IsBrowserService,
    private seoService: SeoService,
    private historyService: HistoryService,
    @Inject('FB_APP_ID') private fbAppId: string
  ) {
    this.rout = router;
    this.isBrowser = this.isBrowserService.isBrowser;
    if (this.isBrowser) {
      this.w = window;
    }
  }

ngOnInit() {
  this.userService.getUserDataFromStorage();
  this.initRoutings();
  if (this.isBrowser) {
    // this.initFB(); facebook is blocked RKN
    this.modalService.closeModalLoaderSubject$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this._ref.detectChanges();
    })
    window.addEventListener("dragover", function(e) {
      e.preventDefault();
    }, false);
    window.addEventListener("drop", function(e) {
      e.preventDefault();
    }, false);
  }
  this.routesDataService.updateDataSubject$.subscribe((data:any) => {
    this.showHeader = !data.hideHeader;
    // if (data.whiteBack) {
    //   document.body.style.backgroundColor = '#fff';
    // } else {
    //   document.body.style.backgroundColor = '#f5f5f5';
    // }
    // if (data.title) {
    //   this.w.pageTitle = data.title;
    // } else {
    //   this.w.pageTitle = '';
    // }
  });
  if (this.isBrowserService.isBrowser && this.isBrowserService.isIosDevice) {
    document.addEventListener('focusin', () => {
      this.isBrowserService.setFocus(true);
    })
    document.addEventListener('focusout', () => {
      this.isBrowserService.setFocus(false);
    })
  }
}

ngOnDestroy() {
  this.componentDestroyed$.next();
  this.componentDestroyed$.complete();
}

checkQueryParams() {
  let result$ = new Subject();
  let initUser = function(userId, token, queryParams = {}) {
    let subscription = this.authService.initUser(userId, token).subscribe((data) => {
      if (data) {
        this.tokenService.deleteToken();
        this.router.navigate(['/']);
        this.messagesService.showMessage('Ошибка авторизации', null, false, true);
        subscription.unsubscribe();
        this.modalService.setModalLoader(false);
      } else {
        let currentRoute = this.router.url.split('?')[0];
        setTimeout(() => {
          this.router.navigate([currentRoute], {queryParams: queryParams});
        })
        subscription.unsubscribe();
        this.modalService.setModalLoader(false);
        setTimeout(() => {
          if (!this.userService.userData.phone_confirmed) {
            this.router.navigate(['/confirm-phone']);
          } else if (this.userService.needConfirmNameOrSurname()) {
            this.router.navigate(['/confirm-name-surname']);
          }
        })
      }
        result$.next();
      })
  }.bind(this);
  let queryParams = {...this.activatedRoute.snapshot.queryParams};
  let token = queryParams.social_auth_token;
  let userId = queryParams.user_id;
  delete queryParams.social_auth_token;
  delete queryParams.user_id;
  delete queryParams.just_registered;
  if (queryParams.popup = 'login') {
    delete queryParams.popup;
  }
  if (userId && token) {
    this.modalService.setModalLoader(true);
    initUser(userId, token, queryParams);
  }
}

initFB() {
  let that = this;
  this.w.fbAsyncInit = function() {
    that.w.FB.init({
      appId      : that.fbAppId,
      cookie     : true,
      xfbml      : true,
      version    : 'v3.0'
    });
    that.w.FB.AppEvents.logPageView();
  };

  (function(d, s, id){
     var js, fjs = d.getElementsByTagName(s)[0];
     if (d.getElementById(id)) {return;}
     js = d.createElement(s); js.id = id;
     js.src = "https://connect.facebook.net/en_US/sdk.js";
     fjs.parentNode.insertBefore(js, fjs);
   }(document, 'script', 'facebook-jssdk'));
}

initRoutings() {
  this.router.events
    .pipe(takeUntil(this.componentDestroyed$))
    .subscribe(event => {
    if (event instanceof NavigationEnd) {
      if (!this.queryParamsChecked) {
        this.checkQueryParams();
        this.initActivatedRouteListener();
        this.queryParamsChecked = true;
      }
      // this.showHeader = !(event.urlAfterRedirects.indexOf('/create-password') + 1) && !(event.urlAfterRedirects.indexOf('/recovery-password') + 1) && !(event.urlAfterRedirects.indexOf('/print') + 1) && !(event.urlAfterRedirects.indexOf('/pdf') + 1) && !(event.urlAfterRedirects.indexOf('/404') + 1) && !(event.urlAfterRedirects.indexOf('/confirm-phone') + 1);
      // this.previousStateSetvice.setPrevState(event.url);
      if (!this.historyService.redirect404) {
        this.historyService.setPrevState(event.url);
        if (!this.historyService.usedPopState && !this.historyService.usedBackBtn && this.historyService.previousState && this.routesDataService.currentData && this.routesDataService.currentData.pageName) {
          let data:any = {};
          data[this.routesDataService.currentData.url] = { previous: { url: this.historyService.previousState, depth: this.historyService.previousDepth } };
          this.historyService.addPageData(this.routesDataService.currentData.pageName, data);
        }
      }
      this.historyService.redirect404 = false;
      this.historyService.usedPopState = false;
      this.historyService.usedBackBtn = false;
    }
    if (event instanceof NavigationStart) {
      if (this.tokenService.isTokenExist() && this.tokenService.getTempPwd() && !(event.url.indexOf('/create-password') + 1)) {
        this.router.navigate(['/create-password']);
      }
      if ((event.url.indexOf('/create-password') + 1) && !this.tokenService.getTempPwd()) {
        this.router.navigate(['/']);
      }
    }
  });
}

initActivatedRouteListener() {
  this.activatedRoute.queryParams.subscribe(params => {
    if (this.modalService.isModalOpened || this.creationService.isCreationModalOpened || this.clientPopupService.popupOpened) {
      if (!params.clientpopup && !params.popup && !params.creation && !params.editidea && !params.editevent && !params.editequipment && !params.editcourse && !params.popupmultistep) {
        this.creationService.closeCreationModal(true);
        this.clientPopupService.closeClientModal(true);
      }
    }
    if (params.popup) {
      this.creationService.isCreationModalOpened = false;
      if (this.modalService.isModalOpened) {
        this.modalService.reset(params.popup);
      } else {
        this.modalService.isModalOpened = true;
        this.modalService.openModal(params.popup);
      }
    } else if (params.creation) {
      this.creationService.openCreationModal(params.creation);
    } else if (params.editidea) {
      this.creationService.openCreationModal('idea');
    } else if (params.editevent) {
      this.creationService.openCreationModal('event');
    } else if (params.editequipment) {
      this.creationService.openCreationModal('equipment');
    } else if (params.editcourse) {
      this.creationService.openCreationModal('course');
    } else if (params.clientpopup) {
      this.clientPopupService.openClientModal(params.clientpopup);
    }});
  }

  isArray(a): boolean {
    return Array.isArray(a);
  }
}
