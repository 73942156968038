import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-date-select',
  templateUrl: './date-select.component.html',
  styleUrls: ['./date-select.component.styl']
})
export class DateSelectComponent implements OnInit {
  @Input() control;
  @Input() dates: Array<{
    value: number;
    data: any;
  }>;

  @Output() onSelectEmitter = new EventEmitter();
  pickerOpened;

  constructor() { }

  ngOnInit() {
    
  }

  selectDate(item) {
    setTimeout(() => {
      this.closePicker();
    }, 0);

    if (this.control) {
      this.control.setValue({
        ...this.control.value,
        ...item
      });
      this.control.markAsTouched();
      this.onSelectEmitter.emit(this.control.value);
    }
  }

  openPicker() {
    this.pickerOpened = true;
  }

  closePicker() {
    if (this.pickerOpened) {
      this.pickerOpened = false;
      if (this.control) {
        this.control.markAsTouched();
      }
    }
  }
}

