import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { EquipmentsService } from '../../services/equipments.service';
import { CurrentEquipmentService } from '../../services/current-equipment.service';
import { forkJoin } from 'rxjs';
import { parseApiError } from '../../utils';

@Component({
  selector: 'app-modal-reserve-instrument-loading',
  templateUrl: './modal-reserve-instrument-loading.component.html',
  styleUrls: ['./modal-reserve-instrument-loading.component.styl']
})
export class ModalReserveInstrumentLoadingComponent implements OnInit {
  @Input() reserveParams;
  @Input() reserveSettings;
  @Input() moveTiming;
  @Input() userId;
  isLoading;
  error;
  errorMessage;
  constructor(private modalService: ModalService,
              private equipmentsService: EquipmentsService,
              private currentEquipmentService: CurrentEquipmentService) { }

  ngOnInit() {
    this.reserveEquip();
  }

  reserveEquip() {
    this.isLoading = true;
    let reserveQuery = this.equipmentsService.reserveEquip(
        this.reserveSettings.equipmentId,
        {
          ...this.reserveParams,
          timings: this.reserveSettings.parsedSessionsTime.map(sessionTime => ({
            date: sessionTime.date,
            start_time: sessionTime.start_time,
            end_time: sessionTime.end_time,
            consultation_id: sessionTime.consultation_id,
            course_timing_id: sessionTime.course_timing_id
          }))
        }
      )
    if (this.moveTiming && this.userId) {
      this.isLoading = true;
      let cancelQuery = this.equipmentsService.cancelReservation(this.moveTiming.split(','), this.userId)
      forkJoin(reserveQuery, cancelQuery).subscribe((data) => {
        this.isLoading = false;
        this.currentEquipmentService.updateReserveEmitter();
        this.currentEquipmentService.updateEmitter();
        this.modalService.nextPage(null, null);
      }, (e) => {
        let error = parseApiError(e);
        if (error === 'reservation_type_does_not_match') {
          this.errorMessage = 'Ошибка. Возможно, вы уже бронировали это оборудование на это время.'
        }
        this.isLoading = false;
        this.error = true;
      })
    } else {
      reserveQuery.subscribe(() => {
        this.isLoading = false;
        this.currentEquipmentService.updateReserveEmitter();
        this.currentEquipmentService.updateEmitter();
        this.modalService.nextPage(null, null);
      }, (e) => {
        let error = parseApiError(e);
        if (error === 'reservation_type_does_not_match') {
          this.errorMessage = 'Ошибка. Возможно, вы уже бронировали это оборудование на это время.'
        }
        this.error = true;
      });
    }
  }

}
