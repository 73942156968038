import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { EquipmentsService } from '../../services/equipments.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-modal-multistep-reserve-instrument',
  templateUrl: './modal-multistep-reserve-instrument.component.html',
  styleUrls: ['./modal-multistep-reserve-instrument.component.styl']
})
export class ModalMultistepReserveInstrumentComponent implements OnInit {
  @Input()
  set state(state) {
    if (state && state.equipment) {
      this._state = state;
      this.setupParams();
    }
  }

  get state() {
    return this._state;
  }

  @Output() onClose = new EventEmitter();
  @Output() onSuccess = new EventEmitter();

  _state;
  equipment;
  reserveParams;
  reserveLoading = true;
  loadingMessage;
  isError;
  error;

  constructor(
    private activatedRoute: ActivatedRoute,
    private equipmentsService: EquipmentsService,
  ) {}

  ngOnInit() {

  }

  setupParams() {
    this.reserveLoading = true;
    this.loadingMessage = 'Загрузка инструмента...';
    forkJoin(
      this.equipmentsService.getEquipment(this.state.equipment.id),
      this.equipmentsService.getReservitionInfo(this.state.equipment.id, 'FOR_ME'),
    )
    .pipe(first())
    .subscribe(([equipment, reserveParams]) => {
      this.equipment = equipment.result;
      this.reserveParams = reserveParams.result;
      this.reserveLoading = false;
    }, () => {this.reserveLoading = false});
  }

  onSubmit(data) {
    this.reserveLoading = true;
    this.loadingMessage = 'Подождите...';

    this.equipmentsService.reserveEquip(
        data.reserveSettings.equipmentId,
        {
          ...data.reserveParams,
          timings: data.reserveSettings.parsedSessionsTime.map(sessionTime => ({
            date: sessionTime.date,
            start_time: sessionTime.start_time,
            end_time: sessionTime.end_time,
          }))
        }
      ).subscribe(_ => {
        this.onSuccess.next(data);
      }, err => {
        this.isError = true;
        this.error = JSON.parse(err._body).error;
        this.reserveLoading = false;
      });
  }

  handleClose() {
    this.onClose.next();
  }

}
