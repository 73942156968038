import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
// import * as bodymovin from 'bodymovin';

@Component({
  selector: 'app-like-button',
  templateUrl: './like-button.component.html',
  styleUrls: ['./like-button.component.styl']
})
export class LikeButtonComponent implements OnInit {
  @Input() active;
  @Input() likesCount;
  @Input() noShadow;
  @Input() update$;
  @Output() clickEmitter = new EventEmitter();
  @ViewChild('animContainer') animContainer: ElementRef;
  likeAnimation;
  unlikeAnimation;
  animationActive;
  liked;
  w: any;
  isBrowser;
  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.w = window;
    }
  }

  ngOnInit() {
    this.liked = !!this.active;
    if (this.update$) {
      this.update$.subscribe(() => {
        this.liked = !!this.active;
      })
    }
  }

  playLikeAnimation() {
    this.animationActive = true;
    this.resetLikeAnimation();
    this.likeAnimation.play();
    setTimeout(() => {
      this.liked = true;
    }, 200)
    setTimeout(() => {
      this.animationActive = false;
      if (this.likeAnimation) {
        this.likeAnimation.destroy();
      }
      // this.resetLikeAnimation()
    }, 1000)
  }
  playUnlikeAnimation() {
    this.resetUnlikeAnimation();
    this.animationActive = true;
    this.unlikeAnimation.play();
    setTimeout(() => {
      this.liked = false;
    }, 200)
    setTimeout(() => {
      this.animationActive = false;
      if (this.unlikeAnimation) {
        this.unlikeAnimation.destroy();
      }
      // this.resetLikeAnimation()
    }, 1000)
  }

  resetLikeAnimation() {
    if (this.unlikeAnimation) {
      this.unlikeAnimation.destroy();
    }
    if (this.likeAnimation) {
      this.likeAnimation.destroy();
    }
    this.likeAnimation = this.w.bodymovin.loadAnimation({
      container: this.animContainer.nativeElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '../../assets/animations/like.json'
    })
  }
  resetUnlikeAnimation() {
    if (this.unlikeAnimation) {
      this.unlikeAnimation.destroy();
    }
    if (this.likeAnimation) {
      this.likeAnimation.destroy();
    }
    this.unlikeAnimation = this.w.bodymovin.loadAnimation({
      container: this.animContainer.nativeElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '../../assets/animations/un-like.json'
    })
  }

  toggleLike(event) {
    this.clickEmitter.emit({event: event, elem: this});
  }

}
