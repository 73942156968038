import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { ApiService } from './api.service';
import { Subject, BehaviorSubject } from 'rxjs';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { CookiesService } from '@ngx-utils/cookies';
import { TokenService } from './token.service';

const USER_DATA_STATE_KEY = makeStateKey('user-data');
const USER_ID_STATE_KEY = makeStateKey('user-id');

@Injectable({
  providedIn: 'root'
})

export class UserService {
  public updateEmitter;
  updateSubject = new Subject();
  updateImgSubject = new Subject();
  userDeletedSubject = new Subject();
  userId;
  userData;
  constructor(private api: ApiService, private state: TransferState, private cookies: CookiesService,
              private tokenService: TokenService) {
    this.userData = this.getUserDataFromTransferState();
    const userIdFromToken = this.tokenService.getUserIdFromToken();
    if (!userIdFromToken) {
      this.deleteUser();
      this.updateSubject.next();
    }

    // CR-3198 избавляемся от устаревших куки, используем TransferState для обмена данными
    if (this.cookies.get('userData')) {
      this.cookies.remove('userData');
    }
    if (this.cookies.get('userId')) {
      this.cookies.remove('userId');
    }
  }

  setUser(userId, userData?) {
    this.userId = userId;
    if (this.userId) {
      this.state.set(USER_ID_STATE_KEY, this.userId);
    }
    if (userData) {
      this.setUserData(userData);
    }
    this.update();
  }

  setUserData(userData) {
    if (userData) {
      this.state.set(USER_DATA_STATE_KEY, userData);
    }
    this.userData = userData;
  }

  deleteUser() {
    this.userId = null;
    this.userData = null;
    this.tokenService.deleteToken();
    this.state.remove(USER_ID_STATE_KEY);
    this.state.remove(USER_DATA_STATE_KEY);
    this.update();
  }

  getUserData(userId) {
    return this.api.get(`v1/users/${userId}`);
  }
  changePassword(data) {
    return this.api.post(`v1/users/password/change`, null, data);
  }

  update() {
    this.updateSubject.next();
  }
  updateImg() {
    this.updateImgSubject.next();
  }

  updateUserData(form, userId?) {
    if (!userId) {
      userId = this.userId;
    }
    return this.api.post(`v1/users/${userId}`, null, form);
  }

  deleteUserPhoto() {
    if (this.userId) {
      return this.api.delete(`v1/users/${this.userId}/photo`, null);
    }
  }

  createPassword(password, code) {
    return this.api.post(`v1/users/password/creation`, null, {password, code});
  }

  addSubscription(amount, type) {
    if (type === 'hours') {
      this.userData.subscription_hours += +amount;
    } else if (type === 'days') {
      this.userData.subscription_days += +amount;
    }
  }

  removeUser(userId) {
    return this.api.delete(`v1/users/${userId}`);
  }

  deleteEmitter() {
    this.userDeletedSubject.next();
  }

  getUserDataFromStorage() {
    this.userId = this.state.get(USER_ID_STATE_KEY, null as any) || this.tokenService.getUserIdFromToken();

    this.userData = this.state.get(USER_DATA_STATE_KEY, null as any);
    if (this.userId) {
      if (!this.userData) {
        this.getUserData(this.userId).subscribe((data) => {
          this.setUser(this.userId, data.result);
        });
      } else {
        this.setUser(this.userId, this.userData);
      }
    }
  }

  facebook(access_token) {
    return this.api.post(`v1/users/facebook/sign_in`, null, { access_token });
  }

  resetPassword(email) {
    return this.api.post(`v1/users/password/recovery/request`, null, { email });
  }

  recoveryPassword(newPassword, code, email) {
    return this.api.post(`v1/users/password/recovery`, null, { newPassword, code, email });
  }

  getUserIdFromTransferState() {
    let result = this.state.get(USER_ID_STATE_KEY, null as any);
    if (result === null) {
      this.state.remove(USER_ID_STATE_KEY);
    }
    return result;
  }

  getUserDataFromTransferState() {
    let result = this.state.get(USER_DATA_STATE_KEY, null as any);
    if (result === null) {
      this.state.remove(USER_DATA_STATE_KEY);
    }
    return result;
  }


  needConfirmNameOrSurname(): boolean {
    const empty = (a: any) => !a || (typeof a === 'string' && a.length === 0);
    return this.userData && (empty(this.userData.name) || empty(this.userData.last_name));
  }

  getPhoneConfirmationCode(phone) {
    return this.api.get('phone/code', { phone });
  }

  needConfirmPhone(): boolean {
    return this.userData && this.userId && !this.userData.phone_confirmed;
  }

  confirmPhone(model) {
    return this.api.post('phone/confirm', null, model);
  }


  initUser(userId) {
    if (userId) {
      let initUserSubject$ = new Subject();
      this.getUserData(userId).subscribe((data) => {
        this.setUserData(data.result);
        this.update();
        initUserSubject$.next(false);
      }, (e) => {
        initUserSubject$.next(e);
      });
      return initUserSubject$;
    } else {
      return new BehaviorSubject(true);
    }
  }
}
