import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { EquipmentsService } from '../../services/equipments.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-description-breakdown',
  templateUrl: './modal-description-breakdown.component.html',
  styleUrls: ['./modal-description-breakdown.component.styl']
})
export class ModalDescriptionBreakdownComponent implements OnInit {
  @Input() equipment;
  @Output() formChangeEmitter = new EventEmitter();
  breakdown;
  constructor(private fb: FormBuilder,
              private equipmentsService: EquipmentsService) { }

  ngOnInit() {
    this.initForm();
    this.onFormChange();
  }

  initForm() {
    this.breakdown = this.fb.group({
      date: [null, Validators.required],
      comment: null,
      replacement: [false],
      equipment: this.fb.group({
        equipment_id: [null],
        name: null,
      })
    })
  }

  public handleSearchEquip = (query) => {
    return this.equipmentsService.getEquipmentsList({ query_str: query, hide_broken: true, hidden_equipments_ids: [this.equipment.id] }).pipe(map((data:any) => {
      return {
        result: data.result.data
      }
    }))
  };

  onReplacement() {
    this.onFormChange();
    if (this.breakdown.value.replacement) {
      this.breakdown.controls.equipment.setValidators([Validators.required]);
      this.breakdown.controls.equipment.controls.equipment_id.setValidators([Validators.required]);
      this.breakdown.controls.equipment.controls.name.setValidators([Validators.required]);
    } else {
      this.breakdown.controls.equipment.setValidators();
      this.breakdown.controls.equipment.controls.equipment_id.setValidators();
      this.breakdown.controls.equipment.controls.name.setValidators();
    }
    this.safeReset(this.breakdown.controls.equipment);
  }

  onFormChange() {
    this.formChangeEmitter.emit(this.breakdown);
  }

  private safeReset(control) {
    let val = control.value;
    control.reset();
    control.setValue(val);
  }

}
