import { Component, Input, Output, EventEmitter, OnInit, ChangeDetectorRef, Inject, PLATFORM_ID, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { first } from 'rxjs/operators';
import { parseApiError, encodeBase64 } from '../../utils';
@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.styl']
})
export class SigninComponent implements OnInit {
  @Input() titleText = 'Добро пожаловать на Фабрику Leroy Merlin!';
  @Input() inAction;
  @Output() afterSigninAction = new EventEmitter();
  @ViewChild('loginEmailInput')  loginEmailInput: ElementRef;
  @ViewChild('loginPasswordInput')  loginPasswordInput: ElementRef;
  signupStatus = true;
  errorMessage;
  fewlinesLink;
  googleLink;
  currentRoute;
  currentState;
  signupQueryParams;
  vkLink;
  w: any;
  isBrowser;
  fbLoading;
  public signinForm: FormGroup;


  constructor(public modalService: ModalService,
              private fb: FormBuilder,
              private authService: AuthService,
              private tokenService: TokenService,
              private userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private _ref: ChangeDetectorRef,
              @Inject(PLATFORM_ID) platformId: string,
              @Inject('API_ROOT_URL') private apiRootUrl: string,
              @Inject('API_ROOT_URL_HTTP') private apiRootUrlHttp: string,
              @Inject('HOSTNAME') private hostName: string,
              @Inject('VK_CLIENT_ID') private authVkClientId: string,
              @Inject('FEWLINES_CLIENT_ID') private fewlinesClientId: string,
              @Inject('GOOGLE_CLIENT_ID') private authGoogleClientId: string) {
                this.isBrowser = isPlatformBrowser(platformId);
                if (this.isBrowser) {
                  this.w = window;
                }
                this.signinForm = this.fb.group({
                  login: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/)]],
                  password: ['', [Validators.required]]
                });
              }

  ngOnInit() {
    this.hostName = this.hostName.slice(0, -1);
    this.currentRoute = this.router.url.split('?')[0];
    let queryParams = {...this.activatedRoute.snapshot.queryParams};
    delete queryParams.popup;
    this.signupQueryParams = { ...queryParams, popup: 'registration' };
    let index = 0;
    let paramsString = '';
    for (let key in queryParams) {
      if (index === 0) {
        paramsString += `?${key}=${queryParams[key]}`;
      } else {
        paramsString += `&${key}=${queryParams[key]}`;
      }
      index++;
    }
    this.currentState = encodeURIComponent(encodeBase64(this.hostName + this.currentRoute + paramsString));
    this.initSocialLinks();
    this.signinForm.valueChanges.subscribe(val => {
      this.errorMessage = false;
    });
  }

  inputChange(evt, pass) {
    if (!pass && this.signinForm.value.login !== evt.target.value) {
      this.signinForm.controls.login.setValue(evt.target.value);
      evt.target.onfocus();
    }
    if (pass && this.signinForm.value.password !== evt.target.value) {
      this.signinForm.controls.password.setValue(evt.target.value);
      evt.target.onfocus();
    }
  }

  logIn(evt) {
    evt.preventDefault();
    this.authService.logIn(this.signinForm.value.login.toLowerCase(), this.signinForm.value.password)
      .pipe(first())
      .subscribe((data: any) => {
        this.authService.initUser(data.result.userId, data.result.authToken).pipe(first()).subscribe((data) => {
          if (data) {
            this.tokenService.deleteToken();
            this.errorMessage = 'Ошибка загрузки данных пользователя';
          } else {
            this.modalService.nextPage(null, null);
            setTimeout(() => {
              if (!this.userService.userData.phone_confirmed) {
                this.router.navigate(['/confirm-phone']);
              }
            });
          }
        })
      }, (e) => {
        let error = parseApiError(e);
        console.log(error);
        if (error === 'user-not-found' || error === 'wrong-password') {
          this.errorMessage = 'Неверный логин или пароль';
        } else {
          this.errorMessage = 'Ошибка';
        }
      })
  }

  initSocialLinks() {
    this.fewlinesLink = `${this.apiRootUrl}oauth/sign-in?state=${this.currentState}`;
    this.googleLink = `https://accounts.google.com/o/oauth2/v2/auth?scope=email profile&redirect_uri=${this.apiRootUrl}google/callback/code&response_type=code&state=${this.currentState}&client_id=${this.authGoogleClientId}`;
    this.vkLink = `https://oauth.vk.com/authorize?client_id=${this.authVkClientId}&display=page&redirect_uri=${this.apiRootUrl}vk/callback/code&scope=email&response_type=code&v=5.73&state=${this.currentState}`;
  }

  facebook() {
    this.fbLoading = true;
    let that = this;
    that.modalService.setModalLoader(true);
    this.w.FB.login(function(response){
      that.fbLoading = true;
      that.userService.facebook(response.authResponse.accessToken).subscribe((data) => {
        that.fbLoading = false;
        that._ref.detectChanges();
        that.authService.initUser(data.result.userId, data.result.authToken).pipe(first()).subscribe((data) => {
          if (data) {
            that.tokenService.deleteToken();
            that.errorMessage = 'Ошибка загрузки данных пользователя';
            that.modalService.setModalLoader(false);
            setTimeout(() => {
              that._ref.detectChanges();
            })
          } else {
            that.modalService.setModalLoader(false);
            setTimeout(() => {
              that._ref.detectChanges();
              that.modalService.nextPage(null, null);
              setTimeout(() => {
                if (!that.userService.userData.phone_confirmed) {
                  that.router.navigate(['/confirm-phone']);
                }
              })
            })
          }
        })
      }, () => {
        that.modalService.setModalLoader(false);
        that._ref.detectChanges();
      })
    }, { scope: 'email' });

  }
}
