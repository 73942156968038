import { Component, OnInit, OnDestroy, PLATFORM_ID, Inject, ChangeDetectorRef, Input, AfterViewInit } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { UserService } from '../../services/user.service';
import { FABRIC_WORK_HOURS } from '../../config';
declare var $;

@Component({
  selector: 'app-subscription-diagram',
  templateUrl: './subscription-diagram.component.html',
  styleUrls: ['./subscription-diagram.component.styl']
})
export class SubscriptionDiagramComponent implements OnInit, AfterViewInit {
  @Input() user;
  @Input() blockId = 'circles-1';
  isBrowser;
  w: any;
  constructor(@Inject(PLATFORM_ID) platformId: string,
              private userService: UserService) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.w = window;
    }
  }

  ngOnInit() {
    this.userService.updateSubject.subscribe(() => {
      this.initCircles();
    })
  }

  ngAfterViewInit() {
    this.initCircles();
  }

  initCircles() {
    if (this.isBrowser) {
      $('#' + this.blockId).html('');
      let value = (this.user.subscription_days + this.user.spent_days) * FABRIC_WORK_HOURS + (this.user.subscription_hours + this.user.spent_hours) - (this.user.subscription_days * FABRIC_WORK_HOURS + this.user.subscription_hours);
      this.w.Circles.create({
        id:           this.blockId,
        radius:       56,
        value:        value,
        maxValue:     (this.user.subscription_days + this.user.spent_days) * FABRIC_WORK_HOURS + (this.user.subscription_hours + this.user.spent_hours),
        width:        9.8,
        text:         '',
        colors:       ['#78be20', value ? '#edeef0' : 'transparent'],
        duration:     600,
        wrpClass:     'circles-wrp',
        textClass:    'circles-text-1',
        styleWrapper: true,
        styleText:    true
      })
    }
  }

}
