import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {Router} from '@angular/router';
import {SubscriptionsService} from '../../services/subscriptions.service';

@Component({
  selector: 'app-modal-subscribe',
  templateUrl: './modal-subscribe.component.html',
  styleUrls: ['./modal-subscribe.component.styl']
})
export class ModalSubscribeComponent implements OnInit {
  currentRoute;
  cards: any[] = this.subscriptionsService.buildCards();

  constructor(public modalService: ModalService,
              private subscriptionsService: SubscriptionsService,
              private router: Router) {
  }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];

    this.subscriptionsService.price().subscribe((data) => {
      this.cards = this.subscriptionsService.buildCards(data.result.hour_price, data.result.day_price);
    }, (error) => {
      console.log('subscription price failed ', error);
    });
  }
}
