import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { StorageService } from '../../services/storage.service';

@Component({
  selector: 'app-shelf-storage',
  templateUrl: './shelf-storage.component.html',
  styleUrls: ['./shelf-storage.component.styl']
})
export class ShelfStorageComponent implements OnInit {
  @Input() user;
  @Output() onChangeEmitter = new EventEmitter();
  countDay = 1;
  storageDate;
  // cellsSm = [1,2,3,45,64];
  // cellsLg = [11,46,19,94,73]
  rack = ['A', 'B', 'C'];
  floor = [0, 1, 2, 3, 4, 5];
  radio;
  isLoading;
  smallCellsList;
  bigCellsList;
  error;

  constructor(private fb: FormBuilder,
              private storageService: StorageService) { }

  ngOnInit() {
      this.initForm();
      this.updateDate();
      this.onChange();
    // this.isLoading = true;
    // this.storageService.getFreeCells().subscribe(data => {
    //   console.log(data);
    //   this.smallCellsList = data.result.filter(item => item.type === 'SQM1');
    //   this.bigCellsList = data.result.filter(item => item.type === 'SQM6');
    //
    //   this.isLoading = false;
    //   console.log(this.smallCellsList);
    //   this.initForm();
    //   this.updateDate();
    // }, () => {this.isLoading = false; this.error = true})
  }

  updateDay(e) {
    this.countDay = e;
    this.updateDate();
    this.radio.controls.duration.setValue(this.countDay);
    this.onChange();
  }

  updateDate() {
    this.storageDate = new Date().setDate(new Date().getDate()+(this.countDay - 1));
    this.radio.controls.untilDate.setValue(this.storageDate);
  }

  initForm() {
    this.radio = this.fb.group({
      floor: [this.floor[0], Validators.required],
      rack: [this.rack[0], Validators.required],
      duration: [1, Validators.required],
      untilDate: ['', Validators.required]
    });
  }

  onChange() {
    let model = {
      cell: `${this.radio.value.rack}${this.radio.value.floor}-${this.user.id}`,
      duration: this.countDay,
      untilDate: this.storageDate
    }
    this.onChangeEmitter.emit(model);
  }
}
