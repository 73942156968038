// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: 'true',
  envName: 'prod',
  hostname: 'https://fabrika.leroymerlin.ru',
  hostnameHttp: 'https://fabrika.leroymerlin.ru',
  pageAddress: 'https://fabrika.leroymerlin.ru',
  apiBaseUrl: 'https://fabrika.leroymerlin.ru/backend/',
  apiBaseUrlHttp: 'https://fabrika.leroymerlin.ru/backend/',
  socket: 'https://fabrika.leroymerlin.ru/backend',
  facebookAppId: '1886279318101000',
  vkClientId: '7345449',
  googleClientId: '51457631295-bg2pdp2f78ro05pp5pe8hovma69jtfap.apps.googleusercontent.com',
  fewlinesClientId: 'ios3O-HdSrfAYUGd6znn0w==',
  googleApiKey: 'AIzaSyCK15AlP5EJHMxtLNLNHkfO2UW_vdS3zrY',
  yandexApiKey: '0444c1bd-6684-4ba6-b4bf-9937c5c8a8e1'
};
