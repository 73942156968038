import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-sort-button',
  templateUrl: './sort-button.component.html',
  styleUrls: ['./sort-button.component.styl']
})
export class SortButtonComponent implements OnInit {
  @Output() onSort: EventEmitter<any> = new EventEmitter();
  @Input() sortType: string;
  @Input() initSortType: string;
  @Input() sortDirection: boolean;

  constructor() { }

  ngOnInit() {
  }

  setSortType() {
    this.onSort.emit(this.initSortType);
  }

}
