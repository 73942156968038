import { Component, OnInit } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { AdminService } from '../../services/admin.service';
import { parseApiError, parseDateToString } from '../../utils';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-modal-upload-shelude-of-masters',
  templateUrl: './modal-upload-shelude-of-masters.component.html',
  styleUrls: ['./modal-upload-shelude-of-masters.component.styl']
})
export class ModalUploadSheludeOfMastersComponent implements OnInit {
  currentMonthDate = new Date(new Date().setDate(1));
  nextMonthDate = new Date(new Date(new Date().setDate(32)).setDate(1));
  currentMonthUploader = new FileUploader({});
  nextMonthUploader = new FileUploader({});
  currentMonthFile: any = {

  }
  nextMonthFile: any = {

  }
  scheduleLoading;
  hidePickers;

  constructor(private adminService: AdminService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.scheduleLoading = true;
    this.adminService.getScheduleList().subscribe((data) => {
      data.result.forEach(item => {
        if (new Date(new Date(item.schedule_date).setDate(1)).setHours(0,0,0,0) === this.currentMonthDate.setHours(0,0,0,0)) {
          this.currentMonthFile.file = item.file_item;
          this.currentMonthFile.id = item.id;
          this.currentMonthFile.old = true;
        }
        if (new Date(new Date(item.schedule_date).setDate(1)).setHours(0,0,0,0) === this.nextMonthDate.setHours(0,0,0,0)) {
          this.nextMonthFile.file = item.file_item;
          this.nextMonthFile.id = item.id;
          this.nextMonthFile.old = true;
        }
      })
      this.scheduleLoading = false;
    }, (e) => {
      let errors = parseApiError(e);
      errors && errors[0] && errors.forEach(item => {
        alert(`Code: ${item.code}; Field: ${item.field}`)
      })
    })
  }

  updateCurrentMonth(file) {
    let zone = `GMT${this.currentMonthDate.getTimezoneOffset() > 0 ? this.currentMonthDate.getTimezoneOffset() / -60 : '+' + this.currentMonthDate.getTimezoneOffset() / -60}`
    // this.adminService.uploadSchedule(file, this.parseDate(this.currentMonthDate), zone)
  }
  updateMonth(event, next) {
    let monthDate;
    let monthFile;
    if (next) {
      monthDate = this.nextMonthDate;
      monthFile = this.nextMonthFile;
    } else {
      monthDate = this.currentMonthDate;
      monthFile = this.currentMonthFile;
    }
    let zone = `GMT${monthDate.getTimezoneOffset() > 0 ? monthDate.getTimezoneOffset() / -60 : '+' + monthDate.getTimezoneOffset() / -60}`
    monthFile.loading = true;
    if (!event.replace) {
      this.adminService.uploadSchedule(event.file.rawFile, parseDateToString(monthDate), zone).subscribe((data) => {
        monthFile.file = data.result.file_item;
        monthFile.loaded = true;
        monthFile.loading = false;
        this.messagesService.showMessage('Файл загружен.', false, true, true);
        this.updatePickers();
      }, (e) => {
        monthFile.loading = false;
        this.handleUploadError(e);
        this.updatePickers();
      })
    } else {
      this.adminService.updateSchedule(monthFile.id, event.file.rawFile, zone).subscribe((data) => {
        monthFile.file = data.result.file_item;
        monthFile.id = data.result.id;
        monthFile.loaded = true;
        monthFile.loading = false;
        this.messagesService.showMessage('Файл загружен.', false, true, true);
        this.updatePickers();
      }, (e) => {
        monthFile.loading = false;
        this.handleUploadError(e);
        this.updatePickers();
      })
    }
  }

  updatePickers() {
    this.hidePickers = true;
    setTimeout(() => {
      this.hidePickers = false;
    })
  }

  handleUploadError(e) {
    let errors = parseApiError(e);
    if (errors[0]) {
      errors = errors[0];
    }
    console.log(errors);
    if (Array.isArray(errors)) {
      errors = errors[0];
    }
    if (errors && errors.message) {
      let message = errors.message;
      if (errors.row_num && errors.cell_num) {
        message += `. Строка ${errors.row_num}, столбец ${errors.cell_num}`;
      } else if (errors && errors.row_num) {
        message += `. Строка ${errors.row_num}`;
      } else if (errors && errors.cell_num) {
        message += `. Столбец ${errors.cell_num}`;
      }
      if (!errors.cell_num && !errors.row_num && errors.incorrect_value && errors.message) {
        message = [errors.message, errors.incorrect_value];
      }
      this.messagesService.showMessage(message, false, false, true);
    } else {
      this.messagesService.showMessage('Ошибка загрузки файла.', false, false, true);
    }
  }

}
