import { Pipe } from '@angular/core';

@Pipe({
  name: 'readableMaterial'
})
export class ReadableMaterialPipe {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'WOOD':
        return 'Работает с деревом';
      case 'METAL':
        return 'Работает с металлом';
      case 'TEXTILE':
        return 'Работает с тканью';
      case 'THREE_D':
        return 'Работает с пластиком (3D)';
      case 'LIGHT':
        return 'Работает со светом';
      case 'FILM':
        return 'Работает с пленкой';
    }
  }

}
