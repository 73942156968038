import { Component, OnInit } from '@angular/core';
import { CoursesService } from '../../services/courses.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentCourseService } from '../../services/current-course.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { CANCEL_COURSE_CAUSES } from '../../config';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-modal-cancel-course',
  templateUrl: './modal-cancel-course.component.html',
  styleUrls: ['./modal-cancel-course.component.styl']
})
export class ModalCancelCourseComponent implements OnInit {
  currentCourseId;
  currentTimingId;
  courseLoading;
  courseParams;
  courseTiming;
  cancelForm;
  causes;
  cancelLoading;
  error;
  constructor(private fb: FormBuilder,
              private coursesService: CoursesService,
              private activatedRoute: ActivatedRoute,
              private currentCourseService: CurrentCourseService,
              private modalService: ModalService,
              private messagesService: MessagesService,
              private router: Router,
              private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.causes = CANCEL_COURSE_CAUSES;
    if (this.activatedRoute.snapshot.queryParams.course && this.activatedRoute.snapshot.queryParams.timing) {
      this.currentCourseId = this.activatedRoute.snapshot.queryParams.course;
      this.currentTimingId = this.activatedRoute.snapshot.queryParams.timing;
      this.courseLoading = true;
      this.coursesService.getCourse(this.activatedRoute.snapshot.queryParams.course).subscribe((data) => {
        this.courseParams = data.result;
        this.courseTiming = this.courseParams.course_timings.filter(item => item.id == this.activatedRoute.snapshot.queryParams.timing)[0];
        if (!this.courseTiming) {
          this.courseParams = null;
        }
        this.courseLoading = false;
      }, () => {this.courseLoading = false; this.error = true})
    } else {
      this.error = true;
    }
    this.cancelForm = this.fb.group({
      reason: ['', [Validators.required]]
    })
  }

  back() {
    this.modalService.back();
  }

  closeModal() {
    this.modalService.onClose();
  }

  submit() {
    let model = {
      cause: this.cancelForm.value.reason
    }
    this.cancelLoading = true;
    this.coursesService.cancelCourse(this.currentCourseId, this.currentTimingId, model).subscribe((data) => {
      this.cancelLoading = false;
      this.notificationsService.updateEmitter();
      if (data.result.notification_id) {
        let currentRoute = this.router.url.split('?')[0];
        this.router.navigate([currentRoute], { queryParams: { popup: 'notify-cancel-course', notification: data.result.notification_id } })
      } else {
        this.modalService.onClose();
      }
    });
  }

}
