import { Component, OnInit } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { ActivatedRoute, Router } from '@angular/router';
import { CurrentEventService } from '../../services/current-event.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { sameYear } from '../../utils';
import { CANCEL_COURSE_CAUSES } from '../../config';
import { NotificationsService } from '../../services/notifications.service';

@Component({
  selector: 'app-modal-cancel-event',
  templateUrl: './modal-cancel-event.component.html',
  styleUrls: ['./modal-cancel-event.component.styl']
})
export class ModalCancelEventComponent implements OnInit {
  eventLoading;
  eventParams;
  eventTiming;
  cancelForm;
  sameYear;
  cancelLoading;
  currentEventId;
  error;
  causes;
  constructor(private fb: FormBuilder,
              private eventsService: EventsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private currentEventsService: CurrentEventService,
              private modalService: ModalService,
              private messagesService: MessagesService,
              private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.causes = CANCEL_COURSE_CAUSES;
    this.sameYear = sameYear;
    let event = this.activatedRoute.snapshot.queryParams.event;
    if (event) {
      this.eventLoading = true;
      this.currentEventId = event;
      this.eventsService.getEvent(event).subscribe((data) => {
        this.eventParams = data.result;
        this.eventLoading = false;
      }, () => {this.eventLoading = false; this.error = true;})
    } else {
      this.error = true;
    }
    this.cancelForm = this.fb.group({
      reason: ['', [Validators.required]]
    })
  }

  closeModal() {
    this.modalService.onClose();
  }

  submit() {
    let model = {
      cause: this.cancelForm.value.reason
    }
    this.cancelLoading = true;
    this.eventsService.cancelEvent(this.currentEventId, model).subscribe((data) => {
      this.cancelLoading = false;
      this.notificationsService.updateEmitter();
      if (data.result.notification_id) {
        let currentRoute = this.router.url.split('?')[0];
        this.router.navigate([currentRoute], {queryParams: {popup: 'notify-cancel-event', notification: data.result.notification_id}})
      } else {
        this.modalService.onClose();
      }
    });
  }

}
