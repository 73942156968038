import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CoursesService } from '../../services/courses.service';
import { MessagesService } from '../../services/messages.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { UserService } from '../../services/user.service';
import { notNull } from '../../utils';
import { Router } from '@angular/router';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'app-courses-list-option',
  templateUrl: './courses-list-option.component.html',
  styleUrls: ['./courses-list-option.component.styl']
})
export class CoursesListOptionComponent implements OnInit {

  isOpenBox;
  confirmOpened;
  currentRoute;
  @Input() course;
  @Input() forMaster;
  @ViewChild(ClickOutsideDirective) clickOutside;
  constructor(private modalService: ModalService,
              private coursesService: CoursesService,
              private messagesService: MessagesService,
              private cancelRegistrationService: CancelRegistrationService,
              private currentCourseService: CurrentCourseService,
              private router: Router,
              private userService: UserService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    if (!notNull(this.forMaster)) {
      this.forMaster = this.userService.userId === this.course.master.id || this.userService.userData.role === 'SUPER_ADMIN';
    }
  }

  openBox() {
    if (!this.isOpenBox) this.isOpenBox = true;
    else this.isOpenBox = false;
    this.clickOutside.enable();
  }

  closeBox() {
    if (this.isOpenBox) this.isOpenBox = false;
    this.clickOutside.disable();
  }

  cancel() {
    this.cancelRegistrationService.cancelCourse(this.course);
  }

}
