import {Inject, Injectable} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';
import { FileUploadService } from './file-upload.service';
declare var $;

@Injectable({
  providedIn: 'root'
})
export class EmbedVideoService {

  private validYouTubeOptions = [
    'default',
    'mqdefault',
    'hqdefault',
    'sddefault',
    'maxresdefault'
  ];
  private validVimeoOptions = [
    'thumbnail_small',
    'thumbnail_medium',
    'thumbnail_large'
  ];
  private validDailyMotionOptions = [
    'thumbnail_60_url',
    'thumbnail_120_url',
    'thumbnail_180_url',
    'thumbnail_240_url',
    'thumbnail_360_url',
    'thumbnail_480_url',
    'thumbnail_720_url',
    'thumbnail_1080_url'
  ];

  constructor(
    @Inject('GOOGLE_API_KEY') private googleApiKey: string,
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private fileUploadService: FileUploadService
  ) { }

  public embed(url: any, options?: any): any {
    let id;
    url = new URL(url);

    id = this.detectYoutube(url);
    if (id) {
      return this.embed_youtube(id, options);
    }
    id = this.detectRutube(url);
    if (id) {
      return this.embed_rutube(id, options);
    }

    // id = this.detectVimeo(url);
    // if (id) {
    //   return this.embed_vimeo(id, options);
    // }
    //
    // id = this.detectDailymotion(url);
    // if (id) {
    //   return this.embed_dailymotion(id, options);
    // }
  }

  public embed_youtube(id: string, options?: any): string {
    options = this.parseOptions(options);
    let queryString;

    if (options && options.hasOwnProperty('query')) {
      queryString = '?' + this.serializeQuery(options.query);
    }

    return this.sanitize_iframe('<iframe src="//www.youtube.com/embed/'
      + id + options.query + '"' + options.attr
      + ' frameborder="0" allowfullscreen></iframe>');
  }
  public embed_rutube(id: string, options?: any): string {
    options = this.parseOptions(options);
    let queryString;

    if (options && options.hasOwnProperty('query')) {
      queryString = '?' + this.serializeQuery(options.query);
    }

    return this.sanitize_iframe('<iframe src="//rutube.ru/play/embed/'
      + id + options.query + '"' + options.attr
      + ' frameborder="0" webkitAllowFullScreen mozallowfullscreen allowfullscreen></iframe>');
  }


  public embed_vimeo(id: string, options?: any): string {
    options = this.parseOptions(options);
    let queryString;

    if (options && options.hasOwnProperty('query')) {
      queryString = '?' + this.serializeQuery(options.query);
    }

    return this.sanitize_iframe('<iframe src="//player.vimeo.com/video/'
      + id + options.query + '"' + options.attr
      + ' frameborder="0" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe>');
  }

  public embed_dailymotion(id: string, options?: any): string {
    options = this.parseOptions(options);
    let queryString;

    if (options && options.hasOwnProperty('query')) {
      queryString = '?' + this.serializeQuery(options.query);
    }

    return this.sanitize_iframe('<iframe src="//www.dailymotion.com/embed/video/'
      + id + options.query + '"' + options.attr
      + ' frameborder="0" allowfullscreen></iframe>');
  }

  public embed_image(url: any, options?: any): any {
    let id;

    url = new URL(url);

    id = this.detectYoutube(url);
    if (id) {
      return this.embed_youtube_image(id, options);
    }

    id = this.detectVimeo(url);
    if (id) {
      return this.embed_vimeo_image(id, options);
    }

    id = this.detectDailymotion(url);
    if (id) {
      return this.embed_dailymotion_image(id, options);
    }
  }

  public embed_youtube_image(id: string, options?: any): any {
    if (typeof options === 'function') {
      options = {};
    }
    options = options || {};

    options.image = this.validYouTubeOptions.indexOf(options.image) > 0 ? options.image : 'default';

    let src = '//img.youtube.com/vi/' + id + '/' + options.image + '.jpg';

    let result = {
      link: src,
      html: '<img src="' + src + '"/>'
    };

    return new Promise((resolve, reject) => {
      resolve(result);
    });
  }

  private embed_vimeo_image(id: string, options?: any): any {
    if (typeof options === 'function') {
      options = {};
    }

    options = options || {};

    options.image = this.validVimeoOptions.indexOf(options.image) >= 0 ? options.image : 'thumbnail_large';

    return this.http.get('https://vimeo.com/api/v2/video/' + id + '.json').pipe(map(res => {
      return {
          'link': res[0][options.image],
          'html': '<img src="' + res[0][options.image] + '"/>'
        };
      }))
      .toPromise()
      .catch(error => console.log(error));
  }

  private embed_dailymotion_image(id: string, options?: any): any {
    if (typeof options === 'function') {
      options = {};
    }

    options = options || {};

    options.image = this.validDailyMotionOptions.indexOf(options.image) >= 0 ? options.image : 'thumbnail_480_url';

    return this.http.get('https://api.dailymotion.com/video/' + id + '?fields=' + options.image).pipe(map(res => {
        return {
          'link': res[options.image],
          'html': '<img src="' + res[options.image] + '"/>'
        };
      }))
      .toPromise()
      .catch(error => console.log(error));
  }

  private parseOptions(options: any): any {
    let queryString = '',
      attributes = '';

    if (options && options.hasOwnProperty('query')) {
      queryString = '?' + this.serializeQuery(options.query);
    }

    if (options && options.hasOwnProperty('attr')) {
      let temp = <any>[];

      Object.keys(options.attr).forEach(function (key) {
        temp.push(key + '="' + (options.attr[key]) + '"');
      });

      attributes = ' ' + temp.join(' ');
    }
    return {
      query: queryString,
      attr: attributes
    };
  }

  private serializeQuery(query: any): any {
    let queryString: any = [];

    for (let p in query) {
      if (query.hasOwnProperty(p)) {
        queryString.push(encodeURIComponent(p) + '=' + encodeURIComponent(query[p]));
      }
    }

    return queryString.join('&');
  }

  private sanitize_iframe(iframe: string): any {
    return this.sanitizer.bypassSecurityTrustHtml(iframe);
  }

  private detectVimeo(url: any): string {
    return (url.hostname === 'vimeo.com') ? url.pathname.split('/')[1] : null;
  }

  public detectYoutube(url: any): string {
    if (url.hostname.indexOf('youtube.com') > -1) {
      let result;
      if (url.search.split('?v=').length > 1) {
        result = url.search.split('?v=')[1];
      }
      if (url.search.split('&v=').length > 1) {
        result = url.search.split('&v=')[1];
      }
      if (result.indexOf('&') > -1) {
        result = result.substr(0, result.indexOf('&'));
      }
      return result;
    }

    if (url.hostname === 'youtu.be') {
      let result = url.pathname.split('/')[1];
      if (result.indexOf('&') > -1) {
        result = result.substr(0, result.indexOf('&'));
      }
      return result;
    }

    return '';
  }

  public detectRutube(url: any): string {
    if (url.hostname.indexOf('rutube.ru') > -1) {
      return url.pathname.split('/')[2];
    }
    return '';
  }

  private detectDailymotion(url: any): string {
    if (url.hostname.indexOf('dailymotion.com') > -1) {
      return url.pathname.split('/')[2].split('_')[0];
    }

    if (url.hostname === 'dai.ly') {
      return url.pathname.split('/')[1];
    }

    return '';
  }

  public isLinkValid(url) {
    if (url) {
      url = new URL(url);
      if (this.detectYoutube(url)) {
        return this.http.get(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${this.detectYoutube(url)}&key=${this.googleApiKey}`, undefined)
      }
      if (this.detectRutube(url)) {
        return this.fileUploadService.checkRT(this.detectRutube(url));
      }
      return Observable.create(() => {});
    } else {
      return Observable.create(() => {});
    }
  }

  public isYTLinkValid(url) {
    return this.http.get(`https://www.googleapis.com/youtube/v3/videos?part=snippet&id=${this.detectYoutube(url)}&key=${this.googleApiKey}`, undefined)
  }
  public isRTLinkValid(url) {
    url = new URL(url);
    return this.fileUploadService.checkRT(this.detectRutube(url));
  }

}
