import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { MessagesService } from '../../services/messages.service';
import { phoneMask, parseApiError } from '../../utils';
import { Router, ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-profile-info-item',
  templateUrl: './profile-info-item.component.html',
  styleUrls: ['./profile-info-item.component.styl']
})

export class ProfileInfoItemComponent implements OnInit {
  @Input() type: string;
  @Input() value: string;
  @Input() cantEdit: boolean;
  @ViewChild('elem') elem: ElementRef;
  @ViewChild('input') input: ElementRef;
  @ViewChild('placeholderdata') placeholderData: ElementRef;
  @ViewChild('placeholderhint') placeholderHint: ElementRef;
  paramClassName;
  actionText;
  fieldName;
  inputActive: boolean = false;
  getType;
  isError;
  postType;
  userForm: FormGroup;
  paramLoading;
  placeholderText;
  savedVal;
  bdayMask = [/\d/, /\d/, /\d/, /\d/,'-', /\d/, /\d/, '-', /\d/, /\d/];
  dateReg = /^\d{4}[\-]\d{2}[\-]\d{2}$/;
  dateReg1 = /^\d{4}$/;
  dateReg2 = /^\d{4}[\-]\d{2}$/;
  previousBdayValue;
  phoneMask = phoneMask;
  constructor(private fb: FormBuilder,
              private userService: UserService,
              private messagesService: MessagesService,
              private el: ElementRef,
              private router: Router,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.createForm();
    this.setParamClassName();
  }

  private setParamClassName = function() {
    if (!this.userForm.value.param) {
      this.paramClassName = this.type + '-add';
    } else {
      this.paramClassName = this.type;
    }
  }

  private setActionText = function() {
    let action = this.value ? 'Изменить' : 'Добавить';
    let subject;
    switch (this.type) {
      case 'subscription':
        subject = 'абонемент';
        this.placeholderText = 'Введите абонемент';
        break;
      case 'phone':
        subject = 'телефон';
        this.placeholderText = 'Введите телефон';
        break;
      case 'mail':
        subject = 'email';
        this.placeholderText = 'Введите email';
        break;
      case 'bday':
        subject = 'день рождения';
        this.placeholderText = 'гггг-мм-дд';
        break;
    }
    this.fieldName = subject;
    return action + ' ' + subject;
  }

  createForm() {
    this.actionText = this.setActionText();
    this.userForm = this.fb.group({
      param: [this.value],
    });
    switch(this.type) {
      case 'phone' :
        this.userForm.controls.param.setValidators([Validators.required])
        break;
      case 'mail' :
        this.userForm.controls.param.setValidators([Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$|(\d{17})/)]);
        break;
      case 'bday' :
        this.userForm.controls.param.setValidators([Validators.required, Validators.pattern(/\d{4}-\d{1,2}-\d{1,2}/), this.bdayValidator]);
        this.previousBdayValue = this.value;
        break;
      case 'subscription':
        this.userForm.controls.param.setValidators([Validators.required, Validators.minLength(17), Validators.maxLength(17), Validators.pattern(/^\d+$/)]);
        break;
    }
    this.setType();
  }
  changeValue() {
    if (!this.cantEdit) {
      if (this.type !== 'phone') {
        this.inputActive = true;
        if (this.type === 'bday') {
          this.updatePlaceholder();
        }
        setTimeout(() => {
          this.input.nativeElement.focus();
        }, 0);
      } else {
        let currentRoute = this.router.url.split('?')[0];
        let currentQueryParams = this.activatedRoute.snapshot.queryParams;
        this.router.navigate([currentRoute], { queryParams: { ...currentQueryParams, ...{ popup: 'change-phone' } } });
      }

    }
  }

  onBlur() {
    if (this.userForm.value.param === this.savedVal) {
      this.inputActive = false;
    } else if (!this.userForm.value.param) {
      this.createForm();
      this.inputActive = false;
    } else if (!this.userForm.valid) {
      this.changeValue();
      this.isError = true;
    }
  }

  onBdayInput(e) {
    this.onInput();
    let isDelete: boolean = (e.keyCode && (e.keyCode === 8 || e.keyCode === 46)) || (e.inputType && (e.inputType.indexOf('delete') !== -1));
    if (!this.dateReg.test(this.userForm.value.param + '1111-11-11'.substring(this.userForm.value.param.length)) && !isDelete) {
      this.userForm.controls.param.setValue(this.previousBdayValue);
    } else {
      if (e.type === 'keydown' && ((this.dateReg1.test(this.userForm.value.param) || this.dateReg2.test(this.userForm.value.param)) && !isDelete)) {
        this.userForm.controls.param.setValue(this.userForm.value.param + '-');
      }
      this.previousBdayValue = this.userForm.value.param;
    }
    this.updatePlaceholder();
  }

  onFocus() {
    this.savedVal = '' + this.userForm.value.param;
  }

  updatePlaceholder() {
    this.placeholderData.nativeElement.textContent = this.userForm.value.param;
    this.placeholderHint.nativeElement.textContent = 'гггг-мм-дд'.substring(this.userForm.value.param.length);
  }

  onInput(e?) {
    this.isError = false;
  }

  private setType() {
    switch (this.type) {
      case 'subscription':
        this.getType = 'service_card';
        this.postType = 'serviceCard';
        break;
      case 'phone':
        this.getType = 'phone';
        this.postType = 'phone';
        break;
      case 'mail':
        this.getType = 'email';
        this.postType = 'email';
        break;
      case 'bday':
        this.getType = 'birth_date';
        this.postType = 'birthDate';
        break;
    }
  }

  parseValue(val) {
    switch (this.type) {
      case 'phone':
        return val.replace('+7', '8').split(' ').join('');
      case 'mail':
        return val;
      case 'bday':
        return val;
      case 'subscription':
        return val;
      default:
        return null;
    }
  }

  submit() {
    this.input.nativeElement.blur();
    this.onSubmit();
  }

  onSubmit(evt?) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    if (this.userForm.value.param) {
      if (this.userForm.valid && this.userForm.value.param !== this.value) {
        let form: any = {};
        form[this.postType] = this.parseValue(this.userForm.value.param);
        this.paramLoading = true;
        this.userService.updateUserData(form).subscribe((data: any) => {
          this.inputActive = false;
          this.paramLoading = false;
          this.userService.setUserData(data.result);
          this.value = data.result[this.getType];
          this.setParamClassName();
          this.createForm();

        }, (e) => {
          this.inputActive = false;
          this.paramLoading = false;
          let error = parseApiError(e);
          if (error && error[0] && error[0].code === 'duplicate-phone') {
            this.messagesService.showMessage('Этот телефон уже используется', null, false, true);
          } else if (error && error[0] && error[0].code === 'duplicate-email') {
            this.messagesService.showMessage('Этот email уже используется', null, false, true);
          } else {
            this.messagesService.showMessage('Ошибка при обновлении данных пользователя', null, false, true);
          }
          this.value = this.userService.userData[this.getType];
          this.createForm();
        })
      }
    } else {
      this.createForm();
    }
  }

  private bdayValidator: ValidatorFn = function(control: FormControl) {
    if (new Date(control.value).toString() !== 'Invalid Date' && new Date(control.value).setHours(0,0,0,0) <=  new Date().setHours(0,0,0,0)) {
      return null;
    } else return {
      incorrect: true
    }
  }.bind(this)
}
