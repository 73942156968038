import { Component, OnInit, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ConsultDatePickerComponent } from '../consult-date-picker/consult-date-picker.component';
import { areaTypesShort } from '../../utils';

@Component({
  selector: 'app-select-consult',
  templateUrl: './select-consult.component.html',
  styleUrls: ['./select-consult.component.styl']
})
export class SelectConsultComponent implements OnInit {
  @Output() onSelect = new EventEmitter();
  @Output() onAreaSelect = new EventEmitter();
  @ViewChild('consultPicker') consultPicker: ConsultDatePickerComponent;
  areas = areaTypesShort;
  activeSpace;

  constructor(private _ref: ChangeDetectorRef) { }

  ngOnInit() {

  }
  selectSpace(space) {
    this.consultPicker.unselectDate();
    this.activeSpace = space;
    this.onAreaSelect.emit(space);
    this.consultPicker.setCurrentSpace(space);
    this.consultPicker.getConsultations(true);
  }

  onTimeSelect(evt) {
    this.onSelect.emit(evt);
  }

}
