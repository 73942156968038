import { Component, OnInit, OnDestroy, ViewChild, ChangeDetectorRef, HostListener, ElementRef, Input } from '@angular/core';
import { UserService } from '../../services/user.service';
import { CalendarService } from '../../services/calendar.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { CurrentCourseService } from '../../services/current-course.service';
import { CurrentEventService } from '../../services/current-event.service';
import { CurrentIdeaService } from '../../services/current-idea.service';
import { CurrentEquipmentService } from '../../services/current-equipment.service';
import { CalendarComponent } from '../calendar/calendar.component'
const USER_MASTER_CALENDAR_KEY = makeStateKey('user-master-calendar');
import { getGreenwichDate, combineDates, getCurrentMoscowTime } from '../../utils';
import { Subject } from 'rxjs';
import { takeUntil, first } from 'rxjs/operators';

import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-profile-master-calendar',
  templateUrl: './profile-master-calendar.component.html',
  styleUrls: ['./profile-master-calendar.component.styl']
})
export class ProfileMasterCalendarComponent implements OnInit {
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  @ViewChild('calendarWrapper') calendarWrapper: ElementRef;
  sortParams: any[] = [
  ]

  sortedEvents;
  filterModel: any = {};
  eventsList;
  searchQuery;
  calendarLoading;
  currentMonth;
  eventsDatesArr = [];
  @Input() isHidden;
  @Input() userId;
  currentUserId;
  isMe;
  componentDestroyed$ = new Subject();

  @HostListener('window:scroll') onScroll() {
    if (!this.isHidden) {
      // this.initCalendarPosition();
    }
  }
  @HostListener('window:resize') onResize() {
    if (!this.isHidden) {
      // this.initCalendarPosition();
    }
  }

  constructor(private userService: UserService,
              private calendarService: CalendarService,
              private state: TransferState,
              private currentCourseService: CurrentCourseService,
              private currentEventService: CurrentEventService,
              private currentEquipmentService: CurrentEquipmentService,
              private currentIdeaService: CurrentIdeaService,
              private _ref: ChangeDetectorRef) { }

  ngOnInit() {
    this.currentUserId = this.userId || this.userService.userId;
    this.filterModel.maker_id = this.currentUserId;
    this.filterModel.user_id = this.currentUserId;
    this.isMe = this.userService.userId === this.currentUserId;
    if (this.isMe) {
      this.sortParams = [{
            title: 'все',
            value: 'all',
          },{
            title: 'мои курсы',
            value: 'MY_COURSE',
            iconClass: 'courses'
          },{
            title: 'обучение',
            value: 'COURSE',
            iconClass: 'courses2'
          },{
            title: 'консультации',
            value: 'CONSULTATION',
            iconClass: 'consult'
          },{
            title: 'оборудование',
            value: 'EQUIPMENT',
            iconClass: 'equip'
          },{
            title: 'события',
            value: 'EVENT',
            iconClass: 'events'
          }]
    } else {
      this.sortParams = [{
            title: 'все',
            value: 'all',
          },{
            title: 'курсы мастера',
            value: 'MY_COURSE',
            iconClass: 'courses'
          },{
            title: 'обучение',
            value: 'COURSE',
            iconClass: 'courses2'
          },{
            title: 'консультации',
            value: 'CONSULTATION',
            iconClass: 'consult'
          },{
            title: 'оборудование',
            value: 'EQUIPMENT',
            iconClass: 'equip'
          },{
            title: 'события',
            value: 'EVENT',
            iconClass: 'events'
          }]
    }
    this.setCurrentMonth(new Date, true);
    this.eventsList = this.state.get(USER_MASTER_CALENDAR_KEY, null as any);
    if (!this.eventsList) {
      this.updateCalendar();
    } else {
      this.eventsDatesArr = this.eventsList.filter(day => (day[0] && day[0].start_date)).map(day => day[0].start_date);
      this._ref.detectChanges();
      this.calendar.setCurrentMonth(this.currentMonth);
    }
    // if (this.eventsList) {
    //   this.eventsDatesArr = this.eventsList.filter(day => (day[0] && day[0].start_date)).map(day => day[0].start_date);
    //   this._ref.detectChanges();
    //   this.calendar.setCurrentMonth(this.currentMonth);
    // }
    // if (!this.eventsList) {
    //   this.calendarLoading = true;
    //   this.calendarService.getUserCalendar(this.filterModel).subscribe((data) => {
    //     this.eventsList = this.sortByDates(data.result.filter(item => item && item.start_date));
    //     this.state.set(USER_MASTER_CALENDAR_KEY, this.eventsList as any);
    //     this.calendarLoading = false;
    //     this.eventsDatesArr = this.eventsList.map(day => new Date(day[0].start_date));
    //     this._ref.detectChanges();
    //     this.calendar.setCurrentMonth(this.currentMonth);
    //   })
    // }
    this.calendarService.updateSubject.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.updateCalendar();
    })
    this.currentCourseService.enrollSubject.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.updateCalendar();
    })
    this.currentCourseService.updateSubject.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.updateCalendar();
    });
    this.currentEventService.updateSubject.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.updateCalendar();
    });
    this.currentEquipmentService.updateSubject.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      this.updateCalendar();
    });
    // this.initCalendarPosition();
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
    this.eventsList = null;
    this.state.set(USER_MASTER_CALENDAR_KEY, null as any);
  }


  selectMonth(date) {
    if (new Date(new Date(date).setDate(1)).setHours(0,0,0,0) === new Date(new Date().setDate(1)).setHours(0,0,0,0)) {
      this.filterModel.date_from = getGreenwichDate(new Date());
      this.filterModel.date_to = getGreenwichDate(new Date(new Date(new Date(new Date(date).setDate(32))).setDate(0)));
    } else {
      this.filterModel.date_from = getGreenwichDate(new Date(new Date(new Date(date).setDate(1))));
      this.filterModel.date_to = getGreenwichDate(new Date(new Date(new Date(new Date(date).setDate(32))).setDate(0)));
    }
  }


  updateCalendar() {
    this.calendarLoading = true;
    this.calendarService.getUserCalendar(this.filterModel).pipe(first()).subscribe((data) => {
      this.calendarLoading = false;
      let events = this.sortByDates(data.result.filter(item => combineDates(item.start_date, item.start_time) > getCurrentMoscowTime()));
      // if (events[0] && events[0][0] && new Date(events[0][0].start_date).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
      //   events[0] = events[0].filter(item => combineDates(item.start_date, item.start_time) > getCurrentMoscowTime());
      // }
      // events = events.filter(item => item.length);
      if (!this.eventsList) {
        this.state.set(USER_MASTER_CALENDAR_KEY, events as any);
      }
      this.eventsList = events;
      this.eventsDatesArr = this.eventsList.map(day => new Date(day[0].start_date));
      this._ref.detectChanges();
      this.calendar.setCurrentMonth(this.currentMonth);
    })
  }

  filterChange(filter) {
    if (filter.paramName === 'entity_type') {
      if (filter.paramValue === 'all') {
        delete this.filterModel.entity_type;
      } else {
        this.filterModel.entity_type = filter.paramValue;
      }
    }
    if (filter.paramName === 'query') {
      if (filter.paramValue) {
        this.filterModel.query = filter.paramValue;
      } else {
        delete this.filterModel.query;
      }
    }
    this.updateCalendar();
  }

  sortingChange(val) {
    if (val === 'all') {
      this.filterModel.maker_id = this.currentUserId;
      this.filterModel.user_id = this.currentUserId;
      this.filterChange({paramName: 'entity_type', paramValue: 'all'});
    } else if (val === 'MY_COURSE') {
      this.filterModel.maker_id = this.currentUserId;
      delete this.filterModel.user_id;
      this.filterChange({paramName: 'entity_type', paramValue: 'COURSE'});
    } else if (val === 'COURSE') {
      delete this.filterModel.maker_id;
      this.filterModel.user_id = this.currentUserId;
      this.filterChange({paramName: 'entity_type', paramValue: val});
    } else {
      this.filterModel.maker_id = this.currentUserId;
      this.filterModel.user_id = this.currentUserId;
      this.filterChange({paramName: 'entity_type', paramValue: val});
    }
  }

  onSearch(evt) {
    this.filterChange({paramName: 'query', paramValue: evt});
    this.searchQuery = evt;
  }

  clearSearchQuery() {
    this.onSearch('');
  }

  setCurrentMonth(date, notUpdate = false) {
    this.currentMonth = new Date(date).setDate(1);
    this.selectMonth(this.currentMonth);
    if (!notUpdate) {
      this.updateCalendar();
    }
  }

  selectDate(date) {
    this.scrollToDate(date);
  }

  nextMonth() {
    this.setCurrentMonth(new Date(this.currentMonth).setMonth(new Date(this.currentMonth).getMonth() + 1));
  }

  prevMonth() {
    if (new Date(new Date(this.currentMonth).setMonth(new Date(this.currentMonth).getMonth() - 1)) >= new Date(new Date(new Date().setDate(1)).setHours(0,0,0,0))) {
      this.setCurrentMonth(new Date(this.currentMonth).setMonth(new Date(this.currentMonth).getMonth() - 1));
    }
  }

  onNextMonth() {
    this.calendar.nextMonth();
  }

  onPrevMonth() {
    this.calendar.prevMonth();
  }

  scrollToDate(date) {
    let offset = 80;
    let target;
    if ($('.lm-modal').length) {
      target= $('.lm-modal');
      offset = $('#' + this.parseDate(date)).offset().top - $('.client-popup').offset().top;
    } else {
      target = $('html, body');
      offset = $('#' + this.parseDate(date)).offset().top - 80
    }
    target.animate({
      scrollTop: offset
    }, 1000);
  }

  private initCalendarPosition() {
    if (this.calendarWrapper.nativeElement.getBoundingClientRect().top < 92) {
      this.calendarWrapper.nativeElement.firstElementChild.style.position = 'fixed';
      this.calendarWrapper.nativeElement.firstElementChild.style.left = this.calendarWrapper.nativeElement.getBoundingClientRect().left + 'px';
    } else {
      this.calendarWrapper.nativeElement.firstElementChild.style.position = 'static';
    }
  }

  private sortByDates = function(events) {
    function groupByDay(arr) {
      function dropTime(date) {
        return new Date(date).setHours(0,0,0,0);
      }
      let result = [];
      let currentDay = dropTime(new Date(arr[0].start_date));
      result.push([arr[0]]);
      if (arr.length > 1) {
        for (let i = 1; i < arr.length; i++) {
          if (dropTime(new Date(arr[i].start_date)) === currentDay) {
            result[result.length - 1].push(arr[i]);
          } else {
            currentDay = dropTime(new Date(arr[i].start_date));
            result.push([arr[i]]);
          }
        }
      }
      return result;
    }
    if (events && events.length) {
      let sorted = events.sort((a, b) => {
        return +(new Date(a.start_date) > new Date(b.start_date)) * 2 - 1;
      })
      return groupByDay(sorted);
    } else {
      return [];
    }

  }

  public upd = function() {
    this.updateCalendar();
  }.bind(this);

  parseDate(date) {
    return moment(date).format('YYYY-MM-DD');
  }
}
