import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { ActivatedRoute, Router } from '@angular/router';
import { makeStateKey, TransferState } from '@angular/platform-browser';
import { ModalService } from '../../services/modal.service';
import { TranslitService } from '../../services/translit.service';

const ENTITIES_INFO = makeStateKey('entities-info-deleting-master');

@Component({
  selector: 'app-modal-remove-master-after',
  templateUrl: './modal-remove-master-after.component.html',
  styleUrls: ['./modal-remove-master-after.component.styl']
})
export class ModalRemoveMasterAfterComponent implements OnInit {
  masterId;
  entityInfoData;
  loading:boolean;
  error:boolean;
  currentRoute: string;
  constructor(private adminService: AdminService,
              private state: TransferState,
              private router: Router,
              private translitService: TranslitService,
              private modalService: ModalService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    this.masterId = this.activatedRoute.snapshot.queryParams.masterId;
    !this.masterId && this.closeModal();
    this.currentRoute = this.router.url.split('?')[0];
    this.entityInfoData = this.state.get(ENTITIES_INFO, null as any);
    if (!this.entityInfoData) {
      this.loading = true;
      this.adminService.getEntitiesInfoForDeletingMaster(this.masterId).subscribe(data => {
        this.entityInfoData = data.result;
        this.state.set(ENTITIES_INFO, this.entityInfoData as any);
        this.loading = false;
      }, () => {
        this.loading = false;
        this.error = true;
      })
    }
  }

  getCourseUrl(course) {
    return this.translitService.getHumanReadableId(course.name, course.id);
  }

  closeModal() {
    this.modalService.onClose();
  }

}
