import { Pipe } from '@angular/core';

@Pipe({
  name: 'profile'
})
export class ProfilePipe {

  transform(value: any, args?: any): any {
    if (!value) {
      return '';
    }
    switch(args) {
      case 'subscription':
        if (value && +value) {
          let len = value.toString().length;
          let catnum = Math.floor(len / 4);
          let arr = value.toString().split('');
          for (let i = 0; i < catnum; i++) {
            arr.splice(4 * (i + 1) , 0, ' ');
          }
          return arr.join('').trim();
        } else {
          return value;
        }
      case 'phone':
        let arr;
        if (value[0] === '+') {
          arr = value.substr(0,2) + ' (' +  value.substr(2,3) + ') ' + value.substr(5, 3) + '-' + value.substr(8,2) + '-' + value.substr(10);
        } else if (+value[0] === 7 ) {
          arr =  '8' + ' (' +  value.substr(1,3) + ') ' + value.substr(4, 3) + '-' + value.substr(7,2) + '-' + value.substr(9);
        } else {
          arr =  '+' + (+value[0]-1) + ' (' +  value.substr(1,3) + ') ' + value.substr(4, 3) + '-' + value.substr(7,2) + '-' + value.substr(9);
        }
        return arr;
      default:
        return value;

    }

  }

}
