import { Component, OnInit } from '@angular/core';
import { EquipmentsService } from '../../services/equipments.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { forkJoin } from "rxjs";
import { first } from 'rxjs/operators';
import { combineDates, parseApiError } from '../../utils';
import { IsBrowserService } from '../../services/is-browser.service';
import { MessagesService } from '../../services/messages.service';

 @Component({
  selector: 'app-modal-notify-about-breakdown',
  templateUrl: './modal-notify-about-breakdown.component.html',
  styleUrls: ['./modal-notify-about-breakdown.component.styl']
})
export class ModalNotifyAboutBreakdown implements OnInit {
  error;
  isLoading;
  notificationInfo;
  altEquipment;
  notificationId;
  constructor(private equipmentsService: EquipmentsService,
              private activatedRoute: ActivatedRoute,
              private modalService: ModalService,
              private isBrowserService: IsBrowserService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    if (this.isBrowserService.isBrowser) {
      if (this.activatedRoute.snapshot.queryParams.notification) {
        this.updateNotifications();
        this.notificationId = this.activatedRoute.snapshot.queryParams.notification;
      } else {
        this.error = true;
      }
    }
  }

  updateNotifications(disableLoading?) {
    if (!disableLoading) {
      this.isLoading = true;
    }
    this.equipmentsService.getBreakdownInfo(this.activatedRoute.snapshot.queryParams.notification).pipe(first()).subscribe((data) => {
      this.notificationInfo = data.result
      this.notificationInfo.client_warnings.sort(this.sortCallback);
      if (!this.notificationInfo.broken_equipment_info) {
        this.error = true;
        this.isLoading = false;
      } else {
        if (this.notificationInfo.replacing_equipment_id) {
          this.equipmentsService.getEquipment(this.notificationInfo.replacing_equipment_id).pipe(first()).subscribe((data) => {
            this.altEquipment = data.result;
            this.isLoading = false
          }, () => this.error = true, () => this.isLoading = false)
        } else {
          this.isLoading = false
        }
      }
    }, () => this.error = true)
  }

  postpone(warning, params) {
    let cancel = this.equipmentsService.cancelReservation(warning.old_timing_ids, warning.client_id);
    let reservationParams;
    if (params.reserveParams) {
      reservationParams = {
        ...params.reserveParams,
        timings: params.reserveSettings.parsedSessionsTime.map(sessionTime => ({
          date: sessionTime.date,
          start_time: sessionTime.start_time,
          end_time: sessionTime.end_time,
          consultation_id: sessionTime.consultation_id,
          course_timing_id: sessionTime.course_timing_id
       }))
      }
    } else {
      reservationParams = params;
    }
    let reserve = this.equipmentsService.reserveEquip(this.notificationInfo.replacing_equipment_id, reservationParams);
    warning.loading = true;
    reserve.pipe(first()).subscribe((reserveData) => {
      cancel.pipe(first()).subscribe((cancelData) => {
        let changeCallModel = {
          client_warnings: [{
            call_result: 'POSTPONED',
            client_id: warning.client_id,
            timing_ids: reserveData.result.map(item => item.timing_id),
            warning_id: warning.warning_id
          }],
          notification_id: this.notificationId
        }
        this.equipmentsService.changeCallResult(changeCallModel).subscribe((data) => {
          this.updateNotifications(true);
        }, () => {warning.loading = false})
      }, () => {warning.loading = false})
    }, (e) => {
      warning.loading = false;
      let error = parseApiError(e);
      if (error === 'not_enough') {
        this.messagesService.showMessage('На это время нет свободного оборудования.', false, false, true);
      } else {
        this.messagesService.showMessage('Ошибка при бронировании оборудования.', false, false, true);
      }
    })
    // forkJoin(cancel, reserve).pipe(first()).subscribe((data) => {
    //   let changeCallModel = {
    //     client_warnings: [{
    //       call_result: 'POSTPONED',
    //       client_id: warning.client_id,
    //       timing_ids: data[1].result.map(item => item.timing_id),
    //       warning_id: warning.warning_id
    //     }],
    //     notification_id: this.notificationId
    //   }
    //   this.equipmentsService.changeCallResult(changeCallModel).subscribe((data) => {
    //     this.updateNotifications(true);
    //   }, () => {warning.loading = false})
    // }, (e) => {
    //   warning.loading = false;
    //   let error = parseApiError(e);
    //   if (error === 'not_enough') {
    //     this.messagesService.showMessage('На это время нет свободного оборудования.', false, false, true);
    //   } else {
    //     this.messagesService.showMessage('Ошибка при бронировании оборудования.', false, false, true);
    //   }
    // })
  }

  cancel(warning) {
    if (warning.old_timing_ids && warning.old_timing_ids.length) {
      let changeCallModel = {
        client_warnings: [{
          call_result: 'CANCELED',
          client_id: warning.client_id,
          timing_ids: warning.old_timing_ids,
          warning_id: warning.warning_id
        }],
        notification_id: this.notificationId
      }
      let cancel = this.equipmentsService.cancelReservation(warning.old_timing_ids, warning.client_id);
      let call = this.equipmentsService.changeCallResult(changeCallModel);
      warning.loading = true;
      cancel.pipe(first()).subscribe(() => {
        call.pipe(first()).subscribe(() => {
          warning.loading = false;
          this.updateNotifications(true);
        }, () => {warning.loading = false})
      }, () => {warning.loading = false})
    }
  }

  back() {
    this.modalService.back();
  }

  closeModal() {
    this.modalService.onClose();
  }

  private sortCallback = function(a, b) {
    if (!a.last_name && !a.first_name) {
      return 1;
    }
    if (!b.last_name && !b.first_name) {
      return -1;
    }
    if (`${(a.last_name || '').toLowerCase()} ${(a.first_name || '').toLowerCase()}`.trim() > `${(b.last_name || '').toLowerCase()} ${(b.first_name || '').toLowerCase()}`.trim()) {
      return 1;
    }
    if (`${(a.last_name || '').toLowerCase()} ${(a.first_name || '').toLowerCase()}`.trim() < `${(b.last_name || '').toLowerCase()} ${(b.first_name || '').toLowerCase()}`.trim()) {
      return -1;
    }
    return new Date(combineDates(a.reservation_date, a.old_reservation_start_time)).getTime() -  new Date(combineDates(b.reservation_date, b.old_reservation_start_time)).getTime();
  }


}
