import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, filter } from 'rxjs/operators';



@Component({
  selector: 'app-admin-search',
  templateUrl: './admin-search.component.html',
  styleUrls: ['./admin-search.component.styl']
})
export class AdminSearchComponent implements OnInit {
  @ViewChild('input') input: ElementRef;
  @Input() autoFocus = false;
  @Output() searchEmitter = new EventEmitter();
  @Output() onSelect = new EventEmitter();
  @Output() onClear = new EventEmitter();
  searchQuery;
  searchResults;
  focused = false;
  popupOpened;
  searchLoading;
  searchObs;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.searchObs = new Subject();
    this.searchObs.pipe(debounceTime(500), filter((query:any) => query.length >= 3), switchMap((query:any) => {
        this.searchLoading = true;
        return this.adminService.userSearch(query)
      }))
      .subscribe((data) => {
          this.popupOpened = true;
          this.searchLoading = false;
          // this.hideResults = false;
          this.searchResults = data.result;
        }, () => {
          this.searchLoading = false;
        });
    // if (this.autoFocus) {
    //   setTimeout(() => {
    //     this.input.nativeElement.focus();
    //   })
    // }
  }

  onSubmit() {

  }

  clearField() {
    this.searchQuery = '';
    // this.router.navigate(['profile/admin/calendar']);
    this.closeSearchPopup();
    this.onClear.emit();
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  onInput() {
    this.searchObs.next(this.searchQuery);
    if (this.searchQuery.length < 3) {
      this.popupOpened = false;
    }
  }

  closeSearchPopup() {
    this.popupOpened = false;
    this.searchResults = null;
  }

  openUser(user) {
    this.onSelect.next(user);
    this.searchQuery = user.name;
    this.closeSearchPopup();
  }

  getMatch(str, substr) {
    let escape = function(string) {
      return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    };
    return str.toLowerCase().search(new RegExp(escape(substr.toLowerCase())), 'gi');
  }

}
