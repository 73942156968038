import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { areaTypes } from '../../utils';

@Component({
  selector: 'app-courses-modal-filter',
  templateUrl: './courses-modal-filter.component.html',
  styleUrls: ['./courses-modal-filter.component.styl']
})
export class CoursesModalFilterComponent implements OnInit {
  @Output() filterChangeEmitter = new EventEmitter();
  timeFilters: any[] = [
    {
      title: 'Любое время',
      value: '0'
    },{
      title: 'до 12:00',
      value: '1'
    },{
      title: 'после 18:00',
      value: '2'
    },{
      title: 'выходные',
      value: '3'
    }
  ]
  myFilters: any[] = [
    {
      title: 'Все курсы',
      value: 'all'
    },{
      title: 'мои курсы',
      value: 'my'
    }
  ]
  spaces;

  constructor() { }

  ngOnInit() {
    this.spaces = [{title: 'Все пространства', value: 'all'}, ...areaTypes];
  }

  onTimeChange(value) {
    if (value == 0) {
      this.onFilterChange('weekends', false, true);
      this.onFilterChange('min_start_time', this.getTime(0), true);
      this.onFilterChange('max_start_time', this.getTime(24));
    } else if (value == 1) {
      this.onFilterChange('weekends', false, true);
      this.onFilterChange('min_start_time', this.getTime(0), true);
      this.onFilterChange('max_start_time', this.getTime(12));
    } else if (value == 2) {
      this.onFilterChange('weekends', false, true);
      this.onFilterChange('min_start_time', this.getTime(18), true);
      this.onFilterChange('max_start_time', this.getTime(24));
    } else if (value == 3) {
      this.onFilterChange('weekends', true, true);
      this.onFilterChange('min_start_time', this.getTime(0), true);
      this.onFilterChange('max_start_time', this.getTime(24));
    }
  }

  getTime(hour = 0): number {
    return<number> (hour+(new Date().getTimezoneOffset()/60))*3600*1000;
  }

  onSpacesChange(value) {
    this.onFilterChange('areas', value);
  }

  onMonthChange(value) {
    this.onFilterChange('month', value.getMonth(), true);
    this.onFilterChange('year', value.getFullYear());
  }

  onFilterChange(paramName, paramValue, stop = false) {
    this.filterChangeEmitter.emit({ paramName, paramValue, stop })
  }

}
