import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectorRef, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-checkbox-select',
  templateUrl: './checkbox-select.component.html',
  styleUrls: ['./checkbox-select.component.styl']
})
export class CheckboxSelectComponent implements OnInit {
  @Input() params: any;
  @Input() position: any;
  @Input() type: any;
  @Input() noArrow;
  @Output() onChangeEmitter = new EventEmitter();
  @ViewChild('value') valueBlock: ElementRef;
  @ViewChild('popup') popup: ElementRef;
  currentValue;
  dropdownOpened: boolean = false;
  model;
  constructor(private _ref: ChangeDetectorRef) {

  }

  ngOnInit() {
    this.currentValue = this.params[0].title;
    this.model = this.params.slice(1,this.params.length).map((item) => true);
  }

  openDropdown() {
    this.dropdownOpened = true;
    if (this.position === 'left') {
      this.popup.nativeElement.style.left = '-12px';
    } else if (this.position === 'right') {
      this.popup.nativeElement.style.right = '-12px';
    } else {
      this.popup.nativeElement.style.right = (this.valueBlock.nativeElement.offsetWidth - this.popup.nativeElement.offsetWidth) / 2 + 2 + 'px';
    }
  }

  closeDropdown() {
    this.dropdownOpened = false;
  }

  toggleDropdown() {
    if (this.dropdownOpened) {
      this.closeDropdown();
    } else {
      this.openDropdown();
    }
  }

  checkAll() {
    this.model = this.model.map((item) => true);
    this.onChange();
  }

  onChange() {
    this._ref.detectChanges();
    if (this.model.every((item) => item)) {
      this.currentValue = this.params[0].title;
    } else {
      this.currentValue = this.params.slice(1,this.params.length).filter((item, index) => this.model[index]).map(item => item.title).join(', ');
    }
    let result = this.params.slice(1,this.params.length).filter((item, index) => this.model[index]).map(item => item.id || item.value);
    this.onChangeEmitter.emit(result);
  }

  setValue(values) {
    if (values) {
      values = values.map(item => item.toUpperCase());
      this.model = this.params.slice(1,this.params.length).map(item => {
        return values.indexOf(item.value.toUpperCase()) + 1;
      })
      if (this.model.every((item) => item)) {
        this.currentValue = this.params[0].title;
      } else {
        this.currentValue = this.params.slice(1,this.params.length).filter((item, index) => this.model[index]).map(item => item.title).join(', ');
      }
    }
  }

}
