import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CoursesService } from '../../services/courses.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { MessagesService } from '../../services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from "rxjs";
import { first } from 'rxjs/operators';
import { parseApiError } from '../../utils';

@Component({
  selector: 'app-modal-course-users',
  templateUrl: './modal-course-users.component.html',
  styleUrls: ['./modal-course-users.component.styl']
})
export class ModalCourseUsersComponent implements OnInit {
  courseLoading;
  paymentLoading;
  selectedTimeId;
  courseParams;
  courseTiming;
  users;
  registeredCourse;
  error;
  filter;
  currentRoute;
  constructor(private modalService: ModalService,
              private coursesService: CoursesService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private currentCourseService: CurrentCourseService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    if (this.activatedRoute.snapshot.queryParams.course && this.activatedRoute.snapshot.queryParams.timing) {
      this.courseLoading = true;
      forkJoin([this.coursesService.getCourse(this.activatedRoute.snapshot.queryParams.course), this.coursesService.getRegisteredUsers(this.activatedRoute.snapshot.queryParams.course, this.activatedRoute.snapshot.queryParams.timing)]).pipe(first()).subscribe(data => {
        this.courseParams = data[0].result;
        this.courseParams.course_timings = this.courseParams.course_timings
          .filter(item => item.status !== "PASSED")
          .sort((a, b) => {
            return  new Date(a.start_date).getTime() - new Date(b.start_date).getTime();
          })
        this.courseTiming = this.getCourseById(this.activatedRoute.snapshot.queryParams.timing);
        this.users = data[1].result.sort(this.sortCallback);
        this.courseLoading = false;
      }, () => {
        this.courseLoading = false;
        this.error = true;
      });
    } else {
      this.error = true;
    }
  }

  togglePaid(user, params) {
    let payModel:any = {
      userId: user.user.id,
      paid: !user.paid
    }
    if (params && params.certificateNumber) {
      payModel.certificateNumber = params.certificateNumber;
    }
    this.coursesService.paid(this.courseParams.id, this.courseTiming.id, payModel).pipe(first()).subscribe((data) => {
      user.paid = data.result;
      if (payModel.certificateNumber) {
        user.certificate_number = payModel.certificateNumber;
      }
    }, (e) => {
      let error = parseApiError(e);
      if (error === 'certificate-already-used') {
        this.messagesService.showMessage('Этот сертификат уже используется.', null, false, true)
      } else {
        this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.', null, false, true)
      }
    })
  }

  toggleCame(user) {
    this.coursesService.attend(this.courseParams.id, this.courseTiming.id, user.user.id, !user.attended).pipe(first()).subscribe((data) => {
      user.attended = data.result;
    })
  }

  onSearchInput(evt) {
    this.filter = evt;
  }

  onSearchClose() {
    this.filter = '';
  }

  getCourseById(id) {
    return this.courseParams.course_timings.filter(item => item.id == id)[0];
  }

  closeModal() {
    this.modalService.onClose();
  }

  private sortCallback = function(a, b) {
    if (`${(a.user.last_name || '').toLowerCase()} ${(a.user.first_name || '').toLowerCase()}`.trim() > `${(b.user.last_name || '').toLowerCase()} ${(b.user.first_name || '').toLowerCase()}`.trim()) {
      return 1;
    }
    if (`${(a.user.last_name || '').toLowerCase()} ${(a.user.first_name || '').toLowerCase()}`.trim() < `${(b.user.last_name || '').toLowerCase()} ${(b.user.first_name || '').toLowerCase()}`.trim()) {
      return -1;
    }
    return 0;
  }
}
