import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { StorageService } from '../../services/storage.service';
import { FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-storage-products-play',
  templateUrl: './storage-products-play.component.html',
  styleUrls: ['./storage-products-play.component.styl']
})
export class StorageProductsPlayComponent implements OnInit {
  @Input() user;
  @Output() onRestart = new EventEmitter();
  isOpenBox: boolean;
  countDay: number = 1;
  isLoading;
  error;
  smallCellsList;
  bigCellsList;
  rack = ['A', 'B', 'C'];
  floor = [0, 1, 2, 3, 4, 5];
  storageDate;
  radio;
  constructor(private fb: FormBuilder,
              private storageService: StorageService) { }

  ngOnInit() {

  }

  openBox() {
    this.isOpenBox = true;
    this.initForm();
    this.updateDate();
    // this.updateCellsList();
  }

  closeBox() {
    this.isOpenBox = false;
    this.smallCellsList = null;
    this.bigCellsList = null;
    if (this.radio) {
      this.radio.controls.cell.setValue();
    }
  }

  changeAmount(evt) {
    this.countDay = evt;
    this.updateDate();
    this.radio.controls.duration.setValue(this.countDay);
    this.onChange();
  }

  updateDate() {
    this.storageDate = new Date().setDate(new Date().getDate()+this.countDay);
    this.radio.controls.untilDate.setValue(this.storageDate);
  }

  initForm() {
    this.radio = this.fb.group({
      floor: ['0', Validators.required],
      rack: ['A', Validators.required],
      duration: [0, Validators.required],
      untilDate: ['', Validators.required]
    });
  }

  onChange() {
    // this.onChangeEmitter.emit(this.radio);
  }

  submit() {
    let model = {
      cell: `${this.radio.value.rack}${this.radio.value.floor}-${this.user.id}`,
      duration: this.countDay
    }
    this.onRestart.emit(model);
  }

}
