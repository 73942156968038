import { Component, OnInit, Input, Output, EventEmitter, } from '@angular/core';
import { UserService } from '../../services/user.service';
import { FABRIC_WORK_HOURS } from '../../config';

@Component({
  selector: 'app-modal-multistep-reserve-instrument-success',
  templateUrl: './modal-multistep-reserve-instrument-success.component.html',
  styleUrls: ['./modal-multistep-reserve-instrument-success.component.styl']
})
export class ModalMultistepReserveInstrumentSuccessComponent implements OnInit {
  @Input()
  set state(state) {
    if (state) {
      this._state = state;
      this.setupPage();
    }
  }

  get state() {
    return this._state;
  }

  @Output() onClose = new EventEmitter();

  _state;
  isLoading;
  leftDays;
  leftHours;

  constructor(
    private userService: UserService
  ) {
  }

  ngOnInit() {
  }

  setupPage() {
    this.isLoading = true;
    const reserveHours = this.state.reserve.reserveSettings.sessions.length;

    this.userService.getUserData(this.state.user.id).subscribe((data) => {
      this.isLoading = false;

      const userData = data.result;
      const subDays =  userData.subscription_days;
      const subHours = subDays*FABRIC_WORK_HOURS + userData.subscription_hours;

      this.leftHours = (subHours - reserveHours) % FABRIC_WORK_HOURS;
      this.leftDays = Math.floor(subHours / FABRIC_WORK_HOURS);
    });
  }
}
