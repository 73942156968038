import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal-confirm-paid',
  templateUrl: './modal-confirm-paid.component.html',
  styleUrls: ['./modal-confirm-paid.component.styl']
})
export class ModalConfirmPaidComponent implements OnInit, OnDestroy {
  // @Input() event; 
  // @Input() isCourse; 
  config;
  event;
  componentDestroyed$ = new Subject();
  paidForm;
  user;
  sert;
  isCourse;
  causes = [
    {
      id: 1,
      title: 'Клиент не оплатил'
    }, {
      id: 2,
      title: 'Клиент оплатил мастер-класс'
    }, {
      id: 3,
      title: 'Клиент пришел по сертификату'
    }
  ];
  showSertNumber;

  constructor(private fb: FormBuilder,
              private modalService: ModalService) { }

  ngOnInit() {
    this.config = this.modalService.confirmPaidConfig;
    if (this.config) {
      this.event = this.config.event;
      this.user = this.config.user;
      this.sert = this.config.sert;
      this.isCourse = this.config.isCourse;
      if (this.isCourse) {
        this.initForm();
        this.paidForm.controls.cause.valueChanges.subscribe(val => {
          if (val === '3') {
            this.showSertNumber = true;
            this.paidForm.controls.number.setValidators([Validators.pattern(/^\d+$/), Validators.required]);
            setTimeout(() => {
              let input:any = document.querySelector('.confirm-paid-sert-number-input input');
              input.focus();
            }, 350);
          } else {
            this.showSertNumber = false;
            let value = this.paidForm.value.number;
            this.paidForm.controls.number.clearValidators();
            this.paidForm.controls.number.reset();
            this.paidForm.controls.number.setValue(value);
          }
        })
      }
    }

  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  initForm() {
    this.paidForm = this.fb.group({
      cause: ['1', [Validators.required]],
      number: ['', [Validators.pattern(/\d$/)]]
    });
  }

  reject() {
    this.modalService.setConfirmPaid(false);
  }

  confirm() {
    let confirm$ = this.config.confirm$;
    if (confirm$) {
      if (this.isCourse) {
        if (this.paidForm.value.cause == 3 && this.showSertNumber && this.paidForm.value.number) {
          confirm$.next({ certificateNumber: this.paidForm.value.number });
        } else if (this.paidForm.value.cause == 2) {
          confirm$.next();
        }
      } else {
        confirm$.next();
      }
    }
    this.reject();
  }

}
