import { Component, OnInit, PLATFORM_ID, Inject, ElementRef, HostListener, ChangeDetectorRef, OnDestroy} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ModalService } from '../../services/modal.service';
import { IsBrowserService } from '../../services/is-browser.service';
import { Subject } from 'rxjs';
import { debounceTime, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.styl']
})
export class ModalComponent implements OnInit, OnDestroy {
  isBrowser;
  hasFocus: boolean;
  componentDestroyed$ = new Subject();
  updateBodyScrollSubject$ = new Subject();

  constructor(public modalService: ModalService,
              private el: ElementRef,
              private _ref: ChangeDetectorRef,
              public isBrowserService: IsBrowserService) {
    this.isBrowser = this.isBrowserService.isBrowser;
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.updateModalPosition();
    }
    this.updateBodyScrollSubject$.pipe(debounceTime(200)).subscribe(() => {
      if (this.modalService.isModalOpened) {
        this.stopBodyScrolling(false);
      }
    });
    this.isBrowserService.onFocusSubject$.pipe(takeUntil(this.componentDestroyed$)).subscribe(state => {
      this.onKeyboardToggle();
      if (!state) {
        setTimeout(() => {
          if (!this.isBrowserService.hasFocus) {
            this.updateModalPosition();
          }
        }, 100);
      }
    });
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  onKeyboardToggle() {
    let elem:any = document.querySelector('.lm-modal');
    if (elem && window.innerWidth >= 800) {
      elem.style.height = '101vh';
      this._ref.detectChanges();
      elem.style.height = '100vh';
    }
  }

  stopBodyScrolling(bool) {
    this.modalService.bodyScrolling(bool);
  }

  @HostListener('window:scroll') updateModalPosition() {
    if (this.isBrowserService.isBrowser && !this.isBrowserService.hasFocus) {
      this.el.nativeElement.style.top = (window.pageYOffset || document.documentElement.scrollTop) + 'px';
      this.updateBodyScrollSubject$.next();
    }
  }

  @HostListener('window:resize') onWindowResize() {
    if (this.isBrowserService.isBrowser) {
      this.el.nativeElement.style.top = (window.pageYOffset || document.documentElement.scrollTop) + 'px';
      this.updateBodyScrollSubject$.next();
    }
  }

}
