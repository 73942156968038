import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-equipments-list-options',
  templateUrl: './equipments-list-options.component.html',
  styleUrls: ['./equipments-list-options.component.styl']
})
export class EquipmentsListOptionsComponent implements OnInit {
  isOpenBox;
  @Input() equipment;
  @Input() forMaster;
  @Input() userId;
  @Input() top;
  currentRoute;
  constructor(private router: Router,
              private cancelRegistrationService: CancelRegistrationService,
              private userService: UserService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    if (!this.userId) {
      this.userId = this.userService.userId;
    }
  }

  openBox() {
    if (!this.isOpenBox) this.isOpenBox = true;
    else this.isOpenBox = false;
  }
  closeBox() {
    if (this.isOpenBox) this.isOpenBox = false;
  }

  cancelReservation() {
    this.cancelRegistrationService.cancelReservation(this.equipment.entity_id, this.equipment.equipment_timing_ids, this.equipment.user_id, this.equipment)
  }

  unregister() {

  }

}
