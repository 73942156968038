import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ChangeDetectorRef } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CalendarComponent } from '../calendar/calendar.component';
import { ConsultationsService } from '../../services/consultations.service';
import { UserService } from '../../services/user.service';
import { TokenService } from '../../services/token.service';
import { Router, ActivatedRoute } from '@angular/router';
import { compareMonth, getMonthEdges, getCurrentMoscowTime } from '../../utils';
import { debounceTime } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { IsBrowserService } from '../../services/is-browser.service';
import { MessagesService } from '../../services/messages.service';

declare var $;

@Component({
  selector: 'app-consult-date-picker',
  templateUrl: './consult-date-picker.component.html',
  styleUrls: ['./consult-date-picker.component.styl']
})
export class ConsultDatePickerComponent implements OnInit {
  mastersLimit = 2;
  @Input() selectedSpace;
  @Input() resultOut;
  @Input() hideFooter;
  @Input() useUrlParams;
  @Input() oldConsultationId;
  @Output() closePickerEmitter = new EventEmitter;
  @Output() timeSelectEmitter = new EventEmitter;
  @Output() dateSelectEmitter = new EventEmitter;
  @ViewChild(CalendarComponent) calendar: CalendarComponent;
  dateTypes = [];
  masters = [];
  selectedDate;
  selectedDay;
  selectedMaster;
  selectedTime;
  currentMonth;
  consultations = [];
  consultationsArr;
  selectedConsult;
  isBrowser;
  first = true;
  inited;
  componentDestroyed$ = new Subject();
  closePickerAfter;
  nowMonth;
  datesLoading;
  datesError;

  constructor(public modalService: ModalService,
              private consultService: ConsultationsService,
              private _ref: ChangeDetectorRef,
              private userService: UserService,
              private tokenService: TokenService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private isBrowserService: IsBrowserService,
              private messagesService: MessagesService) {
                this.isBrowser = this.isBrowserService.isBrowser;
              }

  ngOnInit() {
    this.initNowMonth();
    // this.setCurrentMonth(new Date());
    // this.calendar.setCurrentMonth(this.currentMonth);
    if (this.useUrlParams) {
      this.initRouteChangeHandler();
    } else {
      this.setCurrentMonth(new Date());
      if (this.selectedSpace) {
        this.getConsultations(true);
      }
    }
    this.consultService.orderedSubject$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      if (this.activatedRoute.snapshot.queryParams.popup === 'buy-consultation') {
        this.closePickerAfter = true;
      }
    })
    this.activatedRoute.queryParamMap.pipe(takeUntil(this.componentDestroyed$)).subscribe((params:any) => {
      if (!params.popup && this.closePickerAfter) {
        setTimeout(() => {
          this.closePicker();
          this.closePickerAfter = false;
        });
      }
    })
  }

  ngOnDestroy() {
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  initNowMonth() {
    let params = this.activatedRoute.snapshot.queryParams;
    let month = params.month;
    let year = params.year;
    let date = new Date();
    date.setDate(1);
    if ((month || month === 0)) {
      date.setMonth(month);
    }
    if (year) {
      date.setFullYear(year);
    }
    this.nowMonth = date;
  }

  initRouteChangeHandler() {
    this.activatedRoute.queryParams.pipe(takeUntil(this.componentDestroyed$), debounceTime(500)).subscribe((params) => {
      let month = parseInt(params.month) + 1 ? parseInt(params.month) : new Date().getMonth();
      let year = parseInt(params.year) || new Date().getFullYear();
      let date = parseInt(params.date);
      let currentMonth = new Date(new Date(new Date(new Date().setDate(1)).setMonth(month)).setFullYear(year));
      let minDate = compareMonth(new Date(), currentMonth) ? 1 : new Date().getDate();
      let maxDate = new Date(new Date(new Date(currentMonth).setDate(32)).setDate(0)).getDate();
      let timing = parseInt(params.consult_timing);
      if (!this.currentMonth || compareMonth(new Date(this.currentMonth), new Date(currentMonth))) {
        if (currentMonth && date && date >= minDate && date <= maxDate) {
          this.setCurrentMonth(new Date(currentMonth));
          this.getConsultations(false, new Date(new Date(currentMonth).setDate(date)), timing);
        } else {
          this.setCurrentMonth(new Date(currentMonth));
          date = null;
          this.getConsultations(this.first, null, timing);
        }
        this.first = false;
      } else {
        if (date) {
          this.calendar.selectDate(new Date(new Date(currentMonth).setDate(date)).setHours(0,0,0,0), false, false);
        }
        if (timing) {
          this.selectTiming(timing);
        }
      }
    })
  }

  setCurrentSpace(space) {
    this.selectedSpace = space;
  }

  setCurrentMonth(date) {
    this.currentMonth = new Date(new Date(date).setDate(1));
    this.nowMonth = new Date(this.currentMonth);
  }

  selectDate(date) {
    if (this.selectedDate != date) {
      this.selectedDate = date;
      this.selectedTime = null;
      let match;
      this.selectedDay = this.consultations.filter(item => item.date === date)[0];
      if (!match) {
        this.selectedMaster = null;
      }
      if (this.resultOut) {
        this.timeSelectEmitter.emit(null);
      }
      this.dateSelectEmitter.emit();
      if (window.innerWidth <= 800 && this.isBrowser && !this.resultOut) {
        setTimeout(() => {
          this.scrollToElem('select-time', 60)
        }, 100)
      }
      if (this.useUrlParams) {
        let params = {...this.activatedRoute.snapshot.queryParams};
        params.date = new Date(date).getDate();
        params.month = new Date(date).getMonth();
        if (this.inited) {
          delete params.consult_timing;
        }
        delete params.submit;
        this.router.navigate(['/abonementy-i-uslugi'], {queryParams: params});
      }
    }
  }

  scrollToElem(id, offset) {
    if (this.isBrowser) {
      let elem = $('#' + id);
      if (elem && window.innerWidth <= 800 && elem.offset()) {
        $('html, body').animate({
          scrollTop: elem.offset().top - offset
        }, 1000);
      }
    }
  }

  checkAuth() {
    let queryParams = {...this.activatedRoute.snapshot.queryParams};
    if (this.userService.userId && this.selectedTime) {
      this.onSubmit();
    } else {
      queryParams.popup = 'login';
      queryParams.submit = 'true';
      this.router.navigate([this.router.url.split('?')[0]], { queryParams });
      let submit = function(timing) {
        setTimeout(() => {
          this.onSubmit(timing);
        }, 100);
      }.bind(this, this.selectedTime);
      setTimeout(() => {
        this.modalService.setPageOptions({signInTitleText: 'Войдите, чтобы записаться на консультацию'});
        this.modalService.setPageOptions({signUpTitleText: 'Зарегистрируйтесть, чтобы записаться на консультацию'});
        this.modalService.setGlobalOptions({afterSuccess: submit});
      }, 0)
    }
  }

  onSubmit(timing?) {
    let queryParams = {...this.activatedRoute.snapshot.queryParams};
    queryParams.popup = 'buy-consultation';
    queryParams.consultation = this.selectedTime || timing;
    delete queryParams.submit;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams })
    setTimeout(() => {
      this.modalService.setGlobalOptions({afterSuccess: this.closePicker});
    }, 0)
  }

  public closePicker = function() {
    let queryParams = { ...this.activatedRoute.snapshot.queryParams };
    delete queryParams.area;
    delete queryParams.month;
    delete queryParams.date;
    delete queryParams.year;
    delete queryParams.consult_timing;
    delete queryParams.submit;
    // setTimeout(() => {
    this.router.navigate([this.router.url.split('?')[0]], { queryParams });
    // }, 100);
  }.bind(this);

  nextMonth() {
    let nextMonth = new Date(this.currentMonth).setMonth(new Date(this.currentMonth).getMonth() + 1);
    if (this.useUrlParams) {
      this.updateMonthParam(nextMonth);
    } else {
      this.setCurrentMonth(nextMonth);
      this.getConsultations();
    }
  }

  prevMonth() {
    if (compareMonth(new Date(), this.currentMonth) < 0) {
      let prevMonth = new Date(this.currentMonth).setMonth(new Date(this.currentMonth).getMonth() - 1)
      if (this.useUrlParams) {
        this.updateMonthParam(prevMonth);
      } else {
        this.setCurrentMonth(prevMonth);
        this.getConsultations();
      }
    }
  }

  onNextMonth() {
    this.calendar.nextMonth();
  }

  onPrevMonth() {
    this.calendar.prevMonth();
  }

  updateMonthParam(date) {
    let params = {...this.activatedRoute.snapshot.queryParams};
    params.month = new Date(date).getMonth();
    params.year = new Date(date).getFullYear();
    delete params.submit;
    this.router.navigate(['/abonementy-i-uslugi'], { queryParams: params });
  }

  getConsultations(first?, date?, timing?) {
    this.calendar.setCurrentMonth(this.currentMonth);
    let model: any = {
      area: this.selectedSpace,
      start_date: getMonthEdges(this.currentMonth)[0].getTime(),
      end_date: getMonthEdges(this.currentMonth)[1].getTime()
    }
    if (this.oldConsultationId) {
      model.oldConsultationId = this.oldConsultationId;
    }
    this.datesLoading = true;
    this.consultService.getAvailableConsultations(model).subscribe((data) => {
      let today = new Date(getCurrentMoscowTime());
      let result = data.result.filter(item => new Date(item.start_time) > today);
      this.consultationsArr = result;
      this.consultations = this.groupConsultationsByDay(result);
      this.masters = this.getMasters(result);
      this._ref.detectChanges();
      this.calendar.setCurrentMonth(this.currentMonth);
      this.selectedDay = this.consultations.filter(item => item.date === this.selectedDate)[0];
      let firstConsult = this.getFirstConsult(this.consultations);
      if (first && firstConsult && firstConsult.date && (this.isBrowser && window.innerWidth > 800)) {
        this.calendar.selectDate(firstConsult.date, false, false);
        if (timing) {
          this._ref.detectChanges();
          this.selectTiming(timing);
        } else {
          this._ref.detectChanges();
          this.selectTiming(null);
        }
      }
      if (date) {
        if (this.isBrowser && window.innerWidth > 800) {
          this.calendar.selectDate(new Date(date).setHours(0,0,0,0), false, false);
        }
        if (!this.selectedDay && firstConsult && firstConsult.date && (this.isBrowser && window.innerWidth > 800)) {
          this.calendar.selectDate(firstConsult.date, false, false);
          if (timing) {
            this._ref.detectChanges();
            this.selectTiming(timing);
          } else {
            this._ref.detectChanges();
            this.selectTiming(null);
          }
        }
        if (timing) {
          this._ref.detectChanges();
          this.selectTiming(timing);
        } else {
          this._ref.detectChanges();
          this.selectTiming(null);
        }
      }
      if (this.activatedRoute.snapshot.queryParams.submit && !this.activatedRoute.snapshot.queryParams.social_auth_token) {
        this.checkAuth();
      }
      if (this.resultOut) {
        this.timeSelectEmitter.emit(this.selectedDay ? this.selectedConsult : null);
      }
      this.inited = true;
      this.datesLoading = false;
    }, () => {
      this.messagesService.showMessage('Не удалось загрузить список консультаций.', null, false, true);
      this.datesError = true;
    })
  }

  getFirstConsult(consultations) {
    let firstConsult;
    let counter = 0;
    let currentMonthIndex = new Date(this.currentMonth).getMonth();
    let currentYear = new Date(this.currentMonth).getFullYear();
    while (!firstConsult && counter < consultations.length) {
      if (new Date(consultations[counter].date).getMonth() === currentMonthIndex && new Date(consultations[counter].date).getFullYear() === currentYear) {
        firstConsult = this.consultations[counter];
      }
      counter++;
    }
    return firstConsult;
  }

  onTimeSelect() {
    this.setActiveTiming(this.selectedTime);
  }

  setActiveTiming(timing) {
    if (this.useUrlParams) {
      let params = {...this.activatedRoute.snapshot.queryParams};
      if (timing) {
        params.consult_timing = timing;
      } else {
        delete params.consult_timing;
      }
      this.router.navigate(['/abonementy-i-uslugi'], {queryParams: params});
    } else {
      this.selectTiming(timing);
    }

  }

  selectTiming(timing) {
    this.selectedTime = timing;
    this.selectedConsult = this.getConsultById(timing);
    if (this.resultOut) {
      this.timeSelectEmitter.emit(this.selectedConsult);
    }
    this.scrollToElem('order-button', 120);
  }

  unselectDate() {
    this.selectedDay = null;
    this.selectedDate = null;
    this.calendar.selectedDate = null;
  }

  private getMonthDays = function() {
    let firstMonthDay = new Date(new Date(this.currentMonth).setDate(1)).setHours(0,0,0,0);
    let lastMonthDay = new Date(new Date(new Date(new Date(this.currentMonth).setDate(1)).setMonth(new Date(this.currentMonth).getMonth() + 1)).setDate(0)).setHours(23, 59, 59, 59);
    return [firstMonthDay, lastMonthDay];
  }

  private groupConsultationsByDay = function(data) {
    let consultations = data.sort((a, b) => a.start_time - b.start_time);
    let result = [];
    let that = this;
    function newDay(item) {
      result.push({
        date: that.dropTime(item.start_time),
        masters: [{
          master: item.maker,
          sessions: [{
            id: item.id,
            start: item.start_time,
            end: item.end_time
          }]
        }]
      })
    }
    let currentDate;
    consultations.forEach((item) => {
      if (this.dropTime(item.start_time) === currentDate && result.length) {
        let sameMaster;
        if (result[result.length - 1].masters) {
          let sameMasters = result[result.length - 1].masters.filter(x => x.master.id === item.maker.id);
          if (sameMasters.length) {
            sameMaster = sameMasters[0];
          }
        }
        if (sameMaster) {
          sameMaster.sessions.push({
            id: item.id,
            start: item.start_time,
            end: item.end_time
          })
        } else {
          result[result.length - 1].masters.push({
            master: item.maker,
            sessions: [{
              id: item.id,
              start: item.start_time,
              end: item.end_time
            }]
          })
        }
      } else {
        newDay(item);
        currentDate = this.dropTime(item.start_time);
      }
    })
    return result;
  }.bind(this)

  private getMasters = function(data) {
    let mastersArr = data.map(item => item.maker);
    let result = [];
    mastersArr.forEach(item => {
      if (!result.some(elem => elem.id === item.id)) {
        result.push(item);
      }
    })
    return result.sort((a, b) => a.id - b.id);
  }

  private dropTime = function(date) {
    return new Date(date).setHours(0,0,0,0);
  }
  private getConsultById = function(id) {
    return this.consultationsArr.filter(item => item.id === id)[0];
  }
}
