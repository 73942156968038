import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { StorageService } from '../../services/storage.service';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-modal-storage-cell-reservation',
  templateUrl: './modal-storage-cell-reservation.component.html',
  styleUrls: ['./modal-storage-cell-reservation.component.styl']
})
export class ModalStorageCellReservationComponent implements OnInit {
  currentStep = 0;
  finalStep = 2;
  state:any = {};
  isLoading;
  error;
  registerLoading;
  errorMessage;
  selectedCell;
  reserveLoading;
  constructor(private modalService: ModalService,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private storageService: StorageService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.user) {
      this.selectUser({user: {id: this.activatedRoute.snapshot.queryParams.user}});
    }
  }

  selectUser(state) {
    this.error = false;
    this.errorMessage = '';
    this.isLoading = true;
    forkJoin(this.userService.getUserData(state.user.id), this.storageService.getUsersStorageSessions(state.user.id)).subscribe((data) => {
      if (data[0].result && data[1].result) {
        this.isLoading = false;
        if (data[1].result.length) {
          this.error = true;
          this.errorMessage = 'У этого пользователя уже забронирована ячейка';
        } else {
          this.nextStep({
            user: data[0].result
          });
        }
      } else {
        this.error = true;
      }

    }, () => this.error = true)
  }

  nextStep(state) {
    this.currentStep++;
    this.state = {
      ...this.state,
      ...state
    };
    if (this.currentStep > this.finalStep) {
      this.submit();
    }
  }

  onCellSelect(evt) {
    this.selectedCell = evt;
  }

  submit() {
    this.reserveLoading = true;
    this.error = false;
    this.storageService.startStorageSession(this.state.user.id, this.state.cell.cell, this.state.cell.duration, this.state.paid).subscribe((data) => {
      if (data.result.errors && data.result.errors.length) {
        this.error = true;
        this.reserveLoading = false;
        this.currentStep = 1;
        if (data.result.errors[0].field === 'Storage slot is not free') {
          this.errorMessage = 'Эта ячейка занята'
        }
      } else {
        this.reserveLoading = false;
        this.storageService.updateEmitter();
        this.modalService.nextPage({storageCellPaid: data.result.paid, storageCellParam:
           {
             cell: data.result.slot_identifier,
             date: data.result.end_date,
             cost: data.result.sum
           }}, null);
      }
    },() => {this.reserveLoading = false; this.error = true})
  }

  reserv() {
    this.currentStep++;
  }

  back() {
    this.modalService.back();
  }
}
