import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';
import { UserService } from '../../services/user.service';
import { FileUploadService } from '../../services/file-upload.service';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { IsBrowserService } from '../../services/is-browser.service';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-write-comment',
  templateUrl: './write-comment.component.html',
  styleUrls: ['./write-comment.component.styl']
})
export class WriteCommentComponent implements OnInit {
  @ViewChild('writecomment') writeComment: ElementRef;
  @ViewChild('uploader') uploader: ElementRef;
  @Output() sendCommentEmitter = new EventEmitter();
  @Output() dontClose = new EventEmitter();
  @ViewChild(ClickOutsideDirective) clickOutside;
  fileAdded;
  commentText = "";
  myFileInputIdentifier = 'commentImageUploader';
  user: any;
  sendingState = 0;
  errorMessage = '';
  sendingMessage = '';
  commentLoading = false;
  w;
  isDisabledBtn:boolean;
  quillRange;
  public uploader2:any = new FileUploader({url: URL});
  public hasDropZoneOver:boolean = false;

  public fileOver(e:any):void {
    this.hasDropZoneOver = e;
  }

  constructor(private userService: UserService,
              private isBrowserService: IsBrowserService,
              private fileUploadService: FileUploadService){ }

  ngOnInit() {
    this.uploader2.onAfterAddingFile = (item:any, response:any, status:any, headers:any) => {
      if (!(item.file.type.indexOf('image/') + 1)) {
        this.uploader2.queue.pop(); // clear old file & replace it with the new one
      } else if (this.uploader2.queue.length > 1) {
        this.uploader2.queue.splice(0, 1); // clear old file & replace it with the new one
      }
    };
    this.user = this.userService.userData;
    if (this.isBrowserService.isBrowser) {
      this.w = window;
      var Block = this.w.Quill.import('blots/block');
      Block.tagName = 'DIV';
      this.w.Quill.register(Block, true)
      let quill = new this.w.Quill(this.writeComment.nativeElement, {
        modules: {
          toolbar: {
            container: [],
            handlers: {'emoji': function(evt) {
            }}
          },
          "emoji-textarea": {
            buttonIcon: '<button id="emoji-button" class="btn emoji-picker-btn"></button>'
          },
          "emoji-shortname": {
            fuse: {
              shouldSort: true,
              threshold: 0.1,
              location: 0,
              distance: 100,
              maxPatternLength: 32,
              minMatchCharLength: 1,
              keys: [
                "shortname"
              ]
            },
          },
        },
        placeholder: '',
        theme: 'snow'
      });

      setTimeout(() => {
        quill.setSelection(0);
      }, 100);

      quill.on('editor-change', () => {
        this.commentText = quill.editor.scroll.domNode.innerHTML;
        this.isDisabledBtn = !quill.editor.scroll.domNode.innerText.trim();
        this.dontClose.emit(true);
        setTimeout(() => this.dontClose.emit(false), 1000);
        const range = quill.getSelection() || this.quillRange;
        if (this.quillRange && range.index !== this.quillRange.index) {
          const layout = document.getElementById('emoji-close-div');
          if (layout) {
            setTimeout(() => {
              try {
                layout.dispatchEvent(new Event('click'));
              } catch (e) {

              }
            }, 100);
          }
        }
        this.quillRange = range;
      });

      document.getElementById('emoji-button').addEventListener('click', () => {
        setTimeout(() => {
          const tabPanel = document.getElementById('tab-panel');
          if (tabPanel) {
            tabPanel.addEventListener('click', () => {
              let range;
              if (this.quillRange) {
                range = +this.quillRange.index;
              }
              setTimeout(() => {
                if (range || range === 0) {
                  quill.setSelection(range + 1);
                }
              }, 100);
            });
          }
        }, 100);
      });
    }
  }

  onAdded(evt) {
    this.fileAdded = evt.file;
    setTimeout(() => {
      let img = this.uploader.nativeElement.getElementsByTagName('img')[0];
      img.onload = () => {
        let prop = img.offsetWidth / img.offsetHeight;
        if (prop <= 1) {
          img.style.width = '100%';
          img.style.top = -img.parentNode.offsetWidth * (1 - prop) / 2 + 'px';
        } else {
          img.style.height = '100%';
          img.style.left = -img.parentNode.offsetWidth * (prop - 1) / 2 + 'px';
        }
      }
    }, 0)
  }


  onRemoved(evt) {
    this.fileAdded = null;
  }

  onAction(evt) {

  }

  removeImage(evt?) {
    if (evt) {
      this.prevent(evt);
    }
    this.uploader2.clearQueue();
  }

  prevent(evt) {
    evt.stopPropagation();
  }

  private uploadImage = function() {
    return this.fileUploadService.uploadFile(this.uploader2.queue[0]._file, 'COMMENTS');
  }

  onSuccess(message) {
    this.sendingMessage = message;
    this.commentLoading = false;
    this.errorMessage = '';
  }

  onError(message) {
    this.errorMessage = message;
    this.sendingMessage = '';
    this.commentLoading = false;
  }

  sendComment() {
    this.errorMessage = '';
    this.sendingMessage = '';
    if (this.uploader2.queue && this.uploader2.queue.length) {
      this.commentLoading = true;
      this.sendingMessage = 'Загрузка';
      this.uploadImage().subscribe((data) => {
        this.sendCommentEmitter.emit({
          text: this.commentText,
          photo_id: data.result.id
        })
      }, () => {
        this.onError('Ошибка');
      })
    } else {
      this.sendCommentEmitter.emit({
        text: this.commentText
      })
    }
  }
}
