import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { compareMonth } from '../../utils';

@Component({
  selector: 'app-select-month-with-popup',
  templateUrl: './select-month-with-popup.component.html',
  styleUrls: ['./select-month-with-popup.component.styl']
})
export class SelectMonthWithPopupComponent implements OnInit {
  @Output() onChangeEmitter = new EventEmitter();
  @Input() right;
  @Input() disableFuture;
  @Input() disablePast;
  @Input() monthsSwithOut: boolean;
  disableRight;
  disableLeft;
  disableMonthLeft: Date;
  disableMonthRight: Date;
  today;
  activeDate;
  months = [];
  isOpenCalendar;
  isAllTime;
  constructor() { }

  ngOnInit() {
    this.today = new Date(new Date().setDate(1));
    this.activeDate = new Date(new Date().setDate(1));
    this.initMonth(true);
  }

  nextMonth() {
    if (!this.monthsSwithOut) {
      let nextMonth = this.activeDate.getMonth() + 1;
      this.activeDate = new Date(this.activeDate.setMonth(nextMonth));
      this.initMonth();
    } else {
      this.onChangeEmitter.emit(new Date(new Date().setMonth(this.activeDate.getMonth() + 1)));
    }

  }

  prevMonth() {
    if (!this.monthsSwithOut) {
      let prevMonth = this.activeDate.getMonth() - 1;
      this.activeDate = new Date(this.activeDate.setMonth(prevMonth));
      this.initMonth();
    } else {
      this.onChangeEmitter.emit(new Date(new Date().setMonth(this.activeDate.getMonth() - 1)));
    }
  }

  initMonth(notUpdate?) {
    for (let i = 0; i < 12; i++) {
      let newYear = new Date( new Date(new Date().setDate(1)).setFullYear(this.activeDate.getFullYear()));
      newYear.setMonth(i);
      this.months[i] = new Date(newYear);
    }
    if (!notUpdate) {
      this.onChangeEmitter.emit(this.activeDate);
    }
  }

  selectMonth(index) {
    this.isAllTime = false;
    if (this.monthsSwithOut) {
      this.onChangeEmitter.emit(new Date(new Date(this.activeDate).setMonth(index)));
    } else {
      if (
          !(this.months[index].getMonth() > this.today.getMonth() && this.disableFuture &&
          this.today.getFullYear() === this.activeDate.getFullYear()) &&
          !(this.months[index].getMonth() < this.today.getMonth() && this.disablePast &&
          this.today.getFullYear() === this.activeDate.getFullYear()) &&
          !(this.disableLeft && this.activeDate.getMonth() > this.months[index].getMonth()) &&
          !(this.disableRight && this.activeDate.getMonth() < this.months[index].getMonth()) &&
          !(this.disableMonthLeft && (this.disableMonthLeft.getMonth() > this.months[index].getMonth())
                                  && (this.disableMonthLeft.getFullYear() >= this.months[index].getFullYear())) &&
          !(this.disableMonthRight && (this.disableMonthRight.getMonth() < this.months[index].getMonth())
                                   && (this.disableMonthRight.getFullYear() <= this.months[index].getFullYear()))
        ) {
        this.activeDate = new Date(this.activeDate.setMonth(index));
        this.onChangeEmitter.emit(this.activeDate);
      }
    }
  }

  prevYear() {
    let prevYear = this.activeDate.getFullYear() - 1;
    let date = compareMonth(new Date(), new Date(this.activeDate).setFullYear(prevYear)) === -1 ? new Date() : this.activeDate;
    if (!this.monthsSwithOut) {
      this.activeDate = new Date(new Date(date).setFullYear(prevYear));
      if (this.disableMonthLeft && this.activeDate.getFullYear() === this.disableMonthLeft.getFullYear() && this.activeDate.getMonth() < this.disableMonthLeft.getMonth()) {
        this.activeDate.setMonth(this.disableMonthLeft.getMonth());
      }
      this.initMonth();
    } else {
      this.onChangeEmitter.emit(new Date(new Date(date).setFullYear(prevYear)));
    }
  }

  nextYear() {
    let nextYear = this.activeDate.getFullYear() + 1;
    let date = compareMonth(new Date(), new Date(this.activeDate).setFullYear(nextYear)) === -1 ? new Date() : this.activeDate;
    if (!this.monthsSwithOut) {
      this.activeDate = new Date(new Date(date).setFullYear(nextYear));
      if (this.disableMonthRight && this.activeDate.getFullYear() === this.disableMonthRight.getFullYear() && this.activeDate.getMonth() > this.disableMonthRight.getMonth()) {
        this.activeDate.setMonth(this.disableMonthRight.getMonth());
      }
      this.initMonth();
    } else {
      this.onChangeEmitter.emit(new Date(new Date(date).setFullYear(nextYear)));
    }
  }

  openCalendar() {
    if (this.isOpenCalendar) this.isOpenCalendar = false;
    else this.isOpenCalendar = true;
  }

  closeCalendar() {
    this.isOpenCalendar = false;
  }

  allTime() {
    if (!this.monthsSwithOut) {
      this.isAllTime = true;
      this.closeCalendar();
    }
    this.onChangeEmitter.emit('all');
  }
}
