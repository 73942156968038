import { Directive, OnInit, AfterViewInit, ElementRef, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Directive({
  selector: '[appLmInput]'
})

export class LmInputDirective {
  @Input() placeholder;
  @Input() fieldDescription;
  @Input() bigFont: boolean;
  @Input() errorMessage: string;
  @Input() control: any;
  @Input() autoFocus: any;
  isBrowser;
  placeholderElem;
  descriptionElem;
  showPasswordBtn;
  isPasswordShown = false;
  errorMessageElem;
  constructor(private el: ElementRef,
              @Inject(PLATFORM_ID) platformId: string) {
                  this.isBrowser = isPlatformBrowser(platformId);
              }

  ngOnInit() {
    if (this.isBrowser) {
      this.el.nativeElement.classList.add('lm-input');
      if (this.bigFont) {
        this.el.nativeElement.classList.add('lm-input-big');
      }
      let wrapper = $('<label class="lm-input-wrapper"></label>');
      if (!this.placeholder) {
        wrapper.css('padding-top', 0);
        wrapper.css('position', 'relative');
      }
      $(this.el.nativeElement).wrap(wrapper);
      if (this.placeholder) {
        this.placeholderElem = $(`<div class="lm-input-placeholder">${this.placeholder}</div>`);
        if (this.bigFont) {
          this.placeholderElem.addClass('lm-input-placeholder-big');
        }
        $(this.el.nativeElement).closest('.lm-input-wrapper').append(this.placeholderElem);
      }
      if (this.fieldDescription) {
        this.descriptionElem = $(`<div class="lm-input-field-description">${this.fieldDescription}</div>`);
        // this.descriptionElem.css('top', 0);
        // this.descriptionElem.css('left', '100%');
        // this.descriptionElem.css('padding-left', '16px');
        // this.descriptionElem.css('width', '266px');
        // this.descriptionElem.css('position', 'absolute');
        this.descriptionElem.css('display', 'none');
        $(this.el.nativeElement).closest('.lm-input-wrapper').append(this.descriptionElem);
      }
      if (this.el.nativeElement.getAttribute('type') === 'password') {
        this.showPasswordBtn = $('<button type="button" class="btn btn-show-password"></button>');
        this.showPasswordBtn.css('position', 'absolute');
        this.showPasswordBtn.css('top', '24px');
        this.showPasswordBtn.css('right', '0');
        $(this.el.nativeElement).closest('.lm-input-wrapper').append(this.showPasswordBtn);
        this.showPasswordBtn.click(this.toggleShowPassword);
      }
      if (this.el.nativeElement.getAttribute('type') === 'password' && !this.placeholder ) {
        this.showPasswordBtn.css('top', '7px');
      }
      if (this.errorMessage || this.control) {
        if (!this.errorMessage) {
          this.errorMessage = 'Пожалуйста, заполните это поле';
        }
        this.errorMessageElem = $(`<div class="lm-input-error-message">${this.errorMessage}</div>`);
        $(this.el.nativeElement).closest('.lm-input-wrapper').append(this.errorMessageElem);
      }
      this.el.nativeElement.onfocus = this.onFocus;
      this.el.nativeElement.onblur = this.onBlur;
      this.el.nativeElement.oninput = this.onInput;
      this.control && this.control.valueChanges.subscribe(() => {
        if (this.el.nativeElement.value) {
          this.upPlaceholder();
        }
      });
      // setTimeout(() => {
      //   if (this.el.nativeElement.value) {
      //     this.upPlaceholder();
      //   }
      // });
      // setTimeout(() => {
      //   if (this.el.nativeElement.value) {
      //     this.upPlaceholder();
      //   }
      // }, 100);
      // setTimeout(() => {
      //   if (this.el.nativeElement.value) {
      //     this.upPlaceholder();
      //   }
      // }, 500);
      if (this.autoFocus) {
        this.el.nativeElement.focus();
      }
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.el.nativeElement.value) {
        this.upPlaceholder();
      }
    }, 100)
  }
  upPlaceholder() {
    if (this.placeholderElem) {
      this.placeholderElem.addClass('lm-input-placeholder-small');
    }
  }

  downPlaceholder() {
    if (this.placeholderElem) {
      this.placeholderElem.removeClass('lm-input-placeholder-small');
    }
  }

  private onFocus = function() {
    this.upPlaceholder();
    this.checkDescription();
    $(this.el.nativeElement).closest('.lm-input-wrapper').addClass('focus');
  }.bind(this)

  private onBlur = function() {
    $(this.el.nativeElement).closest('.lm-input-wrapper').removeClass('focus');
    if (!this.el.nativeElement.value) {
      this.downPlaceholder();
    }
    this.checkDescription(true);
    if (this.control && this.control.errors) {
      if (this.control.errors.required) {
        this.changeErrorMessage('Пожалуйста, заполните это поле.')
      } else if (this.control.errors.incorrect || this.control.errors.pattern) {
        this.changeErrorMessage('Некорректное значение.')
      }
    }
  }.bind(this)

  private onInput = function() {
    this.checkDescription();
  }.bind(this)


  private checkDescription = function(hide) {
    if (this.descriptionElem) {
      if (!hide && !this.el.nativeElement.value && this.el.nativeElement.focus) {
        this.descriptionElem.fadeIn(300);
      } else {
        this.descriptionElem.fadeOut(300);
      }
    }
  }

  private toggleShowPassword =  function() {
    this.isPasswordShown = !this.isPasswordShown;
    if (this.isPasswordShown) {
      this.el.nativeElement.setAttribute('type', 'text');
      this.showPasswordBtn.addClass('btn-show-password-active');
    } else {
      this.el.nativeElement.setAttribute('type', 'password');
      this.showPasswordBtn.removeClass('btn-show-password-active');
    }
  }.bind(this)

  private changeErrorMessage(text) {
    if (this.errorMessageElem) {
      if (text) {
        this.errorMessageElem.text(text);
      } else {
        this.errorMessageElem.text(this.errorMessage);
      }
    }
  }

}
