import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
@Injectable()
export class CoursesGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router, private activatedRoute: ActivatedRoute) {

  }
  
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    let params = this.activatedRoute.snapshot.params;
    if (params.id || (params.year && params.month)) {
      return true;
    } else {
      const currentMonth = new Date().getMonth() + 1;
      const currentYear = new Date().getFullYear(); 
      this.router.navigate([`/master-klassy/${currentYear}/${currentMonth}`])
      return false;
    }

  }
}
