import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sorting-filter-with-icons',
  templateUrl: './sorting-filter-with-icons.component.html',
  styleUrls: ['./sorting-filter-with-icons.component.styl']
})
export class SortingFilterWithIconsComponent implements OnInit {
  @Input() title: string;
  @Input() iconsSize: number;
  @Input() params: any[];
  @Input() notHidden: boolean;
  @Output() onChangeEmitter = new EventEmitter();
  currentValue;
  popupOpened;
  constructor() { }

  ngOnInit() {
    this.currentValue = this.params[0].title;
  }

  onChange(evt) {
    this.currentValue = evt.target.title;
    this.onChangeEmitter.emit(evt.target.value);
    this.closePopup();
  }

  openPopup() {
    this.popupOpened = true;
  }

  closePopup() {
    this.popupOpened = false;
  }

  togglePopup() {
    if (this.popupOpened) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

}
