import { Directive, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Directive({
  selector: '[appInitAction]'
})
export class InitActionDirective implements AfterViewInit {
  @Output() onInitAction = new EventEmitter();
  constructor() { }

  ngAfterViewInit() {
    this.onInitAction.emit();
  }
}
