import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { FileUploadService } from '../../services/file-upload.service';
import { CoursesService } from '../../services/courses.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { CreationService } from '../../services/creation.service';
import { EquipmentsService } from '../../services/equipments.service';
import { notNull, getApiTime, areaTypes, parseApiError, parseDateToString } from '../../utils';
import { Subject } from 'rxjs';
import { first, debounceTime, map } from 'rxjs/operators';
import { TranslitService } from '../../services/translit.service';
import { IsBrowserService } from '../../services/is-browser.service';
import { HistoryService } from '../../services/history.service';
import { RoutesDataService } from '../../services/routes-data.service';
import * as moment from 'moment';

declare var $;

@Component({
  selector: 'app-course-creation',
  templateUrl: './course-creation.component.html',
  styleUrls: ['./course-creation.component.styl']
})
export class CourseCreationComponent implements OnInit {
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('mainPhotoPicker') mainPhotoPicker: ElementRef;
  currentCourse;
  loadingState;
  errorMessage;
  deleteLoading;
  deleted;
  confirmType;
  creationForm;
  courseLoading;
  isEdition;
  loadedSubject$;
  isBrowser;
  defaultSchedule;
  editedTiming;
  enabledTiming;
  currentRoute;
  areas;
  maxRegistered;
  masters = [];
  instruments = [];
  courseDates = [];
  courseSchedule = [];

  constructor(private router: Router,
              private modalService: ModalService,
              private fb: FormBuilder,
              private fileUploadService: FileUploadService,
              private coursesService: CoursesService,
              private currentCourseService: CurrentCourseService,
              private _ref: ChangeDetectorRef,
              private creationService: CreationService,
              private activatedRoute: ActivatedRoute,
              private equipmentsService: EquipmentsService,
              private isBrowserService: IsBrowserService,
              private translitService: TranslitService,
              private historyService: HistoryService,
              private routesDataService: RoutesDataService,
            ) { this.isBrowser = this.isBrowserService.isBrowser }

  ngOnInit() {
    this.areas = areaTypes;
    this.currentRoute = this.router.url.split('?')[0];
    this.loadedSubject$ = new Subject();
    if (this.activatedRoute.snapshot.queryParams.editcourse) {
      this.courseLoading = true;
      this.isEdition = true;
      this.coursesService.getCourse(this.activatedRoute.snapshot.queryParams.editcourse).subscribe((data) => {
        this.courseLoading = false;
        this.currentCourse = data.result;
        this.maxRegistered = Math.max(...this.currentCourse.course_timings.map(item => item.places_filled));
        this.defaultSchedule = data.result.course_timings.map(item => {
          return {
            id: item.id,
            date: item.start_date,
            time: `${moment(item.start_time).format('HH:mm')}-${moment(item.end_time).format('HH:mm')}`,
            maker: item.maker.id
          }
        })
        console.log(this.defaultSchedule);
        this.initCourseEdition();
        this.wrapper.nativeElement.scrollTop = 0;
        this.initMasters();
        this.initInstruments();
        this.loadedSubject$.next();
      });
    } else if (this.activatedRoute.snapshot.queryParams.creation && this.activatedRoute.snapshot.queryParams.creation === 'course') {
      this.initCourseCreation();
      this.initMasters();
      this.initInstruments();
    }
    if (this.activatedRoute.snapshot.queryParams.field && this.isBrowser) {
      if (this.activatedRoute.snapshot.queryParams.field === 'schedule') {
        if (this.activatedRoute.snapshot.queryParams.timing) {
          this.enabledTiming = this.activatedRoute.snapshot.queryParams.timing;
        }
        this.loadedSubject$.pipe(first(), debounceTime(100)).subscribe(() => {
          this._ref.detectChanges();
          $(this.wrapper.nativeElement).animate({
            scrollTop: document.getElementById('schedule-section').getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
          }, 1000);
        })
      }
    }

  }

  initMasters() {
    this.coursesService.getMasters().subscribe((data) => {
      this.masters = data.result;
    })
  }

  initInstruments() {
    this.equipmentsService.getEquipmentsList({}).pipe(first()).subscribe(data => {
      this.instruments = data.result.data;
    });
  }

  initCourseCreation() {
    console.log('CREATION')
    this.creationForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      mainImage: [null, [Validators.required]],
      space: ['DECORATION', [Validators.required]],
      price: ['', [Validators.required, this.numberValidator]],
      maxCapacity: ['', [Validators.required, this.getNumberValidator(1)]],
      schedule: this.fb.array([]),
      equipments: this.fb.array([]),
      needReservation: [false],
      materials: this.fb.array([]),
      albumUrl: ['', [Validators.pattern(/^(https?:\/\/)?(www\.)?(vk|facebook)\.com\/.{1,}$/)]],
      importantCourse: [''],
      mainPageImage: [null],
      gallery: this.fb.array([
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null)
      ])
    });
    this.addSchedule();
    this.addMaterial();
    this.addEquipment();
    this.onChangeNeedReservation();
  }

  initCourseEdition() {
    console.log('EDITION')
    this.creationForm = this.fb.group({
      title: [this.currentCourse.name || '', [Validators.required]],
      description: [this.encode(this.currentCourse.description) || '', [Validators.required]],
      mainImage: [this.currentCourse.photo ? {
        old: true,
        fileId: this.currentCourse.photo.id,
        fileUrl: this.currentCourse.photo.url
      } : '', [Validators.required]],
      space: [this.currentCourse.area || 'DECORATION', [Validators.required]],
      price: [this.currentCourse.cost || '', [Validators.required, this.numberValidator]],
      maxCapacity: [this.currentCourse.places_amount, [Validators.required, this.getNumberValidator(this.maxRegistered)]],
      schedule: this.fb.array([]),
      equipments: this.fb.array([]),
      needReservation: [this.currentCourse.need_reservation],
      materials: this.fb.array([]),
      albumUrl: [this.currentCourse.photo_album_link || '', [Validators.pattern(/^(https?:\/\/)?(www\.)?(vk|facebook)\.com\/.{1,}$/)]],
      importantCourse: [!!this.currentCourse.important || ''],
      mainPageImage: [this.currentCourse.main_photo ? {
        old: true,
        fileId: this.currentCourse.photo.id,
        fileUrl: this.currentCourse.photo.url
      } : ''],
      gallery: this.fb.array([
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null),
        this.fb.control(null)
      ])
    });

    const timingIndex = {};
    const equipment_indexes = {};

    this.currentCourse.course_timings.forEach((timing, index) => {
      timingIndex[timing.id] = index;
    });
    this.currentCourse.course_equipments.forEach((instrument, equipIndex) => {
      if (!instrument.for_all_timings) {
        instrument.timing_ids.forEach(timingId => {
          const courseTiming = this.currentCourse.course_timings.find(timing => timing.id === timingId);

          if (courseTiming) {
            if (equipment_indexes[timingId]) {
              equipment_indexes[timingId].push(equipIndex);
            } else {
              equipment_indexes[timingId] = [equipIndex];
            }

            this.addEquipment({
              instrument,
              index: equipIndex,
              timing_index: timingIndex[courseTiming.id],
              time: `${moment(courseTiming.start_time).format('HH:mm')}-${moment(courseTiming.end_time).format('HH:mm')}`,
              date: {
                date: courseTiming.start_date,
                value: timingIndex[courseTiming.id],
              },
              forAllTimings: instrument.for_all_timings,
            });
          }
        });
      } else {
        this.addEquipment({
          instrument,
          index: equipIndex,
          timing_index: null,
          time: `${moment(this.currentCourse.course_timings[0].start_time).format('HH:mm')}-${moment(this.currentCourse.course_timings[0].end_time).format('HH:mm')}`,
          date: {
            date: 'Все даты курса',
            value: 'all'
          },
          forAllTimings: true
        });
      }
    });
    this.currentCourse.course_timings.forEach((item, index) => {
      this.addSchedule({
        ...item,
        equipment_indexes: equipment_indexes[item.id] ? equipment_indexes[item.id] : [],
        old: true
      });
    });
    this.currentCourse.materials.forEach(item => {
      this.addMaterial(item);
    })
    this.currentCourse.attachments.forEach((item, index) => {
      this.creationForm.controls.gallery.controls[index].setValue({
        old: true,
        fileId: item.id,
        fileUrl: item.url
      })
    })
    this.onImportantChange();
    this.addMaterial();
    this.addSchedule();
    this.addEquipment();
    this.updateCourseDates();
    this.onChangeNeedReservation();
  }

  addSchedule(day?) {
    let group: any;
    if (day) {
      group = this.fb.group({
        date: [day.start_date, Validators.required],
        time: [`${moment(day.start_time).format('HH:mm')}-${moment(day.end_time).format('HH:mm')}`, Validators.required],
        maker: [day.maker, Validators.required],
        equipment_indexes: [day.equipment_indexes],
        id: [day.id]
      })
      if (day.old) {
        group.old = true;
        group.timing_id = day.id;
      }
    } else {
      group = this.fb.group({
        date: ['', Validators.required],
        time: ['', Validators.required],
        maker: ['', Validators.required],
        equipment_indexes: [[]]
      })
    }
    group.makers = {
      loading: false,
      list: []
    }
    if (day) {
      this.updateMastersList(group);
    }
    this.creationForm.controls.schedule.push(group);
    this.initScheduleValidators();
    this.updateCourseDates();
  }

  removeSchedule(index) {
    this.creationForm.controls.schedule.controls.splice(index, 1);
    if (!this.creationForm.controls.schedule.controls.length) {
      this.addSchedule();
    }
    this.initScheduleValidators();
    this.updateCourseDates();
  }

  initScheduleValidators() {
    if (this.creationForm.controls.schedule.controls.length > 1) {
      this.creationForm.controls.schedule.controls[this.creationForm.controls.schedule.controls.length - 1].controls.date.setValidators();
      this.creationForm.controls.schedule.controls[this.creationForm.controls.schedule.controls.length - 1].controls.time.setValidators();
      this.creationForm.controls.schedule.controls[this.creationForm.controls.schedule.controls.length - 1].controls.maker.setValidators();
      this.safeReset(this.creationForm.controls.schedule.controls[this.creationForm.controls.schedule.controls.length - 1].controls.date);
      this.safeReset(this.creationForm.controls.schedule.controls[this.creationForm.controls.schedule.controls.length - 1].controls.time);
      this.safeReset(this.creationForm.controls.schedule.controls[this.creationForm.controls.schedule.controls.length - 1].controls.maker);
      for (let i = 0; i < this.creationForm.controls.schedule.controls.length - 1; i++) {
        this.creationForm.controls.schedule.controls[i].controls.date.setValidators(Validators.required);
        this.creationForm.controls.schedule.controls[i].controls.time.setValidators(Validators.required);
        this.creationForm.controls.schedule.controls[i].controls.maker.setValidators(Validators.required);
        this.safeReset(this.creationForm.controls.schedule.controls[i].controls.date);
        this.safeReset(this.creationForm.controls.schedule.controls[i].controls.time);
        this.safeReset(this.creationForm.controls.schedule.controls[i].controls.maker);
      }
    } else if (this.creationForm.controls.schedule.controls.length === 1) {
      this.creationForm.controls.schedule.controls[0].controls.date.setValidators(Validators.required);
      this.creationForm.controls.schedule.controls[0].controls.time.setValidators(Validators.required);
      this.creationForm.controls.schedule.controls[0].controls.maker.setValidators(Validators.required);
      this.safeReset(this.creationForm.controls.schedule.controls[0].controls.date);
      this.safeReset(this.creationForm.controls.schedule.controls[0].controls.time);
      this.safeReset(this.creationForm.controls.schedule.controls[0].controls.maker);
    }
  }

  onSetScheduleDate(index, date, id) {
    if (index === this.creationForm.controls.schedule.controls.length - 1) {
      this.addSchedule();
    }
    this.compareTimings(id);
    let control = this.creationForm.controls.schedule.controls[index];
    setTimeout(() => {
      this.updateMastersList(control);
    }, 100)
  }

  updateCourseDates() {
    // console.log(this.creationForm.controls.schedule.controls);
    this.courseDates = [{
      date: 'Все даты курса',
      value: 'all'
    }];
    if (this.creationForm.controls.schedule.controls.length > 1) {
      for (let i = 0; i < this.creationForm.controls.schedule.controls.length - 1; i++) {
        this.courseDates.push({
          date: this.creationForm.controls.schedule.controls[i].controls.date.value,
          value: i
        });
      }
    }
  }

  onSelectInstrumentDate(instrumentIndex, courseDate) {
    let scheduleIndex = courseDate.value;
    let forAllTimings = false;

    if (scheduleIndex === 'all') {
      forAllTimings = true;
      scheduleIndex = 0;
    }

    if (!this.creationForm.controls.schedule.controls[scheduleIndex]) {
      return;
    }

    const instrument = this.creationForm.controls.equipments.controls.find(equip => equip.value.index === instrumentIndex);
    const currentScheduleIndex = instrument.controls.timing_index.value;

    if (notNull(currentScheduleIndex)) {
      this.removeEquipmentFromSchedule(currentScheduleIndex, instrumentIndex);
    }

    this.addEquipmentToSchedule(scheduleIndex, instrumentIndex, forAllTimings);
  }

  onChangeNeedReservation() {
    if (this.creationForm.controls.needReservation.value) {
      this.initEquipmentsValidators();
    } else {
      for (let i = 0; i < this.creationForm.controls.equipments.controls.length; i++) {
        this.creationForm.controls.equipments.controls[i].controls.instrument.controls.equipment_id.setValidators();
        this.creationForm.controls.equipments.controls[i].controls.date.controls.value.setValidators();
        this.creationForm.controls.equipments.controls[i].controls.time.setValidators();
        this.safeReset(this.creationForm.controls.equipments.controls[i].controls.instrument.controls.equipment_id);
        this.safeReset(this.creationForm.controls.equipments.controls[i].controls.date.controls.value);
        this.safeReset(this.creationForm.controls.equipments.controls[i].controls.time);
      }
    }
  }

  onSelectInstrument(index, instrumentIndex) {
    if (index === this.creationForm.controls.equipments.controls.length - 1) {
      this.addEquipment();
    }
  }

  public handleSearchEquip = function(query) {
    return this.equipmentsService.getEquipmentsList({ query_str: query, hide_broken: true }).pipe(map((data:any) => {
      return {
        result: data.result.data
      }
    }))
  }.bind(this);

  getEquipmentIndexesForTiming(scheduleIndex) {
    return this.creationForm.value.equipments
      .filter(item => item.instrument.equipment_id !== null)
      .map((item, index) => {
        if (item.timing_index === scheduleIndex) {
          return item.index;
        }
      })
      .filter(val => notNull(val));
  }

  addEquipment(equipment?) {
    let group;
    if (equipment) {
      group = this.fb.group({
        instrument: this.fb.group({
          equipment_id: [equipment.instrument.equipment_id, Validators.required],
          name: equipment.instrument.name,
        }),
        date: this.fb.group({
          date: equipment.date.date,
          value: [equipment.date.value, Validators.required],
        }),
        time: [equipment.time, Validators.required],
        timing_index: equipment.timing_index,
        forAllTimings: equipment.forAllTimings,
        index: equipment.index
      });
    } else {
      group = this.fb.group({
        instrument: this.fb.group({
          equipment_id: [null, Validators.required],
          name: null,
        }),
        date: this.fb.group({
          date: null,
          value: [null, Validators.required],
        }),
        time: [null, Validators.required],
        timing_index: null,
        forAllTimings: false,
        index: this.creationForm.controls.equipments.controls.length
      });
    }

    this.creationForm.controls.equipments.push(group);
    this.initEquipmentsValidators();
  }

  removeEquipment(index, instrumentIndex) {
    const scheduleIndex = this.creationForm.controls.equipments.controls[index].controls.timing_index.value;

    if (notNull(scheduleIndex)) {
      this.removeEquipmentFromSchedule(scheduleIndex, instrumentIndex);
    }

    this.creationForm.controls.equipments.removeAt(index);
    if (!this.creationForm.controls.equipments.controls.length) {
      this.addEquipment();
    }
    this.initEquipmentsValidators();
  }

  removeEquipmentFromSchedule(scheduleIndex, instrumentIndex) {
    const equipment_indexes = this.creationForm.controls.schedule.controls[scheduleIndex].controls.equipment_indexes.value;
    equipment_indexes.splice(equipment_indexes.indexOf(instrumentIndex), 1);

    this.creationForm.controls.schedule.controls[scheduleIndex].patchValue({
      equipment_indexes
    });
  }

  addEquipmentToSchedule(scheduleIndex, instrumentIndex, forAllTimings) {
    const instrument = this.creationForm.controls.equipments.controls.find(equip => equip.value.index === instrumentIndex);

    instrument.patchValue({
      forAllTimings,
      time: this.creationForm.controls.schedule.controls[scheduleIndex].controls.time.value,
      timing_index: scheduleIndex,
    });

    const equipment_indexes = this.creationForm.controls.schedule.controls[scheduleIndex].controls.equipment_indexes.value;

    if (!equipment_indexes.includes(instrumentIndex)) {
      equipment_indexes.push(instrumentIndex);

      this.creationForm.controls.schedule.controls[scheduleIndex].patchValue({
        equipment_indexes
      });
    }
  }

  initEquipmentsValidators() {
    if (this.creationForm.controls.equipments.controls.length > 1) {
      this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.instrument.setValidators();
      this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.instrument.controls.equipment_id.setValidators();
      this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.date.controls.value.setValidators();
      this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.time.setValidators();
      this.safeReset(this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.instrument);
      this.safeReset(this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.instrument.controls.equipment_id);
      this.safeReset(this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.date.controls.value);
      this.safeReset(this.creationForm.controls.equipments.controls[this.creationForm.controls.equipments.controls.length - 1].controls.time);
      for (let i = 0; i < this.creationForm.controls.equipments.controls.length - 1; i++) {
        this.creationForm.controls.equipments.controls[i].controls.instrument.setValidators(Validators.required);
        this.creationForm.controls.equipments.controls[i].controls.instrument.controls.equipment_id.setValidators(Validators.required);
        this.creationForm.controls.equipments.controls[i].controls.date.controls.value.setValidators(Validators.required);
        this.creationForm.controls.equipments.controls[i].controls.time.setValidators(Validators.required);
        this.safeReset(this.creationForm.controls.equipments.controls[i].controls.instrument);
        this.safeReset(this.creationForm.controls.equipments.controls[i].controls.instrument.controls.equipment_id);
        this.safeReset(this.creationForm.controls.equipments.controls[i].controls.date.controls.value);
        this.safeReset(this.creationForm.controls.equipments.controls[i].controls.time);
      }
    } else if (this.creationForm.controls.equipments.controls.length === 1) {
      this.creationForm.controls.equipments.controls[0].controls.instrument.setValidators(Validators.required);
      this.creationForm.controls.equipments.controls[0].controls.instrument.controls.equipment_id.setValidators(Validators.required);
      this.creationForm.controls.equipments.controls[0].controls.date.controls.value.setValidators(Validators.required);
      this.creationForm.controls.equipments.controls[0].controls.time.setValidators(Validators.required);
      this.safeReset(this.creationForm.controls.equipments.controls[0].controls.instrument);
      this.safeReset(this.creationForm.controls.equipments.controls[0].controls.instrument.controls.equipment_id);
      this.safeReset(this.creationForm.controls.equipments.controls[0].controls.date.controls.value);
      this.safeReset(this.creationForm.controls.equipments.controls[0].controls.time);
    }
  }

  addMaterial(material:any = {}) {
    let group = this.fb.group({
      name: [material.name],
      price: [material.price],
      in_leroy: [material.exists_in_leroy],
      in_fabric: [material.exists_in_fabric]
    })
    this.creationForm.controls.materials.push(group);
    this.initMaterialsValidators();
  }

  removeMaterial(index) {
    this.creationForm.controls.materials.controls.splice(index, 1);
    if (!this.creationForm.controls.materials.controls.length) {
      this.addMaterial();
    }
    this.initMaterialsValidators();
  }

  initMaterialsValidators() {
    if (this.creationForm.controls.materials.controls.length > 1) {
      this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.name.setValidators();
      this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.price.setValidators();
      this.safeReset(this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.name);
      this.safeReset(this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.price);
      for (let i = 0; i < this.creationForm.controls.materials.controls.length - 1; i++) {
        this.creationForm.controls.materials.controls[i].controls.name.setValidators(Validators.required);
        this.creationForm.controls.materials.controls[i].controls.price.setValidators([Validators.pattern(/^[\d]{1,}$/)]);
        this.safeReset(this.creationForm.controls.materials.controls[i].controls.name);
        this.safeReset(this.creationForm.controls.materials.controls[i].controls.price);
      }
    } else if (this.creationForm.controls.materials.controls.length === 1) {
      this.creationForm.controls.materials.controls[0].controls.name.setValidators();
      this.creationForm.controls.materials.controls[0].controls.price.setValidators();
      this.safeReset(this.creationForm.controls.materials.controls[0].controls.name);
      this.safeReset(this.creationForm.controls.materials.controls[0].controls.price);
    }
  }

  onMaterialInput(index) {
    if (index === this.creationForm.controls.materials.controls.length - 1) {
      if (this.creationForm.controls.materials.controls[index].controls.name.value) {
        this.addMaterial();
      }
    } else {
      if (!this.creationForm.controls.materials.controls[index].controls.name.value && !this.creationForm.controls.materials.controls[index].controls.price.value && !this.creationForm.controls.materials.controls[index].controls.in_fabric.value && !this.creationForm.controls.materials.controls[index].controls.in_leroy.value) {
        this.removeMaterial(index);
      }
    }
  }

  onMaterialBlur(index) {
    if (index < this.creationForm.controls.materials.controls.length - 1) {
      this.creationForm.controls.materials.controls[index].controls.price.markAsTouched();
    }
  }

  onImportantChange() {
    if (this.creationForm.controls.importantCourse.value) {
      this.creationForm.controls.mainPageImage.setValidators(Validators.required);
      this.safeReset(this.creationForm.controls.mainPageImage);
    } else {
      this.creationForm.controls.mainPageImage.setValidators();
      this.safeReset(this.creationForm.controls.mainPageImage);
    }
  }

  closeModal() {
    this.creationService.closeCreationModal();
  }

  onTimeInput(id, event) {
    console.log(id, event.target.value);
    console.log(this.creationForm.controls.schedule);

  }

  onTimeEdit(id, time) {
    let timing;
    if (this.defaultSchedule) {
      timing = this.defaultSchedule.filter(item => item.id == id)[0];
    }
    if (timing) {
      console.log(timing.time, time)
      // console.log(new Date(timing.date), new Date(date))

    }
  }

  onTimeBlur(index, evt, id) {
    setTimeout(() => {
      this.creationForm.controls.schedule.controls[index].controls.time.setValue(evt.target.value)
      const equipment_indexes = this.creationForm.controls.schedule.controls[index].controls.equipment_indexes.value;
      if (equipment_indexes.length > 0) {
        equipment_indexes.forEach(equipment_index => {
          this.creationForm.controls.equipments.controls[equipment_index].patchValue({
            time: evt.target.value
          });
        });
      }
    }, 0);
    this.compareTimings(id);
  }

  onTimeChange(index) {
    let control = this.creationForm.controls.schedule.controls[index];
    setTimeout(() => {
      this.updateMastersList(control);
    }, 100)
  }

  updateMastersList(control) {
    let value = control.value;
    console.log(value);
    control.makers.list = [];
    if (value.time && value.date) {
      control.makers.show = true;
      control.makers.loading = true;
      let start = value.time.split('-')[0];
      let end = value.time.split('-')[1];
      let model:any = {
        busyStartDate: value.date,
        busyStartTime: moment(`01.01.1970 ${start}`, 'DD.MM.YYYY HH:mm').valueOf(),
        busyEndTime: moment(`01.01.1970 ${end}`, 'DD.MM.YYYY HH:mm').valueOf()
      }
      if (this.isEdition) {
        model.courseId = this.currentCourse.id;
      }
      if (model.busyEndTime <= model.busyStartTime) {
        model.busyEndTime += 24 * 3600 * 1000;
      }
      this.coursesService.getMasters(model).subscribe((data) => {
        control.makers.list = data.result;
        control.makers.loading = false;
        if (value.maker && value.maker.id) {
          let maker = data.result.filter(item => item.id == value.maker.id)[0];
          console.log(maker);
          if (!maker || maker.busy) {
            control.controls.maker.reset();
          }
        }
      }, () => {
        control.makers.loading = false;
      })
    } else {
      control.makers.show = false;
    }
  }

  compareTimings(id) {
    let defaultTiming;
    // console.log(this.creationForm.controls.schedule)
    let editedTiming = this.creationForm.controls.schedule.controls.filter(item => item.timing_id == id)[0].value;
    if (this.defaultSchedule) {
      defaultTiming = this.defaultSchedule.filter(item => item.id == id)[0];
    }
    // console.log(defaultTiming, editedTiming);
    if (defaultTiming && editedTiming) {
      // console.log(new Date(defaultTiming.date).setHours(0,0,0,0),new Date(editedTiming.date).setHours(0,0,0,0))
      // console.log(defaultTiming.time, editedTiming.time)
      // console.log(defaultTiming.maker, editedTiming.maker.id)
      if (new Date(defaultTiming.date).setHours(0,0,0,0) === new Date(editedTiming.date).setHours(0,0,0,0) && defaultTiming.time === editedTiming.time && defaultTiming.maker == editedTiming.maker.id) {
        this.editedTiming = null;
      } else {
        this.editedTiming = id;
      }
    }
  }

  uploadFiles() {
    this.errorMessage = '';
    this.loadingState = 1;
    let that = this;
    function* generator() {
      if (that.creationForm.controls.mainImage.value) {
        yield 'main'
      }
      if (that.creationForm.controls.mainPageImage.value && that.creationForm.controls.importantCourse.value) {
        yield 'mainPage'
      }
      for (let n = 0; n < that.creationForm.controls.gallery.controls.length; n++) {
        if (that.creationForm.controls.gallery.controls[n].value) {
          yield n;
        }
      }
      return that.submitForm();
    }
    let gen = generator();
    gen.next();
    let courseName = this.creationForm.value.title;
    this.uploadImage(that.creationForm.controls.mainImage, gen);
    if (that.creationForm.controls.mainPageImage.value && that.creationForm.controls.importantCourse.value) {
      this.uploadImage(that.creationForm.controls.mainPageImage, gen, courseName);
    }
    that.creationForm.controls.gallery.controls.forEach(item => {
      if (item.value) {
        this.uploadImage(item, gen, courseName);
      }
    })
  }

  private uploadImage = function(file, gen, courseName?) {
    if (file.value) {
      if (file.value.old) {
        file.loaded = true;
        gen.next();
      } else if (file.loaded) {
        gen.next();
      } else {
        file.loading = true;
        file.hasErr = false;
        this.fileUploadService.uploadFile(file.value, 'COURSES', courseName).subscribe((data) => {
          file.value.fileUrl = data.result.url;
          file.value.fileId = data.result.id;
          file.loaded = true;
          file.loading = false;
          gen.next();
        }, () => {
          file.hasErr = true;
          file.loading = false;
          this.loadingState = 'error'
          this.errorMessage = 'Ошибка загрузки файла';
        });
      }
    }
  }

  submitForm() {
    this.loadingState = 2;
    if (this.isEdition) {
      this.coursesService.edit(this.currentCourse.id, this.getCourseCreationForm()).subscribe((data) => {
        this.loadingState = 3;
        // this.closeModal();
        let redirectUrl;
        let redirectQueryParams;
        if (data.result.notification_id) {
          redirectQueryParams = { popup: 'notify-move-course', notification: data.result.notification_id };
        } else {
          redirectQueryParams = {};
        }
        if (this.router.url.indexOf('master-klassy/') + 1) {
          redirectUrl = '/master-klassy/' + this.translitService.getHumanReadableId(data.result.name, data.result.new_entity_id || this.currentCourse.id);
          // redirectUrl = '/sobytiya/' + this.translitService.getHumanReadableId(data.result.name, data.result.new_entity_id || this.currentCourse.id);
          try {
            this.historyService.redirect404 = true;
            this.historyService.currentState = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url].previous.url;
            let pageData:any = {};
            pageData[redirectUrl] = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url]
            this.historyService.addPageData('course', pageData);
          } catch {
            this.historyService.redirect404 = false;
          }
        } else {
          redirectUrl = this.currentRoute;
        }
        this.router.navigate([redirectUrl], { queryParams: redirectQueryParams });
        setTimeout(() => {
          this.currentCourseService.updateEmitter();
        }, 100);
        // if (data.result.notification_id) {
        //   setTimeout(() => {
        //     this.router.navigate([this.currentRoute], { queryParams: { popup: 'notify-move-course', notification: data.result.notification_id } });
        //   }, 0)
        // }
      }, (e) => {
        this.loadingState = 'error';
        let error = parseApiError(e);
        if (error === 'not_enough') {
          this.errorMessage = 'Нельзя забронировать оборудование на это время';
        } else {
          this.errorMessage = 'Ошибка публикации';
        }
      });
    } else {
      this.coursesService.create(this.getCourseCreationForm()).subscribe((data) => {
        this.loadingState = 3;
        this.closeModal();
        this.currentCourseService.updateEmitter();
      }, (e) => {
        this.loadingState = 'error';
        let error = parseApiError(e);
        if (error === 'not_enough') {
          this.errorMessage = 'Нельзя забронировать оборудование на это время';
        } else {
          this.errorMessage = 'Ошибка публикации';
        }
      });
    }
  }

  onSubmit() {
    if (this.creationForm.invalid) {
      console.log(this.creationForm);
      this.markInvalidFields();
      this._ref.detectChanges();
      this.scrollToInvalidField();
    } else {
      this.uploadFiles();
    }
  }

  openConfirmDeletion() {
    this.confirmType = 'delete';
    this.wrapper.nativeElement.style.overflow = 'hidden';
  }

  closeConfirm() {
    this.confirmType = '';
    this.wrapper.nativeElement.style.overflow = 'auto';
  }

  deleteCourse() {
    this.confirmType = '';
    this.loadingState = 0;
    if (this.currentCourse.id) {
      this.deleteLoading = true;
      this.coursesService.deleteCourse(this.currentCourse.id).subscribe((data) => {
        this.deleteLoading = false;
        this.deleted = true;
        this.closeModal();
        if (this.router.url.indexOf('master-klassy/') + 1) {
          this.router.navigate(['/master-klassy']);
        }
      }, () => {
        this.deleteLoading = false;
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка удаления';
        this.closeConfirm();
      })
    }
  }

  private scrollToInvalidField = function() {
    let elem;
    if (!this.creationForm.value.mainImage) {
      let elem = this.mainPhotoPicker.nativeElement;
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
    } else {
      if ($('.ng-invalid')[0].tagName === 'FORM' && $('.ng-invalid')[1]) {
        elem = $('.ng-invalid')[1].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-touched.ng-invalid')[1]
        }
      } else {
        elem = $('.ng-invalid')[0].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-invalid')[0]
        }
      }
      console.log(elem,elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop);
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
    }
  }

  markInvalidFields() {
    const controls = this.creationForm.controls;
    Object.keys(controls)
    .forEach(controlName => controls[controlName].markAsTouched());
    this.creationForm.controls.schedule.controls.forEach(control => {
      Object.keys(control.controls)
      .forEach(controlName => {
        control.controls[controlName].markAsTouched();
      });
    });
    this.creationForm.controls.equipments.controls.forEach(control => {
      Object.keys(control.controls)
      .forEach(controlName => {
        control.controls[controlName].markAsTouched();
      });
    });
    this.creationForm.controls.materials.controls.forEach(control => {
      Object.keys(control.controls)
      .forEach(controlName => {
        control.controls[controlName].markAsTouched();
      });
    });
    this.creationForm.controls.mainImage.markAsTouched();
  }

  private getCourseCreationForm = function() {
    let form:any = {};
    form.name = this.creationForm.value.title;
    form.description = this.escape(this.creationForm.value.description);
    if (this.creationForm.controls.gallery.controls.filter(item => item.value).length) {
      form.attachments = this.creationForm.controls.gallery.controls.filter(item => item.value).map((item, index) => {
        return {
          file_id: item.value.fileId,
          attachment_url: item.value.fileUrl,
          file_item_type: 'IMAGE',
          order: index + 1
        }
      })
    }
    if (this.creationForm.value.albumUrl) {
      form.photo_album_link = this.creationForm.value.albumUrl;
    }
    if (this.creationForm.value.mainPageImage) {
      form.main_photo_id = this.creationForm.value.mainPageImage.fileId;
    }
    form.photo_id = this.creationForm.value.mainImage.fileId;
    form.area = this.creationForm.value.space;
    form.cost = this.creationForm.value.price;
    form.places_amount = this.creationForm.value.maxCapacity;
    form.need_reservation = this.creationForm.value.needReservation;
    form.course_timings = this.creationForm.value.schedule.filter(item => (item.date && item.time && item.maker)).map((item, index) => {
      const equipmentIndexes = form.need_reservation ? this.getEquipmentIndexesForTiming(index) : [];

      let result: any = {
        start_date: parseDateToString(item.date),
        start_time: getApiTime(item.time.split('-')[0]),
        end_time: getApiTime(item.time.split('-')[1]),
        maker_id: item.maker.id,
        equipment_indexes: equipmentIndexes,
      }
      if (item.id) {
        result.id = item.id;
      }
      return result;
    })
    form.course_timings.forEach(item => {
      item.places_amount = this.creationForm.value.maxCapacity;
    })
    if (form.need_reservation) {
      form.equipments = this.creationForm.value.equipments
        .filter(item =>
          notNull(item.instrument.equipment_id) && notNull(item.date.value)
        )
        .map(item => {
          return {
            equipment_id: item.instrument.equipment_id,
            forAllTimings: item.forAllTimings,
            timing_index: item.forAllTimings ? null : item.index
          }
        });
    } else {
      form.equipments = [];
    }
    form.materials = this.creationForm.value.materials.filter(item => (item.name)).map(item => {
      let result:any = {
        exists_in_fabric: !!item.in_fabric,
        exists_in_leroy: !!item.in_leroy,
        name: item.name,
        price: item.price || 0
      }
      // if (item.price) {
      //   result.price = item.price;
      // }
      return result;
    })
    console.log(form);
    form.important = !!this.creationForm.value.importantCourse;
    return form;
  }

  private numberValidator: ValidatorFn = function(control: FormControl) {
    return +control.value > 0 || (control.value === null || control.value === '') ? null : {
      incorrect: 'true'
    }
  }.bind(this)

  private getNumberValidator = function(minValue) {
    let numberValidator: ValidatorFn = function(control: FormControl) {
      return +control.value >= minValue || (control.value === null || control.value === '') ? null : {
        incorrect: 'true'
      }
    }.bind(this)
    return numberValidator;
  }.bind(this)

  private safeReset(control) {
    let val = control.value;
    control.reset();
    control.setValue(val);
  }

  private escape = function (string) {
    let escaped = $("<div>").text(string).html();
    return escaped;
  }

  private encode = function (string) {
    let encoded = $("<div>").html(string).text();
    return encoded;
  }

}
