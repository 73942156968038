import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-area-icon',
  templateUrl: './area-icon.component.html',
  styleUrls: ['./area-icon.component.styl']
})
export class AreaIconComponent implements OnInit {
  @Input() type: string;
  @Input() popupSide;
  constructor() { }

  ngOnInit() {
  }

}
