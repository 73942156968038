import { Pipe } from '@angular/core';

@Pipe({
  name: 'rusMonth'
})
export class RusMonthPipe {

  transform(value: any, cs?: any): any {
    let rdt = ['января', 'февраля', 'марта', 'апреля', 'мая', 'июня', 'июля', 'августа', 'сентября', 'октября', 'ноября', 'декабря'];
    let imn = ['январь', 'февраль', 'март', 'апрель', 'май', 'июнь', 'июль', 'август', 'сентябрь', 'октябрь', 'ноябрь', 'декабрь'];
    let prd = ['январе', 'феврале', 'марте', 'апреле', 'мае', 'июне', 'июле', 'августе', 'сентябре', 'октябре', 'ноябре', 'декабре'];
    let months = rdt;
    if (cs === '0') {
      months = imn;
    } else if (cs === '1') {
      months = prd;
    }
    if (value && (value => 1) && (value <= 12)) {
      return months[value - 1];
    } else return null;

  }

}
