import { Pipe, PipeTransform } from '@angular/core';
import { FABRIC_WORK_HOURS } from '../config';
@Pipe({
  name: 'nowWork'
})
export class NowWorkPipe implements PipeTransform {

  transform(items: any[], param: string, direction: boolean) {
    let callback;
   

    function clientsSortCallback(a, b) {
      if (!a.user.last_name && !a.user.first_name) {
        return 1;
      }
      if (!b.user.last_name && !b.user.first_name) {
        return -1;
      }
      if (`${(a.user.last_name || '').toLowerCase()} ${(a.user.first_name || '').toLowerCase()}`.trim() > `${(b.user.last_name || '').toLowerCase()} ${(b.user.first_name || '').toLowerCase()}`.trim()) {
        return direction ? 1 : -1;
      }
      if (`${(a.user.last_name || '').toLowerCase()} ${(a.user.first_name || '').toLowerCase()}`.trim() < `${(b.user.last_name || '').toLowerCase()} ${(b.user.first_name || '').toLowerCase()}`.trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }

    function subscriptionSortCallback(a, b) {
      if (direction) {
        return (a.subscription_days * FABRIC_WORK_HOURS + a.subscription_hours) - (b.subscription_days * FABRIC_WORK_HOURS + b.subscription_hours);

      } else {
        return (b.subscription_days * FABRIC_WORK_HOURS + b.subscription_hours) - (a.subscription_days * FABRIC_WORK_HOURS + a.subscription_hours);
      }
    }

    function paramsSortCallback(param, a, b) {
      let result = a[param] - b[param];
      return direction ? result : -result;
    }

    function defaultSortCallback(a, b) {
      return 0
    }

    if (!items) {
      return items;
    }

    switch(param) {
      case 'duration':
        callback = paramsSortCallback.bind(null, 'current_work_session_duration_ms');
        break;
      case 'start':
        callback = paramsSortCallback.bind(null, 'start_date_time_ms');
        break;
      case 'name':
        callback = clientsSortCallback;
        break;
      case 'subscription':
        callback = paramsSortCallback.bind(null, 'remaining_total_ms');;
        break;
      default:
        callback = defaultSortCallback;
        break;
      }

    return items.sort(callback);
  }

}
