import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { NotificationsService } from '../../services/notifications.service';
import { SocketService } from '../../services/socket.service';
import { UserService } from '../../services/user.service';
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { IsBrowserService } from '../../services/is-browser.service';
import { forkJoin, Subject } from 'rxjs';
import { first, takeUntil } from 'rxjs/operators';
import { MessagesService } from '../../services/messages.service';
import { getDatesRange } from '../../utils';
import { TranslitService } from '../../services/translit.service';
import { ControlContainer } from '@angular/forms';

const NOTIFICATIONS_KEY = makeStateKey('notifications');
const NOTIFICATIONS_COUNT_KEY = makeStateKey('notifications-count');

@Component({
  selector: 'app-user-notifications',
  templateUrl: './user-notifications.component.html',
  styleUrls: ['./user-notifications.component.styl']
})
export class UserNotificationsComponent implements OnInit, OnDestroy {

  isOpenBox = false;
  today;
  socket$;
  notificationsList;
  unreadNotificationsCount;
  socketSubscription;
  isBrowser;
  clearLoading;
  notificationsSeenTimer;
  getDatesRange = getDatesRange;
  componentDestroyed$ = new Subject();
  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private notificationsService: NotificationsService,
              private socketService: SocketService,
              private state: TransferState,
              private userService: UserService,
              private isBrowserService: IsBrowserService,
              private translitService: TranslitService,
              private messagesService: MessagesService) {
                this.isBrowser = this.isBrowserService.isBrowser;
              }

  ngOnInit() {
    this.notificationsList = this.state.get(NOTIFICATIONS_KEY, null as any);
    this.unreadNotificationsCount = this.state.get(NOTIFICATIONS_COUNT_KEY, null as any);
    if (!this.notificationsList) {
      this.updateNotifications();
    }
    this.today = new Date().setHours(0,0,0,0);
    if (this.isBrowser) {
      this.socketService.initializeClientWebSocketConnection();
      this.socketService.updateSocket$.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
        this.updateNotifications();
      })
    }
    this.router.events.pipe(takeUntil(this.componentDestroyed$)).subscribe(() => {
      if (this.isOpenBox) {
        this.closeNotification();
      }
    })
  }

  ngOnDestroy() {
    this.socketService.disconnectClient();
    this.state.set(NOTIFICATIONS_KEY, null as any);
    this.state.set(NOTIFICATIONS_COUNT_KEY, null as any);
    this.componentDestroyed$.next();
    this.componentDestroyed$.complete();
  }

  openNotifications() {
    this.isOpenBox = true;

    // this.getDatesRange('2018-06-29', '2018-07-01');
  }

  closeNotification() {
    this.isOpenBox = false;
    clearTimeout(this.notificationsSeenTimer);
    this.notificationsSeenTimer = null;
  }

  navigateTo(item) {
    let currentLink = item.event_type;

    switch (currentLink) {
      case 'COURSE_COMMENT':
        currentLink = 'COURSES';
        break;
      case 'IDEA_COMMENT':
        currentLink = 'IDEAS';
        break;
      case 'EVENT_COMMENT':
        currentLink = 'EVENTS';
        break;
    }

    if (currentLink === 'COURSES' || currentLink === 'EVENTS' || currentLink === 'IDEAS') {
      currentLink = currentLink.toLowerCase();
      this.closeNotification();
      this.router.navigate(['/'+currentLink+'/'+currentLink.substr(0,currentLink.length-1)+'/'+item.entity_id]);
    } else if (currentLink === 'SUBSCRIPTIONS') {
      this.router.navigate([this.router.url.split('?')[0]], { queryParams: { popup: 'extend-subscription' }});
      this.closeNotification();
    } else if (currentLink === 'STORAGE') {
      this.router.navigate([this.router.url.split('?')[0]], { queryParams: { popup: 'prolongate-storage-period' }});
      this.closeNotification();
    }
  }

  getItemUrl(item) {
    let currentLink = item.event_type;
    let humanReadableLink;
    if (currentLink === 'COURSE_COMMENT' || currentLink === 'COURSES') {
      currentLink = 'COURSES';
      humanReadableLink = 'master-klassy';
    } else if (currentLink === 'IDEA_COMMENT' || currentLink === 'IDEAS') {
      currentLink = 'IDEAS';
      humanReadableLink = 'idei';
    } else if (currentLink === 'EVENT_COMMENT' || currentLink === 'EVENTS' || currentLink === 'EVENT_CHANGED') {
      currentLink = 'EVENTS';
      humanReadableLink = 'sobytiya';
    }
    if (currentLink === 'COURSES' || currentLink === 'EVENTS' || currentLink === 'IDEAS') {
      if (item.entity_name) {
        let humanReadableId = this.translitService.getHumanReadableId(item.entity_name, item.entity_id);
        return `/${humanReadableLink}/${humanReadableId}`
      } else {
        return null;
      }
    } else if (currentLink === 'SUBSCRIPTIONS' || currentLink === 'STORAGE') {
      return this.router.url.split('?')[0];
      // this.closeNotification();
    } else {
      return null;
    }
  }

  getItemQueryParams(item) {
    let currentLink = item.event_type;

    if (currentLink === 'COURSE_COMMENT' || currentLink === 'COURSES') {
      currentLink = 'COURSES';
    } else if (currentLink === 'IDEA_COMMENT' || currentLink === 'IDEAS') {
      currentLink = 'IDEAS';
    } else if (currentLink === 'EVENT_COMMENT' || currentLink === 'EVENTS') {
      currentLink = 'EVENTS';
    }

    if (currentLink === 'COURSES' || currentLink === 'EVENTS' || currentLink === 'IDEAS') {
      return {};
    } else if (currentLink === 'SUBSCRIPTIONS') {
      return { popup: 'extend-subscription' };
    } else if (currentLink === 'STORAGE') {
      return { popup: 'prolongate-storage-period' };
    }
  }

  toggleNotifications() {
    if (this.isOpenBox) {
      this.closeNotification();
    } else {
      this.openNotifications();
    }
  }

  deleteNotification(item) {
    item.loading = true;
    this.notificationsService.markAsRead(item.id).subscribe(() => {
      // this.updateNotifications();
      item.read = true;
      this.notificationsList = this.notificationsList.filter(notification => !notification.read);
      this.updateUnreadNotificationsCount();
    }, () => {
      this.messagesService.showMessage('Ошибка при удалениии уведомления. Подождие немного и повторите попытку.', false, false, true);
    })
  }

  openSettings() {
    let currentQueryParams = this.activatedRoute.snapshot.queryParams;
    this.router.navigate([this.router.url.split('?')[0]], { queryParams: { ...currentQueryParams, popup: 'notifications-settings' } });
    this.closeNotification();
  }

  updateNotifications() {
    forkJoin(this.notificationsService.getClientNotificationsList(), this.notificationsService.getUnreadNotificationsCount()).subscribe((data) => {
      let notifications = data[0].result.sort((a, b) => {
        return b.created_date - a.created_date;
      })
      this.unreadNotificationsCount = data[1].result;
      if (!this.notificationsList) {
        this.state.set(NOTIFICATIONS_KEY, notifications as any);
        this.state.set(NOTIFICATIONS_COUNT_KEY, this.unreadNotificationsCount as any);
      }
      this.notificationsList = notifications.map(item => {
        return {
          ...item,
          redirectUrl: this.getItemUrl(item),
          redirectQueryParams: this.getItemQueryParams(item)
        }
      });
    })

  }

  clearAll() {
    this.clearLoading = true;
    this.notificationsService.clearAllClientNotifications().subscribe(() => {
      this.closeNotification();
      this.clearLoading = false;
      this.notificationsList = [];
      this.updateUnreadNotificationsCount();
    }, () => {
      this.clearLoading = false;
      this.messagesService.showMessage('Ошибка при удалениии уведомлений. Подождие немного и повторите попытку.', false, false, true);
    })
  }

  updateUnreadNotificationsCount() {
    this.notificationsService.getUnreadNotificationsCount().pipe(first()).subscribe((data) => {
      this.unreadNotificationsCount = data.result;
    })
  }

  setNotificationsSeenTimer() {
    this.notificationsSeenTimer = setTimeout(() => {
      this.notificationsService.markAllNotificationsSeen().pipe(first()).subscribe(() => {
        this.notificationsList.forEach(item => {
          item.seen = true;
        })
        this.updateUnreadNotificationsCount();
      })
    }, 3000);
  }

}
