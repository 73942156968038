import { Component, OnInit, Input } from '@angular/core';
import { IdeasService } from '../../services/ideas.service';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { TranslitService } from '../../services/translit.service';

@Component({
  selector: 'app-idea-card',
  templateUrl: './idea-card.component.html',
  styleUrls: ['./idea-card.component.styl']
})
export class IdeaCardComponent implements OnInit {
  @Input() idea: any;
  @Input() additional;
  complexityArr = ['Просто', 'Средне', 'Сложно'];
  humanReadableId;
  constructor(private ideasService: IdeasService,
              private userService: UserService,
              private router: Router,
              private modalService: ModalService,
              private translitService: TranslitService) { }

  ngOnInit() {
    this.humanReadableId = this.translitService.getHumanReadableId(this.idea.name, this.idea.id);
  }

  getPriceLevel(price) {
    if (price < 1000) {
      return 1;
    } else if (price >= 1000 && price < 5000) {
      return 2;
    } else if (price > 5000) {
      return 3;
    }
  }

  getDurationLevel(duration) {
    if (+duration <= 3 * 3600 * 1000) {
      return 1;
    } else if (+duration > 3 * 3600 * 1000 && +duration <= 24 * 3600 * 1000) {
      return 2;
    } else if (+duration > 24 * 3600 * 1000) {
      return 3;
    }
  }

  toggleLike(evt) {
    evt.event.stopPropagation();
    evt.event.preventDefault();
    if (this.userService.userId) {
      this.ideasService.like(this.idea.id, !this.idea.is_liked).subscribe((data) => {
        this.idea.likes_count = data.result.likes_count;
        this.idea.is_liked = data.result.is_liked;
        if (data.result.is_liked) {
          evt.elem.playLikeAnimation();
        } else {
          evt.elem.playUnlikeAnimation();
        }
      })
    } else {
      this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'login'}});
      let like = function() {
        this.ideasService.like(this.idea.id, true).subscribe((data) => {
          this.idea.likes_count = data.result.likes_count;
          this.idea.is_liked = data.result.is_liked;
          if (data.result.is_liked) {
            evt.elem.playLikeAnimation();
          } else {
            evt.elem.playUnlikeAnimation();
          }
        })
      }.bind(this);
      setTimeout(() => {
        this.modalService.setPageOptions({signInTitleText: 'Войдите, чтобы добавить идею в избранное'});
        this.modalService.setPageOptions({signUpTitleText: 'Зарегистрируйтесть, чтобы добавить идею в избранное'});
        this.modalService.setGlobalOptions({afterSuccess: like});
      }, 0)
    }
  }

  toggleDone(evt) {
    evt.event.stopPropagation();
    evt.event.preventDefault();
    if (this.userService.userId) {
      this.ideasService.done(this.idea.id, !this.idea.is_realized).subscribe((data) => {
        this.idea.realized_count = data.result.realized_count;
        this.idea.is_realized = data.result.is_realized;
        if (data.result.is_realized) {
          evt.elem.playDoneAnimation();
        } else {
          evt.elem.playUndoneAnimation();
        }
      })
    } else {
      this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'login'}});
      let made = function() {
        this.ideasService.done(this.idea.id, true).subscribe((data) => {
          this.idea.realized_count = data.result.realized_count;
          this.idea.is_realized = data.result.is_realized;
          if (data.result.is_realized) {
            evt.elem.playDoneAnimation();
          } else {
            evt.elem.playUndoneAnimation();
          }
        })
      }.bind(this);
      setTimeout(() => {
        this.modalService.setPageOptions({signInTitleText: 'Войдите, чтобы отметить идею как сделанную'});
        this.modalService.setPageOptions({signUpTitleText: 'Зарегистрируйтесть, чтобы отметить идею как сделанную'});
        this.modalService.setGlobalOptions({afterSuccess: made});
      }, 0);
    }
  }
}
