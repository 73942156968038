import {Inject, Injectable} from '@angular/core';
import {TokenService} from './token.service';
import {HttpClient, HttpHeaders, HttpParameterCodec, HttpParams} from '@angular/common/http';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(@Inject('API_ROOT_URL') private apiRootUrl: string, private tokenService: TokenService, private httpClient: HttpClient) {
  }

  get(url: string, paramsObj?): Observable<any> {
    let headers = this.createCommonHeaders();
    let params = new HttpParams({encoder: new CustomEncoder()});
    if (paramsObj) {
      for (let param in paramsObj) {
        params = params.append(param, paramsObj[param]);
      }
    }
    return this.httpClient.get(`${this.apiRootUrl}${url}`, {headers, params});
  }

  post(url: string, body, paramsObj?, headersObj?): Observable<any> {
    return this.sendQuery('post', url, body, paramsObj, headersObj);
  }

  put(url: string, body, paramsObj?, headersObj?): Observable<any> {
    return this.sendQuery('put', url, body, paramsObj, headersObj);
  }

  postForm(url: string, form) {
    let formData = new FormData();
    for (let param in form) {
      formData.append(param, form[param]);
    }
    return this.httpClient.post<any>(`${this.apiRootUrl}${url}`, formData);
  }

  delete(url: string, paramsObj?) {
    let headers = this.createCommonHeaders();
    let params = new HttpParams({encoder: new CustomEncoder()});
    if (paramsObj) {
      for (let param in paramsObj) {
        params = params.append(param, paramsObj[param]);
      }
    }
    return this.httpClient.delete<any>(`${this.apiRootUrl}${url}`, {headers, params});
  }

  sendQuery(type: string, url: string, body, paramsObj?, headersObj?) {
    let headers = new HttpHeaders();
    if (headersObj) {
      for (let header in headersObj) {
        headers = headers.append(header, headersObj[header]);
      }
    } else {
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('Accept', 'application/json');
    }
    if (this.tokenService.isTokenExist()) {
      headers = headers.append('Authorization', 'Bearer ' + this.tokenService.getToken());
    }
    let params = new HttpParams({encoder: new CustomEncoder()});
    if (paramsObj) {
      for (let param in paramsObj) {
        params = params.append(param, paramsObj[param]);
      }
    }
    if (this.apiRootUrl.endsWith('/') && url.startsWith('/') && url.length > 1) {
      url = url.substring(1);
    }
    return this.httpClient[type]<any>(`${this.apiRootUrl}${url}`, body, {headers, params});
  }

  private createCommonHeaders(): HttpHeaders {
    let headers = new HttpHeaders()
      .append('Content-Type', 'application/json')
      .append('Accept', 'application/json');

    if (this.tokenService.isTokenExist()) {
      return headers.append('Authorization', 'Bearer ' + this.tokenService.getToken());
    }
  }
}

class CustomEncoder implements HttpParameterCodec {
  encodeKey(key: string): string {
    return encodeURIComponent(key);
  }

  encodeValue(value: string): string {
    return encodeURIComponent(value);
  }

  decodeKey(key: string): string {
    return decodeURIComponent(key);
  }

  decodeValue(value: string): string {
    return decodeURIComponent(value);
  }
}
