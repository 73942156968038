import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-equipment-reservation-popup',
  templateUrl: './equipment-reservation-popup.component.html',
  styleUrls: ['./equipment-reservation-popup.component.styl']
})
export class EquipmentReservationPopupComponent implements OnInit {
  @Input() equipment;
  @Input() broken;
  @Input() warning;
  @Output() reserveEmitter = new EventEmitter();
  @Output() cancelEmitter = new EventEmitter();
  cancelEntry;
  replacedEntry;
  isModalOpen;
  isMaster;
  currentUserId: number;
  reserveParams;
  forConsult;
  forCourse;

  constructor() { }

  ngOnInit() {
    this.forConsult = this.warning.reservation_type === 'FOR_CONSULTATION';
    this.forCourse = this.warning.reservation_type === 'FOR_COURSE';
  }

  cancel() {
    this.cancelEntry = true;
    this.cancelEmitter.emit();
  }

  postpone() {
    if (this.forConsult || this.forCourse) {
      this.reserveEmitter.emit(this.getParamsForAdmin(this.warning));
    } else {
      this.openModal();
    }
  }

  openModal() {
    this.isModalOpen = true;
  }

  closeModal() {
    if (this.isModalOpen) this.isModalOpen = false;
  }

  onSubmit(evt) {
    this.closeModal();
    this.reserveEmitter.emit(evt);
  }

  getParamsForAdmin(warning) {
    let params: any = {
      amount: 1,
      reservation_type: warning.reservation_type,
      timings: [{
        date: new Date(warning.reservation_date).getTime(),
        start_time: warning.old_reservation_start_time,
        end_time: warning.old_reservation_end_time,
        consultation_id: warning.purpose_entity_id,
        course_timing_id: warning.purpose_entity_id
      }]
    }
    return params;
  }

}
