export const RESERVE_START_TIME = 8;
export const RESERVE_END_TIME = 23;
export const FABRIKA_IS_WORK = false;
export const SUBSCRIPTION_HOUR_DEFAULT_PRICE = 250;
export const SUBSCRIPTION_DAY_DEFAULT_PRICE = 2000;
export const FABRIC_WORK_HOURS = RESERVE_END_TIME - RESERVE_START_TIME;
export const MOSCOW_TIME_ZONE = 3;
export const CANCEL_COURSE_CAUSES = [
  {
    id: 'NOT_ENOUGH_REGISTRATIONS',
    title: 'Записалось недостаточно человек'
  },{
    id: 'POSTPONED',
    title: 'Переносится на другую дату'
  },{
    id: 'ILL_MAKER',
    title: 'Заболел ведущий'
  },{
    id: 'OTHER',
    title: 'Другое...'
  }
];
export const CANCEL_COURSE_CAUSES_NAMES = {
  NOT_ENOUGH_REGISTRATIONS: 'Записалось недостаточно человек',
  POSTPONED: 'Переносится на другую дату',
  ILL_MAKER: 'Заболел ведущий',
  OTHER: 'Другое...'
}
