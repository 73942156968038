import { Directive, ElementRef, OnInit, Inject, PLATFORM_ID} from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;
@Directive({
  selector: '[appTimeAutocomplete]'
})
export class TimeAutocompleteDirective implements OnInit {
  isBrowser;
  placeholder;
  previousValue;
  constructor(private el: ElementRef, @Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    if (this.isBrowser) {
      this.el.nativeElement.classList.add('lm-input-time');
      if (!this.el.nativeElement.hasAttribute('appLmInput')) {
        const wrapper = $('<label class="lm-input-wrapper"></label>');
        $(this.el.nativeElement).wrap(wrapper);
      }
      this.placeholder = $('<div class="lm-input-time-placeholder">00:00-00:00</div>');
      $(this.el.nativeElement).closest('.lm-input-wrapper').append(this.placeholder);
    }
    this.el.nativeElement.addEventListener('input', e => this.onInput(e));
    this.el.nativeElement.addEventListener('keydown', e => this.onInput(e));
    this.el.nativeElement.addEventListener('blur', this.onBlur);
    this.previousValue = this.el.nativeElement.value;
    setTimeout(() => {
      const defaultPlaceholder = '00:00-00:00';
      this.placeholder.text(this.el.nativeElement.value + defaultPlaceholder.substr(this.el.nativeElement.value.length));
    }, 0);
  }

  private onInput(event) {
    const defaultPlaceholder = '00:00-00:00';
    const val = this.parseValue(this.el.nativeElement.value);
    const isDelete: boolean = (event.keyCode && (event.keyCode === 8 || event.keyCode === 46))
                              || (event.inputType && (event.inputType.indexOf('delete') !== -1));
    if (!isDelete) {
      if (event.type === 'keydown' || this.el.nativeElement.value.length > 11) {
        this.el.nativeElement.value = val;
      }
      this.placeholder.text(this.el.nativeElement.value + defaultPlaceholder.substr(this.el.nativeElement.value.length));
    } else {
      if (this.el.nativeElement.value) {
        this.placeholder.text(this.el.nativeElement.value);
      } else {
        this.placeholder.text(defaultPlaceholder);
      }
    }
    this.parseValue(val);
  }

  private onBlur = (event) => {
    const defaultPlaceholder = '00:00-00:00';
    if (this.el.nativeElement.value.length) {
      const val = this.parseValue(this.el.nativeElement.value, true);
      this.el.nativeElement.value = val;
    }
    this.placeholder.text(this.el.nativeElement.value + defaultPlaceholder.substr(this.el.nativeElement.value.length));
  }

  private parseValue = function(val, completeAll?) {
    const mask = val.split('').filter(item => this.isNumber(item) || item === ':' || item === '-');
    const timesArr = this.getTimesArr(mask, completeAll);
    if (completeAll) {
      for (let i = 0; i < timesArr.length; i++) {
        timesArr[i] = this.completeTime(timesArr[i]);
      }
    }
    let result = '';
    if (timesArr[0].length === 2) {
      if (parseInt(timesArr[0]) <= 23) {
        result += timesArr[0] + ':';
      } else {
        result += 23 + ':';
      }
    }
    if (timesArr[0].length === 1) {
      result += timesArr[0];
    }
    if (timesArr[1].length === 2) {
      if (parseInt(timesArr[1]) <= 59) {
        result += timesArr[1] + '-';
      } else {
        result += 59 + '-';
      }
    }
    if (timesArr[1].length === 1) {
      result += timesArr[1];
    }
    if (timesArr[2].length === 2) {
      if (parseInt(timesArr[2]) <= 23) {
        result += timesArr[2] + ':';
      } else {
        result += 23 + ':';
      }
    }
    if (timesArr[2].length === 1) {
      result += timesArr[2];
    }
    if (timesArr[3].length) {
      if (parseInt(timesArr[3]) <= 59) {
        result += timesArr[3];
      } else {
        result += 59;
      }
    }

    return result;
  };

  private getTimesArr = function(mask, completeAll) {
    const timesArr = ['', '', '', ''];
    let currentIndex = 0;
    const stop = false;
    mask.forEach((item, index) => {
      if (currentIndex < 4) {
        if (this.isNumber(item)) {
          if (timesArr[currentIndex].length < 2) {
            timesArr[currentIndex] += item;
          } else {
            currentIndex++;
            if (currentIndex < 4) {
              timesArr[currentIndex] += item;
            }
          }
        } else if (item === ':') {
          if (this.completeTime(timesArr[currentIndex]) !== timesArr[currentIndex]) {
            timesArr[currentIndex] = this.completeTime(timesArr[currentIndex]);
            currentIndex++;
          }
        } else if (item === '-') {
          if (currentIndex < 2) {
            timesArr[0] = this.completeTime(timesArr[0]);
            timesArr[1] = this.completeTime(timesArr[1]);
            currentIndex = 2;
          }
        }
      }
    });
    return timesArr;
  };

  private completeTime = function (str) {
    let result = '';
    if (str.length === 1) {
      result = '0' + str;
      return result;
    } else if (str.length === 0) {
      result = '00';
      return result;
    } else {
      return str;
    }

  };

  private isNumber = function(c) {
    return ('0123456789'.indexOf(c) !== -1);
  };
}
