import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CommentsService {

  constructor(private api: ApiService) { }

  getUnreplied() {
    return this.api.get('v1/comments/unreplied');
  }

  getCommentsWithoutReplies() {
    return this.api.get('v1/comments/list');
  }

  readComment(commentId, read) {
    return this.api.post(`v1/comments/${commentId}/read`, null, {read});
  }

  getCommentForMaster(isNew, userId?) {
    let model: any = {new: isNew};
    if (userId) model.userId = userId;
    return this.api.get('v1/comments/list/maker', model);
  }

  getNotViewed() {
    return this.api.get('v1/comments/newestcount');
  }

}
