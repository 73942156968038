import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslitService } from '../../services/translit.service';
import { Router } from '@angular/router';
import { getDatesRange } from '../../utils';

@Component({
  selector: 'app-user-notifications-item',
  templateUrl: './user-notifications-item.component.html',
  styleUrls: ['./user-notifications-item.component.styl']
})
export class UserNotificationsItemComponent implements OnInit {
  @Input() item;
  @Output() deleteEmitter = new EventEmitter();
  isToday;
  getDatesRange = getDatesRange;
  constructor(private translitService: TranslitService,
              private router: Router) { }

  ngOnInit() {
    this.isToday = new Date(this.item.entity_date).setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
  }


  deleteNotification(evt) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.deleteEmitter.emit();
  }

}
