import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';
import { spaces } from '../../utils';

@Component({
  selector: 'app-admin-master',
  templateUrl: './admin-master.component.html',
  styleUrls: ['./admin-master.component.styl']
})
export class AdminMasterComponent implements OnInit {
  @Input() userId;
  @Input() inPopup;
  @Input() user;
  userLoading;
  spaces;
  error;
  currentUserId;
  activeTab = 0;
  activatedTabs = [0];
  constructor(private activatedRoute: ActivatedRoute,
              private userService: UserService) { }

  ngOnInit() {
    this.spaces = spaces;
    if (!this.user) {
      this.activatedRoute.params.subscribe((params) => {
        if (!this.userId) {
          if (params.user) {
            this.initUser(params.user);
          } else {
            this.error = true;
          }
        } else {
          this.initUser(this.userId);
        }
      })
    }

  }

  initUser(user) {
    this.userLoading = true;
    this.error = null;
    this.currentUserId = user;
    // this.userCalendarFilter.user_id = this.currentUserId;
    this.userService.getUserData(user).subscribe((data) => {
      this.user = data.result;
      this.userLoading = false;
      // this.setUserTab('today');
      // this.setFilter(this.userTab);
    }, () => {
      this.userLoading = false;
      this.error = true;
    })
  }

  setActiveTab(tab) {
    this.activeTab = tab;
    if (!(this.activatedTabs.indexOf(tab) + 1)) {
      this.activatedTabs.push(tab);
    }
  }

}
