import { Component, OnInit, Input } from '@angular/core';
import { EmbedVideoService } from '../../services/embed-video.service'
import { Subject } from 'rxjs';

@Component({
  selector: 'app-idea-media-picker',
  templateUrl: './idea-media-picker.component.html',
  styleUrls: ['./idea-media-picker.component.styl']
})
export class IdeaMediaPickerComponent implements OnInit {
  @Input() uploader: any;
  @Input() attachments: any[];
  @Input() titleText;
  hasDropZoneOver;
  linkAdditionOpened;
  videoValid = false;
  videoLink;
  constructor(public embedService: EmbedVideoService) { }

  ngOnInit() {
    this.uploader.onAfterAddingFile = (item:any, response:any, status:any, headers:any) => {
      let type;
      if (item.file.type.match('image/')) {
        type = 'IMAGE';
      }
      if (item.file.type.match('video/')) {
        type = 'VIDEO'
      }
      console.log(item.file.type);
      if (!type) {
        this.uploader.queue.pop(); // clear old file & replace it with the new one
      } else {
        this.attachments.push({
            file: this.uploader.queue[this.uploader.queue.length - 1]._file,
            loaded: false,
            type: type,
            url: type === 'VIDEO' ? URL.createObjectURL(this.uploader.queue[this.uploader.queue.length - 1]._file) : null
          });
        // console.log(URL.createObjectURL(this.uploader.queue[this.uploader.queue.length - 1]._file));
        // this.getFileSrc(this.uploader.queue[this.uploader.queue.length - 1]._file).subscribe(data => {
        //   console.log(data);
        // })
      }
      if (this.attachments.length > 5) {
        this.attachments.pop();
        this.uploader.queue.pop();
      }
    };
  }

  removeFile(index) {
    let deleted = this.attachments.splice(index, 1);
    if (typeof deleted[0] === 'object') {
      let i = this.uploader.queue.indexOf(deleted[0]);
      if (i + 1) {
        this.uploader.queue.splice(i, 1);
      }
    }
  }

  public fileOver(e:any):void {
    this.hasDropZoneOver = e;
  }

  openAddLink(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.linkAdditionOpened = true;
    this.videoValid = false;
  }

  closeAddLink(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    this.linkAdditionOpened = false;
    this.videoValid = false;
    this.videoLink = '';
  }

  onVideoLinkInput(evt) {
    this.videoValid = false;
    let url;
    try {
      url = new URL(evt.target.value);
    } catch(e) {

    }
    if (url) {
      if (this.embedService.detectYoutube(url)) {
        this.embedService.isYTLinkValid(url)
          .subscribe((data:any) => {
            if (data.pageInfo.totalResults) {
              this.videoValid = true;
            }
          }, (e) => {
            if (e && e.status === 403) {
              // skip youtube check, token invalid
              this.videoValid = true;
            }
          })
      } else if (this.embedService.detectRutube(url)) {
        this.embedService.isRTLinkValid(url)
          .subscribe((data) => {
            this.videoValid = data.result.exists;
          }, (e) => {

          })
      }
    }
  }

  onVideoLinkSubmit(evt) {
    evt.preventDefault();
    evt.stopPropagation();
    let video = {
      url: this.videoLink,
      loaded: false,
      type: 'URL'
    }
    this.attachments.push(video);
    if (this.attachments.length > 5) {
      this.attachments.pop();
      this.uploader.queue.pop();
    }
    this.linkAdditionOpened = false;
    this.videoLink = '';
  }

  getFileSrc(file) {
    let resultSubject$ = new Subject();
    var reader = new FileReader();
    reader.onload = function(e:any) {
      resultSubject$.next(e.target.result);
    }
    reader.readAsDataURL(file);
    return resultSubject$;
  }

}
