import { Component, OnInit, Input, ViewChild, ChangeDetectorRef } from '@angular/core';
import { EquipmentCardComponent } from '../equipment-card/equipment-card.component';
import { EquipmentsService } from '../../services/equipments.service';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { notNull, parseApiError } from '../../utils';
import { ActivatedRoute } from '@angular/router';
import { ConsultationsService } from '../../services/consultations.service';
import { UserService } from '../../services/user.service';
import { CalendarComponent } from '../calendar/calendar.component';
import { ModalService } from '../../services/modal.service';
import { forkJoin, Subject } from "rxjs";
import { switchMap } from 'rxjs/operators';
import { groupConsultationsByDay, dropTime, getConsultById, getMonthEdges, getCurrentMoscowTime } from '../../utils';
import { CurrentCourseService } from '../../services/current-course.service';
import { MessagesService } from '../../services/messages.service';
import { first, map } from 'rxjs/operators';
import * as moment from 'moment';

@Component({
  selector: 'app-modal-move-consult',
  templateUrl: './modal-move-consult.component.html',
  styleUrls: ['./modal-move-consult.component.styl']
})
export class ModalMoveConsultComponent implements OnInit {
  @ViewChild('calendar') calendar: CalendarComponent;
  instruments;
  user;
  makerId;
  area;
  consultLoading;
  moveLoading;
  consultData;
  filterModel:any = {};
  consultsSubject$;
  currentMonth;
  consultations;
  consultDays;
  dropTime;
  selectedDate;
  selectedDay;
  selectedTime;
  selectedConsult;
  consultationsArr;
  consultForm;
  error;
  currentConsultId;
  constructor(private equipmentsService: EquipmentsService,
              private activatedRoute: ActivatedRoute,
              private modalService: ModalService,
              private userService: UserService,
              private _ref: ChangeDetectorRef,
              private fb: FormBuilder,
              private consultationsService: ConsultationsService,
              private currentCourseService: CurrentCourseService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.initForm();
    this.dropTime = dropTime;
    this.setCurrentMonth(new Date);
    this.consultLoading = true;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.consultation) {
        this.currentConsultId = params.consultation;
        forkJoin(this.consultationsService.getOrderedConsultationById(params.consultation), this.equipmentsService.getEquipmentsList({})).subscribe((data) => {
          this.filterModel.area = data[0].result.area;
          this.makerId = data[0].result.maker_id;
          this.consultData = data[0].result;
          this.instruments = data[1].result.data;
          this.updateConsultations();
          this.userService.getUserData(data[0].result.user_id).subscribe(data => {
            this.user = data.result;
            this.consultLoading = false;
          }, () => this.error = true);
        }, () => this.error = true);
      } else {
        this.error = true;
      }
    });
    this.consultsSubject$ = new Subject();
    this.consultsSubject$.pipe(switchMap(model => this.getConsultations(model)))
      .subscribe(data => {
       this.consultationsArr = data.result.filter(item => item.maker.id === this.makerId && item.start_time > new Date(getCurrentMoscowTime()));
        this.consultations = groupConsultationsByDay.bind(this)(this.consultationsArr);
        this.consultDays = this.consultations.map(day => day.date);
        this._ref.detectChanges();
        this.calendar.setCurrentMonth(this.currentMonth);
        this.selectedDay = this.consultations.filter(item => item.date === this.selectedDate)[0];
      })
  }
  getConsultations(params) {
    let model: any = {
      area: params.area,
      start_date: params.date_from,
      end_date: params.date_to
    }
    return this.consultationsService.getAvailableConsultations(model);
  }

  updateConsultations() {
    this.consultsSubject$.next(this.filterModel);
  }

  setCurrentMonth(date) {
    this.currentMonth = date;
    this.selectMonth(this.currentMonth);
  }

  selectMonth(date) {
    let edges = getMonthEdges(date);
    if (new Date(new Date(date).setDate(1)).setHours(0,0,0,0) === new Date(new Date().setDate(1)).setHours(0,0,0,0)) {
      this.filterModel.date_from = new Date().setHours(0,0,0,0);
    } else {
      this.filterModel.date_from = new Date(edges[0]).setHours(23,59,59,0);
    }
    this.filterModel.date_to = new Date(edges[1]).setHours(0,0,0,0);
  }

  selectDate(date) {
    if (this.selectedDate != date) {
      this.selectedTime = null;
      this.selectedDate = date;
      let match;
      this.selectedDay = this.consultations.filter(item => item.date === date)[0];
    }
  }

  onTimeSelect() {
    this.selectedConsult = getConsultById(this.selectedTime, this.consultationsArr);
  }

  prevMonth() {
    let prevMonth = new Date(new Date(this.currentMonth).setDate(0));
    this.setCurrentMonth(prevMonth);
    this.updateConsultations();
  }

  nextMonth() {
    let nextMonth = new Date(new Date(this.currentMonth).setDate(32));
    this.setCurrentMonth(nextMonth);
    this.updateConsultations();
  }

  back() {
    this.modalService.back();
  }

  closeModal() {
    this.modalService.onClose();
  }

  initForm() {
    this.consultForm = this.fb.group({
      needReservation: [false],
      equipment: this.fb.group({
        equipment_id: [null],
        name: null,
      })
    })
  }

  onNeedReserveChange() {
    if (this.consultForm.controls.needReservation.value) {
      this.consultForm.controls.equipment.controls.equipment_id.setValidators(Validators.required);
    } else {
      this.consultForm.controls.equipment.controls.equipment_id.setValidators();
    }
    this.safeReset(this.consultForm.controls.equipment);
  }

  private safeReset(control) {
    let val = control.value;
    control.reset();
    control.setValue(val);
  }

  submit() {
    let model:any = {
      new_available_consultation_id: this.selectedConsult.id
    }
    if (this.consultForm.value.needReservation && this.consultForm.value.equipment.equipment_id) {
      model.equipment_id = this.consultForm.value.equipment.equipment_id;
    }

    this.moveLoading = true;
    this.consultationsService.moveConsultation(this.currentConsultId, model).pipe(first()).subscribe(() => {
      this.currentCourseService.updateEmitter();
      this.moveLoading = false;
      this.modalService.nextPage({consultParams: this.selectedConsult, consultInstrument: this.consultForm.value.equipment.equipment_id ? this.consultForm.value.equipment : null}, null);
    }, (e) => {
      this.moveLoading = false;
      let error = parseApiError(e);
      if (error === 'not_enough') {
        this.messagesService.showMessage('На это время нет свободного оборудования.', false, false, true);
      } else {
        this.messagesService.showMessage('Ошибка при переносе консультации.', false, false, true);
      }
    })
  }

  public handleSearchEquip = function(query) {
    return this.equipmentsService.getEquipmentsList({ query_str: query, hide_broken: true }).pipe(map((data:any) => {
      return {
        result: data.result.data
      }
    }))
  }.bind(this);

}
