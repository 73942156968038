import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { EquipmentsService } from '../../services/equipments.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-modal-reserve-equip-for-client',
  templateUrl: './modal-reserve-equip-for-client.component.html',
  styleUrls: ['./modal-reserve-equip-for-client.component.styl']
})
export class ModalReserveEquipForClientComponent implements OnInit {
  currentStep: number = 0;
  state:any = {};
  isLoading;
  equipLoading;
  error;
  selectedInstrument;
  constructor(private modalService: ModalService,
              private userService: UserService,
              private equipmentsService: EquipmentsService,
              private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.user) {
      this.selectUser({user: {id: this.activatedRoute.snapshot.queryParams.user}});
    }
    if (this.activatedRoute.snapshot.queryParams.instrument) {
      this.equipLoading = true;
      this.equipmentsService.getEquipment(this.activatedRoute.snapshot.queryParams.instrument).pipe(first()).subscribe((data) => {
        this.selectedInstrument = data.result;
        this.equipLoading = false;
      }, () => {this.equipLoading = false; this.isLoading = false; this.error = true;})
    }
  }

  selectUser(state) {
    this.isLoading = true;
    this.userService.getUserData(state.user.id).subscribe((data) => {
      if (data.result) {
        this.isLoading = false;
        this.nextStep({
          user: data.result
        });
      } else {
        this.error = true;
      }

    }, () => this.error = true)
  }

  nextStep(state) {
    this.currentStep++;
    this.state = {
      ...this.state,
      ...state
    };
    if (this.currentStep == 1 && this.selectedInstrument) {
      this.nextStep({equipment: this.selectedInstrument})
    }
  }

  closeModal() {
    this.modalService.onClose();
  }

  onReserveSuccess(evt) {
    this.modalService.nextPage(evt.reserve, null);
  }

}
