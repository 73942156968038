import { Pipe } from '@angular/core';

@Pipe({
  name: 'namesFilter'
})
export class NamesFilterPipe {

  transform(items: any[], filter: string) {
    if (!items || !filter) {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item) => {
      return !!([item.user.first_name || '', item.user.last_name || '', item.user.name || ''].join(' ').toLowerCase().indexOf(filter.toLowerCase()) + 1);
    });
  }

}
