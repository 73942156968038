import {Component, OnInit, ChangeDetectorRef, Input, Output, EventEmitter, Inject, PLATFORM_ID} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { phoneMask, parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';
import { UserService } from '../../services/user.service';
import { first } from 'rxjs/operators';
import { Router } from '@angular/router';
import { IsBrowserService } from '../../services/is-browser.service';
import { AuthService } from '../../services/auth.service';
import {isPlatformBrowser} from '@angular/common';

interface NameSurnameData {
  name: string;
  surname: string;
  // email: string;
  // phone: string;
  agree: boolean;
}

@Component({
  selector: 'app-name-surname-confirm',
  templateUrl: './name-surname-confirm.component.html',
  styleUrls: ['./name-surname-confirm.component.styl']
})
export class NameSurnameConfirmComponent implements OnInit {
  @Output() onSuccess = new EventEmitter();
  titleText: string;
  isBrowser: boolean;
  phoneMask = phoneMask;
  error: boolean;
  phone;
  user;
  errorMessage;
  nameSurnameForm: FormGroup;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private router: Router,
              private messagesService: MessagesService,
              private isBrowserService: IsBrowserService,
              private _ref: ChangeDetectorRef,
              private authService: AuthService,
              @Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
  }

  ngOnInit() {
    const user = this.userService.userData;
    this.nameSurnameForm = this.fb.group({
      name: [user.name, [Validators.required]],
      surname: [user.last_name, [Validators.required]],
      // email: [user.email, [Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/)]],
      // phone: [user.phone, [Validators.required, Validators.minLength(11)]],
      agree: [false, [Validators.required]]
    });


    if (this.isBrowser) {
      const regData: NameSurnameData = localStorage.getItem('lm_user_name_surname_form_data') && <NameSurnameData>JSON.parse(localStorage.getItem('lm_user_name_surname_form_data'));
      if (regData) {
        this.setNameSurnameData(regData);
      }
      this.deleteNameSurnameData();
    }
    // this.initForm();
  }

  setNameSurnameData(data: NameSurnameData) {
    data.name && this.nameSurnameForm.controls.name.setValue(data.name);
    data.surname && this.nameSurnameForm.controls.surname.setValue(data.surname);
    // data.email && this.nameSurnameForm.controls.email.setValue(data.email);
    // data.phone && this.nameSurnameForm.controls.phone.setValue(data.phone);
    data.agree && this.nameSurnameForm.controls.agree.setValue(data.agree);
  }

  saveNameSurnameData() {
    const values = JSON.stringify(this.nameSurnameForm.value);
    localStorage.setItem('lm_user_name_surname_form_data', values);
  }

  deleteNameSurnameData() {
    localStorage.removeItem('lm_user_name_surname_form_data');
  }

  updateNameSurname() {
    // this.input.nativeElement.blur();
    if (this.nameSurnameForm.value.name) {
      if (this.nameSurnameForm.valid) {
        const form: any = {};
        form.firstName = this.nameSurnameForm.value.name;
        form.lastName = this.nameSurnameForm.value.surname;
        this.userService.updateUserData(form).subscribe((data: any) => {
          // this.inputActive = false;
          // this.paramLoading = false;
          this.userService.setUserData(data.result);
          // this.value = ((data.result.name || '') + ' ' + (data.result.last_name || '')).trim();
          // this.createForm();
          this.onSuccess.emit();
          this.messagesService.showMessage('Данные пользователя успешно обновлены', null, true, true);
        }, () => {
          // this.inputActive = false;
          // this.paramLoading = false;
          this.messagesService.showMessage('Ошибка при обновлении данных пользователя');
          // this.createForm();
        });
      }
    } else {
      // this.createForm();
    }

  }
}
