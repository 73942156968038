import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-prolongate-storage-period-cell',
  templateUrl: './prolongate-storage-period-cell.component.html',
  styleUrls: ['./prolongate-storage-period-cell.component.styl']
})
export class ProlongateStoragePeriodCellComponent implements OnInit {
  @Input() cell;
  @Input() hideTitle;
  @Output() changeEmitter = new EventEmitter();
  extend:number;
  endDate;
  constructor() { }

  ngOnInit() {
    this.extend = this.cell.control.value.duration;
    this.updateDate();
  }
  updateDate() {
    this.cell.control.controls.duration.setValue(this.extend);
    this.endDate = new Date(new Date(this.cell.end_date)).setDate(new Date(this.cell.end_date).getDate()+this.extend);
    this.cell.control.controls.new_end_date.setValue(this.endDate);
    this.changeEmitter.emit();
  }
  updateAmount(e) {
    this.extend = e;
    this.updateDate();
  }

}
