//Сервис для передачи данных из компоненты конкретного МК в компоненту редактирования

import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentCourseService {

  currentCourse;
  updateSubject;
  enrollSubject;
  constructor() {
    this.updateSubject = new Subject();
    this.enrollSubject = new Subject();
  }

  updateEmitter() {
    this.updateSubject.next();
  }

  enrollEmitter(id) {
    this.enrollSubject.next(id);
  }

}
