import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Router, NavigationEnd, NavigationStart, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-header-mobile-navigation',
  templateUrl: './header-mobile-navigation.component.html',
  styleUrls: ['./header-mobile-navigation.component.styl']
})
export class HeaderMobileNavigationComponent implements OnInit {
  menuOpened;
  currentPage;
  currentRoute;
  @Input() user;
  @Output() openLoginEmitter = new EventEmitter();
  pages = {
    'main': 'Главная',
    'idei': 'Идеи',
    'master-klassy': 'Мастер-классы',
    'sobytiya': 'События',
    'oborudovanie': 'Оборудование',
    'abonementy-i-uslugi': 'Услуги',
    'o-fabrike': 'О Фабрике',
    'profile': 'Мой профиль',
    'admin': 'Администратор'
  }
  constructor(private router: Router, private activatedRoute: ActivatedRoute) { }

  ngOnInit() {
    if (this.router.url.indexOf('profile/superadmin') + 1 || this.router.url.indexOf('profile/admin') + 1) {
      this.currentPage = 'admin';
    } else {
      this.currentPage = this.router.url.split('/')[1] || 'main';
    }
    this.currentRoute = this.router.url.split('?')[0];
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.closeMenu();
        if (event.urlAfterRedirects.indexOf('profile/superadmin') + 1 || event.urlAfterRedirects.indexOf('profile/admin') + 1) {
          this.currentPage = 'admin';
        } else {
          this.currentPage = event.urlAfterRedirects.split('/')[1] || 'main';
        }
        this.currentRoute = this.router.url.split('?')[0];
      }
    });
  }

  openMenu() {
    this.menuOpened = true;
  }

  closeMenu() {
    this.menuOpened = false;
  }

  toggleMenu() {
    if (this.menuOpened) {
      this.closeMenu();
    } else {
      this.openMenu();
    }
  }

  openLogin() {
    this.openLoginEmitter.emit();
  }

}
