import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-events-list-options',
  templateUrl: './events-list-options.component.html',
  styleUrls: ['./events-list-options.component.styl']
})
export class EventsListOptionsComponent implements OnInit {
  isOpenBox;
  currentRoute;
  @Input() event;
  @Input() top;
  @ViewChild(ClickOutsideDirective) clickOutside;
  @Input() isMe: boolean = false;
  constructor(private router: Router, private cancelRegistrationService: CancelRegistrationService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
  }

  openBox() {
    this.isOpenBox = true;
    this.clickOutside.enable();
  }
  closeBox() {
    this.isOpenBox = false;
    this.clickOutside.disable();
  }

  unregister() {
    this.cancelRegistrationService.cancelEvent(this.event);
  }
}
