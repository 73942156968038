import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TextMaskModule } from 'angular2-text-mask';
import { AppRoutingModule } from './app-routing.module';
import { ModalModule } from './modules/modal/modal.module';

import { AppComponent } from './app.component';
// import { UpButtonComponent } from './shared/up-button/up-button.component';
// import { ComplexitySetterComponent } from './shared/complexity-setter/complexity-setter.component';
import { environment } from './../environments/environment';
import { LoggedGuard } from './guards/logged.guard';
import { AdminGuard } from './guards/admin.guard';
import { SuperadminGuard } from './guards/superadmin.guard';
import { NameSurnameGuard } from './guards/name-surname.guard';
import { PhoneGuard } from './guards/phone.guard';
import { CoursesGuard } from './guards/courses.guard';
import { MetaService } from './services/meta.service';

import { BrowserCookiesModule } from '@ngx-utils/cookies/browser';

import { HttpErrorInterceptorProvider } from './services/http-error.interceptor';
import { TransferHttpCacheModule } from '@nguniversal/common';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { NgtUniversalModule } from '@ng-toolkit/universal';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { addSlashToEndIfNotExist } from '../app/utils';


@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    ModalModule,
    BrowserModule.withServerTransition({appId: 'leroy-universal'}),
    HttpClientModule,
    BrowserTransferStateModule,
    AppRoutingModule,
    TextMaskModule,
    InfiniteScrollModule,
    BrowserCookiesModule.forRoot(),
    NgtUniversalModule,
    BrowserAnimationsModule
  ],
  providers: [
    { provide: 'SOCKET', useValue: environment.socket },
    { provide: 'API_ROOT_URL', useValue: addSlashToEndIfNotExist(environment.apiBaseUrl) },
    { provide: 'HOSTNAME', useValue: addSlashToEndIfNotExist(environment.hostname) },
    { provide: 'API_ROOT_URL_HTTP', useValue: addSlashToEndIfNotExist(environment.apiBaseUrlHttp) },
    { provide: 'HOSTNAME_HTTP', useValue: addSlashToEndIfNotExist(environment.hostnameHttp) },
    { provide: 'PAGE_ADDRESS', useValue: addSlashToEndIfNotExist(environment.pageAddress) },
    { provide: 'FEWLINES_CLIENT_ID', useValue: environment.fewlinesClientId },
    { provide: 'FB_APP_ID', useValue: environment.facebookAppId },
    { provide: 'VK_CLIENT_ID', useValue: environment.vkClientId },
    { provide: 'GOOGLE_CLIENT_ID', useValue: environment.googleClientId },
    { provide: 'ENV_NAME', useValue: environment.envName },
    { provide: 'GOOGLE_API_KEY', useValue: environment.googleApiKey},
    LoggedGuard,
    AdminGuard,
    SuperadminGuard,
    PhoneGuard,
    NameSurnameGuard,
    CoursesGuard,
    MetaService,
    HttpErrorInterceptorProvider
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
