import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoggedGuard } from './guards/logged.guard';
import { AdminGuard } from './guards/admin.guard';
import { SuperadminGuard } from './guards/superadmin.guard';
import { PhoneGuard } from './guards/phone.guard';
import { NameSurnameGuard } from './guards/name-surname.guard';

const currentMonth = new Date().getMonth() + 1;
const currentYear = new Date().getFullYear();

const routes: Routes = [
  // { path: '', redirectTo: 'main', pathMatch: 'full' },
  { path: '', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/main/main.module#MainModule' },
  { path: 'idei', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/ideas/ideas.module#IdeasModule' },
  { path: 'oborudovanie', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/equipments/equipments.module#EquipmentsModule' },
  // { path: 'master-klassy', redirectTo: `master-klassy/${currentYear}/${currentMonth}`, pathMatch: 'prefix' },
  { path: 'master-klassy', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/courses/courses.module#CoursesModule' },
  { path: 'sobytiya', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/events/events.module#EventsModule' },
  { path: 'abonementy-i-uslugi', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/subscriptions/subscriptions.module#SubscriptionsModule' },
  { path: 'profile', loadChildren: './modules/profile/profile.module#ProfileModule', canActivate: [LoggedGuard, PhoneGuard, NameSurnameGuard] },
  { path: 'profile/admin', canActivate: [AdminGuard, PhoneGuard, NameSurnameGuard], loadChildren: './modules/admin/admin.module#AdminModule' },
  { path: 'profile/superadmin', canActivate: [SuperadminGuard, PhoneGuard, NameSurnameGuard], loadChildren: './modules/superadmin/superadmin.module#SuperadminModule' },
  { path: 'o-fabrike', canActivate: [PhoneGuard], loadChildren: './modules/about/about.module#AboutModule' },
  { path: 'create-password', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/create-password/create-password.module#CreatePasswordModule', data: { hideHeader: true }  },
  { path: 'recovery-password', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/recovery-password/recovery-password.module#RecoveryPasswordModule', data: { isCreation: true, hideHeader: true } },
  { path: 'master-create-password', canActivate: [PhoneGuard, NameSurnameGuard], loadChildren: './modules/recovery-password/recovery-password.module#RecoveryPasswordModule', data: { isCreation: true, hideHeader: true } },
  { path: 'confirm-phone', canActivate: [LoggedGuard], loadChildren: './modules/confirm-phone/confirm-phone.module#ConfirmPhoneModule', data: { isCreation: true, hideHeader: true } },
  { path: 'confirm-name-surname', canActivate: [LoggedGuard], loadChildren: './modules/confirm-name-surname/confirm-name-surname.module#ConfirmNameSurnameModule', data: { isCreation: true, hideHeader: true } },
  { path: '404', loadChildren: './modules/not-found/not-found.module#NotFoundModule' },

  { path: '**', redirectTo: '404'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
