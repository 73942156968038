import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})

export class EquipmentsService {
  constructor(
    private api: ApiService,
    private userService: UserService,
  ) {}

  create(model) {
    return this.api.post('v1/equipments/new', JSON.stringify(model));
  }

  edit(equipmentId, model) {
    return this.api.post(`v1/equipments/${equipmentId}/edit`, JSON.stringify(model));
  }

  deleteEquipment(equipmentId, model) {
    return this.api.post(`v1/equipments/${equipmentId}/delete`, model);
  }

  getEquipmentsList(filter) {
    return this.api.post('v2/equipments/list', JSON.stringify(filter));
  }

  getEquipment(equipmentId) {
    return this.api.get(`v1/equipments/${equipmentId}`);
  }

  passMiniCourse(equipmentId, miniCourseId, params: {
    userId: number;
    passed: boolean;
  }) {
    return this.api.post(`v1/equipments/${equipmentId}/courses/${miniCourseId}/pass`, null,  params);
  }

  getReservitionInfo(
    equipmentId: number,
    reservation_type: 'FOR_ME' | 'FOR_COURSE' | 'FOR_CONSULTATION',
    userId = this.userService.userId
  ) {
    return this.api.post(`v1/equipments/${equipmentId}/reservation/info`, {
      reservation_type,
      userId,
    });
  }

  reserveEquip(
    equipmentId: number,
    params
  ) {
    return this.api.post(`v1/equipments/${equipmentId}/reservation`, params);
  }

  cancelReservation(equipment_timing_ids, user_id) {
    return this.api.post(`v1/equipments/reservations/cancel`, { equipment_timing_ids, user_id });
  }

  getStatistic(
    equipmentId: number,
    params
  ) {
    return this.api.post(`v1/equipments/${equipmentId}/statistic`, params);
  }

  getNearestReservationDate(equipmentId) {
    return this.api.post(`v1/equipments/${equipmentId}/reservation/nearest`, null);
  }

  registerBreakdown(model) {
    return this.api.post(`v1/breakages/new`, model);
  }

  getBreakdownInfo(notificationId) {
    return this.api.get(`v1/admin/warnings/equipments/breakage/change/info`, { notificationId });
  }

  getDeletionInfo(notificationId) {
    return this.api.get(`v1/admin/warnings/equipments/deletion/change/info`, { notificationId });
  }

  changeCallResult(model) {
    return this.api.post(`v1/admin/warnings/equipments/breakage/change`, model);
  }

  changeDeletionCallResult(model) {
    return this.api.post(`v1/admin/warnings/equipments/deletion/change`, model);
  }

  getBrokenList() {
    return this.api.get(`v1/breakages/list`);
  }

  postponeBreakage(breakageId, date) {
    return this.api.post(`v1/breakages/${breakageId}/postpone`, null, { date });
  }

  repair(breakageId) {
    return this.api.post(`v1/breakages/${breakageId}/repaired`, null);
  }

  getUsersMiniCourses(userId) {
    return this.api.get(`v1/equipments/courses/passed`, { userId });
  }


}
