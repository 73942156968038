import {
    HTTP_INTERCEPTORS, HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor,
    HttpRequest
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, ObservableInput } from "rxjs";
import { Router } from "@angular/router";
import { empty, throwError } from 'rxjs';
import { catchError, tap } from "rxjs/operators";

@Injectable({
providedIn: 'root'
})
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
        }), catchError((err:any, caught: Observable<any>):ObservableInput<{}> => {
            if (err instanceof HttpErrorResponse && err.status == 404) {
                console.log(err);
                this.router.navigateByUrl('/404', { replaceUrl: true });

                return throwError(err);
            }
            else {
                return throwError(err);
            }
        }))
    }
}

export const HttpErrorInterceptorProvider = {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true,
};
