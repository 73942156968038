import { Component, OnInit, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { CalendarService } from '../../services/calendar.service';
import { AdminService } from '../../services/admin.service';
import { MessagesService } from '../../services/messages.service';
import { compareMonth, getGreenwichDate, groupByDay } from '../../utils';
import { MOSCOW_TIME_ZONE } from '../../config';
import { CurrentCourseService } from '../../services/current-course.service';
import { CurrentEventService } from '../../services/current-event.service';
import { CurrentEquipmentService } from '../../services/current-equipment.service';
import { StorageService } from '../../services/storage.service';
import { ModalService } from '../../services/modal.service';
import { forkJoin } from "rxjs";
import { FABRIC_WORK_HOURS } from '../../config';

const userTabsNames = {
    today: 'Сегодня',
    courses: 'Обучение',
    events: 'События',
    consults: 'Консультации',
    equip: 'Оборудование'
  }

@Component({
  selector: 'app-admin-user',
  templateUrl: './admin-user.component.html',
  styleUrls: ['./admin-user.component.styl']
})
export class AdminUserComponent implements OnInit {
  @Input() userId;
  @Input() inPopup;
  @Input() user;
  @Input() withControls;
  fabricWorkHours = FABRIC_WORK_HOURS;
  userLoading;
  error;
  userTab;
  userTabName;
  userCalendarLoading;
  userCalendarList;
  loadingText;
  userCalendarFilter: any = {};
  currentUserId;
  currentMonth;
  isAllTime;
  haveCells;
  cellsReady;
  currentRoute;
  cellsList;
  isTodayMonth = true;
  filterType = 'all';
  canDelete;
  removeModalStatus;
  sortParams = [
  {
      title: 'Будущие',
      value: 'future'
    },{
      title: 'пройденные',
      value: 'past'
    }
  ];

  tabs = [{
    name: 'Сегодня',
    value: 'today'
  },{
    name: 'Обучение',
    value: 'courses'
  },{
    name: 'События',
    value: 'events'
  },{
    name: 'Консультации',
    value: 'consults'
  },{
    name: 'Оборудование',
    value: 'equip'
  }]

  tabsSwitcherOpened;
  userRole;

  constructor(private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private calendarService: CalendarService,
              private adminService: AdminService,
              private messagesService: MessagesService,
              private currentCourseService: CurrentCourseService,
              private currentEventService: CurrentEventService,
              private currentEquipmentService: CurrentEquipmentService,
              private storageService: StorageService,
              private router: Router,
              private modalService: ModalService) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.user) {
      this.userId = this.activatedRoute.snapshot.queryParams.user;
    }
    this.canDelete = this.userService.userData.role === 'ADMIN' || this.userService.userData.role === 'SUPER_ADMIN';
    this.currentRoute = this.router.url.split('?')[0];
    if (!this.user) {
      if (!this.userId) {
        if (this.activatedRoute.snapshot.queryParams.user) {
          this.initUser(this.activatedRoute.snapshot.queryParams.user);
        } else {
          this.error = true;
        }
      } else {
        this.initUser(this.userId);
      }
    } else {
      this.currentUserId = this.user.id;
      this.userRole = this.user.role;
      this.setUserTab('today');
    }
    this.currentCourseService.updateSubject.subscribe(() => {
      this.updateCalendar();
    })
    this.currentEventService.updateSubject.subscribe(() => {
      this.updateCalendar();
    })
    this.currentEquipmentService.updateSubject.subscribe(() => {
      this.updateCalendar();
    })

    this.userService.updateSubject.subscribe(() => {
      this.initUser(this.currentUserId);
    })
  }

  initUser(user) {
    this.userLoading = true;
    this.error = null;
    this.currentUserId = user;
    this.userCalendarFilter.user_id = this.currentUserId;
    this.userService.getUserData(user).subscribe((data) => {
      this.user = data.result;
      this.userRole = this.user.role;
      this.userLoading = false;
    }, () => {
      this.userLoading = false;
      this.error = true;
    })
  }

  updateCalendar() {
    this.userCalendarLoading = true;
    this.calendarService.getUserCalendar(this.userCalendarFilter).subscribe((data) => {
      let result = data.result.sort((a, b) => {
        return +a.start_time - +b.start_time;
      }).filter((item) => {
        return item.entity_type != 'WELCOME_EVENT';
      });
      let past;
      let future;
      if (this.userCalendarFilter.entity_type === 'EVENT') {
        past = result.filter(item => item.entity_status !== 'PLANNED').map(item => groupByDay([item], 'start_date')).map(item => item[0]).map(day => {
          return {
            ...day,
            status: 'PASSED',
            end_date: day.sessions[0].item.end_date
          }
        }).sort((a, b) => {return b.date - a.date});
        future = result.filter(item => item.entity_status === 'PLANNED').map(item => groupByDay([item], 'start_date')).map(item => item[0]).map(day => {
          return {
            ...day,
            status: 'PLANNED',
            end_date: day.sessions[0].item.end_date
          }
        }).sort((a, b) => {return a.date - b.date});
      } else {
        past = groupByDay(result.filter(item => item.entity_status !== 'PLANNED'), 'start_date').map(item => {
          return {
            status: 'PASSED',
            ...item
          }
        }).sort((a, b) => {return b.date - a.date});
        future =  groupByDay(result.filter(item => item.entity_status === 'PLANNED'), 'start_date').map(item => {
          return {
            status: 'PLANNED',
            ...item
          }
        }).sort((a, b) => {return a.date - b.date});
      }
      this.userCalendarList = [...past, ...future];
      this.userCalendarLoading = false;
    })
  }

  setUserTab(tab) {
    if (!this.currentMonth) {
      this.currentMonth = new Date();
    }
    if (this.userTab !== tab) {

      if (tab === 'today') {
        this.filterType = 'all';
      }
      if (this.userTab === 'today') {
        this.filterType = 'future';
      }
      this.userTab = tab;
      this.userTabName = userTabsNames[tab];
      this.setFilter(tab);
    }
  }

  setFilter(tab) {
    switch (tab) {
      case 'today':
        this.userCalendarFilter.date_from = getGreenwichDate(new Date());
        this.userCalendarFilter.date_to = getGreenwichDate(new Date());
        delete this.userCalendarFilter.entity_type;
        this.userCalendarFilter.user_id = this.currentUserId;
        break;
      case 'courses':
        this.userCalendarFilter.entity_type = 'COURSE';
        this.userCalendarFilter.user_id = this.currentUserId;
        this.setFilterMonth(this.currentMonth);
        break;
      case 'events':
        this.userCalendarFilter.entity_type = 'EVENT';
        this.userCalendarFilter.user_id = this.currentUserId;
        this.setFilterMonth(this.currentMonth);
        break;
      case 'consults':
        this.userCalendarFilter.entity_type = 'CONSULTATION';
        this.userCalendarFilter.user_id = this.currentUserId;
        this.setFilterMonth(this.currentMonth);
        break;
      case 'equip':
        this.userCalendarFilter.entity_type = 'EQUIPMENT';
        this.userCalendarFilter.user_id = this.currentUserId;
        this.setFilterMonth(this.currentMonth);
        break;
    }
    this.updateCalendar();
  }

  setFilterMonth(date) {
    this.currentMonth = date;
    if (!this.isAllTime) {
      this.userCalendarFilter.date_from = getGreenwichDate(new Date(date).setDate(1));
      this.userCalendarFilter.date_to = getGreenwichDate(new Date(new Date(date).setDate(32)).setDate(0));
    }
  }

  selectMonth(date) {
    if (date === 'all') {
      delete this.userCalendarFilter.date_from;
      delete this.userCalendarFilter.date_to;
      this.isAllTime = true;
    } else {
      this.isTodayMonth = compareMonth(new Date(), date) === 0;
      if (this.isTodayMonth) {
        if (!this.isAllTime) {
          this.filterType = 'future';
        }
      } else {
        this.filterType = 'all';
      }
      this.isAllTime = false;
      this.setFilterMonth(date);
    }

    this.updateCalendar();
  }

  extendSubscription() {

  }

  onFilterChange(type) {
    this.filterType = type;
  }

  onStorageInit(evt) {
    this.haveCells = !!evt.length;
    this.cellsList = evt;
    this.cellsReady = true;
  }

  openTabsSwitcher() {
    this.tabsSwitcherOpened = true;
  }

  closeTabsSwitcher() {
    this.tabsSwitcherOpened = false;
  }

  toggleTabsSwitcher() {
    if (this.tabsSwitcherOpened) {
      this.closeTabsSwitcher();
    } else {
      this.openTabsSwitcher();
    }
  }

  finishStorage() {
    if (this.cellsList.length) {
      forkJoin(this.cellsList.map(item => {
        return this.storageService.finishSession(item.id);
      })).subscribe(() => {
        this.storageService.updateEmitter();
      })
    }
  }

  openRemoveModal() {
    this.removeModalStatus = true;
  }

  closeRemoveModal() {
    this.removeModalStatus = false;
  }

  removeClient() {
    this.closeRemoveModal();
    this.userService.removeUser(this.currentUserId).subscribe(() => {
      if (this.userRole !== 'CLIENT') {
        this.adminService.openEntitiesInfoForDeletingMaster(this.userId);
      } else {
        this.closeModal();
      }
      this.userService.deleteEmitter();
    });
  }

  closeModal() {
    this.modalService.onClose();
  }
}
