import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessagesService } from '../../services/messages.service';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { areaTypesShort } from '../../utils';
import { TranslitService } from '../../services/translit.service';

@Component({
  selector: 'app-master-calendar-item',
  templateUrl: './master-calendar-item.component.html',
  styleUrls: ['./master-calendar-item.component.styl']
})
export class MasterCalendarItemComponent implements OnInit {
  @Input() event: any;
  @Input() hideDate: any;
  @Input() past: boolean;
  @Input() history: boolean;
  @Input() userId;
  @Output() updateEmitter = new EventEmitter();
  modalStatus;
  modalOpen;

  spaces: any = {painting: 'Покраска', free: 'Свободная'}
  unregisterLoading;
  myCourse;
  myConsult;
  isMe;
  constructor(private router: Router,
              private messagesService: MessagesService,
              private userService: UserService,
              private translitService: TranslitService) { }

  ngOnInit() {
    areaTypesShort.forEach(item => {
      this.spaces[item.value.toLowerCase()] = item.title;
    })
    if (!this.userId) {
      this.userId = this.userService.userId;
    }
    this.isMe = !this.userId || this.userId == this.userService.userId;
    if (this.event.master) {
      this.myCourse = this.event.entity_type === 'COURSE' && (this.event.master.id == this.userId || this.event.author_id == this.userId);
      this.myConsult = this.event.entity_type === 'CONSULTATION' && this.event.master.id == this.userId;
    }
    if (this.myCourse) {
      this.event.entity_type = 'MYCOURSE';
    }
  }

  getDescription() {
    let result = [];
    if (this.event.areas && this.event.areas.length) {
      if (+this.event.areas.length === 1 && this.event.areas.length > 0) {
        result.push('Пространство «' + this.spaces[this.event.areas[0].toLowerCase()] + '»');
      } else {
        let str = '';
        this.event.areas.forEach((item, index) => {
          if (index === 0) {
            str += `«${this.spaces[item.toLowerCase()]}»`;
          } else if (index === this.event.areas.length - 1) {
            str += ` и «${this.spaces[item.toLowerCase()]}»`;
          } else {
            str += `, «${this.spaces[item.toLowerCase()]}»`;
          }
        });
        result.push('Пространства ' + str);
      }
    } else {
      return '';
    }
    if (!this.myCourse && !this.myConsult && this.event.master && this.event.master.first_name) {
      result.push('мастер ' + this.event.master.first_name);
    }
    return result.join(', ');
  }

  moveCourse(event) {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'move-course', course: event.entity_second_id}});
  }

  moveConsult(id) {

  }

  cancelConsult(id) {
    let enrollAgain = function () {
      this.messagesService.showMessage('Ваша запись на консультацию восстановлена.');
    }.bind(this);
    this.messagesService.showMessage('Ваша запись на консультацию отменена.', {
      text: 'ВЕРНУТЬ ЗАПИСЬ',
      func: enrollAgain
    })
  }


  routeToPage() {
    if (this.event.entity_type === 'COURSE') {
      this.router.navigate(['/master-klassy/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_second_id || this.event.entity_id)])
    } else if (this.event.entity_type === 'EVENT') {
      this.router.navigate(['/sobytiya/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_id)])
    }
  }

  getRoute() {
    if (this.event.entity_type === 'COURSE' || this.event.entity_type === 'MYCOURSE') {
      return ['/master-klassy/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_second_id || this.event.entity_id)];
    } else if (this.event.entity_type === 'EVENT') {
      return ['/sobytiya/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_id)];
    } else if (this.event.entity_type === 'EQUIPMENT') {
      return ['/oborudovanie/', this.translitService.getHumanReadableId(this.event.name, this.event.entity_id)];
    }
  }
}
