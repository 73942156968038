import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CreationService } from '../../services/creation.service';
import { FileUploadService } from '../../services/file-upload.service';
import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
import { areaTypesShort, parseApiError } from '../../utils';
import { ActivatedRoute, Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-create-master',
  templateUrl: './create-master.component.html',
  styleUrls: ['./create-master.component.styl']
})
export class CreateMasterComponent implements OnInit {

  @ViewChild('mainPhotoPicker') mainPhotoPicker;
  creationLoading;
  loadingState: any;
  errorMessage;
  clientForm: FormGroup;
  areas;
  userId;
  user;
  changeRoleStatus;
  isUserToMaster;
  isMasterEdition;
  isMasterToUser;
  removeModal;
  currentRoute;

  constructor(private creationService: CreationService,
              private fb: FormBuilder,
              private adminService: AdminService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private fileUploadService: FileUploadService,
              private userService: UserService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.areas = areaTypesShort;
    this.currentRoute = this.router.url.split('?')[0];
    this.clientForm = this.fb.group({
      serviceCard: ['', [Validators.minLength(17), Validators.maxLength(17)]],
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Zа-яА-ЯёЁ][a-zA-Zа-яА-ЯёЁ0-9-_\.]{1,20}$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Zа-яА-ЯёЁ][a-zA-Zа-яА-ЯёЁ0-9-_\.]{1,20}$/)]],
      phone: ['', [Validators.required, Validators.minLength(11)]],
      email: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)]],
      space: [this.areas[0].value, [Validators.required]],
      image: [null]
    });
    this.userId = this.activatedRoute.snapshot.queryParams.user;
    if (this.userId) {
      this.getUserInfo();
    }
  }

  changeRole() {

  }

  closeChangeRole() {
    this.changeRoleStatus = false;
  }

  openChangeRole() {
    this.changeRoleStatus = true;
  }

  getUserInfo() {
    this.loadingState = 3;
    this.userService.getUserData(this.userId).pipe(first()).subscribe(data => {
      this.user = data.result;
      this.clientForm.controls.serviceCard.setValue(data.result.service_card);
      this.clientForm.controls.name.setValue(data.result.name);
      this.clientForm.controls.lastName.setValue(data.result.last_name);
      this.clientForm.controls.email.setValue(data.result.email);
      this.clientForm.controls.phone.setValue(data.result.phone);
      if (data.result.area) {
        this.clientForm.controls.space.setValue(data.result.area);
      }
      if (data.result.image) {
        this.clientForm.controls.image.setValue({fileUrl: data.result.image && data.result.image.url ? data.result.image.url: null, old: true, fileId: data.result.image.id});
      }
      let w = window;
      if (w && document.querySelectorAll) {
        let inputs: any = document.querySelectorAll('.account-create-inputs-wrapper input');
        inputs.forEach((el:any) => el.value && el.onfocus && el.onfocus());
      }
      this.loadingState = null;
      this.isUserToMaster = this.user.role === 'CLIENT';
      this.isMasterEdition = this.user.role === 'ADMIN'
    }, () => {
      this.loadingState = 'error'
      this.errorMessage = 'Ошибка';
    })
  }

  phoneMask = (rawValue) => {
    if (rawValue && (rawValue[0] === '+' || rawValue[0] === '7')) {
      return ['+', /7/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    } else {
      return [/8/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    }
  }

  modalClose() {
    this.creationService.closeCreationModal();
  }

  submit() {
    this.validPhoneNumber();
    if(!this.clientForm.valid) {
      this.markInvalidFields();
    } else {
      if (this.clientForm.value.image) {
        this.uploadImage(this.clientForm.controls.image);
      } else {
        this.sendForm();
      }
    }
  }

  markInvalidFields() {
    const controls = this.clientForm.controls;
    Object.keys(controls)
    .forEach(controlName => controls[controlName].markAsTouched());
  }

  getModel() {
    let model: any = {};
    if (this.clientForm.value.serviceCard) {
      model.service_card = this.clientForm.value.serviceCard;
    }
    if (this.clientForm.value.name) {
      model.first_name = this.clientForm.value.name;
    }
    if (this.clientForm.value.lastName) {
      model.last_name = this.clientForm.value.lastName;
    }
    if (this.clientForm.value.phone) {
      model.phone = this.getPhoneNumber();
    }
    if (this.clientForm.value.email) {
      model.email = this.clientForm.value.email.toLowerCase();
    }
    if (this.clientForm.value.space) {
      model.area = this.clientForm.value.space;
    }
    if (this.clientForm.value.image && this.clientForm.value.image.fileId) {
      model.photo_id = this.clientForm.value.image.fileId
    }
    model.language = 'RU-ru';
    model.user_role = 'ADMIN';
    return model;
  }

  sendForm() {
    this.loadingState = 2;
    let method;
    let isCreation;
    if (this.isUserToMaster || this.isMasterEdition) {
      method = this.adminService.editAccount(this.userId, this.getModel());
    } else {
      method = this.adminService.createAccount(this.getModel());
      isCreation = true;
    }
    method.pipe(first()).subscribe((data) => {
      this.loadingState = 3;
      this.userService.update();
      this.modalClose();
      let message = 'Мастер успешно добавлен.';
      if (this.isMasterEdition) {
        message = 'Карточка мастера успешно отредактирована.';
      }
      this.messagesService.showMessage(message, null, true, true);
    }, (e) => {
      this.loadingState = 'error'
      this.errorMessage = 'Ошибка создания аккаунта';
      let error = parseApiError(e);
      if (error.code === 'already_registered' && error.field === 'service_card') {
        this.errorMessage = 'Пользователь с такой сервисной картой уже существует';
      }
      if (error.code === 'already_registered' && error.field === 'phone') {
        this.errorMessage = 'Пользователь с таким телефоном уже существует';
      }
      if (error.code === 'already_registered' && error.field === 'email') {
        this.errorMessage = 'Пользователь с таким email уже существует';
      }
    })
  }

  private uploadImage = function(file) {
    if (file.value && !file.value.fileUrl) {
      this.loadingState = 1;
      file.loading = true;
      file.hasErr = false;
      this.fileUploadService.uploadFile(file.value, 'PROFILE').subscribe((data) => {
        file.value.fileUrl = data.result.url;
        file.value.fileId = data.result.id;
        file.loaded = true;
        file.loading = false;
        this.sendForm();
      }, () => {
        file.hasErr = true;
        file.loading = false;
        this.loadingState = 'error'
        this.errorMessage = 'Ошибка загрузки файла';
      });
    } else {
      file.loaded = true;
      this.sendForm();
    }
  }

  masterToUser() {
    this.closeChangeRole();
    let model: any = {};
    if (this.user.service_card) {
      model.service_card = this.user.service_card;
    }
    if (this.user.first_name || this.user.name) {
      model.first_name = this.user.first_name || this.user.name;
    }
    if (this.user.last_name) {
      model.last_name = this.user.last_name;
    }
    if (this.user.phone) {
      model.phone = this.user.phone;
    }
    if (this.user.email) {
      model.email = this.user.email;
    }
    if (this.user.image && this.user.image.id) {
      model.photo_id = this.user.image.id;
    }
    model.language = 'RU-ru';
    model.user_role = 'CLIENT';
    this.adminService.editAccount(this.userId, model).pipe(first()).subscribe(() => {
      this.loadingState = 3;
      this.userService.update();
      this.adminService.openEntitiesInfoForDeletingMaster(this.userId);
      this.modalClose();
    }, () => {
      this.loadingState = 'error'
      this.errorMessage = 'Ошибка редактирования аккаунта';
    })
  }

  openRemoveModal() {
    this.removeModal = true;
  }

  closeRemoveModal() {
    this.removeModal = false;
  }

  removeMaster() {
    this.loadingState = 4;
    this.closeRemoveModal();
    this.userService.removeUser(this.userId).subscribe(() => {
      this.modalClose();
      this.userService.deleteEmitter();
      if (this.user.role !== "CLIENT") {
        this.adminService.openEntitiesInfoForDeletingMaster(this.userId);
      }
    }, () => {
      this.loadingState = 'error'
      this.errorMessage = 'Ошибка при удалении мастера';
    });
  }

  private validPhoneNumber() {
    let phoneNumber = this.getPhoneNumber();

    if (phoneNumber.length < 11) {
      this.clientForm.controls.phone.setErrors({ incorrect: 'true' })
    }
  }

  private getPhoneNumber(): string {
    if (this.clientForm.value.phone) {
      return this.clientForm.value.phone.replace(/\D+/g, '');
    } else {
      return '';
    }
  }
}
