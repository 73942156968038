import { Directive } from '@angular/core';
import { IsBrowserService } from '../services/is-browser.service';

@Directive({
  selector: '[appScrollUp]'
})
export class ScrollUpDirective {

  constructor(private isBrowserService: IsBrowserService) { 
    if (this.isBrowserService.isBrowser) {
      window.scrollTo(0, 0);
    }
  }

}
