import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { compareMonth } from '../../utils';

const dayMs = 24 * 3600 * 1000;
const timezoneOffset = new Date().getTimezoneOffset() * 60 * 1000;


@Component({
  selector: 'app-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.styl']
})

export class CalendarComponent implements OnInit {
  mastersLimit = 2;
  currentMonth;
  isCurrentMonth;
  currentMonthDates;
  selectedDate;
  weekDays = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
  monthLoading;
  @Input() section;
  @Input() type;
  @Input() dates;
  @Input() disablePastDates: any;
  @Input() disableFutureDates: any;
  @Input() disabledDates:any;
  @Input() consultations;
  @Input() courses;
  @Input() events;
  @Input() masters;
  @Input() minDate;
  @Input() maxDate;
  @Input() disableFree;
  @Input() monthsSwithOut: boolean = false;
  @Input() defaultMonth;
  @Input() notSelectDisabled;
  @Output() onDateSelect = new EventEmitter();
  @Output() prevMonthEmitter = new EventEmitter();
  @Output() nextMonthEmitter = new EventEmitter();
  eventDates = [];
  loading:boolean = false;
  consultDays;
  constructor() { }

  ngOnInit() {
    if (!this.monthsSwithOut && !this.defaultMonth) {
      this.setCurrentMonth(new Date());
    }
    if (this.defaultMonth) {
      this.setCurrentMonth(new Date(this.defaultMonth));
    }
  }

  setCurrentMonth(date) {
    this.currentMonth = new Date(date);
    this.isCurrentMonth = !compareMonth(new Date(), date);
    this.getMonthDays();
  }

  onNextMonth() {
    if (this.monthsSwithOut) {
      this.nextMonthEmitter.emit();
    } else {
      this.nextMonth();
    }
  }

  onPrevMonth() {
    if (this.monthsSwithOut) {
      this.prevMonthEmitter.emit();
    } else {
      this.prevMonth();
    }
  }

  nextMonth() {
    this.loading = false;
    this.setCurrentMonth(new Date(new Date(this.currentMonth).setDate(32)).setDate(1));
  }

  prevMonth() {
    this.loading = false;
    this.setCurrentMonth(new Date(new Date(this.currentMonth).setDate(0)).setDate(1));
  }

  getMonthDays() {
    let firstMonthDay = new Date(this.currentMonth).setDate(1);
    let firstWeekDay = +new Date(firstMonthDay).getDay() || 7;
    let lastMonthDay = new Date(new Date(new Date(this.currentMonth).setDate(1)).setMonth(new Date(this.currentMonth).getMonth() + 1)).setDate(0);
    let lastWeekDay = +new Date(lastMonthDay).getDay() || 7;
    let firstClaendarDay = new Date(new Date(firstMonthDay).setDate(new Date(firstMonthDay).getDate() - (firstWeekDay - 1)));
    let lastClaendarDay = new Date(new Date(lastMonthDay).setDate(new Date(lastMonthDay).getDate() + (7 - lastWeekDay)));
    this.currentMonthDates = this.getDatesRange(firstClaendarDay, lastClaendarDay, firstMonthDay, lastMonthDay);
  }

  private dropTime = function(date) {
    console.log('dropTime');
    return new Date(date).setHours(0,0,0,0);
  }

  private getDatesRange = function(first, last, firstMonthDay, lastMonthDay) {
    let result = {
      prev: [],
      current: [],
      next: []
    };
    let firstDay = this.dropTime(firstMonthDay);
    let lastDay = this.dropTime(lastMonthDay);
    let date = this.dropTime(first);
    let todayDate = this.dropTime(new Date());
    let lastDropped = this.dropTime(last);
    do {
      if (date < firstDay) {
        let day: any = {
          date: date,
          today: date === todayDate,
          type: this.getDateType(date)
        }
        if (this.consultations && this.consultations.length) {
          let consult = this.consultations.filter(item => {
            return item.date === date
          });
          if (consult.length) {
            day.type = 'consultation';
            day.consultation = consult[0];
          }
        }
        result.prev.push(day);
      } else if (date > lastDay) {
        let day: any = {
          date: date,
          today: date === todayDate,
          type: this.getDateType(date)
        }
        if (this.consultations && this.consultations.length) {
          let consult = this.consultations.filter(item => {
            return item.date === date
          });
          if (consult.length) {
            day.type = 'consultation';
            day.consultation = consult[0];
          }
        }
        result.next.push(day);
      } else {
        let day: any = {
          date: date,
          today: date === todayDate,
          type: this.getDateType(date)
        }
        if (this.consultations && this.consultations.length) {
          let consult = this.consultations.filter(item => {
            return item.date === date
          });
          if (consult.length) {
            day.type = 'consultation';
            day.consultation = consult[0];
          }
        }

        if (this.courses && this.courses.length) {
          let course = this.courses.filter(item => {
            return item.date === date
          });
          if (course.length) {
            day.type = 'course';
            day.course = course[0];
          }
        }

        if (day.type === 'courseOrConsult') {
          day.courseOrConsult = { date };
        }

        result.current.push(day);
      }
      // date = this.dropTime(new Date(date).setDate(new Date(date).getDate() + 1));
      date += dayMs;
    } while (date <= lastDropped);
    return result;
  }

  selectDate(date, disabled, page) {
    if (!disabled) {
      if (page === 'next') {
        this.onNextMonth();
      } else if (page === 'prev') {
        this.onPrevMonth();
      }
      this.selectedDate = date;
      // console.error(date);
      this.onDateSelect.emit(date);
    }
  }

  private getDateType = function(date) {
    const currentTime = new Date().setHours(0,0,-1);
    let result = '';
    if (this.disableFree) {
      result = 'disabled';
    }

    if (this.disablePastDates && date < currentTime) {
      // console.log(new Date(date), new Date(currentTime), date < currentTime)
      // console.log('PAST')
      return 'disabled';
    } else if (this.disableFutureDates && date > currentTime + dayMs){
      return 'disabled';
    } else if (this.isDateInArray(this.disabledDates, date)) {
      return 'disabled';
    } else if (this.isDateInArray(this.consultations, date)) {
      if (this.courses && this.isDateInArray(this.courses, date)) {
        return 'courseOrConsult';
      } else {
        return 'consultation';
      }
    } else if (this.isDateInArray(this.events, date)) {
      return 'event';
    } else if (this.isDateInArray(this.courses, date)) {
      return 'course';
    } else if (this.section === 'modal-reserve') {
      if (['course', 'consultation'].includes(this.type)) {
        return 'disabled';
      }
    }
    return result;
  }

  private isDateInArray = function(array, date) {
    if (array && array.length) {
      // console.log(array.map(item => this.dropTime(item)))
      // console.log('DAAATEEE',date)
      let shortArr = array.filter(item => Math.abs(item - date) < dayMs);
      return shortArr.map(item => this.dropTime(item)).indexOf(date) + 1;
    } else {
      return false;
    }
  }

  isMasterInArr(master, arr) {
    return arr.some(item => item.master.id === master.id);
  }

}
