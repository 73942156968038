import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ConsultationsService } from '../../services/consultations.service';
import { UserService } from '../../services/user.service';
import { EquipmentsService } from '../../services/equipments.service';
import { CurrentEventService } from '../../services/current-event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { getCurrentMoscowTime } from '../../utils';
import { forkJoin } from 'rxjs';

@Component({
  selector: 'app-modal-consultation',
  templateUrl: './modal-consultation.component.html',
  styleUrls: ['./modal-consultation.component.styl']
})
export class ModalConsultationComponent implements OnInit {
  consultParams;
  consultLoading;
  userLoading;
  paymentLoading;
  user;
  error;
  isToday;
  currentRoute;
  passed;
  miniCoursesList;
  miniCoursesShow:boolean;
  constructor(private modalService: ModalService,
              private consultationsService: ConsultationsService,
              private activatedRoute: ActivatedRoute,
              private currentEventService: CurrentEventService,
              private userService: UserService,
              private equipmentsService: EquipmentsService,
              private router: Router) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    if (this.activatedRoute.snapshot.queryParams.consultation) {
      this.consultLoading = true;
      this.consultationsService.getOrderedConsultationById(this.activatedRoute.snapshot.queryParams.consultation).subscribe((data) => {
        this.consultParams = data.result;
        this.passed = new Date(this.consultParams.start_time) < new Date(getCurrentMoscowTime());
        let date = new Date(data.result.start_time).setHours(0,0,0,0);
        this.isToday = (date === new Date().setHours(0,0,0,0) || date === new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0));
        this.consultLoading = false;
        this.userLoading = true;
        forkJoin(this.userService.getUserData(data.result.user_id), this.equipmentsService.getUsersMiniCourses(data.result.user_id)).subscribe((data) => {
          this.userLoading = false;
          this.user = data[0].result;
          this.miniCoursesList = data[1].result;
        }, () => {
          this.userLoading = false;
          this.error = true;
        })
      })
    } else {
      this.error = true;
    }
  }

  closeModal() {
    this.modalService.onClose();
  }

  back() {
    this.modalService.back();
  }

  showCourses() {
    this.miniCoursesShow = !this.miniCoursesShow;
  }

}
