import { Pipe } from '@angular/core';

@Pipe({
  name: 'numberCase'
})

export class NumberCasePipe {

  declOfNum(number, titles) {
    let cases = [2, 0, 1, 1, 1, 2];
    number = Math.abs(number);
    if (number === 0 || number === '0') {
      return titles[2];
    } else if (Math.round(number) === +number) {
      if (+number < 1) {
        return titles[1];
      }
      number = Math.round(Math.abs(number));
      return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
    } else {
      return titles[1];
    }
  }

  transform(value: any, type: any): any {
    if (type === 'hours') {
      return this.declOfNum(value, ['час', 'часа', 'часов']);
    } else if (type === 'days') {
      return this.declOfNum(value, ['день', 'дня', 'дней']);
    } else if (type === 'sites') {
      return this.declOfNum(value, ['место', 'места', 'мест']);
    } else if (type === 'answer') {
      return this.declOfNum(value, ['ответ', 'ответа', 'ответов']);
    } else if (type === 'minutes') {
      return this.declOfNum(value, ['минута', 'минуты', 'минут']);
    } else if (type === 'minutes2') {
      return this.declOfNum(value, ['минуту', 'минуты', 'минут']);
    }else if (type === 'notifications') {
      return this.declOfNum(value, ['уведомление', 'уведомления', 'уведомлений']);
    } else if (type === 'consultation') {
      return this.declOfNum(value, ['консультация', 'консультации', 'консультаций']);
    } else if (type === 'course') {
      return this.declOfNum(value, ['курс', 'курса', 'курсов']);
    } else if (type === 'mc') {
      return this.declOfNum(value, ['мастер-класс', 'мастер-класса', 'мастер-классов']);
    } else if (type === 'ideas') {
      return this.declOfNum(value, ['идея', 'идеи', 'идей']);
    } else if (type === 'planedM') {
      return this.declOfNum(value, ['запланирован', 'запланировано', 'запланировано']);
    } else if (type === 'planedF') {
      return this.declOfNum(value, ['запланирована', 'запланировано', 'запланировано']);
    } else if (type === 'break') {
      return this.declOfNum(value, ['поломка', 'поломки', 'поломок']);
    } else if (type === 'equipment') {
      return this.declOfNum(value, ['оборудование', 'оборудования', 'оборудований']);
    } else if (type === 'clients') {
      return this.declOfNum(value, ['клиента', 'клиентов', 'клиентов']);
    }
  }
}
