import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-user-short',
  templateUrl: './user-short.component.html',
  styleUrls: ['./user-short.component.styl']
})
export class UserShortComponent implements OnInit {
  @Input() user;
  @Input() small;
  @Input() noName;
  @Input() parentSize;
  @ViewChild('image') userPic: ElementRef;
  constructor(private userService: UserService) { }

  ngOnInit() {

  }

}
