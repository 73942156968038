import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class StatisticsService {
  constructor(private api: ApiService) {}

  getPresenseStatistics(startDate, endDate) {
    return this.api.get('v1/superadmin/statistic/presence', {startDate, endDate});
  }

  getUsersStatistics() {
    return this.api.get('v1/superadmin/statistic/users');
  }

  getEquipmentsStatistics(startDate, endDate) {
    return this.api.post('v1/superadmin/statistics/equipments', {start_date: startDate, end_date: endDate});
  }

  getEquipmentsStatisticsEmployment() {
    return this.api.get('v1/superadmin/statistics/equipments/employment');
  }

  getEquipmentsStatisticsTable(filter) {
    return this.api.post('v1/superadmin/statistics/equipments/table', filter);
  }

  getMastersStatistics() {
    return this.api.get('v1/superadmin/statistic/masters');
  }

  getFullMastersStatistics(model) {
    return this.api.get('v1/superadmin/statistic/masters/full', model);
  }

  getConsultationsStatistics(area, model) {
    return this.api.post('v1/superadmin/statistics/consultations', model, area ? { area } : null);
  }

  getCoursesStatistics(model, area) {
    return this.api.post('v1/superadmin/statistics/courses', model, area ? { area } : null);
  }

  getEventsStatistics(model) {
    return this.api.post('v1/superadmin/statistics/events', model);
  }

  getStorageSimpleStatistics() {
    return this.api.get('v1/superadmin/statistics/storage/statistic/simple');
  }

  getStorageStatisticsList(dateFrom, dateTo) {
    return this.api.post('v1/superadmin/statistics/storage/statistic/list', null, { dateFrom, dateTo });
  }

  getRevenueStatistic() {
    return this.api.get('v1/superadmin/statistic/revenue');
  }

  getRevenueStatisticArea(date) {
    return this.api.get('v1/superadmin/statistic/revenue/area', date);
  }

  getRevenueStatisticFull(date) {
    return this.api.get('v1/superadmin/statistic/revenue/full', date);
  }

  downloarRevenueStatistic(date) {
    return this.api.get('v1/superadmin/statistic/revenue/download', date);
  }

}
