import { Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormBuilder } from '@angular/forms';
import { CreationService } from '../../services/creation.service';
import { FileUploadService } from '../../services/file-upload.service';
import { AdminService } from '../../services/admin.service';
import { UserService } from '../../services/user.service';
import { parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.styl']
})
export class CreateAccountComponent implements OnInit {

  @ViewChild('mainPhotoPicker') mainPhotoPicker;
  creationLoading;
  loadingState: any;
  errorMessage;
  clientForm: FormGroup;

  constructor(private creationService: CreationService,
              private fb: FormBuilder,
              private adminService: AdminService,
              private fileUploadService: FileUploadService,
              private userService: UserService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.clientForm = this.fb.group({
      serviceCard: ['', [Validators.minLength(17), Validators.maxLength(17)]],
      name: ['', [Validators.required, Validators.pattern(/^[a-zA-Zа-яА-ЯёЁ][a-zA-Zа-яА-ЯёЁ0-9-_\.]{1,20}$/)]],
      lastName: ['', [Validators.required, Validators.pattern(/^[a-zA-Zа-яА-ЯёЁ][a-zA-Zа-яА-ЯёЁ0-9-_\.]{1,20}$/)]],
      phone: ['', [Validators.required, Validators.minLength(11)]],
      email: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/)]],
      image: [null]
    });
  }

  phoneMask = (rawValue) => {
    if (rawValue && (rawValue[0] === '+' || rawValue[0] === '7')) {
      return ['+', /7/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    } else {
      return [/8/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
    }
  }

  modalClose() {
    this.creationService.closeCreationModal();
  }

  submit() {
    this.validPhoneNumber();
    if(!this.clientForm.valid) {
      this.markInvalidFields();
    } else {
      if (this.clientForm.value.image) {
        this.uploadImage(this.clientForm.controls.image);
      } else {
        this.sendForm();
      }
    }
  }

  markInvalidFields() {
    const controls = this.clientForm.controls;
    Object.keys(controls)
    .forEach(controlName => controls[controlName].markAsTouched());
  }

  getModel() {
    let model: any = {};
    if (this.clientForm.value.serviceCard) {
      model.service_card = this.clientForm.value.serviceCard;
    }
    if (this.clientForm.value.name) {
      model.first_name = this.clientForm.value.name;
    }
    if (this.clientForm.value.lastName) {
      model.last_name = this.clientForm.value.lastName;
    }
    if (this.clientForm.value.phone) {
      model.phone = this.getPhoneNumber();
    }
    if (this.clientForm.value.email) {
      model.email = this.clientForm.value.email.toLowerCase();
    }
    if (this.clientForm.value.space) {
      model.area = this.clientForm.value.space;
    }
    if (this.clientForm.value.image && this.clientForm.value.image.fileId) {
      model.photo_id = this.clientForm.value.image.fileId
    }
    model.language = 'RU-ru';
    model.user_role = 'CLIENT';
    return model;
  }

  sendForm() {
    this.loadingState = 2;
    this.adminService.createAccount(this.getModel()).subscribe((data) => {
      this.loadingState = 3;
      this.userService.update();
      this.modalClose();
      this.messagesService.showMessage('Клиент успешно добавлен.', null, true, true);
    }, (e) => {
      this.loadingState = 'error'
      this.errorMessage = 'Ошибка создания аккаунта';
      let error = parseApiError(e);
      console.log(error);
      if (error.code === 'already_registered' && error.field === 'service_card') {
        this.errorMessage = 'Пользователь с такой сервисной картой уже существует';
      }
      if (error.code === 'already_registered' && error.field === 'phone') {
        this.errorMessage = 'Пользователь с таким телефоном уже существует';
      }
      if (error.code === 'already_registered' && error.field === 'email') {
        this.errorMessage = 'Пользователь с таким email уже существует';
      }
    })
  }

  private uploadImage = function(file) {
    if (file.value) {
      this.loadingState = 1;
      file.loading = true;
      file.hasErr = false;
      this.fileUploadService.uploadFile(file.value, 'PROFILE').subscribe((data) => {
        file.value.fileUrl = data.result.url;
        file.value.fileId = data.result.id;
        file.loaded = true;
        file.loading = false;
        this.sendForm();
      }, () => {
        file.hasErr = true;
        file.loading = false;
        this.loadingState = 'error'
        this.errorMessage = 'Ошибка загрузки файла';
      });
    }
  }

  private validPhoneNumber() {
    let phoneNumber = this.getPhoneNumber();

    if (phoneNumber.length < 11) {
      this.clientForm.controls.phone.setErrors({ incorrect: 'true' })
    }
  }

  private getPhoneNumber(): string {
    if (this.clientForm.value.phone) {
      return this.clientForm.value.phone.replace(/\D+/g, '');
    } else {
      return '';
    }
  }
}
