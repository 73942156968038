import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CoursesService {
  updateSubject;
  constructor(private api: ApiService) {

  }

  create(model) {
    return this.api.post('v1/courses/new', JSON.stringify(model));
  }

  edit(courseId, model) {
    return this.api.post(`v1/courses/${courseId}/edit`, JSON.stringify(model));
  }

  deleteCourse(courseId) {
    return this.api.delete(`v1/courses/${courseId}/delete`);
  }

  getCoursesList(filter) {
    return this.api.post('v2/courses/list', JSON.stringify(filter));
  }

  getAdditional(ids) {
    return this.api.get('v1/courses/list/additional', {courseIds: ids});
  }

  getCourse(courseId) {
    return this.api.get(`v1/courses/${courseId}`);
  }

  getComments(courseId) {
    return this.api.get(`v1/courses/${courseId}/comments`);
  }

  sendComment(courseId, model) {
    return this.api.post(`v1/courses/${courseId}/comments`, JSON.stringify(model));
  }

  sendReply(courseId, commentId, model) {
    return this.api.post(`v1/courses/${courseId}/comments/${commentId}/reply`, JSON.stringify(model));
  }

  deleteComment(courseId, commentId) {
    return this.api.delete(`v1/courses/${courseId}/comments/${commentId}`)
  }

  likeComment(courseId, commentId, value) {
    return this.api.post(`v1/courses/${courseId}/comments/${commentId}/like`, undefined, { favorite: value });
  }

  getMasters(model = {}) {
    return this.api.get(`v1/users/makers`, model);
  }

  register(courseId, model) {
    return this.api.post(`v1/courses/${courseId}/registration`, JSON.stringify(model));
  }

  cancel(courseId) {
    return this.api.post(`v1/courses/${courseId}/registration/cancel`, null);
  }

  move(courseId, timingId, byCertificate = false) {
    return this.api.post(`v1/courses/${courseId}/registration/change`, undefined, { courseTimingId: timingId, byCertificate });
  }

  getRegisteredUsers(courseId, courseTimingId) {
    return this.api.get(`v1/courses/${courseId}/${courseTimingId}/users`);
  }

  checkUserRegistered(courseId, userId) {
    return this.api.get(`v1/courses/${courseId}/registered`, {userId});
  }

  paid(courseId, courseTimingId, model) {
    return this.api.post(`v1/courses/${courseId}/${courseTimingId}/users/paid`, undefined, model);
  }

  attend(courseId, courseTimingId, userId, attended) {
    return this.api.post(`v1/courses/${courseId}/${courseTimingId}/users/attend`, undefined, { userId, attended });
  }

  cancelCourse(courseId, courseTimingId, model) {
    return this.api.post(`v1/courses/${courseId}/${courseTimingId}/cancel`, model);
  }

  getAdditionalTimings(courseIds) {
    return this.api.get(`v1/courses/list/additional/timings`, { courseIds });
  }

}
