import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'floor'
})
export class FloorPipe implements PipeTransform {

  transform(value: any): any {
    if (value && !isNaN(value)) {
      return Math.floor(value);
    } else {
      return 0;
    }
  }
}
