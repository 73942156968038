import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'price'
})
export class PricePipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (value && +value) {
      if (args && Array.isArray(args) ? args.indexOf('abs') + 1 : args === 'abs') {
        value = Math.abs(value);
      }
      let len = value.toString().length;
      let catnum = Math.floor(len / 3);
      let arr = value.toString().split('');
      for (let i = 0; i < catnum; i++) {
        arr.splice(len - 3 * (i + 1) , 0, '\u00A0');
      }
      return arr.join('').trim();
    } else {
      return value;
    }
  }

}
