import { Pipe } from '@angular/core';

@Pipe({
  name: 'formatBytes'
})
export class FormatBytesPipe {
  private bytesToSize = function(bytes) {
    const sizes = ['Б', 'Кб', 'Мб', 'Гб', 'Тб']
    if (bytes === 0) return 'n/a'
    const i = parseInt(""+Math.floor(Math.log(bytes) / Math.log(1024)), 10)
    if (i === 0) return `${bytes} ${sizes[i]}`
    return `${(bytes / (1024 ** i)).toFixed(0)} ${sizes[i]}`
  }
  transform(value: any, args?: any): any {
    return this.bytesToSize(value);
  }

}
