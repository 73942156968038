import { Component, OnInit, Input, ChangeDetectorRef, Inject, PLATFORM_ID } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { AuthService } from '../../services/auth.service';
import { TokenService } from '../../services/token.service';
import { UserService } from '../../services/user.service';
import { Router, ActivatedRoute } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { phoneMask, parseApiError } from '../../utils';
import { first } from 'rxjs/operators';
import { MessagesService } from '../../services/messages.service';
import { encodeBase64 } from '../../utils';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.styl']
})
export class SignupComponent implements OnInit {
  @Input() titleText = 'Добро пожаловать на Фабрику Leroy Merlin!';
  @Input() inAction;
  errorMessage;
  googleLink;
  fewlinesLink;
  vkLink;
  w;
  currentRoute;
  currentState;
  isBrowser;
  phoneMask = phoneMask;
  public signupForm: FormGroup;
  confirmPhonePage: boolean;
  confirmPhoneForm: FormGroup;
  setCode;
  signinQueryParams;
  constructor(public modalService: ModalService,
              private fb: FormBuilder,
              private authService: AuthService,
              private userService: UserService,
              private tokenService: TokenService,
              private messageService: MessagesService,
              private _ref: ChangeDetectorRef,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              @Inject(PLATFORM_ID) platformId: string,
              @Inject('API_ROOT_URL') private apiRootUrl: string,
              @Inject('API_ROOT_URL_HTTP') private apiRootUrlHttp: string,
              @Inject('HOSTNAME') private hostName: string,
              @Inject('VK_CLIENT_ID') private authVkClientId: string,
              @Inject('FEWLINES_CLIENT_ID') private fewlinesClientId: string,
              @Inject('GOOGLE_CLIENT_ID') private authGoogleClientId: string) {
                  this.isBrowser = isPlatformBrowser(platformId);
                  if (this.isBrowser) {
                    this.w = window;
                  }
                  this.signupForm = this.fb.group({
                    login: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/)]],
                    password: ['', [Validators.required]],
                    phone: ['', [Validators.required, Validators.minLength(11)]],
                    agree: ['', [Validators.required]]
                  });
                  // this.confirmPhoneForm = this.fb.group({
                  //   phone: ['', [Validators.required, Validators.minLength(11)]],
                  //   agree: ['', [Validators.requiredTrue]],
                  //   code: ['', [Validators.required]]
                  // })
                }

  ngOnInit() {
    this.hostName = this.hostName.slice(0, -1);
    this.currentRoute = this.router.url.split('?')[0];
    let queryParams = {...this.activatedRoute.snapshot.queryParams};
    delete queryParams.popup;
    this.signinQueryParams = { ...queryParams, popup: 'login' };
    let index = 0;
    let paramsString = '';
    for (let key in queryParams) {
      if (index === 0) {
        paramsString += `?${key}=${queryParams[key]}`;
      } else {
        paramsString += `&${key}=${queryParams[key]}`;
      }
      index++;
    }
    this.currentState = encodeURIComponent(encodeBase64(this.hostName + this.currentRoute + paramsString));
    this.initSocialLinks();
    this.signupForm.valueChanges.subscribe(val => {
      this.errorMessage = false;
    });
    if (this.isBrowser) {
      let regData = localStorage.getItem('lm_user_reg_data') && JSON.parse(localStorage.getItem('lm_user_reg_data'));
      if (regData) {
        this.setRegData(regData);
      }
      this.deleteRegData();
    }
  }

  signUp() {
    this.authService.register(this.signupForm.value.login.toLowerCase(), this.signupForm.value.password, this.signupForm.value.phone.split(' ').join('').trim())
      .pipe(first())
      .subscribe((data: any) => {
        let subscription = this.authService.initUser(data.result.token.userId, data.result.token.authToken).subscribe((data) => {
          if (data) {
            this.errorMessage = 'Ошибка загрузки данных пользователя';
            subscription.unsubscribe();
          } else {
            this.modalService.nextPage(null, null);
            setTimeout(() => {
              if (!this.userService.userData.phone_confirmed) {
                this.router.navigate(['/confirm-phone']);
              }
            })
            subscription.unsubscribe();
            setTimeout(_ => {
              this.messageService.showMessage('Добро пожаловать на Фабрику Леруа Мерлен!', null, 'smile', true);
            }, 350);
          }
        })
      }, (e) => {
        let error = parseApiError(e);
        if (error.code) {
          error = error.code;
        }
        console.log(error);
        if (error === 'user-not-found' || error === 'wrong-password') {
          this.errorMessage = 'Неверный логин или пароль';
        } else if (error === 'duplicate-email') {
          this.errorMessage = 'Этот email уже используется';
        } else if (error === 'duplicate-phone') {
          this.errorMessage = 'Этот телефон уже используется';
        } else {
          this.errorMessage = 'Ошибка';
        }
      })
  }

  saveRegData() {
    let values = JSON.stringify(this.signupForm.value);
    localStorage.setItem('lm_user_reg_data', values);
  }

  setRegData(data) {
    data.login && this.signupForm.controls.login.setValue(data.login);
    data.password && this.signupForm.controls.password.setValue(data.password);
    data.phone && this.signupForm.controls.phone.setValue(data.phone);
    data.agree && this.signupForm.controls.agree.setValue(data.agree);
  }

  deleteRegData() {
    localStorage.removeItem('lm_user_reg_data');
  }

  initSocialLinks() {
    let fewlinesClientIdEncoded = encodeURIComponent(this.fewlinesClientId);
    let fewlinesRedirectUriEncoded = encodeURIComponent(`${this.apiRootUrl}oauth/callback`);
    this.fewlinesLink = `https://leroy-merlin-provider.prod.connect.eu-west-3.k8s.fewlines.net/oauth/authorize?client_id=${fewlinesClientIdEncoded}&response_type=code&redirect_uri=${fewlinesRedirectUriEncoded}&state=${this.currentState}`;
    this.googleLink = `https://accounts.google.com/o/oauth2/v2/auth?scope=email profile&redirect_uri=${this.apiRootUrl}google/callback/code&response_type=code&state=${this.currentState}&client_id=${this.authGoogleClientId}`;
    this.vkLink = `https://oauth.vk.com/authorize?client_id=${this.authVkClientId}&display=page&redirect_uri=${this.apiRootUrl}vk/callback/code&scope=email&response_type=code&v=5.73&state=${this.currentState}`;
  }

  facebook() {
    let that = this;
    that.modalService.setModalLoader(true);
    this.w.FB.login(function(response){
      that.userService.facebook(response.authResponse.accessToken).subscribe((data) => {
        that._ref.detectChanges();
        that.authService.initUser(data.result.userId, data.result.authToken).pipe(first()).subscribe((data) => {
          if (data) {
            that.tokenService.deleteToken();
            that.errorMessage = 'Ошибка загрузки данных пользователя';
            that.modalService.setModalLoader(false);
            setTimeout(() => {
              that._ref.detectChanges();
            })
          } else {
            that.modalService.setModalLoader(false);
            setTimeout(() => {
              that._ref.detectChanges();
              that.modalService.nextPage(null, null);
              setTimeout(() => {
                if (!this.userService.userData.phone_confirmed) {
                  this.router.navigate(['/confirm-phone']);
                }
              })
            })
          }
        })
      }, () => {
        that.modalService.setModalLoader(false);
        that._ref.detectChanges();
      })
    }, {scope: 'email'});

  }
  // next() {
  //   this.router.navigate([this.router.url.split('?')[0]], {
  //     queryParams: {
  //       popup: 'confirm-phone'
  //     }
  //   });

  // }
}
