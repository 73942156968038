import {Injectable} from '@angular/core';
import * as JwtDecode from 'jwt-decode';
import {makeStateKey, StateKey, TransferState} from '@angular/platform-browser';
import {IsBrowserService} from './is-browser.service';
import {CookiesService} from '@ngx-utils/cookies';

const TEMP_PASSWORD_STATE_KEY = makeStateKey('temp_pwd');
const TOKEN_STATE_KEY = makeStateKey('token');

@Injectable({
  providedIn: 'root'
})

export class TokenService {
  private token: string;
  private tempPassword: object;

  constructor(private cookies: CookiesService,
              private state: TransferState,
              private isBrowserService: IsBrowserService) {

    // CR-4388 migration to new 'token' storage mechanism, localStorage and TransferState
    if (this.cookies.get('temp_password')) {
      this.setItem(TEMP_PASSWORD_STATE_KEY, this.cookies.get('temp_password'));
      this.cookies.remove('temp_password');
    }
    if (this.cookies.get('token')) {
      this.setItem(TOKEN_STATE_KEY, this.cookies.get('token'));
      this.cookies.remove('token');
    }

    this.token = this.getItem(TOKEN_STATE_KEY, null);
    this.tempPassword = this.getTempPassword();
  }

  private getItem(key: StateKey<any>, defaultValue: any): any {
    const stateValue = this.state.get(key, undefined);
    if (stateValue === undefined && this.isBrowserService.isBrowser) {
      const localStorageValue = localStorage.getItem(key.toString());
      if (localStorageValue) {
        this.state.set(key, localStorageValue);
      }
      return localStorageValue ? localStorageValue : defaultValue;
    }
    return stateValue ? stateValue : defaultValue;
  }

  private setItem(key: StateKey<any>, value: any): void {
    this.state.set(key, value);
    if (this.isBrowserService.isBrowser) {
      localStorage.setItem(key.toString(), value);
    }
  }

  private deleteItem(key: StateKey<any>): void {
    this.state.remove(key);
    if (this.isBrowserService.isBrowser) {
      localStorage.removeItem(key.toString());
    }
  }

  private getTempPassword(): object {
    const tempPassword = this.getItem(TEMP_PASSWORD_STATE_KEY, null);
    if (tempPassword && tempPassword !== 'undefined') {
      try {
        return JSON.parse(tempPassword);
      } catch (e) {
        console.error('can not parse json tempPassword, skipping');
        return null;
      }
    }
    return null;
  }

  setToken(data) {
    this.token = data.authToken;
    if (this.token && this.token !== 'undefined') {
      this.setItem(TOKEN_STATE_KEY, this.token);
    }
    if (data.temp_password) {
      this.setTempPassword(data.temp_password);
    }
  }

  setTempPassword(isTemp) {
    this.tempPassword = isTemp;
    this.setItem(TEMP_PASSWORD_STATE_KEY, isTemp);
  }

  getUserIdFromToken(): string {
    if (this.token && this.token !== 'undefined') {
      try {
        const data = JwtDecode<string>(this.token);
        if (data) {
          return data['user_id'] as string;
        }
      } catch (e) {
        console.error('Can\'t parse token from server, not JWT.', this.token, e);
        return null;
      }
    }
    return null;
  }

  isTokenExist(): boolean {
    return !!this.token;
  }

  deleteToken(): void {
    this.token = null;
    this.tempPassword = null;
    this.deleteItem(TOKEN_STATE_KEY);
    this.deleteItem(TEMP_PASSWORD_STATE_KEY);
  }

  getToken(): string {
    return this.token || null;
  }

  getTempPwd(): object {
    return this.tempPassword;
  }
}
