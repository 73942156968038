import { Injectable, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { MessagesService } from './messages.service';
import { TranslitService } from './translit.service';

@Injectable({
  providedIn: 'root'
})
export class CopyTextService {
  isBrowser;
  constructor(private messagesService: MessagesService,
              private translitService: TranslitService,
              @Inject(PLATFORM_ID) platformId: string,
              @Inject('HOSTNAME') private hostname) {
                this.isBrowser = isPlatformBrowser(platformId);
              }

  copyUrl(type, id) {
    if (type === 'idea') {
      this.copyTextToClipboard(`${this.hostname}idei/${id}`, type);
    }
    if (type === 'event') {
      this.copyTextToClipboard(`${this.hostname}sobytiya/${id}`, type);
    }
    if (type === 'course') {
      this.copyTextToClipboard(`${this.hostname}master-klassy/${id}`, type);
    }
    if (type === 'equipment') {
      this.copyTextToClipboard(`${this.hostname}oborudovanie/${id}`, type);
    }
  }

  private copyTextToClipboard = function(text, type) {
    let types = {
      idea: 'идею',
      event: 'событие',
      course: 'мастер-класс'
    }
    if (this.isBrowser) {
      var textArea = document.createElement("textarea");
      textArea.style.position = 'fixed';
      textArea.style.top = '0';
      textArea.style.left = '0';
      textArea.style.width = '2em';
      textArea.style.height = '2em';
      textArea.style.padding = '0';
      textArea.style.border = 'none';
      textArea.style.outline = 'none';
      textArea.style.boxShadow = 'none';
      textArea.style.background = 'transparent';
      textArea.value = text;
      document.body.appendChild(textArea);
      textArea.select();
      try {
        var successful = document.execCommand('copy');
        var msg = successful ? 'successful' : 'unsuccessful';
        if (type === 'idea')
          this.messagesService.showMessage(`Ссылка на ${types[type]} скопирована`, null, false, true);
        else
          this.messagesService.showMessage(`Ссылка на ${types[type]} скопирована. Поделитесь ею с другом, чтобы он смог зарегистрироваться и прийти вместе с вами!`);
      } catch (err) {
        this.messagesService.showMessage('Не удалось скопировать ссылку', null, false, true)
      }
      document.body.removeChild(textArea);
    }
  }

}
