import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, tap, switchMap, map } from 'rxjs/operators';
import { EquipmentsService } from '../../services/equipments.service';
import { areaTypesEquipmentsShort } from '../../utils';

@Component({
  selector: 'app-equipment-search',
  templateUrl: './equipment-search.component.html',
  styleUrls: ['./equipment-search.component.styl']
})
export class EquipmentSearchComponent implements OnInit {
  @Input() filter: 'reserve';
  @Output() onSelect = new EventEmitter();

  focused;
  searchQuery;
  equipmentsList: any[] = [];
  equipmentsSubject$;
  equipmentsLoading;
  error;



  areas: any[] = areaTypesEquipmentsShort;
  filterModel: any = {
    page: 1,
    area: this.areas[0].value,
    limit: 5,
    query_str: '',
    hide_broken: true
  };

  constructor(private equipmentsService: EquipmentsService) { }

  ngOnInit() {
    this.equipmentsSubject$ = new Subject();
    this.equipmentsSubject$.pipe(debounceTime(300), tap(() => {
      this.equipmentsList = [];
      this.equipmentsLoading = true;
    }), switchMap((model:any) => this.equipmentsService.getEquipmentsList(model)))
      .subscribe(data => {
          this.error = false;
          this.equipmentsList = data.result.data;
          this.equipmentsLoading = false;
        }, () => this.error = true, () => this.equipmentsLoading = false
      )
    this.updateEquipments();
    // this.searchObs = new Subject();
    // let searchRes$ = this.searchObs
    //   .debounceTime(500)
    //   .filter(query => query.length >= 3)
    //   .subscribe(query => {
    //     this.query = query;
    //     this.searchEmitter.next();
    //   });
    //
    // this.searchEmitter
    //   .switchMap(() => {
    //     this.searchLoading = true;
    //     const params: any = {
    //       query_str: this.query,
    //     };
    //
    //     if (this.filterArea) {
    //       params.area = this.filterArea;
    //     }
    //
    //     return this.equipmentsService.getEquipmentsList(params);
    //   })
    //   .map(data => {
    //     if (this.filter && this.filter === 'reserve') {
    //       return data.result.filter(instrument => {
    //         return instrument.free_access === false;
    //       });
    //     } else {
    //       return data.result;
    //     }
    //   })
    //   .subscribe(result => {
    //       this.popupOpened = true;
    //       this.searchLoading = false;
    //       this.searchResults = result;
    //     }, () => {
    //       this.searchLoading = false;
    //     });
  }

  selectEquipment(event) {
    this.onSelect.emit(event);
  }

  filterChange(filter) {
    if (filter.paramName === 'query') {
      if (filter.paramValue) {
        this.filterModel.query_str = filter.paramValue;
      } else {
        delete this.filterModel.query_str;
      }
    }
    if (filter.paramName === 'area') {
      this.filterModel.area = filter.paramValue;
    }
    if (!filter.stop) {
      this.updateEquipments();
    }
  }

  clearField() {
    this.searchQuery = '';
    this.onInput();
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  onInput() {
    this.filterChange({paramName: 'query', paramValue: this.searchQuery});
  }

  updateEquipments() {
    this.equipmentsSubject$.next(this.filterModel);
  }
  //
  // onSubmit() {
  // }
  //
  // clearField() {
  //   this.searchQuery = '';
  //   this.closeSearchPopup();
  // }
  //
  // onFocus() {
  //   this.focused = true;
  // }
  //
  // onBlur() {
  //   this.focused = false;
  // }
  //
  // onInput() {
  //   this.searchObs.next(this.searchQuery);
  //   if (this.searchQuery.length < 3) {
  //     this.popupOpened = false;
  //   }
  // }

  // closeSearchPopup() {
  //   this.popupOpened = false;
  //   this.searchResults = null;
  // }

  // handleSelect(item) {
  //   this.onSelect.next(item);
  //   this.searchQuery = item.name;
  //   this.closeSearchPopup();
  // }
  //
  // setFilterArea(value) {
  //   this.filterArea = value;
  //   this.searchEmitter.next();
  // }
}
