import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-register-user-to',
  templateUrl: './register-user-to.component.html',
  styleUrls: ['./register-user-to.component.styl']
})
export class RegisterUserToComponent implements OnInit {
  @Input() userId;
  popupOpened;
  currentRoute;
  constructor(private router: Router) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
  }

  openPopup() {
    this.popupOpened = true;
  }

  closePopup() {
    this.popupOpened = false;
  }

  togglePopup() {
    if (this.popupOpened) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }


}
