import {Component, OnInit} from '@angular/core';
import {ModalService} from '../../services/modal.service';
import {UserService} from '../../services/user.service';
import {SubscriptionsService} from '../../services/subscriptions.service';
import {ActivatedRoute} from '@angular/router';
import {RESERVE_END_TIME, RESERVE_START_TIME, SUBSCRIPTION_DAY_DEFAULT_PRICE, SUBSCRIPTION_HOUR_DEFAULT_PRICE} from '../../config';

@Component({
  selector: 'app-modal-extend-subscription',
  templateUrl: './modal-extend-subscription.component.html',
  styleUrls: ['./modal-extend-subscription.component.styl']
})
export class ModalExtendSubscriptionComponent implements OnInit {
  extendLoading;
  paymentLoading;
  durationUnit = 'hours';
  amount = 1;
  user;
  userId;
  userDataLoading;
  isOpenConfirm;
  schedule = `${RESERVE_START_TIME}:00-${RESERVE_END_TIME}:00`;
  currentTime: any = {
    hours: 0,
    days: 0
  };
  totalTime: any = {
    hours: 0,
    days: 0
  };
  totalPrice;
  subscriptionPrice = {hour: SUBSCRIPTION_HOUR_DEFAULT_PRICE, day: SUBSCRIPTION_DAY_DEFAULT_PRICE};
  haveSubscription;
  isMe;
  isError;

  constructor(private modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private subscriptionsService: SubscriptionsService) {
  }

  ngOnInit() {
    this.userId = this.activatedRoute.snapshot.queryParams.user || this.userService.userId;
    if (this.userId) {
      this.userDataLoading = true;
      this.userService.getUserData(this.userId).subscribe(data => {
        this.userDataLoading = false;
        this.user = data.result;
        this.isMe = this.user.id == this.userService.userId;
        this.haveSubscription = this.user.service_card;

        if (this.user.subscription_days) {
          this.currentTime.days = this.user.subscription_days;
        }
        if (this.user.subscription_hours) {
          this.currentTime.hours = this.user.subscription_hours;
        }
        this.initTotalPrice();
      });
    }

    this.subscriptionsService.price().subscribe((data) => {
      this.subscriptionPrice = {
        hour: data.result.hour_price,
        day: data.result.day_price
      };
    }, (error) => {
      console.log('subscription price failed ', error);
    });
  }

  updateAmount(e) {
    this.amount = e;
    this.initTotalPrice();
  }

  initTotalPrice() {
    if (this.durationUnit === 'hours') {
      this.totalPrice = this.amount * this.subscriptionPrice.hour;
      this.totalTime.hours = this.currentTime.hours + this.amount;
      this.totalTime.days = this.currentTime.days;

    } else if (this.durationUnit === 'days') {
      this.totalPrice = this.amount * this.subscriptionPrice.day;
      this.totalTime.days = this.currentTime.days + this.amount;
      this.totalTime.hours = this.currentTime.hours;
    }
  }

  extend(payNow, paid?) {

    if (this.isMe || this.isOpenConfirm) {
      let model = {
        amount: this.amount,
        subscription_type: this.durationUnit === 'hours' ? 'HOURLY' : 'DAILY',
        payment_type: payNow ? 'ONLINE' : 'ON_THE_SPOT',
        user_id: this.userId
      };
      this.isError = false;
      this.paymentLoading = true;
      this.subscriptionsService.newSubscription(model).subscribe((data) => {
        if (paid) {
          this.subscriptionsService.paid(data.result.id, true).subscribe(() => {
            this.paymentLoading = false;
            this.userService.update();
            this.modalService.nextPage({
              extSubscriptionParams: {
                subscriptionTime: this.amount,
                subscriptionTimeUnit: this.durationUnit,
                subscriptionPrice: this.totalPrice,
                subscriptionTotalTime: this.totalTime,
                userId: !this.isMe
              }, extSubscriptionPaid: payNow || paid
            }, null);
          }, () => {
            this.paymentLoading = false;
            this.isError = true;
          });
        } else {
          this.paymentLoading = false;
          this.userService.update();
          this.modalService.nextPage({
            extSubscriptionParams: {
              subscriptionTime: this.amount,
              subscriptionTimeUnit: this.durationUnit,
              subscriptionPrice: this.totalPrice,
              subscriptionTotalTime: this.totalTime,
              userId: !this.isMe
            }, extSubscriptionPaid: payNow || paid
          }, null);
        }
      }, () => {
        this.paymentLoading = false;
        this.isError = true;
      });
    } else {
      this.isOpenConfirm = true;
    }
  }

  cancelPay() {
    this.isOpenConfirm = false;
  }

}
