import {Component, OnInit} from '@angular/core';
import {CookiesService} from '@ngx-utils/cookies';
import {animate, style, transition, trigger} from '@angular/animations';
import {IsBrowserService} from '../../services/is-browser.service';

const HIDE_COOKIE_KEY = 'hide_cookies_alert';

@Component({
  selector: 'app-policy-footer',
  templateUrl: './policy-footer.component.html',
  styleUrls: ['./policy-footer.component.styl'],
  animations: [
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateY(100%)', opacity: 0}),
          animate('100ms', style({transform: 'translateY(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateY(0)', opacity: 1}),
          animate('100ms', style({transform: 'translateY(100%)', opacity: 0.5}))
        ])
      ]
    )
  ]
})
export class PolicyFooterComponent implements OnInit {
  showCookies = false;

  constructor(private cookies: CookiesService,
              private isBrowserService: IsBrowserService) {
    // CR-4388 migration to new 'hideCookiesAlert' storage mechanism - localStorage
    if (this.cookies.get('hideCookiesAlert') === 'true') {
      localStorage.setItem(HIDE_COOKIE_KEY, 'true');
      this.cookies.remove('hideCookiesAlert');
    }

    if (this.isBrowserService.isBrowser) {
      this.showCookies = localStorage.getItem(HIDE_COOKIE_KEY) !== 'true';
    }
  }

  ngOnInit() {
  }

  hideCookies() {
    if (this.isBrowserService.isBrowser) {
      localStorage.setItem(HIDE_COOKIE_KEY, 'true');
    }
    this.showCookies = false;
  }
}
