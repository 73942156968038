import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-master-add-menu',
  templateUrl: './master-add-menu.component.html',
  styleUrls: ['./master-add-menu.component.styl']
})
export class MasterAddMenuComponent implements OnInit {
  @Input() left;
  popupOpened;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  openPopup() {
    this.popupOpened = true;
  }

  closePopup() {
    this.popupOpened = false;
  }

  togglePopup() {
    if (this.popupOpened) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

  createIdea() {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {creation: 'idea'}});
  }
  createEvent() {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {creation: 'event'}});
  }
  createCourse() {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {creation: 'course'}});
  }
  createEquipment() {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {creation: 'equipment'}});
  }

}
