import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-select-month',
  templateUrl: './select-month.component.html',
  styleUrls: ['./select-month.component.styl']
})
export class SelectMonthComponent implements OnInit {
  @Input() monthsSwithOut: boolean = false;
  @Input() disablePast: boolean = false;
  @Input() disableFuture: boolean = false;
  @Input() noDefaultDate;
  @Output() prevMonthEmitter = new EventEmitter();
  @Output() nextMonthEmitter = new EventEmitter();
  @Output() onChangeEmitter = new EventEmitter();
  date;
  isCurrnetMonth;
  constructor() { }

  ngOnInit() {
    if (!this.noDefaultDate) {
      this.setDate(new Date().setDate(1));
    }
    // this.date = new Date(new Date().setDate(1));
  }

  nextMonth() {
    if (this.monthsSwithOut) {
      this.nextMonthEmitter.emit();
    } else {
      let nextMonth = this.date.getMonth() + 1;
      this.setDate(this.date.setMonth(nextMonth));
      // this.date = new Date(this.date.setMonth(nextMonth));
      this.onChangeEmitter.emit(this.date);
    }

  }

  prevMonth() {
    if (this.monthsSwithOut) {
      this.prevMonthEmitter.emit();
    } else {
      let prevMonth = this.date.getMonth() - 1;
      this.setDate(this.date.setMonth(prevMonth));
      // this.date = new Date(this.date.setMonth(prevMonth));
      this.onChangeEmitter.emit(this.date);
    }
  }

  setDate(date) {
    // alert(new Date(date))
    this.date = new Date(date);
    this.checkCurrentMonth();
  }

  checkCurrentMonth() {
    this.isCurrnetMonth = new Date(new Date(this.date).setDate(1)).setHours(0,0,0,0) === new Date(new Date().setDate(1)).setHours(0,0,0,0);
  }

}
