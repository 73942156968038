import { Component, OnInit } from '@angular/core';
import { FormControl, FormBuilder, Validators, ValidatorFn } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { EventsService } from '../../services/events.service';
import { EquipmentsService } from '../../services/equipments.service';
import { first } from 'rxjs/operators';

@Component({
  selector: 'app-modal-set-birthday',
  templateUrl: './modal-set-birthday.component.html',
  styleUrls: ['./modal-set-birthday.component.styl']
})
export class ModalSetBirthdayComponent implements OnInit {
  bdayLoading;
  getDataLoading;
  bdayForm;
  limitAge:number;
  type;
  setBday;
  age:number;
  today = new Date();
  eventName;
  equipmentName;
  constructor(private fb: FormBuilder,
              private userService: UserService,
              private modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private eventService: EventsService,
              private equipmentService: EquipmentsService) { }

  ngOnInit() {
    this.bdayForm = this.fb.group({
      day: ['', [Validators.required, this.dayValidator]],
      month: ['', [Validators.required, this.monthValidator]],
      year: ['', [Validators.required, Validators.pattern(/^\d{4}$/)]]
    });
    this.getUserAge();
  }

  getUserAge() {
    if (this.userService.userData.birth_date) {
      this.setBday = true;
      let age = this.userService.userData.birth_date;
      age = new Date(new Date(new Date(new Date().setDate(+age.substr(8,2))).setMonth(+age.substr(5,2))).setFullYear(+age.substr(0,4)));
      this.age = new Date(this.today.getTime() - age.getTime()).getFullYear() - 1970;
      if (this.age >= 18) {
        setTimeout(() => {
          this.modalService.nextPage(null, null);
        })
      } else this.getLimitAge();
    } else {
      this.getLimitAge();
    }
  }

  getLimitAge() {
    switch(this.activatedRoute.snapshot.queryParams.popup) {
      case 'enroll-course':
        this.limitAge = 18;
        this.type = 'course';
        this.getDataLoading = true;
        break;
      case 'enroll-event':
        this.type = 'event'
        this.eventService.getEvent(+this.activatedRoute.snapshot.queryParams.event).pipe(first()).subscribe((data) => {
          this.limitAge = data.result.age_limit;
          this.eventName = data.result.name;
          this.getDataLoading = true;
          if (this.age >= this.limitAge) {
            this.modalService.nextPage(null, null);
          }
        });
        break;
      case 'reserve-instrument':
        this.type = 'equipment';
        this.limitAge = 18;
        this.equipmentService.getEquipment(this.activatedRoute.snapshot.queryParams.instrument).pipe(first()).subscribe((data) => {
          this.equipmentName = data.result.name;
          this.getDataLoading = true;
        });
        break;
      case 'buy-subscription':
        this.type = 'subscription';
        this.limitAge = 18;
        this.getDataLoading = true;
        break;
      case 'choose-buy-subscription':
        this.type = 'subscription';
        this.limitAge = 18;
        this.getDataLoading = true;
        break;
    }
  }

  updateAge() {
    if (this.bdayForm && this.bdayForm.valid) {
      let bday = new Date(new Date(new Date(new Date().setDate(this.bdayForm.value.day)).setMonth(this.bdayForm.value.month-1)).setFullYear(this.bdayForm.value.year));
      this.age = new Date(this.today.getTime() - bday.getTime()).getFullYear() - 1970;
    }
  }

  submitBday() {
    this.bdayLoading = true;
    this.userService.updateUserData({
      birthDate: `${this.bdayForm.value.year}-${this.bdayForm.value.month}-${this.bdayForm.value.day}`
    }).subscribe((data: any) => {
      this.userService.setUserData(data.result);
      this.bdayLoading = false;
      setTimeout(() => {
        this.modalService.nextPage(null, null);
      })
    }, () => {
      this.bdayLoading = false;
    })
  }

  onMonthBlur() {
    if (this.bdayForm.value.month) {
      this.onBlur();
    }
  }

  onYearBlur() {
    if (this.bdayForm.value.year) {
      this.onBlur();
    }
  }

  onBlur() {
    this.safeReset(this.bdayForm.controls.day);
    if (this.bdayForm.value.day) {
      this.bdayForm.controls.day.markAsTouched();
    }
  }

  closeModal() {
    this.modalService.onClose();
  }
  private dayValidator: ValidatorFn = function(control: FormControl) {
    let maxDay = 31;
    if (this.bdayForm && this.bdayForm.value.month) {
      if ([1,3,5,7,8,10,12].indexOf(parseInt(this.bdayForm.value.month)) + 1) {
        maxDay = 31;
      } else if (parseInt(this.bdayForm.value.month) == 2) {
        if (this.bdayForm.value.year && !(+this.bdayForm.value.year % 4 === 0)) {
          maxDay = 28;
        } else {
          maxDay = 29;
        }
      } else {
        maxDay = 30;
      }
    }
    return (+control.value >= 1 && +control.value <= maxDay) ? null : {
      incorrect: 'true'
    }
  }.bind(this)

  private monthValidator: ValidatorFn = function(control: FormControl) {
    return (+control.value >= 1 && +control.value <= 12) ? null : {
      incorrect: 'true'
    }
  }.bind(this)

  private safeReset(control) {
    let val = control.value;
    control.reset();
    control.setValue(val);
  }
}
