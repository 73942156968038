import { Component, OnInit, Input, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { UserService } from '../../services/user.service';
import { notNull } from '../../utils';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'app-consultations-list-option',
  templateUrl: './consultations-list-option.component.html',
  styleUrls: ['./consultations-list-option.component.styl']
})
export class ConsultationsListOptionComponent implements OnInit {

  isOpenBox;
  currentRoute;
  @Input() consult;
  @Input() forMaster;
  @Input() top;
  @ViewChild(ClickOutsideDirective) clickOutside;
  constructor(private userService: UserService,
              private router: Router,
              private cancelRegistrationService: CancelRegistrationService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    if (!notNull(this.forMaster)) {
      this.forMaster = this.userService.userId === this.consult.master.id || this.userService.userData.role === 'SUPER_ADMIN';
    }
  }

  openBox() {
    this.isOpenBox = true;
    this.clickOutside.enable();
  }
  closeBox() {
    this.isOpenBox = false;
    this.clickOutside.disable();
  }

  cancelConsultRegistration() {
    this.closeBox();
    this.cancelRegistrationService.cancelConsult(this.consult);
  }
}
