import { Directive, ElementRef, Renderer, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Directive({
  selector: '[appTextPopup]'
})
export class TextPopupDirective implements OnInit {
  @Input() popupText;
  @Input() popupSide;
  @Input() elemHeight;
  isBrowser;
  popup;
  appearTimeout;
  hideTimeout;

  constructor(private el: ElementRef,
              private renderer: Renderer,
              @Inject(PLATFORM_ID) platformId: string) {
                this.isBrowser = isPlatformBrowser(platformId);
              }

  ngOnInit() {
    this.el.nativeElement.classList.add('text-popup-wrapper');
    this.el.nativeElement.onmouseover = () => {
      this.popup.style.display = 'block';
      this.popup.style.bottom = (this.elemHeight || (this.el.nativeElement.offsetHeight + 8)) + 'px';
      clearTimeout(this.hideTimeout);
      this.appearTimeout = setTimeout(() => {
        this.popup.classList.add('leroy-text-popup-active');
        if (this.popupSide === 'left') {
          this.popup.classList.add('leroy-text-popup-left');
        }
      }, 0);
    }
    this.el.nativeElement.onmouseout = () => {
      this.popup.classList.remove('leroy-text-popup-active');
      clearTimeout(this.appearTimeout);
      this.hideTimeout = setTimeout(() => {
        this.popup.style.display = 'none';
        this.popup.classList.remove('leroy-text-popup-left');
      }, 300);
    }
    if (this.isBrowser) {
      this.popup = document.createElement('div');
      this.popup.classList.add('leroy-text-popup');
      this.popup.innerText = this.popupText;
      this.popup.style.bottom = (this.elemHeight || (this.el.nativeElement.offsetHeight + 8)) + 'px';
      if (this.popupSide === 'left') {
        this.popup.style.right = '12px';
      } else {
        this.popup.style.left = '12px';
      }
      if (this.el.nativeElement.append) {
        this.el.nativeElement.append(this.popup);
      } else {
        this.el.nativeElement.appendChild(this.popup);
      }
    }
  }

}
