import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CoursesService } from '../../services/courses.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { MessagesService } from '../../services/messages.service';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { Router, ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
@Component({
  selector: 'app-modal-enroll-course',
  templateUrl: './modal-enroll-course.component.html',
  styleUrls: ['./modal-enroll-course.component.styl']
})
export class ModalEnrollCourseComponent implements OnInit {
  @Input() isMoving;
  courseLoading;
  paymentLoading;
  selectedTimeId;
  courseParams;
  registeredCourse;
  currentRoute;
  error;
  errorMessage = 'Ошибка';
  isSert: boolean;

  constructor(private modalService: ModalService,
              private router: Router,
              private coursesService: CoursesService,
              private activatedRoute: ActivatedRoute,
              private currentCourseService: CurrentCourseService,
              private messagesService: MessagesService,
              private cancelRegistrationService: CancelRegistrationService) { }

  ngOnInit() {
    this.modalService.steps
    this.currentRoute = this.router.url.split('?')[0];

    if (this.activatedRoute.snapshot.queryParams.course) {

      this.courseLoading = true;
      this.coursesService.getCourse(this.activatedRoute.snapshot.queryParams.course).pipe(first()).subscribe((data) => {
        this.courseParams = data.result;

        this.courseParams.course_timings = this.courseParams.course_timings
          .filter(item => item.status !== "PASSED")
          .sort((a, b) => {
            return a.start_date - b.start_date
          });

        this.courseLoading = false;
        this.initRegisteredCourse(this.courseParams);
        this.errorHandler(this.courseParams);
      }, () => {this.courseLoading = false})
    }
  }

  enrollWrapper() {
    if (this.isSert && !this.isMoving) {
      this.enroll(false, true);
    } else {
      this.moveCourse();
    }
  }

  enroll(payNow, withCert?) {
    let model = {
      course_timing_id: this.selectedTimeId,
      payment_type: withCert ? 'CERTIFICATE' : (payNow ? 'ONLINE' : 'ON_THE_SPOT')
    }
    this.coursesService.register(this.courseParams.id, model).pipe(first()).subscribe((data) => {
      this.currentCourseService.enrollSubject.next(this.courseParams.id);
      this.modalService.nextPage({coursePaid: payNow, hasSert: withCert , courseParams: this.courseParams, courseTimeParams: this.getCourseById(this.selectedTimeId)}, null);
    }, () => {
      this.error = true;
    })
  }

  moveCourse() {
    if (this.courseParams.id && this.selectedTimeId) {
      this.coursesService.move(this.courseParams.id, this.selectedTimeId, this.isSert).pipe(first()).subscribe((data) => {
        this.currentCourseService.enrollSubject.next(this.courseParams.id);
        this.modalService.nextPage({courseParams: this.courseParams, courseTimeParams: this.getCourseById(this.selectedTimeId), isMoving: true, coursePaid: this.courseParams.paid}, null);
      }, () => {
        this.error = true;
      })
    }
  }

  getCourseById(id) {
    return this.courseParams.course_timings.filter(item => item.id == id)[0];
  }

  initRegisteredCourse(course) {
    this.registeredCourse = course.course_timings.filter(item => item.registered)[0];
  }

  back() {
    this.modalService.back();
  }

  unregister() {
    this.modalService.onClose();
    this.cancelRegistrationService.cancelCourse(this.courseParams);
  }

  errorHandler(course) {
    if (course.entity_status === 'PASSED') {
      this.error = true;
      this.errorMessage = 'Этот мастер-класс уже прошел.'
    } else if (!this.isMoving && course.registered) {
      this.error = true;
      this.errorMessage = 'Вы уже записаны на этот мастер-класс.'
    } else if (this.isMoving && !course.registered) {
      this.error = true;
      this.errorMessage = 'Вы не зарегистрированы на этот мастер-класс.'
    }else if (!course) {
      this.error = true;
      this.errorMessage = 'Для этот мастер-класс недоступен.'
    }
  }

}
