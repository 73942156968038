import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class CertificatesService {

  constructor(private api: ApiService) { }

  getCurrentMonthCertificatesCount() {
    return this.api.get('v1/superadmin/statistic/certificates/count');
  }

  getCertificatesStatisticsList(model) {
    return this.api.get('v1/superadmin/statistic/certificates/list', model);
  }

  getUserCertificates(userId, model) {
    return this.api.get(`v1/superadmin/statistic/certificates/users/${userId}`, model);
  }
}
