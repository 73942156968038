import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { CoursesService } from '../../services/courses.service';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';
import { forkJoin } from "rxjs";
import { parseDateToString, getGreenwichDate } from '../../utils';

@Component({
  selector: 'app-courses-search',
  templateUrl: './courses-search.component.html',
  styleUrls: ['./courses-search.component.styl']
})
export class CoursesSearchComponent implements OnInit {
  @Output() onSelect = new EventEmitter();
  filterModel: any = {
    page: 1,
    month: new Date().getMonth(),
    year: new Date().getFullYear(),
    min_start_date_time: '',
    limit: 5,
    query_str: ''
  };
  focused;
  searchQuery;
  coursesList: any[] = [];
  coursesSubject$;
  coursesLoading;
  error;

  constructor(private coursesService: CoursesService) { }

  ngOnInit() {
    // new Date().setHours(new Date().getHours() + new Date().getTimezoneOffset()/60)
    this.filterModel.min_start_date_time = new Date().getTime();
    this.coursesSubject$ = new Subject();
    this.coursesSubject$.pipe(debounceTime(300), tap(() => {
      this.coursesList = [];
      this.coursesLoading = true;
    }), switchMap((model:any) => this.coursesService.getCoursesList(model)))
      .subscribe(data => {
          this.error = false;
          let courses:any = data.result.data;
          if (courses.length) {
            let ids = courses.map(item => item.id);
            this.coursesService.getAdditionalTimings(ids.join(',')).subscribe(timings => {
              this.coursesList = courses.map(item => {
                return {
                  ...item,
                  course_timings: timings.result[item.id].filter(time => time.status === 'PLANNED')
                }
              }).filter(item => item.course_timings && item.course_timings.length);
              this.coursesLoading = false;
            })
          } else {
            this.coursesList = [];
            this.coursesLoading = false;
          }
        }
      )
    this.updateCourses();
  }

  selectCourse(timing) {
    this.onSelect.emit(timing);
  }

  filterChange(filter) {
    if (filter.paramName === 'areas') {
      if (filter.paramValue.length) {
        this.filterModel.area = filter.paramValue;
      } else {
        delete this.filterModel.area;
      }
    } else if (filter.paramName === 'query') {
      if (filter.paramValue) {
        this.filterModel.query_str = filter.paramValue;
      } else {
        delete this.filterModel.query_str;
      }
    } else if (filter.paramName === 'min_start_time') {
      this.filterModel.min_start_time = filter.paramValue;
    } else if (filter.paramName === 'max_start_time') {
      this.filterModel.max_start_time = filter.paramValue;
    } else if (filter.paramName === 'weekends') {
      this.filterModel.weekends = filter.paramValue;
    } else if (filter.paramName === 'month') {
      this.filterModel.month = filter.paramValue;
    } else if (filter.paramName === 'year') {
      this.filterModel.year = filter.paramValue;
    } else if (filter.paramName === 'my_courses') {
      this.filterModel.my_courses = filter.paramValue;
    }
    if (!filter.stop) {
      this.updateCourses();
    }
  }

  clearField() {
    this.searchQuery = '';
    this.onInput();
  }

  onFocus() {
    this.focused = true;
  }

  onBlur() {
    this.focused = false;
  }

  onInput() {
    this.filterChange({paramName: 'query', paramValue: this.searchQuery});
  }

  updateCourses() {
    this.coursesSubject$.next(this.filterModel);
  }

}
