import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { UserService } from '../services/user.service';
import { Router } from '@angular/router';
@Injectable()
export class PhoneGuard implements CanActivate {
  constructor(private userService: UserService, private router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    this.userService.needConfirmPhone();
    if (this.userService.needConfirmPhone()) {
      this.router.navigate(['/confirm-phone']);
      return false;
    } else {
      return true;
    }
  }
}
