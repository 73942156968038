import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { spaces } from '../../utils';

@Component({
  selector: 'app-modal-consult-pay',
  templateUrl: './modal-consult-pay.component.html',
  styleUrls: ['./modal-consult-pay.component.styl']
})
export class ModalConsultPayComponent implements OnInit {
  @Input() consultPaid: boolean;
  @Input() consultParams: any;
  @Input() isMoving: any;
  spaces = spaces;

  constructor(public modalService: ModalService) { }

  ngOnInit() {
  }

}
