import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

@Component({
  selector: 'app-done-button',
  templateUrl: './done-button.component.html',
  styleUrls: ['./done-button.component.styl']
})
export class DoneButtonComponent implements OnInit {
  @Input() active;
  @Input() doneCount;
  @Input() noShadow;
  @Input() update$;
  @Output() clickEmitter = new EventEmitter();
  @ViewChild('animContainer') animContainer: ElementRef;
  doneAnimation;
  undoneAnimation;
  animationActive;
  done;
  imageHidden;
  w: any;
  isBrowser;
  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);
    if (this.isBrowser) {
      this.w = window;
    }
  }

  ngOnInit() {
    this.done = !!this.active;
    if (this.update$) {
      this.update$.subscribe(() => {
        this.done = !!this.active;
      })
    }
  }

  playDoneAnimation() {
    this.resetDoneAnimation();
    this.animationActive = true;
    this.doneAnimation.play();
    this.imageHidden = true;
    setTimeout(() => {
      this.done = true;
    }, 200)
    setTimeout(() => {
      this.imageHidden = false;
      this.animationActive = false;
      if (this.doneAnimation) {
        this.doneAnimation.destroy();
      }
    }, 1000)
  }
  playUndoneAnimation() {
    this.resetUndoneAnimation();
    this.animationActive = true;
    this.undoneAnimation.play();
    setTimeout(() => {
      this.done = false;
    }, 200)
    setTimeout(() => {
      // this.animationActive = false;
      if (this.undoneAnimation) {
        this.undoneAnimation.destroy();
      }
      // this.resetLikeAnimation()
    }, 1000)
  }

  resetDoneAnimation() {
    if (this.undoneAnimation) {
      this.undoneAnimation.destroy();
    }
    if (this.doneAnimation) {
      this.doneAnimation.destroy();
    }
    this.doneAnimation = this.w.bodymovin.loadAnimation({
      container: this.animContainer.nativeElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '../../assets/animations/muscle.json'
    })
  }
  resetUndoneAnimation() {
    if (this.undoneAnimation) {
      this.undoneAnimation.destroy();
    }
    if (this.doneAnimation) {
      this.doneAnimation.destroy();
    }
    this.undoneAnimation = this.w.bodymovin.loadAnimation({
      container: this.animContainer.nativeElement,
      renderer: 'svg',
      loop: false,
      autoplay: false,
      path: '../../assets/animations/un-muscle.json'
    })
  }

  toggleDone(event) {
    this.clickEmitter.emit({event: event, elem: this});
  }

}
