import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-profile-card',
  templateUrl: './profile-card.component.html',
  styleUrls: ['./profile-card.component.styl']
})
export class ProfileCardComponent implements OnInit {
  @Input() userId;
  @Input() inPopup;
  @Output() initUserEmitter = new EventEmitter();
  user;
  userLoading;
  error;
  isSuper;
  constructor(private userService: UserService) { }

  ngOnInit() {
    if (this.userId) {
      this.initUser(this.userId);
    }
    this.isSuper = this.userService.userData.role === 'SUPER_ADMIN';
  }

  initUser(user) {
    this.userLoading = true;
    this.error = null;
    this.userService.getUserData(user).subscribe((data) => {
      this.user = data.result;
      this.initUserEmitter.emit(this.user);
      this.userLoading = false;
    }, () => {
      this.userLoading = false;
      this.error = true;
    })
  }

}
