import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { StorageSortPipe } from '../../pipes/storage-sort.pipe';
import { StorageFilterPipe } from '../../pipes/storage-filter.pipe';
import { SectionNamePipe } from '../../pipes/section-name.pipe';
import { FirstCapitalPipe } from '../../pipes/first-capital.pipe';
import { RusDayPipe } from '../../pipes/rus-day.pipe';
import { PricePipe } from '../../pipes/price.pipe';
import { NumberCasePipe } from '../../pipes/number-case.pipe';
import { RusDifficultyPipe } from '../../pipes/rus-difficulty.pipe';
import { MsDurationPipe } from '../../pipes/ms-duration.pipe';
import { EscapeHtmlPipe } from '../../pipes/keep-html.pipe';
import { FormatBytesPipe } from '../../pipes/format-bytes.pipe';
import { RemoveExtentionPipe } from '../../pipes/remove-extention.pipe';
import { ProfilePipe } from '../../pipes/profile.pipe';
import { ReadableAreaPipe } from '../../pipes/readable-area.pipe';
import { ReadableMaterialPipe } from '../../pipes/readable-material.pipe';
import { NamesFilterPipe } from '../../pipes/names-filter.pipe';
import { SortingPipe } from '../../pipes/sorting.pipe';
import { ClientsSearchPipe } from '../../pipes/clients-search.pipe';
import { ClientsSortPipe } from '../../pipes/clients-sort.pipe';
import { ClientsFilterPipe } from '../../pipes/clients-filter.pipe';
import { EventPastFilterPipe } from '../../pipes/event-past-filter.pipe';
import { SortByDatesPipe } from '../../pipes/sort-by-dates.pipe';
import { MastersSortPipe } from '../../pipes/masters-sort.pipe';
import { EquipmentsSortPipe } from '../../pipes/equipments-sort.pipe';
import { ToFixedPipe } from '../../pipes/to-fixed.pipe';
import { MsTimePipe } from '../../pipes/ms-time.pipe';
import { ConsultationsSortPipe } from '../../pipes/consultations-sort.pipe';
import { CoursesSortPipe } from '../../pipes/courses-sort.pipe';
import { CourseSearchPipe } from '../../pipes/course-search.pipe';
import { ConsultsSearchPipe } from '../../pipes/consults-search.pipe';
import { RusMonthPipe } from '../../pipes/rus-month.pipe';
import { CertificatesSortPipe } from '../../pipes/certificates-sort.pipe';
import { NowWorkPipe } from '../../pipes/now-work.pipe';
import { FloorPipe } from '../../pipes/floor.pipe';

const pipes = [
  RusMonthPipe,
  StorageSortPipe,
  StorageFilterPipe,
  ConsultsSearchPipe,
  CourseSearchPipe,
  ConsultationsSortPipe,
  CoursesSortPipe,
  MsTimePipe,
  EquipmentsSortPipe,
  ToFixedPipe,
  MastersSortPipe,
  EventPastFilterPipe,
  SortByDatesPipe,
  SortingPipe,
  ClientsSearchPipe,
  ClientsSortPipe,
  ClientsFilterPipe,
  NamesFilterPipe,
  ReadableAreaPipe,
  ReadableMaterialPipe,
  ReadableAreaPipe,
  ReadableMaterialPipe,
  ProfilePipe,
  RemoveExtentionPipe,
  RusDifficultyPipe,
  MsDurationPipe,
  EscapeHtmlPipe,
  FormatBytesPipe,
  NumberCasePipe,
  PricePipe,
  RusDayPipe,
  FirstCapitalPipe,
  SectionNamePipe,
  CertificatesSortPipe,
  NowWorkPipe,
  FloorPipe
]

@NgModule({
  imports: [CommonModule],
  declarations: pipes,
  exports: [CommonModule, ...pipes]
})

export class PipesModule {}