import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { FormBuilder, Validators } from '@angular/forms';
import { EquipmentsService } from '../../services/equipments.service';
import { Router, ActivatedRoute } from '@angular/router';
import { IsBrowserService } from '../../services/is-browser.service';
import { first, map } from 'rxjs/operators';
import { CurrentEquipmentService } from '../../services/current-equipment.service';
import { NotificationsService } from '../../services/notifications.service'
import { removingEquipReasons } from '../../utils';
@Component({
  selector: 'app-modal-remove-equipment',
  templateUrl: './modal-remove-equipment.component.html',
  styleUrls: ['./modal-remove-equipment.component.styl']
})
export class ModalRemoveEquipmentComponent implements OnInit {
  breakdown;
  equipment;
  currentRoute;
  isLoading;
  isError;
  currentEquipment;
  currentEquipmentId;
  reasonForm;
  deleteLoading;
  removingReasons = removingEquipReasons;
  constructor(private modalService: ModalService,
              private router: Router,
              private equipmentsService: EquipmentsService,
              private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private isBrowserService: IsBrowserService,
              private currentEquipmentService: CurrentEquipmentService,
              private notificationsService: NotificationsService) { }

  ngOnInit() {
    this.initEquipment();
    this.breakdown = this.fb.group({
      replacement: [false, []],
      equipment: ['', []]
    });
    this.reasonForm = this.fb.group({
      reason: [this.removingReasons[0].id, Validators.required],
      otherReason: ['']
    })
    this.currentRoute = this.router.url.split('?')[0];
  }

  initEquipment() {
    this.isLoading = true;
    if (this.isBrowserService) {
      this.currentEquipmentId = this.activatedRoute.snapshot.queryParams.equipment;
      if (this.currentEquipmentId) {
        this.equipmentsService.getEquipment(this.currentEquipmentId).pipe(first()).subscribe((data) => {
          this.currentEquipment = data.result;
          this.isLoading = false;
        }, () => {
          this.isLoading = false;
          this.isError = true;
        })
      } else {
        this.isLoading = false;
        this.isError = true;
      }
    }
  }

  closeModal() {
    this.modalService.onClose();
  }

  onFormChange() {
    if (!this.breakdown.value.equipment.equipment_id) {
      this.breakdown.controls.equipment.setValue('');
    }
  }

  public handleSearchEquip = (query) => {
    return this.equipmentsService.getEquipmentsList({ query_str: query, hide_broken: true, hidden_equipments_ids: [this.currentEquipmentId] }).pipe(map((data:any) => {
      return {
        result: data.result.data
      }
    }))
  }

  onReplacementChange() {
    if (this.breakdown.value.replacement) {
      this.breakdown.controls.equipment.setValidators([Validators.required]);
      this.breakdown.controls.equipment.reset();
    } else {
      this.breakdown.controls.equipment.setValidators();
      this.breakdown.controls.equipment.reset();
    }
  }

  onReasonChange(evt) {
    if (this.reasonForm.value.reason === 'OTHER') {
      this.reasonForm.controls.otherReason.setValidators([Validators.required]);
      this.reasonForm.controls.otherReason.reset();
    } else {
      this.reasonForm.controls.otherReason.setValidators();
      this.reasonForm.controls.otherReason.reset();
    }
  }

  submit() {
    this.deleteLoading = true;
    let model:any = {
      deletion_cause: this.reasonForm.value.reason
    }
    if (this.reasonForm.value.reason === 'OTHER' && this.reasonForm.value.otherReason) {
      model.other_cause = this.reasonForm.value.otherReason;
    }
    if (this.breakdown.value.replacement && this.breakdown.value.equipment && this.breakdown.value.equipment.equipment_id) {
      model.replacing_equipment_id = this.breakdown.value.equipment.equipment_id;
    }
    this.deleteLoading = true;
    this.equipmentsService.deleteEquipment(this.currentEquipmentId, model).pipe(first()).subscribe((data) => {
      this.deleteLoading = false;
      this.notificationsService.updateEmitter();
      if (this.currentRoute.indexOf('oborudovanie/') + 1) {
        if (data.result.notification_id) {
          this.router.navigate(['/oborudovanie'], { queryParams: { popup: 'notify-remove-equipment', notification: data.result.notification_id } });
        } else {
          this.router.navigate(['/oborudovanie']);
        }
      } else {
       this.currentEquipmentService.updateEmitter();
       if (data.result.notification_id) {
          this.router.navigate([this.router.url.split('?')[0]], { queryParams: { popup: 'notify-remove-equipment', notification: data.result.notification_id } });
        } else {
          this.modalService.nextPage(null, null);
        }
      }

 
    }, () => {
      this.deleteLoading = false;
      this.isError = true;
    })
  }

}
