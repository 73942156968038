import { Component, EventEmitter, ViewChild, OnInit, Output, Input } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';

import { CalendarComponent } from '../calendar/calendar.component';
import { UserService } from '../../services/user.service';
import { CalendarService } from '../../services/calendar.service';
import { ReserveType, Session } from '../../interfaces';
import { getApiReserveType, getApiTime, getFormatTime, getHoursRange, getHoursRangeFromInterval, parseStringToTime, parseTimeToString, addZero, getMonthEdges, groupByDay, getCurrentMoscowTime, combineDates, compareMonth, getGreenwichDate, compressTimings } from '../../utils';
import { RESERVE_START_TIME, RESERVE_END_TIME, FABRIC_WORK_HOURS, MOSCOW_TIME_ZONE } from '../../config';
import { Subject } from 'rxjs';
import { EquipmentsService } from '../../services/equipments.service';

const initialCalendarDisplay = {
  courses: [],
  consultations: [],
  disabled: [],
};

@Component({
  selector: 'app-reserve-instrument',
  templateUrl: './reserve-instrument.component.html',
  styleUrls: ['./reserve-instrument.component.styl']
})
export class ReserveInstrumentComponent implements OnInit {
  @ViewChild('calendar') calendar: CalendarComponent
  @Input() parent: 'multistep' | 'modal' | 'popup';
  @Input() reserveParams;
  @Input() equipmentId;
  @Input() isMaster;
  @Input() isMe;
  @Input() userId;
  @Input() user;
  @Input() oneInterval;
  @Input()
  set equipment(equipment) {
    if (equipment) {
      this._equipment = equipment;
      this.setupEquipment();
    }
  }

  get equipment() {
    return this._equipment;
  }

  @Output() onReserve = new EventEmitter();
  @Output() onClose = new EventEmitter();
  _equipment;
  isBusyDay;
  selectedDate;
  selectedDay;
  selectedType: ReserveType = 'reservation';
  selectedSessions = [];
  currentMonth;
  updateMonthEmitter$;
  monthCourses;
  monthConsultations;
  calendarCourses = [];
  calendarConsultations = [];
  reservations = [];
  miniCourses = [];
  miniCoursesTimeAllMin: number;
  miniCoursesTimeHours: number;
  miniCoursesTimeMin: number;
  miniCoursesComeTime: string;
  calendarDisplay = initialCalendarDisplay;
  reserveLoading = true;
  calendarValues: any = {};
  sessions: any = [];
  showComeTime: any = {};
  notPassedSessions: any = [];
  currentRoute;
  calendarLoading;
  selectedDayReservations;
  error;

  constructor(
    private router: Router,
    private userService: UserService,
    private calendarService: CalendarService,
    private equipmentsService: EquipmentsService
  ) {}

  ngOnInit() {
    this.currentMonth = new Date().setDate(1);
    this.updateMonthEmitter$ = new Subject();
    this.updateMonthEmitter$.subscribe((month) => {
      this.setCurrentMonth(month);
    })
    if (!this.userId) {
      this.userId = this.userService.userId;
    }
    this.currentRoute = this.router.url.split('?')[0];
  }

  setReserveType(type: ReserveType) {
    this.calendar.selectedDate = null;
    this.selectedDate = null;
    this.selectedType = type;
    this.calendarLoading = true;
    this.setupCalendar(this.currentMonth, true, true);
  }

  setupEquipment() {
    this.setupMiniCourses();
  }

  setupCalendar(month, clearDay?, selectClosest?) {
    this.currentMonth = month;
    if (this.selectedType === 'course') {
      this.getMastersSchedule('COURSE', clearDay, selectClosest);
      this.clearMonth();
    } else if (this.selectedType === 'consultation') {
      this.getMastersSchedule('CONSULTATION', clearDay, selectClosest);
      this.clearMonth();
    } else if (this.selectedType === 'reservation') {
      this.getMastersSchedule('RESERVATION', clearDay, selectClosest);
      this.clearMonth();
    }
  }

  getMastersSchedule(type, clearDay?, selectClosest?) {
    let edges = getMonthEdges(this.currentMonth);
    let reserveParam;
    if (type === 'COURSE') {
      reserveParam = 'FOR_COURSE';
    } else if (type === 'CONSULTATION') {
      reserveParam = 'FOR_CONSULTATION'
    } else {
      reserveParam = 'FOR_ME'
    }
    this.equipmentsService.getReservitionInfo(this.equipmentId, reserveParam, this.userId).subscribe((data) => {
      this.reserveParams = data.result;
      if (clearDay) {
        this.calendar.selectedDate = null;
        this.selectedDate = null;
      }
      this.reserveParams = data.result;
      if (type === 'COURSE') {
        this.clearMonth();
        let courses = data.result.courses.map(item => {
          return {
            ...item,
            passed: combineDates(item.date, item.start_time).getTime() <= getCurrentMoscowTime().getTime()
          }
        })
        this.monthCourses = groupByDay(courses, 'date', 'course_timing_id');
        this.calendarCourses = this.monthCourses.filter(item => new Date(item.date).setHours(0,0,0,1) > new Date().setHours(0,0,0,0)).map(item => item.date);
        if (selectClosest) {
          if (this.calendarCourses[0]) {
            this.calendar.selectDate(new Date(this.calendarCourses[0]).setHours(0,0,0,0), false, false);
          }
        }
      }
      if (type === 'CONSULTATION') {
        this.clearMonth();
        let consults = data.result.consultations.map(item => {
          return {
            ...item,
            passed: item.start_time <= getCurrentMoscowTime().getTime()
          }
        })
        this.monthConsultations = groupByDay(consults, 'date', 'id');
        this.monthConsultations = this.monthConsultations.filter(item => new Date(item.date).setHours(0,0,0,1) > new Date().setHours(0,0,0,0))
        this.calendarConsultations = this.monthConsultations.map(item => item.date);
        if (selectClosest) {
          if (this.calendarConsultations[0] && !compareMonth(this.currentMonth, new Date()) && !this.selectedDate) {
            this.calendar.selectDate(new Date(this.calendarConsultations[0]).setHours(0,0,0,0), false, false);
          }
        }
      }
      if (type === 'RESERVATION') {
        if (selectClosest) {
          if (getCurrentMoscowTime().getHours() >= 21) {
            this.calendar.selectDate(new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0), false, false);
          } else {
            this.calendar.selectDate(new Date().setHours(0,0,0,0), false, false);
          }
        }
      }
      this.updateMonthEmitter$.next(this.currentMonth);

      if (!clearDay) {
        this.selectDate(this.selectedDate);
      }
      this.calendarLoading = false;
    }, () => {this.error = true; this.calendarLoading = false;})
  }

  setupMiniCourses() {
    this.miniCoursesTimeAllMin = 0;
    this.equipmentsService.getUsersMiniCourses(this.userId).subscribe((data) => {
      let passed = data.result.map(item => +item.id);
      this.miniCourses = this.equipment.mini_courses.filter(item => !passed.includes(+item.id)).map(miniCourse => {
        this.miniCoursesTimeAllMin += miniCourse.duration;
        return miniCourse.name;
      });

      this.miniCoursesTimeHours = Math.floor(this.miniCoursesTimeAllMin / 60);
      this.miniCoursesTimeMin = this.miniCoursesTimeAllMin % 60;

      if (this.miniCoursesTimeHours > 0) {
        this.miniCoursesComeTime =`${addZero(this.miniCoursesTimeHours)}:${addZero(this.miniCoursesTimeMin)} часа`;
      } else {
        this.miniCoursesComeTime = `${addZero(this.miniCoursesTimeMin)} минут`;
      }
      if (this.isMaster) {
        this.setReserveType('course');
      } else {
        this.setReserveType('reservation');
      }
    })
  }

  setCurrentMonth(date) {
    this.currentMonth = date;
    setTimeout(_ => {
      this.calendar.setCurrentMonth(this.currentMonth);
    }, 0);
  }

  selectDate(date) {
    if (this.selectedDate != date) {
      this.selectedDate = date;
      this.selectedDay = true;
      this.selectedSessions = [];
    }
    this.updateSessions();
  }

  onSelectSession(session, selectOne?) {
    if (!selectOne) {
      session.selected = !session.selected;
      // const sessionIndex = this.selectedSessions.findIndex(findSession => findSession.id === session.id);
      // if (sessionIndex >= 0) {
      //   this.selectedSessions.splice(sessionIndex, 1);
      // } else {
      //   this.selectedSessions.push(session);
      // }
      this.selectedSessions = this.sessions.filter(item => item.selected);
      this.selectedSessions.sort((a,b) => {
        if (a.start_time > b.start_time) {
          return 1;
        } else if (a.start_time < b.start_time) {
          return -1;
        } else {
          return 0;
        }
      });
      if (this.oneInterval && !this.checkSameInterval(this.selectedSessions)) {
        this.sessions.forEach(item => item.selected = false);
        session.selected = true;
        this.selectedSessions = [session];
      }
      // console.log(this.getLastInterval(this.selectedSessions));
      // this.selectedSessions = this.getLastInterval(this.selectedSessions);
    } else {
      this.sessions.forEach(item => item.selected = false);
      session.selected = true;
      this.selectedSessions = [session];
    }
  }

  isShowComeTime(session) {
    return !this.isMaster && session.come_time && this.selectedSessions[0] && this.selectedSessions[0].id === session.id;
  }

  nextMonth() {
    this.setupCalendar(new Date(this.currentMonth).setMonth(new Date(this.currentMonth).getMonth() + 1));
  }

  prevMonth() {
    this.setupCalendar(new Date(this.currentMonth).setMonth(new Date(this.currentMonth).getMonth() - 1));
  }

  bindCalendarValues(date, value, type) {
    if (!this.calendarValues[date]) {
      this.calendarValues[date] = {
        [type]: [value]
      }
    }

    let isExists;

    if (this.calendarValues[date][type]) {
      isExists = this.calendarValues[date][type].find(item => {
        if (type === 'reservation') {
          return item.timing_id === value.timing_id;
        } else {
          return item.id === value.id;
        }
      });
    }

    if (this.calendarValues[date][type] && !isExists) {
      this.calendarValues[date][type].push(value);
    } else {
      this.calendarValues[date][type] = [value];
    }
  }

  updateSessions() {
    this.sessions = [];
    this.notPassedSessions = [];
    this.reservations = this.reserveParams.reservations ? this.reserveParams.reservations.map(reservation => {
      const date = new Date(reservation.date).setHours(0,0,0,0);
      this.bindCalendarValues(date, reservation, 'reservation');
      return date
    }) : [];
    if (this.selectedType === 'reservation') {
      this.getReservationsOfDay(this.selectedDate);
      let datesRange;
      if (new Date(this.selectedDate).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
        datesRange = getHoursRangeFromInterval(Math.max(RESERVE_START_TIME, getCurrentMoscowTime().getHours() + 1), RESERVE_END_TIME);
      } else {
        datesRange = getHoursRangeFromInterval(RESERVE_START_TIME, RESERVE_END_TIME);
      }
      for (let i = 0; i < (datesRange.length - 1); i++) {
        let comeTime = this.miniCoursesTimeAllMin ? this.getComeTime(datesRange[i], false) : null;
        this.sessions.push({
          id: i,
          start_time: datesRange[i],
          end_time: datesRange[i+1],
          come_time: comeTime,
          too_early: comeTime ? parseInt(comeTime.split(':')[0]) <= 7 : false
        });
      }
      let reserved = [];
      if (this.calendarValues[this.selectedDate] && this.calendarValues[this.selectedDate]['reservation']) {
        reserved = this.calendarValues[this.selectedDate]['reservation'].map(reserve => {
          return moment(new Date(reserve.start_time)).format('HH:mm');
        });
      }
      this.sessions = this.sessions.filter(session => {
        return !reserved.includes(session.start_time);
      });
    } else if (this.selectedType === 'course') {
      if (this.monthCourses) {
        let day = this.monthCourses.filter(item => item.date === new Date(this.selectedDate).setHours(0,0,0,0))[0];
        if (day) {
          this.sessions = day.sessions;
          this.notPassedSessions = this.sessions.filter((item) => {
            return !item.item.passed;
          })
        }
      }
    } else if (this.selectedType === 'consultation') {
      if (this.monthConsultations) {
        let day = this.monthConsultations.filter(item => item.date === new Date(this.selectedDate).setHours(0,0,0,0))[0];
        if (day) {
          this.sessions = day.sessions;
          this.notPassedSessions = this.sessions.filter((item) => {
            return !item.item.passed;
          })
        }
      }
    }
    this.isBusyDay = this.sessions.length > 0;
  }

  private getZeroTimezone = function(offset) {
    var d = new Date();
    var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
    var nd = new Date(utc + (3600000*offset));
    return nd;
  }

  private markReserved(list) {
    list.forEach(item => {
      item.sessions.forEach(session => {
        if (session.item.equipment.filter(eq => eq.equipment_id == this.equipmentId)[0]) {
          session.reserved = true;
        }
      })
    })
  }

  clearMonth() {
    this.monthCourses = null;
    this.calendarCourses = null;
    this.monthConsultations = null;
    this.calendarConsultations = null;
  }

  private getComeTime(startTime, isFirst: boolean): string {
    const date = new Date(new Date(new Date().setHours(startTime.split(':')[0])).setMinutes(startTime.split(':')[1]));
    date.setHours(date.getHours() - this.miniCoursesTimeHours, date.getMinutes() - this.miniCoursesTimeMin);
    return parseTimeToString(date);
  }

  getReservationsOfDay(date) {
    this.selectedDayReservations = null;
    this.calendarService.getUserCalendar({
      user_id: this.userId,
      date_from: getGreenwichDate(date),
      date_to: getGreenwichDate(date),
      entity_type: 'EQUIPMENT'
    }).subscribe((data) => {
      this.selectedDayReservations = data.result.length ? this.groupByEquip(data.result) : null;
    })
  }

  private groupByEquip = function(dates) {
    let equipIds = [];
    let grouped:any = {};
    dates.forEach((item) => {
      if (!equipIds.includes(item.entity_id)) {
        equipIds.push(item.entity_id);
        grouped[item.entity_id] = {
          name: item.name,
          timings: [{
            start_time: item.start_time,
            end_time: item.end_time
          }]
        }
      } else {
        grouped[item.entity_id].timings.push({
          start_time: item.start_time,
          end_time: item.end_time
        })
      }
    })
    let result = [];
    for (let key in grouped) {
      result.push(grouped[key]);
    }
    result.forEach(item => {
      item.timings = compressTimings(item.timings);
    })
    return result;
  }

  onSubmit() {
    const params: any = {
      amount: 1,
      reservation_type: getApiReserveType(this.selectedType),
      user_id: this.userId,
    };
    if (this.oneInterval) {
      this.selectedSessions = this.getLastInterval(this.selectedSessions);
    }
    this.onReserve.next({
      reserveParams: params,
      reserveSettings: {
        parsedSessionsTime: this.selectedSessions.map(session => ({
          date: moment(this.selectedDate).format('YYYY-MM-DD'),
          start_time: getApiTime(session.start_time || moment(session.start).format('HH:mm')),
          end_time: getApiTime(session.end_time || moment(session.end).format('HH:mm')),
          course_timing_id: session.item ? session.item.course_timing_id : null,
          consultation_id: session.item ? session.item.id : null
        })),
        isMaster: this.isMaster,
        equipmentId: this.equipmentId,
        equipmentName: this.equipment.name,
        type: this.selectedType,
        sessions: this.selectedSessions.map(item => {
          if (item.item) {
            return item.item;
          } else {
            return item;
          }
        }),
        date: this.selectedDate,
      }
    });
  }

  private checkSameInterval(sessions) {
    return sessions.every((item, index, arr) => {
      if (index === 0) {
        return true;
      } else {
        return arr[index -1].end_time === item.start_time;
      }
    })
  }

  private getLastInterval(sessions) {
    let lastIntervalStart;
    sessions.forEach((item, index, arr) => {
      if (index >= 1) {
        if (arr[index - 1].end_time === item.start_time) {

        } else {
          lastIntervalStart = index;
        }
      }
    })
    if (lastIntervalStart) {
      return sessions.slice(lastIntervalStart, sessions.length);
    } else {
      return sessions;
    }
  }


}
