import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { phoneMask, parseApiError } from '../../utils';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal-set-phone',
  templateUrl: './modal-set-phone.component.html',
  styleUrls: ['./modal-set-phone.component.styl']
})
export class ModalSetPhoneComponent implements OnInit {
  getDataLoading;
  phoneLoading;
  phoneForm;
  setPhone;
  errorMessage;
  phoneMask = phoneMask;
  @ViewChild('phone') phone;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private modalService: ModalService) { }

  ngOnInit() {
    this.getDataLoading = true;
    this.phoneForm = this.fb.group({
      phone: ['', [Validators.required]]
    });
    this.getUserPhone();
    setTimeout(() => {
      if (this.phone && this.phone.nativeElement) {
        this.phone.nativeElement.focus();
      }
    })

  }

  getUserPhone() {
    // this.getDataLoading = false;
    if (this.userService.userData.phone) {
      this.setPhone = true;
      setTimeout(() => {
        this.modalService.nextPage(null, null);
      })
    } else {
      this.getDataLoading = false;
    }
  }

  parseValue(val) {
    return val.replace('+7', '8').split(' ').join('');
  }

  submitPhone(evt?) {
    this.errorMessage = null;
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    if (this.phoneForm.valid) {
      let form: any = {};
      form.phone = this.parseValue(this.phoneForm.value.phone);
      this.phoneLoading = true;
      this.userService.updateUserData(form).subscribe((data: any) => {
        this.phoneLoading = false;
        this.userService.setUserData(data.result);
        this.modalService.nextPage(null, null);
      }, (e) => {
        this.phoneLoading = false;
        let error = parseApiError(e);
        if (error && error[0] && error[0].code === 'duplicate-phone') {
          this.errorMessage = 'Этот телефон уже используется';
        } else {
          this.errorMessage = 'Ошибка';
        }
      })
    }
  }

  closeModal() {
    this.modalService.onClose();
  }

}
