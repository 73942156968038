import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';
import { StorageService } from '../../services/storage.service';
import { StatisticsService } from '../../services/statistics.service';

@Component({
  selector: 'app-modal-storage-products',
  templateUrl: './modal-storage-products.component.html',
  styleUrls: ['./modal-storage-products.component.styl']
})
export class ModalStorageProductsComponent implements OnInit {
  currentRoute;
  statLoading;
  statData;
  statError;
  constructor(private router: Router,
              private modalService: ModalService,
              private storageService: StorageService,
              private statisticsService: StatisticsService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    this.getStorageStaistics();
  }

  cellReserv() {

  }

  getStorageStaistics() {
    this.statLoading = true;
    this.statisticsService.getStorageSimpleStatistics().subscribe((data) => {
      let result = [data.result.occupied, data.result.overdue];
      this.statData = result;
      this.statLoading = false;
    }, () => {this.statLoading = false; this.statError = true;})
  }


  back() {
    this.modalService.back();
  }
}
