import { Directive, Input, ElementRef } from '@angular/core';
import { EmbedVideoService } from '../services/embed-video.service'
@Directive({
  selector: '[appVideoPreview]'
})
export class VideoPreviewDirective {
  @Input() link;
  constructor(private el: ElementRef, private embedService: EmbedVideoService) {
  }

  ngOnInit() {
    if (this.embedService.detectYoutube(new URL(this.link))) {
      let embedImage = this.embedService.embed_image(this.link);
      this.el.nativeElement.style.backgroundImage = `url('${embedImage.__zone_symbol__value.link}')`;
    } else if (this.embedService.detectRutube(new URL(this.link))) {
      this.embedService.isRTLinkValid(this.link)
        .subscribe((data) => {
          this.el.nativeElement.style.backgroundImage = `url('${data.result.thumbnail_url}')`;
        }, (e) => {

        })
    }

  }

}
