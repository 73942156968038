import { Component, AfterViewInit, ViewChild, ElementRef, Input } from '@angular/core';
import { IsBrowserService } from '../../services/is-browser.service';

@Component({
  selector: 'app-native-video-preview',
  templateUrl: './native-video-preview.component.html',
  styleUrls: ['./native-video-preview.component.styl']
})
export class NativeVideoPreviewComponent implements AfterViewInit {
  @ViewChild('canvas') canvas: ElementRef;
  @ViewChild('video') video: ElementRef;
  @Input() videoUrl;
  loaded;
  isBrowser;
  constructor(private el: ElementRef,
              private isBrowserService: IsBrowserService) {
                this.isBrowser = this.isBrowserService.isBrowser;
              }



  ngAfterViewInit() {
    if (this.isBrowser) {
      this.initPreview();
    }
  }

  initPreview() {
    let video = this.video.nativeElement;
    let canvas = this.canvas.nativeElement;
    let context = canvas.getContext("2d");
    let elemWidth = this.el.nativeElement.offsetWidth;
    let elemHeight = this.el.nativeElement.offsetHeight;
    if (this.videoUrl) {
      video.setAttribute('src', this.videoUrl);
      video.onloadeddata = () => {
        this.loaded = true;
        let videoWidth = video.videoWidth;
        let videoHeight = video.videoHeight;
        let horizontal = videoWidth > videoHeight;
        let previewWidth;
        let previewHeight;
        if (!horizontal) {
          previewWidth = elemWidth;
          previewHeight = videoHeight * (elemWidth / videoWidth);
          // canvas.style.marginTop = (elemHeight - previewHeight) / 2 + 'px';
        } else {
          previewWidth = videoWidth * (elemHeight / videoHeight);
          previewHeight = elemHeight;
          // canvas.style.marginLeft = (elemWidth - previewWidth) / 2 + 'px';
        }
        canvas.width = elemWidth;
        canvas.height = elemHeight;
  
        context.drawImage(video, (elemWidth - previewWidth) / 2, (elemHeight - previewHeight) / 2, previewWidth, previewHeight);
      }
      // video.ontimeupdate = () => {
      //   context.drawImage(video, 0, 0, w, h);
      // }
    }
  }

}
