import { Component, OnInit } from '@angular/core';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { Router } from '@angular/router';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal-cancel-mc',
  templateUrl: './modal-cancel-mc.component.html',
  styleUrls: ['./modal-cancel-mc.component.styl']
})
export class ModalCancelMcComponent implements OnInit {
  data;
  dataType;
  type;

  constructor(private cancelRegistrartionService: CancelRegistrationService,
              private modalService: ModalService,
              private router: Router) { }

  ngOnInit() {
    if (this.cancelRegistrartionService.currentCourse) {
      this.data = this.cancelRegistrartionService.currentCourse;
      this.dataType = 'COURSE';
    }
    if (this.cancelRegistrartionService.currentEvent) {
      this.data = this.cancelRegistrartionService.currentEvent;
      this.dataType = 'EVENT';
    }
    if (this.cancelRegistrartionService.currentConsult) {
      this.data = this.cancelRegistrartionService.currentConsult;
      this.dataType = 'CONSULTATION';
    }
  }

  modalClose() {
    this.cancelRegistrartionService.closeConfirmPopup();
  }

  moveCourse() {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'move-course', course: this.data.entity_second_id || this.data.id}});
    let closePopup = function() {
      this.cancelRegistrartionService.closeConfirmPopup();
    }.bind(this);
    setTimeout(() => {
      this.modalService.setGlobalOptions({afterSuccess: closePopup})
    })
  }

  moveConsult() {
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'client-move-consult', consultation: this.data.entity_id || this.data.id}});
    let closePopup = function() {
      this.cancelRegistrartionService.closeConfirmPopup();
    }.bind(this);
    setTimeout(() => {
      this.modalService.setGlobalOptions({afterSuccess: closePopup})
    })
  }

  cancel() {
    if (this.dataType === "EVENT") {
      this.cancelRegistrartionService.cancelPaidEvent();
    }
    if (this.dataType === "COURSE") {
      this.cancelRegistrartionService.cancelPaidCourse();
    }
    if (this.dataType === "CONSULTATION") {
      this.cancelRegistrartionService.cancelPaidConsult();
    }
  }

}
