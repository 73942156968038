import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})



export class ConsultationsService {
  orderedSubject$ = new Subject();

  constructor(private api: ApiService) { }

  getAvailableConsultations(model) {
    return this.api.get('v1/consultations/available', model)
  }

  getConsultationById(consultationId) {
    return this.api.get(`v1/consultations/available/${consultationId}`)
  }

  getOrderedConsultationById(consultationId) {
    return this.api.get(`v1/consultations/ordered/${consultationId}`)
  }

  order(available_consultation_id, payment_type) {
    return this.api.post('v1/consultations/order', { available_consultation_id, payment_type })
  }

  orderClient(model) {
    return this.api.post('v1/consultations/order', model)
  }

  paid(consultationId, paid) {
    return this.api.post(`v1/consultations/${consultationId}/paid`, undefined, { paid });
  }

  attend(consultationId, attended) {
    return this.api.post(`v1/consultations/${consultationId}/attend`, undefined, { attended });
  }

  cancelConsultation(id) {
    return this.api.delete(`v1/consultations/ordered/${id}/cancel`);
  }

  moveConsultation(id, model) {
    return this.api.post(`v1/consultations/ordered/${id}/reschedule`, model);
  }

  onOrder() {
    this.orderedSubject$.next();
  }

}
