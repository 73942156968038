import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ConsultationsService } from '../../services/consultations.service';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { CurrentCourseService } from '../../services/current-course.service';

@Component({
  selector: 'app-modal-cancel-consult',
  templateUrl: './modal-cancel-consult.component.html',
  styleUrls: ['./modal-cancel-consult.component.styl']
})
export class ModalCancelConsultComponent implements OnInit {
  consultLoading;
  consultData;
  consultId;
  area;
  user;
  error;
  currentRoute;
  deleteLoading;
  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private userService: UserService,
              private modalService: ModalService,
              private consultationsService: ConsultationsService,
              private currentCourseService: CurrentCourseService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    this.consultLoading = true;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.consultation) {
        this.consultId = params.consultation;
        this.consultationsService.getOrderedConsultationById(params.consultation).subscribe((data) => {
          this.area = data.result.area;
          this.consultData = data.result;
          this.userService.getUserData(data.result.user_id).subscribe(data => {
            this.user = data.result;
            this.consultLoading = false;
          }, () => this.error = true);
        }, () => this.error = true);
      } else {
        this.error = true;
      }
    });
  }

  closeModal() {
    this.modalService.onClose();
  }

  back() {
    this.modalService.back();
  }

  submit() {
    this.deleteLoading = true;
    this.consultationsService.cancelConsultation(this.consultId).subscribe(() => {
      this.deleteLoading = false;
      this.currentCourseService.updateEmitter();
      this.modalService.nextPage({consultParams: this.consultData}, null);
    }, () => this.error = true)
  }

}
