import { Component, OnInit, Input } from '@angular/core';

interface IItem {
  type: string;
  priceType: string;
  price: number;

  workTime?: string;
}

@Component({
  selector: 'app-subscription-services-price',
  templateUrl: './subscription-services-price.component.html',
  styleUrls: ['./subscription-services-price.component.styl']
})

export class SubscriptionServicesPriceComponent implements OnInit {

  @Input() item: IItem;

  constructor() { }

  ngOnInit() {
  }

}
