import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CoursesService } from '../../services/courses.service';

@Component({
  selector: 'app-courses-search-result-item',
  templateUrl: './courses-search-result-item.component.html',
  styleUrls: ['./courses-search-result-item.component.styl']
})
export class CoursesSearchResultItemComponent implements OnInit {
  @Input() course;
  @Output() appointEmitter = new EventEmitter();
  makerNames;
  openBody;
  makers = [];
  constructor(private coursesService: CoursesService) { }

  ngOnInit() {
    this.initMakers();
  }


  initMakers() {
    let ids = [];
    this.course.course_timings.forEach(item => {
      if (!(ids.indexOf(item.maker.id) + 1)) {
        ids.push(item.maker.id);
        this.makers.push(item.maker);
      }
    })
    this.makerNames = this.makers.map(item => {
      return item.first_name + (item.last_name ? (' ' + item.last_name) : '')
    }).join(', ')
  }

  appoint(timing) {
    this.appointEmitter.emit({
      name: this.course.name,
      cost: this.course.cost,
      ...timing
    });
  }

  checkMonth(index) {
    let dates = this.course.course_timings;
    return !!(index !== dates.length-1 && ((new Date(dates[index].start_date).getMonth()) !== (new Date(dates[index+1].start_date).getMonth())));
  }
}
