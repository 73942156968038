import { Injectable, Inject, PLATFORM_ID, ApplicationRef } from '@angular/core';
import { AuthService } from './auth.service'
import { UserService } from './user.service'
import { Router } from '@angular/router';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from "@angular/router";
import { Subject } from 'rxjs';
import { disableBodyScroll, clearAllBodyScrollLocks } from "body-scroll-lock";

@Injectable({
  providedIn: 'root'
})

export class ModalService {
  isModalOpened = false;
  isBodyFrozen = false;
  isBodyBlured = false;
  isModalLoaderOpened = false;
  confirmPaidConfig;
  isConfirmPaidModalOpened;
  action = '';
  pages;
  globalOptions: any = {};
  currentPageNumber = 0;
  currentPage;
  rulesShown = false;
  steps;
  isBrowser;
  dontUnfreez;
  isClientPopup;
  popupHistory: any[] = [];
  closeModalLoaderSubject$ = new Subject();
  constructor(@Inject(PLATFORM_ID) platformId: string,
              private appRef: ApplicationRef,
              private authService: AuthService, private userService: UserService, private router: Router, private route: ActivatedRoute) {
                this.isBrowser = isPlatformBrowser(platformId);
              }

  openModal(action, options?, globalOptions?) {
    this.isModalOpened = true;

    if (this.isBodyFrozen) {
      console.log('dont unfreez')
      this.dontUnfreez = true;
    }
    this.freezBody();
    this.initPages(action);
    if (options) {
      this.setPageOptions(options);
    }
    if (globalOptions) {
      this.setGlobalOptions(globalOptions);
    }
  }


  closeModal() {
    console.log('closerr')
    this.isModalOpened = false;
    this.action = null;
    this.pages = null;
    this.globalOptions = {};
    this.steps = null;
    this.rulesShown = false;
    this.unfreezBody();
  }

  freezBody(noBlur?) {
    if (this.isBrowser) {
      this.isBodyFrozen = true;
      if (!noBlur) {
        this.isBodyBlured = true;
      }
      this.bodyScrolling(false);
    }
  }

  unfreezBody() {
    if (this.isBrowser) {
      // if (!this.dontUnfreez) {
        this.isBodyFrozen = false;
        this.bodyScrolling(true);
      // }
      this.isBodyBlured = false;
    }
    this.dontUnfreez = false;
  }


  bodyScrolling(state) {
    setTimeout(() => {
      const element = document.querySelector('.lm-modal');
      if (state) {
        clearAllBodyScrollLocks();
      } else {
        disableBodyScroll(element);
      }
    }, 100);
  }

  private initPages = function(action) {
    console.log(this.route);
    this.popupHistory.push({url: this.router.url.split('?')[0], params: this.route.queryParams.value});
    console.log('init pages', action);
    this.currentPageNumber = 0;
    switch(action) {
      case 'consult-pay':
        if (this.checkUser()) {
          this.pages = ['consult-pay'];
          this.isModalOpened = true;
        }
        break;
      case 'order-consultation':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = ['order-consultation'];
        }
        break;
      case 'rules':
        this.isModalOpened = true;
        this.pages = [{name: 'rules', options: {}}];
        break;
      case 'login':
        this.isModalOpened = true;
        this.pages = [{ name: 'signin', options: { signInTitleText: 'Добро пожаловать на Фабрику Leroy Merlin!', signUpTitleText: 'Добро пожаловать на Фабрику Leroy Merlin!' } }];
        break;
      case 'registration':
        this.isModalOpened = true;
        this.pages = [{ name: 'signup', options: { signInTitleText: 'Добро пожаловать на Фабрику Leroy Merlin!', signUpTitleText: 'Добро пожаловать на Фабрику Leroy Merlin!' } }];
        break;
      case 'buy-subscription':
        this.isModalOpened = true;
        this.pages = [{ name: 'sign', options: { signInTitleText: 'Войдите, чтобы заказать абонемент', signUpTitleText: 'Зарегистрируйтесь, чтобы заказать абонемент' } },
                      { name: 'set-birthday' },
                      { name: 'subscription-payment-type', options: {} },
                      { name: 'subscription-payment-success', options: { hideSteps: true } }];
        this.steps = [{ name: 'Вход', breakPoint: 0 },
                      { name: 'Подтверждение', breakPoint: 1 }];
        if (this.userService.userId) {
          this.nextPage(null, null);
        }
        break;
      case 'choose-buy-subscription':
        this.isModalOpened = true;
        this.pages = [{ name: 'sign', options: { signInTitleText: 'Войдите, чтобы заказать абонемент', signUpTitleText: 'Зарегистрируйтесь, чтобы заказать абонемент' } },
                      { name: 'set-birthday' },
                      { name: 'subscription-parameters', options: {} },
                      { name: 'subscription-payment-type', options: {} },
                      { name: 'subscription-payment-success', options: { hideSteps: true } }];
        this.steps = [{ name: 'Вход', breakPoint: 0 },
                      { name: 'Подтверждение', breakPoint: 2 }];
        if (this.userService.userId) {
          this.nextPage(null, null);
        }
        break;
      case 'buy-consultation':
        this.isModalOpened = true;
        console.log('order');
        this.pages = [{ name: 'sign', options: { signInTitleText: 'Войдите, чтобы заказать консультацию', signUpTitleText: 'Зарегистрируйтесь, чтобы заказать консультацию' } },
                      { name: 'set-phone' },
                      { name: 'consult-ordering', options: {} },
                      { name: 'consult-payment-success', options: { hideSteps: true } }];
        this.steps = [{ name: 'Вход', breakPoint: 0 },
                      { name: 'Подтверждение', breakPoint: 1 }];
        if (this.userService.userId) {
          this.nextPage(null, null);
        }
        break;
      case 'enroll-event':
        console.log('OPEN EVENT');
        this.isModalOpened = true;
        this.pages = [{ name: 'sign', options: { signInTitleText: 'Войдите, чтобы записаться на событие', signUpTitleText: 'Зарегистрируйтесь, чтобы записаться на событие' } },
                      { name: 'set-phone' },
                      { name: 'set-birthday' },
                      { name: 'enroll-event' },
                      { name: 'event-payment-success', options: { hideSteps: true } }];
        this.steps = [{ name: 'Вход', breakPoint: 0 },
                      { name: 'Подтверждение', breakPoint: 1 }];
        if (this.userService.userId) {
          console.log('EBT NEXT', this.pages);
          this.nextPage(null, null);
        }
        break;
      case 'enroll-course':
        console.log('OPEN COURSE');
        this.isModalOpened = true;
        this.pages = [
                      { name: 'sign', options: { signInTitleText: 'Войдите, чтобы записаться на курс', signUpTitleText: 'Зарегистрируйтесь, чтобы записаться на курс' } },
                      { name: 'set-phone' },
                      { name: 'set-birthday' },
                      { name: 'enroll-course' },
                      { name: 'course-payment-success', options: { hideSteps: true } }];
        this.steps = [{ name: 'Вход', breakPoint: 0 },
                      { name: 'Выбор времени', breakPoint: 1 }];
        if (this.userService.userId) {
          console.log('OPEN NEXT', this.pages);
          this.nextPage(null, null);
        }
        break;
      case 'move-course':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'move-course', options: { hideSteps: true, isMoving: true } },
                        { name: 'course-payment-success', options: { hideSteps: true, isMoving: true } }];
        }
        break;

      case 'reserve-instrument':
        this.isModalOpened = true;
        this.pages = [{ name: 'sign', options: { signInTitleText: 'Войдите, чтобы зарезервировать оборудование', signUpTitleText: 'Зарегистрируйтесь, чтобы зарезервировать оборудование' } },
                      { name: 'set-phone' },
                      { name: 'set-birthday' },
                      { name: 'reserve-instrument' },
                      { name: 'reserve-instrument-subscription', options: {} },
                      { name: 'subscription-payment-type', options: {} },
                      { name: 'reserve-instrument-loading', options: {} },
                      { name: 'reserve-payment-success', options: { hideSteps: true } }];
        this.steps = [{ name: 'Вход', breakPoint: 0 },
                      { name: 'Выбор времени', breakPoint: 1 },
                      { name: 'Подтверждение', breakPoint: 3 }];

        if (this.userService.userId) {
          this.nextPage(null, null);
        }
        break;
      case 'extend-subscription':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'extend-subscription', options: { hideSteps: true } },
                        { name: 'extend-subscription-payment-success', options: { hideSteps: true } }];
          if (!this.userService.userId) {
            this.onClose();
          }
        }
        break;
      case 'notifications-settings':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notifications-settings' }];
        }
        break;
      case 'upload-shelude-of-masters':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'upload-shelude-of-masters' }];
        }
        break;
      case 'consultation':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'consultation', options: { hideSteps: true } }];
        }
        break;
      case 'course-users':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'course-users', options: { hideSteps: true } }];
        }
        break;
      case 'event-users':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'event-users', options: { hideSteps: true } }];
        }
        break;
      case 'cancel-course':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'cancel-course', options: { hideSteps: true } }];
        }
        break;
      case 'cancel-event':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'cancel-event', options: { hideSteps: true } }];
        }
        break;
      case 'move-consult':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'move-consult', options: { hideSteps: false } },
                        { name: 'move-consult-success', options: { hideSteps: true } }];
          this.steps = [{ name: 'Выбор времени', breakPoint: 0 },
                        { name: 'Подтверждение', breakPoint: 1 }];
        }
        break;
      case 'client-move-consult':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'client-move-consult', options: { hideSteps: true } },
                        { name: 'client-move-consult-success', options: { hideSteps: true } }];
          this.steps = [{ name: 'Выбор времени', breakPoint: 0 },
                        { name: 'Подтверждение', breakPoint: 1 }];
        }
        break;
      case 'cancel-consult':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'cancel-consult', options: { hideSteps: true } },
                        { name: 'cancel-consult-success', options: { hideSteps: true } }];
        }
        break;
      case 'client':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.isClientPopup = true;
        }
        break;
      case 'reserve-equip-for-client':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'reserve-equip-for-client', options: { hideSteps: true } },
                        { name: 'reserve-equip-for-client-success', options: { hideSteps: true } }];
        }
        break;
      case 'notify-clients':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-clients', options: { hideSteps: true } }];
        }
        break;
      case 'notify-cancel-course':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-cancel-course', options: { hideSteps: true } }];
        }
        break;
      case 'notify-move-course':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-move-course', options: { hideSteps: true } }];
        }
        break;
      case 'notify-cancel-event':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-cancel-event', options: { hideSteps: true } }];
        }
        break;
      case 'notify-move-event':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-move-event', options: { hideSteps: true } }];
        }
        break;
      case 'notify-equipment-break':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-equipment-break', options: { hideSteps: true } }];
        }
        break;
      case 'breakdown-equipment':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'breakdown-equipment', options: { hideSteps: true } }];
        }
        break;
      // case 'reserve-instrument-subscription':
      //   this.isModalOpened = true;
      //   this.pages = [{name: 'reserve-instrument-subscription', options: {hideSteps: true}}];
      //   break;
      case 'appointment-for-consult':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'appointment-for-consult', options: { hideSteps: true } },
                        { name: 'appointment-for-consult-payment-success', options: { hideSteps: true } }];
        }
        break;
      case 'appointment-for-event':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'appointment-for-event', options: { hideSteps: true } },
                        { name: 'appointment-for-event-payment-success', options: { hideSteps: true } }];
        }
        break;
      case 'appointment-for-course':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'appointment-for-course', options: { hideSteps: true } },
                        { name: 'appointment-for-course-payment-success', options: { hideSteps: true } }];
        }
        break;
      case 'quantity-of-breakdown':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'quantity-of-breakdown', options: { hideSteps: true } }];
        }
        break;
      case 'storage-cell-reservation':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'storage-cell-reservation', options: { hideSteps: true } },
                        { name: 'storage-cell-reservation-success', options: { hideSteps: true } }];
        }
        break;
      case 'modal-storage-products':
        this.isModalOpened = true;
        this.pages = [{ name: 'modal-storage-products', options: { hideSteps: true } }];
        break;
      case 'prolongate-storage-period':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'prolongate-storage-period', options: { hideSteps: true } },
                        { name: 'prolongate-storage-period-success', options: { hideSteps: true } }];
        }
        break;
      case 'reserve-equip-for-consult':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'reserve-equip-for-consult', options: { hideSteps: true } },
                        { name: 'reserve-equip-for-consult-success', options: { hideSteps: true } }];
        }
        break;
      case 'client-prolongate-storage-period':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'client-prolongate-storage-period', options: { hideSteps: true } },
                        { name: 'client-prolongate-storage-period-success', options: { hideSteps: true } }];
        }
        break;
      case 'recovery-password-email':
        this.isModalOpened = true;
        this.pages = [{ name: 'recovery-password-email', options: { hideSteps: true } }]
                      // {name: 'client-prolongate-storage-period-success', options: {hideSteps: true}}];
        break;
      case 'client-certs':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'client-certs', options: { hideSteps: true } }];
        }
        break;
      case 'remove-equipment':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'remove-equipment', options: { hideSteps: true } }];
        }
        break;
      case 'notify-remove-equipment':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-remove-equipment', options: { hideSteps: true } }];
        }
        break;
      case 'notify-passed-storage':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'notify-passed-storage', options: { hideSteps: true } }];
        }
        break;
      // case 'confirm-phone':
      //   this.isModalOpened = true;
      //   this.pages = [{name: 'confirm-phone', options: {title: 'Пожалуйста, подтвердите свой телефон', isReg: true}}]
      //   break;
      case 'change-phone':
        if (this.checkUser()) {
          this.isModalOpened = true;
          this.pages = [{ name: 'confirm-phone' }];
        }
        break;
      case 'entities-info-deleting-master':
        if (this.checkUser(true)) {
          this.isModalOpened = true;
          this.pages = [{ name: 'entities-info-deleting-master' }];
        }
    }
    if (this.pages) {
      this.currentPage = this.pages[this.currentPageNumber];
    }
  }

  reset(action, options?, globalOptions?) {
    console.log('reset modal');
    this.action = null;
    this.pages = null;
    this.globalOptions = {};
    this.steps = null;
    this.rulesShown = false;
    this.currentPageNumber = 0;
    this.currentPage = null;
    this.isClientPopup = false;
    setTimeout(() => {
      this.initPages(action);
      if (this.isBodyFrozen) {
        this.dontUnfreez = true;
      }
      if (options) {
        this.setPageOptions(options);
      }
      if (globalOptions) {
        this.setGlobalOptions(globalOptions);
      }
    }, 0);
  }

  nextPage(options, globalOptions) {
    if (this.currentPageNumber < this.pages.length - 1) {
      this.currentPageNumber++;
      this.currentPage = this.pages[this.currentPageNumber];
      if (options) {
        this.setPageOptions(options);
      }
      if (globalOptions) {
        console.log('SET GLOBAL')
        console.log(globalOptions);
        this.setGlobalOptions(globalOptions);
      }
    } else {
      console.log('SUCCESS');
      if (this.globalOptions.afterSuccess) {
        this.globalOptions.afterSuccess();
      }
      this.onClose();
    }
  }

  prevPage() {
    this.currentPageNumber--;
    this.currentPage = this.pages[this.currentPageNumber];
  }

  setPageOptions(options) {
    for (let item in options) {
      if (!this.pages[this.currentPageNumber].options) {
        this.pages[this.currentPageNumber].options = {};
      }
      this.pages[this.currentPageNumber].options[item] = options[item];
    }
    console.log('OPTIONS', this.pages[this.currentPageNumber].options);
  }

  setGlobalOptions(options) {
    for (let item in options) {
      this.globalOptions[item] = options[item];
    }
  }

  showRules() {
    console.log('show')
    this.rulesShown = true;
  }

  hideRules() {
    this.rulesShown = false;
  }

  back() {
    if (this.popupHistory.length >= 2) {
      this.popupHistory.pop();
      let state = this.popupHistory.pop();
      this.router.navigate([state.url], {queryParams: state.params});
    } else {
      this.onClose();
    }
  }

  onClose() {
    this.popupHistory = [];
    this.isModalOpened = false;
    this.action = null;
    this.pages = null;
    this.globalOptions = {};
    this.steps = null;
    this.rulesShown = false;
    this.isClientPopup = false;
    this.isModalLoaderOpened = false;
    this.unfreezBody();
    this.closeModalLoaderSubject$.next();
    let params = JSON.parse(JSON.stringify(this.route.snapshot.queryParams));
    delete params.popup;
    delete params.subscriptionTime;
    delete params.subscriptionTimeUnit;
    delete params.subscriptionPrice;
    delete params.consultation;
    delete params.event;
    delete params.course;
    delete params.creation;
    delete params.editidea;
    delete params.editevent;
    delete params.editequipment;
    delete params.editcourse;
    delete params.clientpopup;
    delete params.timing;
    delete params.field;
    delete params.user;
    delete params.instrument;
    delete params.notification;
    delete params.moving;
    delete params.equipment;
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: params});
  }

  checkUser(onlyAdmin?) {
    if (!this.userService.userId) {
      this.onClose();
      return false;
    } else if (onlyAdmin && this.userService.userData.role !== 'ADMIN' && this.userService.userData.role !== 'SUPER_ADMIN') {
      this.onClose();
      return false;
    } else {
      return true;
    }
  }

  setModalLoader(state) {
    this.isModalLoaderOpened = !!state;
    if (state) {
      this.freezBody();
    } else {
      this.unfreezBody();
    }
  }

  setConfirmPaid(config) {
    if (config) {
      this.confirmPaidConfig = config;
      this.isConfirmPaidModalOpened = true;
      if (!this.isModalOpened) {
        this.freezBody(true);
      }
    } else {
      this.isConfirmPaidModalOpened = false;
      this.confirmPaidConfig = null;
      if (!this.isModalOpened) {
        this.unfreezBody();
      }
    }
    setTimeout(() => {
      this.appRef.tick();
    })
  }
}
