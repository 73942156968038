import {Component, OnInit, ChangeDetectorRef, Inject, Output} from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.styl']
})
export class HeaderComponent implements OnInit {
  userId;
  @Output() user;
  constructor(
              public userService: UserService,
              private router: Router,
              private activatedRoute: ActivatedRoute
            ) { }

  ngOnInit() {
    this.updateUser();
    this.userService.updateSubject.subscribe(() => {
      this.updateUser();
    })
    this.userService.updateImgSubject.subscribe(() => {
      this.updateUser();
    })
  }

  private updateUser = function() {
    this.userId = this.userService.userId;
    this.user = this.userService.userData;
  }

  openLogin() {
    let params = {...this.activatedRoute.snapshot.queryParams};
    params.popup = 'login';
    this.router.navigate([this.router.url.split('?')[0]], {queryParams: params});
  }

}
