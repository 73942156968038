import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Subject } from 'rxjs';
import { debounceTime, switchMap, tap } from 'rxjs/operators';

@Component({
  selector: 'app-input-list-search',
  templateUrl: './input-list-search.component.html',
  styleUrls: ['./input-list-search.component.styl']
})
export class InputListSearchComponent implements OnInit {
  selectedItem;
  searchLoading;
  @Input() placeholder;
  @Input() list;
  @Input() searchProp;
  @Input() displayProp;
  @Input() searchMethod;
  @Input() filter;
  @Input() exclude;
  @Input()
  set defaultItem(defaultItem) {
    if (defaultItem && defaultItem[this.displayProp]) {
      this.selectedItem = {name: defaultItem[this.displayProp]};
    }
  }

  @Output() onSelect = new EventEmitter();
  @Output() onBlurEmitter = new EventEmitter();
  @Output() onFocusEmitter = new EventEmitter();

  searchEmitter$;
  focused = false;
  popupOpened = false;
  searchQuery = '';
  searchResults;

  constructor() { }

  ngOnInit() {
    if (this.searchMethod) {
      this.searchEmitter$ = new Subject();
      this.searchEmitter$.pipe(debounceTime(300), tap(() => { this.searchLoading = true }), switchMap(query => {
        return this.searchMethod(query);
      }))
        .subscribe((data: any) => {
          this.popupOpened = true;
          this.searchLoading = false;
          this.searchResults = data.result.filter(this.filter || this.defaultFilter);
          if (this.exclude) {
            this.searchResults = this.searchResults.filter((item) => {
              return !(this.exclude.indexOf(item.id) + 1);
            });
          }
        }, () => {
          this.searchLoading = false;
        });
    }
  }

  onFocus() {
    this.focused = true;
    this.open();
    this.onFocusEmitter.emit();
  }

  onBlur() {
    this.focused = false;
    this.onBlurEmitter.emit();
  }

  onInput() {
    this.open();
  }

  open() {
    this.searchEmitter$.next(this.searchQuery);
  }

  closeSearchPopup() {
    this.popupOpened = false;
    this.searchResults = null;
  }

  selectItem(item) {
    this.selectedItem = item;
    this.onSelect.next(item);
  }

  clearSearch() {
    this.selectItem(null);
    this.searchQuery = '';
  }

  defaultFilter() {
    return true;
  }
}
