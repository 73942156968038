import { Component, OnInit, Input, Inject, ElementRef, ChangeDetectorRef } from '@angular/core';
import { CopyTextService } from '../../services/copy-text.service';
import { IsBrowserService } from '../../services/is-browser.service';
import { TranslitService } from '../../services/translit.service';

@Component({
  selector: 'app-share-button',
  templateUrl: './share-button.component.html',
  styleUrls: ['./share-button.component.styl']
})
export class ShareButtonComponent implements OnInit {
  @Input() type;
  @Input() id;
  @Input() name;
  @Input() right;
  title;
  w: any;
  popupOpened: boolean = false;
  humanReadableId;
  humanReadableType;
  constructor(private copyTextService: CopyTextService,
              private el: ElementRef,
              private _ref: ChangeDetectorRef,
              private isBrowserService: IsBrowserService,
              private translitService: TranslitService,
              @Inject('HOSTNAME') private hostname) {
  }

  ngOnInit() {
    switch(this.type) {
      case 'idea':
        this.title = 'Поделиться идеей'
        this.humanReadableType = 'idei'
        break;
      case 'course':
        this.title = 'Поделиться курсом'
        this.humanReadableType = 'master-klassy'
        break;
      case 'event':
        this.title = 'Поделиться событием'
        this.humanReadableType = 'sobytiya'
        break;
      case 'equipment':
        this.title = 'Поделиться инструментом'
        this.humanReadableType = 'oborudovanie'
        break;
      default:
        this.title = 'Поделиться'
        break;
    }
    if (this.id && this.name) {
      this.humanReadableId = this.translitService.getHumanReadableId(this.name, this.id);
    }
  }

  openPopup() {
    this.popupOpened = true;
    this._ref.detectChanges();
    this.initVKButton();
  }

  closePopup() {
    this.popupOpened = false;
  }

  togglePopup() {
    if (this.popupOpened) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

  copyLink() {
    if (this.type && this.humanReadableId) {
      this.copyTextService.copyUrl(this.type, this.humanReadableId);
    }
    this.closePopup();
  }

  facebook() {
    if (this.isBrowserService.isBrowser && this.humanReadableId) {
      let that = this;
      // console.log(`${that.hostname}/${that.type}/${this.id}`);
      (window as any).FB.ui({
        method: 'share',
        display: 'popup',
        href: `${that.hostname}${that.humanReadableType}/${this.humanReadableId}`
      }, function(response){});
    }
  }

  initVKButton() {
    if (this.isBrowserService.isBrowser && this.humanReadableId) {
      let that = this;
      this.el.nativeElement.querySelector('.vk-button-wrapper').innerHTML = (window as any).VK.Share.button(`${that.hostname}${that.type}s/${that.type}/${this.humanReadableId}`, {type: 'custom', text: '<div class="lm-share-vk-btn"></div>'});
    }
  }
}
