import { Pipe } from '@angular/core';

@Pipe({
  name: 'equipmentsSort'
})
export class EquipmentsSortPipe {
  transform(items: any[], param: string, direction: boolean) {
    let callback;

    function nameSortCallback(a, b) {
      if (a.name.toLowerCase().trim() > b.name.toLowerCase().trim()) {
        return direction ? 1 : -1;
      }
      if (a.name.toLowerCase().trim() < b.name.toLowerCase().trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }

    function reservationSortCallback(a, b) {
      if (+a.reservation_amount == 0 && +b.reservation_amount == 0) {
        return 0;
      }

      let result = a.reservation_amount - b.reservation_amount;
      return direction ? result : -result;
    }

    function congestionSortCallback(a, b) {
      let result = a.average_total_percent - b.average_total_percent;
      return direction ? result : -result;
    }

    function durationSortCallback(a, b) {
      let result = a.duration_ms - b.duration_ms;
      return direction ? result : -result;
    }

    function defaultSortCallback(a, b) {
      return 0;
    }

    if (!items) {
      return items;
    }

    switch(param) {
      case 'name':
        callback = nameSortCallback;
        break;
      case 'reservation':
        callback = reservationSortCallback;
        break;
      case 'congestion':
        callback = congestionSortCallback;
        break;
      case 'duration':
        callback = durationSortCallback;
        break;
      default:
        callback = defaultSortCallback;
        break;
      }

    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.sort(callback);
  }
}
