import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-events-modal-filter',
  templateUrl: './events-modal-filter.component.html',
  styleUrls: ['./events-modal-filter.component.styl']
})
export class EventsModalFilterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
