import { Injectable } from '@angular/core';
import { transliterate as tr, slugify } from 'transliteration';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class TranslitService {

  constructor(private router: Router) { }

  getHumanReadableId(name:string, id:string) {
    let result = `${slugify(name)}-${id}`;
    return result;
  }

  getIdFromHumanReadable(humanReadableId:string) {
    let arr = humanReadableId.split('-');
    let result = arr[arr.length - 1];
    return result;
  }

  checkUrl(name, id, url) {
    let rightUrl = this.getHumanReadableId(name, id);
    let result = url === rightUrl;
    if (!result) {
      console.log('REDIRECT');
      this.router.navigate(['404']);
    }
    return result;
  }
}
