import { Directive, OnInit, AfterViewInit, ElementRef, Input, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';

declare var $: any;

@Directive({
  selector: '[appLmTextarea]'
})
export class LmTextareaDirective {

  @Input() placeholder;
  @Input() fieldDescription;
  @Input() bigFont: boolean;
  @Input() errorMessage: string;
  isBrowser;
  placeholderElem;
  descriptionElem;
  isPasswordShown = false;
  constructor(private el: ElementRef,
              @Inject(PLATFORM_ID) platformId: string) {
                this.isBrowser = isPlatformBrowser(platformId);
              }

  ngOnInit() {
    if (this.isBrowser) {
      this.el.nativeElement.classList.add('lm-textarea');
      if (this.bigFont) {
        this.el.nativeElement.classList.add('lm-input-big');
      }
      let wrapper = $('<label class="lm-textarea-wrapper"></label>');
      $(this.el.nativeElement).wrap(wrapper);
      if (this.fieldDescription) {
        this.descriptionElem = $(`<div class="lm-input-field-description">${this.fieldDescription}</div>`);
        // this.descriptionElem.css('top', 0);
        // this.descriptionElem.css('left', '100%');
        // this.descriptionElem.css('padding-left', '16px');
        // this.descriptionElem.css('width', '266px');
        // this.descriptionElem.css('position', 'absolute');
        this.descriptionElem.css('display', 'none');
        $(this.el.nativeElement).closest('.lm-textarea-wrapper').append(this.descriptionElem);
      }
      if (this.errorMessage) {
        let elem = $(`<div class="lm-input-error-message">${this.errorMessage}</div>`);
        $(this.el.nativeElement).closest('.lm-textarea-wrapper').append(elem);
      }
      this.el.nativeElement.onfocus = this.onFocus;
      this.el.nativeElement.onblur = this.onBlur;
      this.el.nativeElement.oninput = this.onInput;
    }
  }
  private onFocus = function() {
    this.checkDescription();
  }.bind(this)

  private onBlur = function() {
    this.checkDescription(true);
  }.bind(this)

  private onInput = function() {
    this.checkDescription();
  }.bind(this)

  private checkDescription = function(hide) {
    if (this.descriptionElem) {
      if (!hide && !this.el.nativeElement.value && this.el.nativeElement.focus) {
        this.descriptionElem.fadeIn(300);
      } else {
        this.descriptionElem.fadeOut(300);
      }
    }
  }
}
