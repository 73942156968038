import { Pipe } from '@angular/core';

@Pipe({
  name: 'storageSort'
})
export class StorageSortPipe {

  transform(items: any[], param: string, direction: boolean) {
    let callback;
    function dateSortCallback(a, b) {
      let result = new Date(a.date).getTime() - new Date(b.date).getTime();
      return direction ? result : -result;
    }
    function paramsSortCallback(param, a, b) {
      let result = a[param] - b[param];
      return direction ? result : -result;
    }
    function defaultSortCallback(a, b) {
      return 0;
    }

    if (!items) {
      return items;
    }

    switch(param) {
      case 'date':
        callback = dateSortCallback;
        break;
      case 'occupied':
        callback = paramsSortCallback.bind(null, 'occupied');
        break;
      case 'overdue':
        callback = paramsSortCallback.bind(null, 'overdue');
        break;
      case 'free':
        callback = paramsSortCallback.bind(null, 'free');
        break;
      default:
        callback = defaultSortCallback;
        break;
      }

    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.sort(callback);
  }

}
