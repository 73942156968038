import { Pipe } from '@angular/core';

@Pipe({
  name: 'readableArea'
})
export class ReadableAreaPipe {

  transform(value: any, args?: any): any {
    switch (value) {
      case 'DECORATION':
        return 'Декор стен';
      case 'WOOD':
        return 'Дерево';
      case 'SEWING':
        return 'Текстиль';
      case 'METAL':
        return 'Металл';
      case 'LIGHT':
        return 'Освещение';
      case 'PAINTING':
        return 'Покраска';
      case 'RESTAVRATION':
        return '2-я жизнь';
      case 'PRODUCTION':
        return 'Студия';
      case 'ELECTRONIC':
        return '3D и ЧПУ';
      case 'FREE':
        return 'Свободная'
    }
  }

}
