import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef, Input, Output, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { IdeasService } from '../../services/ideas.service';
import { EventsService } from '../../services/events.service';
import { CoursesService } from '../../services/courses.service';
import { UserService } from '../../services/user.service';
import { WriteCommentComponent } from '../write-comment/write-comment.component';
import { NgxImageGalleryComponent, GALLERY_IMAGE, GALLERY_CONF } from "ngx-image-gallery";
import { TransferState, makeStateKey } from '@angular/platform-browser';
import { isPlatformBrowser } from '@angular/common';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

const COMMENTS_KEY = makeStateKey('comments');

@Component({
  selector: 'app-comments',
  templateUrl: './comments.component.html',
  styleUrls: ['./comments.component.styl']
})
export class CommentsComponent implements OnInit, OnDestroy {
  @Input() idea;
  @Input() event;
  @Input() course;
  @Output() updateFeedEmitter = new EventEmitter();
  @ViewChild(WriteCommentComponent) writeComment;
  @ViewChild('commentsImgGallery') commentsGallery: NgxImageGalleryComponent;
  @ViewChild(ClickOutsideDirective) clickOutside: ClickOutsideDirective;
  fieldOpened: boolean = false;
  commentsFeed;
  currentId;
  currentOwnerId;
  canComment = false;
  service;
  type;
  conf: GALLERY_CONF = {
    imageOffset: '0px',
    showDeleteControl: false,
    showImageTitle: false,
    showThumbnails: false
  };
  commentsGalleryImages: GALLERY_IMAGE[] = [];
  isBrowser;

  constructor(private _ref: ChangeDetectorRef,
              private ideasService: IdeasService,
              private eventsService: EventsService,
              private state: TransferState,
              private coursesService: CoursesService,
              private userService: UserService,
              @Inject(PLATFORM_ID) platformId: string) {
                this.isBrowser = isPlatformBrowser(platformId);
              }

  ngOnInit() {
    if (this.idea) {
      this.currentId = this.idea.id;
      this.currentOwnerId = this.idea.owner.id;
      this.service = this.ideasService;
      this.type = 'IDEA';
    } else if (this.event) {
      this.currentId = this.event.id;
      this.currentOwnerId = this.event.maker_id;
      this.service = this.eventsService;
      this.type = 'EVENT';
    } else if (this.course) {
      this.currentId = this.course.id;
      this.currentOwnerId = this.course.owner.id;
      this.service = this.coursesService;
      this.type = 'COURSE';
    }
    this.commentsFeed = this.state.get(COMMENTS_KEY, null as any);
    if (!this.commentsFeed) {
      this.updateFeed();
    } else {
      this.updateCommentsCount();
      this.canComment = !!this.userService.userId;
    }
    this.userService.updateSubject.subscribe(() => {
      this.updateRole();
    })

  }

  ngOnDestroy() {
    this.state.set(COMMENTS_KEY, null as any);
  }

  updateFeed() {
    this.canComment = !!this.userService.userId;
    if (this.service.getComments) {
      this.service.getComments(this.currentId).subscribe((data) => {
        let comments = data.result.sort((a, b) => {
          return new Date(a.created_date) < new Date(b.created_date);
        });
        if (!this.commentsFeed) {
          this.state.set(COMMENTS_KEY, comments as any);
        }
        this.commentsFeed = comments;
        this.updateCommentsCount();
      });
    }
  }

  updateCommentsCount() {
    let commentsCount = 0;

    this.commentsFeed.forEach(item => {
      if (item.replies) {
        commentsCount += 1 + item.replies.length;
      } else  {
        commentsCount++;
      }
    })
    this.updateFeedEmitter.emit(commentsCount);
  }

  updateRole() {
    this.canComment = !!this.userService.userId;
  }

  openField() {
    this.fieldOpened = true;
    this._ref.detectChanges();
    this.clickOutside.enable();
  }

  closeField() {
    this.fieldOpened = false;
    this.clickOutside.disable();
  }

  dontCloseWriter(state) {
    if (state) {
      this.clickOutside.disable();
    } else {
      this.clickOutside.enable();
    }
  }

  sendComment(evt) {
    if (this.service.sendComment) {
      this.service.sendComment(this.currentId, evt).subscribe((data) => {
        this.writeComment.onSuccess('Комментарий отправлен');
        this.closeField();
        this.updateFeed();
      }, () => {
        this.writeComment.onError('Ошибка при отправке комментария');
      })
    }
  }

  initModalGallery(url) {
    this.commentsGalleryImages = [{
      url: url
    }]
    setTimeout(() => {
      this.commentsGallery.open();
    }, 50);
  }
}
