import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { parseApiError } from '../../utils';

@Component({
  selector: 'app-modal-recovery-password-email',
  templateUrl: './modal-recovery-password-email.component.html',
  styleUrls: ['./modal-recovery-password-email.component.styl']
})
export class ModalRecoveryPasswordEmailComponent implements OnInit {
  recoveryForm;
  isSuccess;
  isError;
  errorMessage;
  isLoading;
  constructor(private fb: FormBuilder, private userService: UserService, private modalService: ModalService) { }

  ngOnInit() {
    this.recoveryForm = this.fb.group({
      email: ['', [Validators.required, Validators.minLength(5), Validators.pattern(/^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})/)]]
    });
  }

  submit() {
    this.isLoading = true;
    this.userService.resetPassword(this.recoveryForm.value.email).subscribe((data) => {
      this.isSuccess = true;
      this.isLoading = false;
    }, (e) => {
      let error = parseApiError(e);
      this.isLoading = false;
      this.isError = true;
      if (error === 'Invalid email') {
        this.errorMessage = 'Аккаунта с таким email не существует';
      } else {
        this.errorMessage = 'Ошибка'
      }
    })
  }

  closeModal() {
    this.modalService.nextPage(null, null);
  }

  onFormChange() {
    this.isError = false;
  }

}
