import { Pipe } from '@angular/core';
import { combineDates, getCurrentMoscowTime } from '../utils';
@Pipe({
  name: 'eventPastFilter'
})
export class EventPastFilterPipe {

  transform(items: any[], param: string) {
    if (!items) {
      return items;
    }
    if (!param) {
      param = 'future';
    }
    if (param === 'all') {
      return items;
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter(item => {
      if (param === 'past') {
        if (item.status) {
          return item.status !== 'PLANNED' && item.entity_status !== 'PLANNED';
        } else {
          return combineDates(item.start_date, item.start_time) <= getCurrentMoscowTime();
        }

      } else {
        if (item.status) {
          return item.status === 'PLANNED' || item.entity_status === 'PLANNED';
        } else {
          return combineDates(item.start_date, item.start_time) > getCurrentMoscowTime();
        }
      }
    })
  }

}
