import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {

  constructor(private api: ApiService) { }
  uploadFile(file, module, entityName?) {
    let formData: FormData = new FormData();
    formData.append('file', file, file.name);
    let params:any = { module };
    if (entityName) {
      params.entityName = entityName;
    }
    return this.api.post('v1/files/upload', formData, params, { });
  }

  checkRT(id) {
    return this.api.get('v1/files/check/rutube', { id })
  }
}
