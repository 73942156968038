import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ConsultationsService } from '../../services/consultations.service';
import { UserService } from '../../services/user.service';
import { EquipmentsService } from '../../services/equipments.service';
import { CurrentEventService } from '../../services/current-event.service';
import { Router, ActivatedRoute } from '@angular/router';
import { getCurrentMoscowTime } from '../../utils';
import { first } from 'rxjs/operators';
import { getApiTime, parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';
import { CalendarService } from '../../services/calendar.service';

import * as moment from 'moment';

@Component({
  selector: 'app-modal-reserve-equipment-for-consult',
  templateUrl: './modal-reserve-equipment-for-consult.component.html',
  styleUrls: ['./modal-reserve-equipment-for-consult.component.styl']
})
export class ModalReserveEquipmentForConsultComponent implements OnInit {
  consultParams;
  consultLoading;
  userLoading;
  user;
  error;
  isToday;
  currentRoute;
  passed;
  consultId;
  reserveLoading;
  equipmentSearchParams;
  constructor(private modalService: ModalService,
              private consultationsService: ConsultationsService,
              private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private equipmentsService: EquipmentsService,
              private messagesService: MessagesService,
              private router: Router,
              private calendarService: CalendarService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    this.consultId = this.activatedRoute.snapshot.queryParams.consultation;
    if (this.consultId) {
      this.consultLoading = true;
      this.consultationsService.getOrderedConsultationById(this.consultId).subscribe((data) => {
        this.consultParams = data.result;
        this.equipmentSearchParams = {
          reservation_date: this.consultParams.start_time,
          reservation_start_time: this.consultParams.start_time,
          reservation_end_time: this.consultParams.end_time
        }
        this.passed = new Date(this.consultParams.start_time) < new Date(getCurrentMoscowTime());
        let date = new Date(data.result.start_time).setHours(0,0,0,0);
        this.isToday = (date === new Date().setHours(0,0,0,0) || date === new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0));
        this.consultLoading = false;
        this.userLoading = true;
        this.userService.getUserData(data.result.user_id).subscribe((data) => {
          this.userLoading = false;
          this.user = data.result;
        }, () => {
          this.userLoading = false;
          this.error = true;
        })
      })
    } else {
      this.error = true;
    }
  }

  nextStep() {
    this.modalService.nextPage({
      reserveParams: {},
      reserveSettings: {
        equipmentName: 'test',
        type: 'consultation',
        date: new Date(),
        sessions: [{
          start_time: new Date(),
          end_time: new Date(),
          client: {first_name: 'Jessy'}
        }]
      }
    }, null)
  }

  closeModal() {
    this.modalService.onClose();
  }

  back() {
    this.modalService.back();
  }

  selectEquipment(evt) {
    let params: any = {
      amount: 1,
      reservation_type: 'FOR_CONSULTATION',
      consultation_id: this.consultId,
      // user_id: this.user.id,
      timings: [{
        date: this.consultParams.start_time,
        start_time: this.consultParams.start_time,
        end_time: this.consultParams.end_time,
        consultation_id: this.consultParams.id
      }]
    }
    // this.onSuccess(evt, params);
    this.reserveLoading = true;
    this.equipmentsService.reserveEquip(evt.id, params).pipe(first()).subscribe((data) => {
      this.calendarService.updateEmitter();
      this.onSuccess(evt, params);
    }, (e) => {
      this.reserveLoading = false;
      let error = parseApiError(e);
      if (error === 'not_enough') {
        this.messagesService.showMessage('На это время нет свободного оборудования.', false, false, true);
      } else {
        this.messagesService.showMessage('Ошибка при бронировании оборудования.', false, false, true);
      }
    })
  }


  onSuccess(equipment, params) {
    let settings: any = {
      reserveParams: params,
      reserveSettings: {
        parsedSessionsTime: [{
          date: moment(this.consultParams.start_date).format('YYYY-MM-DD'),
          start_time: getApiTime(moment(this.consultParams.start_time).format('HH:mm')),
          end_time: getApiTime(moment(this.consultParams.end_time).format('HH:mm')),
          consultation_id: this.consultParams.id
        }],
        isMaster: true,
        equipmentId: equipment.id,
        equipmentName: equipment.name,
        type: 'consultation',
        sessions: [{ ...this.consultParams, ...{ client: this.user } }],
        date: this.consultParams.start_time,
      }
    }
    this.modalService.nextPage({...settings, isMe: false}, null);
  }

  // onSubmit() {
  //   const params: any = {
  //     amount: 1,
  //     reservation_type: getApiReserveType(this.selectedType),
  //     user_id: this.userId,
  //   };
  //   if (this.oneInterval) {
  //     this.selectedSessions = this.getLastInterval(this.selectedSessions);
  //   }
  //   this.onReserve.next({
  //     reserveParams: params,
  //     reserveSettings: {
  //       parsedSessionsTime: this.selectedSessions.map(session => ({
  //         date: moment(this.selectedDate).format('YYYY-MM-DD'),
  //         start_time: getApiTime(session.start_time || moment(session.start).format('HH:mm')),
  //         end_time: getApiTime(session.end_time || moment(session.end).format('HH:mm')),
  //         course_timing_id: session.item ? session.item.course_timing_id : null,
  //         consultation_id: session.item ? session.item.id : null
  //       })),
  //       isMaster: this.isMaster,
  //       equipmentId: this.equipmentId,
  //       equipmentName: this.equipment.name,
  //       type: this.selectedType,
  //       sessions: this.selectedSessions.map(item => {
  //         if (item.item) {
  //           return item.item;
  //         } else {
  //           return item;
  //         }
  //       }),
  //       date: this.selectedDate,
  //     }
  //   });
  // }

}
