import { Component, OnInit, Input } from '@angular/core';
import { EventsService } from '../../services/events.service';
import { CoursesService } from '../../services/courses.service';
import { EquipmentsService } from '../../services/equipments.service';
import { ConsultationsService } from '../../services/consultations.service';
import { MessagesService } from '../../services/messages.service';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { Router } from '@angular/router';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { spaces, parseApiError } from '../../utils';
@Component({
  selector: 'app-admin-calendar-item',
  templateUrl: './admin-calendar-item.component.html',
  styleUrls: ['./admin-calendar-item.component.styl']
})
export class AdminCalendarItemComponent implements OnInit {
  @Input() event: any;
  @Input() user: any;
  myId;
  superadmin;
  admin;
  currentRoute;
  spaces = spaces;
  paid = false;
  came = false;
  constructor(private eventsService: EventsService,
              private router: Router,
              private modalService: ModalService,
              private coursesService: CoursesService,
              private equipmentsService: EquipmentsService,
              private userService: UserService,
              private consultationsService: ConsultationsService,
              private messagesService: MessagesService,
              private cancelRegistrationService: CancelRegistrationService) { }
  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    this.myId = this.userService.userId;
    this.superadmin = this.userService.userData.role === 'SUPER_ADMIN';
    this.admin = this.userService.userData.role === 'MAKER';
    if (Array.isArray(this.event.mini_courses)) {
      this.event.mini_courses.sort((a, b) => a.start_time - b.start_time);
    }
  }

  togglePaid(params?) {
    if (this.event.entity_type === 'EVENT') {
      this.eventsService.paid(this.event.entity_id, this.event.user_id, !this.event.paid).subscribe((data) => {
        this.event.paid = data.result;
      }, () => {
        this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.')
      })
    } else if (this.event.entity_type === 'COURSE') {
      let payModel: any = {
        userId: this.event.user_id,
        paid: !this.event.paid
      }
      if (params && params.certificateNumber) {
        payModel.certificateNumber = params.certificateNumber;
      }
      this.coursesService.paid(this.event.entity_second_id, this.event.entity_id, payModel).subscribe((data) => {
        this.event.paid = data.result;
        if (payModel.certificateNumber) {
          event['certificate_number'] = payModel.certificateNumber;
        }
      }, (e) => {
        let error = parseApiError(e);
        if (error === 'certificate-already-used') {
          this.messagesService.showMessage('Этот сертификат уже используется.', null, false, true)
        } else {
          this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.', null, false, true)
        }
      })
    } else if (this.event.entity_type === 'CONSULTATION') {
      this.consultationsService.paid(this.event.entity_id, !this.event.paid).subscribe((data) => {
        this.event.paid = data.result;
      }, () => {
        this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.')
      })
    }

  }


    toggleCame() {
      if (this.event.entity_type === 'EVENT') {
        this.eventsService.attend(this.event.entity_id, this.event.user_id, !this.event.attended).subscribe((data) => {
          this.event.attended = data.result;
        }, () => {
          this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.')
        })
      } else if (this.event.entity_type === 'COURSE') {
        this.coursesService.attend( this.event.entity_second_id, this.event.entity_id, this.event.user_id, !this.event.attended).subscribe((data) => {
          this.event.attended = data.result;
        }, () => {
          this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.')
        })
      } else if (this.event.entity_type === 'CONSULTATION') {
        this.consultationsService.attend(this.event.entity_id, !this.event.attended).subscribe((data) => {
          this.event.attended = data.result;
        }, () => {
          this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.')
        })
      }
    }

    getDescription() {
      let result = [];
      if (this.event.areas && this.event.areas.length) {

        if (+this.event.areas.length === 1) {
          result.push('Пространство «' + this.spaces[this.event.areas[0].toLowerCase()] + '»');
        } else if (!this.event.areas.length) {
          result.push('Пространство «' + this.spaces[this.event.areas.toLowerCase()] + '»');
        } else {
          let str = '';
          this.event.areas.forEach((item, index) => {
            if (index === 0) {

              str += `«${this.spaces[item.toLowerCase()]}»`;
            } else if (index === this.event.areas.length - 1) {
              str += ` и «${this.spaces[item.toLowerCase()]}»`;
            } else {
              str += `, «${this.spaces[item.toLowerCase()]}»`;
            }
          });
          result.push('Пространства ' + str);
        }
      } else {
        return '';
      }
      if (this.event.master && this.event.master.first_name) {
        result.push('мастер ' + this.event.master.first_name);
      }
      return result.join(', ');
    }

    cancelReservation() {
      this.cancelRegistrationService.cancelReservation(this.event.entity_id, this.event.equipment_timing_ids, this.event.user_id, this.event)
    }

    passMiniCourse(miniCourse) {
       let params = {userId: this.user.id, passed: !miniCourse.passed};
       this.equipmentsService.passMiniCourse(miniCourse.equipment_id, miniCourse.mini_course_id, params).subscribe((data) => {
         miniCourse.passed = !miniCourse.passed;
       })
     }


}
