import { ReserveType } from './interfaces';
import { MOSCOW_TIME_ZONE } from './config';
import * as moment from 'moment';

export function addZero(i) {
  return ('0' + i).slice(-2);
}

export function parseStringToTime(time: string, date = new Date()) {
  const timeSplit = time.split(':');

  if (+timeSplit[2]) {
    return date.setHours(+timeSplit[0], +timeSplit[1], +timeSplit[2]);
  } else {
    return date.setHours(+timeSplit[0], +timeSplit[1]);
  }
}

export function parseTimeToString(date: Date) {
  return `${addZero(date.getHours())}:${addZero(date.getMinutes())}`;
}

export function parseDateToString(date: Date) {
  let year = new Date(date).getFullYear();
  let month = addZero((new Date(date).getMonth() + 1) + '');
  let day = addZero(new Date(date).getDate() + '');
  return `${year}-${month}-${day}`;
}

export function getFormatTime(time: string) {
  return parseTimeToString(new Date(parseStringToTime(time)));
}

export function getHoursRange(startTime, amount) {
  const range = [];
  const date = new Date();
  date.setHours(startTime, 0);
  range.push(parseTimeToString(date));

  for (let i = 0; i < amount; i++) {
    date.setHours(date.getHours() + 1);
    range.push(parseTimeToString(date));
  }

  return range;
}

export function getHoursRangeFromInterval(startTime, endTime) {
  const range = [];
  const date = new Date();
  date.setHours(startTime, 0);
  range.push(parseTimeToString(date));

  for (let i = 0; i < endTime - startTime; i++) {
    date.setHours(date.getHours() + 1);
    range.push(parseTimeToString(date));
  }

  return range;
}

export function getDatesRange(startDate, endDate) {
  let start = new Date(getGreenwichDate(startDate));
  let end = new Date(getGreenwichDate(endDate));
  let result = [];
  do {
    result.push(start.getTime());
    start = new Date(start.setDate(start.getDate() + 1));
  } while (start.getTime() <= end.getTime());
  // console.log(result);
  return result;
}


export function getApiReserveType(reserveType: ReserveType) {
  return {
    'course': 'FOR_COURSE',
    'consultation': 'FOR_CONSULTATION',
    'reservation': 'FOR_ME',
  }[reserveType];
}

export function getApiTime(time: string) {
  return moment(`2018-01-01 ${time}`).utc().format('HH:mm:ss');
}

export function notNull(value) {
  return value !== null && value !== undefined;
}

export function compareMonth(a, b) {
  let month1 = new Date(new Date(a).setDate(1)).setHours(0, 0, 0, 0);
  let month2 = new Date(new Date(b).setDate(1)).setHours(0, 0, 0, 0);
  if (month1 > month2) {
    return 1;
  } else if (month1 < month2) {
    return -1;
  } else {
    return 0;
  }
}

export function sameYear(start, end) {
  return new Date(start).getFullYear() === new Date(end).getFullYear();
}

export const areas: any[] = [
  {
    title: 'Все пространства',
    value: 'all'
  }, {
    title: 'Декор стен',
    value: 'DECORATION'
  }, {
    title: 'Дерево',
    value: 'WOOD'
  }, {
    title: 'Текстиль',
    value: 'SEWING'
  }, {
    title: 'Металл',
    value: 'METAL'
  }, {
    title: 'Освещение',
    value: 'LIGHT'
  }, {
    title: 'Покраска',
    value: 'PAINTING'
  }, {
    title: '2-я жизнь',
    value: 'RESTAVRATION'
  }, {
    title: '3D и ЧПУ',
    value: 'ELECTRONIC'
  }
];

export const areasShort: any[] = [
  {
    title: 'Все',
    value: 'all'
  }, {
    title: 'Декор стен',
    value: 'DECORATION'
  }, {
    title: 'Дерево',
    value: 'WOOD'
  }, {
    title: 'Текстиль',
    value: 'SEWING'
  }, {
    title: 'Металл',
    value: 'METAL'
  }, {
    title: 'Освещение',
    value: 'LIGHT'
  }, {
    title: 'Покраска',
    value: 'PAINTING'
  }, {
    title: '2-я жизнь',
    value: 'RESTAVRATION'
  }, {
    title: '3D',
    value: 'ELECTRONIC'
  }
];

export let materialTypes = [{name: 'Дерево', id: 'WOOD'}, {name: 'Металл', id: 'METAL'}, {name: 'Свет', id: 'LIGHT'}, {
  name: 'Ткань',
  id: 'TEXTILE'
}, {name: '3D', id: 'THREE_D'}];

export const materialTypesEquipments = [...materialTypes, {name: 'Пленка', id: 'FILM'}];

export const areaTypes = [
  {
    title: 'Декор стен',
    value: 'DECORATION'
  }, {
    title: 'Дерево',
    value: 'WOOD'
  }, {
    title: 'Текстиль',
    value: 'SEWING'
  }, {
    title: 'Металл',
    value: 'METAL'
  }, {
    title: 'Освещение',
    value: 'LIGHT'
  }, {
    title: 'Студия',
    value: 'PRODUCTION'
  }, {
    title: '2-я жизнь',
    value: 'RESTAVRATION'
  }, {
    title: '3D и ЧПУ',
    value: 'ELECTRONIC'
  }
];
export const areaTypesShort = [
  {
    title: 'Декор стен',
    value: 'DECORATION'
  }, {
    title: 'Дерево',
    value: 'WOOD'
  }, {
    title: 'Текстиль',
    value: 'SEWING'
  }, {
    title: 'Металл',
    value: 'METAL'
  }, {
    title: 'Освещение',
    value: 'LIGHT'
  }, {
    title: 'Студия',
    value: 'PRODUCTION'
  }, {
    title: '2-я жизнь',
    value: 'RESTAVRATION'
  }, {
    title: '3D',
    value: 'ELECTRONIC'
  }
];

export const areaTypesEquipments = [
  ...areaTypes,
  {
    title: 'Свободная',
    value: 'FREE'
  }
];

export const areaTypesEquipmentsShort = [
  ...areaTypesShort,
  {
    title: 'Свободная',
    value: 'FREE'
  }
];

export const uses = [
  {
    title: 'Пилить',
    value: 'SAW'
  }, {
    title: 'Сверлить',
    value: 'DRILL'
  }, {
    title: 'Шлифовать',
    value: 'GRIND'
  }, {
    title: 'Фрезеровать',
    value: 'MILL'
  }, {
    title: 'Печатать',
    value: 'PRINT'
  }, {
    title: 'Шить',
    value: 'SEW'
  }, {
    title: 'Гравировать',
    value: 'ENGRAVE'
  }, {
    title: 'Сваривать',
    value: 'WELD'
  }, {
    title: 'Другое',
    value: 'OTHER'
  }
];

export let spaces = {
  wood: 'Дерево',
  metal: 'Металл',
  light: 'Освещение',
  painting: 'Покраска',
  production: 'Студия',
  decoration: 'Декор стен',
  restavration: '2-я жизнь',
  sewing: 'Текстиль',
  electronic: '3D и ЧПУ',
  free: 'Свободная'
};

export function groupConsultationsByDay(data) {
  let consultations = data.sort((a, b) => a.start_time - b.start_time);
  let result = [];
  let that = this;

  function newDay(item) {
    result.push({
      date: that.dropTime(item.start_time),
      masters: [{
        master: item.maker,
        sessions: [{
          id: item.id,
          start: item.start_time,
          end: item.end_time
        }]
      }]
    });
  }

  let currentDate;
  consultations.forEach((item) => {
    if (this.dropTime(item.start_time) === currentDate && result.length) {
      let sameMaster;
      if (result[result.length - 1].masters) {
        let sameMasters = result[result.length - 1].masters.filter(x => x.master.id === item.maker.id);
        if (sameMasters.length) {
          sameMaster = sameMasters[0];
        }
      }
      if (sameMaster) {
        sameMaster.sessions.push({
          id: item.id,
          start: item.start_time,
          end: item.end_time
        });
      } else {
        result[result.length - 1].masters.push({
          master: item.maker,
          sessions: [{
            id: item.id,
            start: item.start_time,
            end: item.end_time
          }]
        });
      }
    } else {
      newDay(item);
      currentDate = this.dropTime(item.start_time);
    }
  });
  return result;
}

export function getMasters(data) {
  let mastersArr = data.map(item => item.maker);
  let result = [];
  mastersArr.forEach(item => {
    if (!result.some(elem => elem.id === item.id)) {
      result.push(item);
    }
  });
  return result.sort((a, b) => a.id - b.id);
}

export function dropTime(date) {
  return new Date(date).setHours(0, 0, 0, 0);
}

export function getConsultById(id, consultationsArr) {
  return consultationsArr.filter(item => item.id === id)[0];
}

export function groupByDay(data, param, idParam?) {
  function dropTime(date) {
    return new Date(date).setHours(0, 0, 0, 0);
  }

  let items = data.sort((a, b) => a[param] - b[param]);
  // console.log(items.map(item => new Date(item[param])));
  let result = [];

  function newDay(item) {
    result.push({
      date: dropTime(item[param]),
      sessions: [{
        id: item[idParam || 'id'],
        start: item.start_time,
        end: item.end_time,
        item: item
      }]
    });
  }

  let currentDate;
  items.forEach((item) => {
    if (dropTime(item[param]) === currentDate && result.length) {
      result[result.length - 1].sessions.push({
        id: item[idParam || 'id'],
        start: item.start_time,
        end: item.end_time,
        item: item
      });
    } else {
      newDay(item);
      currentDate = dropTime(item[param]);
    }
  });
  result.forEach(item => {
    item.sessions.sort((a, b) => {
      return a.start - b.start;
    });
  });
  // console.log(result)
  return result;
}


export function getMonthEdges(date) {
  let firstMonthDay = new Date(date).setDate(1);
  let firstWeekDay = +new Date(firstMonthDay).getDay() || 7;
  let lastMonthDay = new Date(new Date(new Date(date).setDate(1)).setMonth(new Date(date).getMonth() + 1)).setDate(0);
  let lastWeekDay = +new Date(lastMonthDay).getDay() || 7;
  let firstClaendarDay = new Date(new Date(firstMonthDay).setDate(new Date(firstMonthDay).getDate() - (firstWeekDay - 1)));
  let lastClaendarDay = new Date(new Date(lastMonthDay).setDate(new Date(lastMonthDay).getDate() + (7 - lastWeekDay)));
  return [firstClaendarDay, lastClaendarDay];
}

export function getCurrentMoscowTime() {
  var d = new Date();
  var utc = d.getTime() + (d.getTimezoneOffset() * 60000);
  var nd = new Date(utc + (3600000 * 3));
  return nd;
}

export function combineDates(date, time) {
  let result = new Date(date);
  result = new Date(result.setHours(new Date(time).getHours()));
  result = new Date(result.setMinutes(new Date(time).getMinutes()));
  return result;
}

export function getGreenwichDate(date) {
  return new Date(new Date(date).setHours(-(new Date().getTimezoneOffset() / 60), 0, 0, 0)).toISOString();
}

export function getFirstMonthDate(date) {
  return getGreenwichDate(new Date(date).setDate(1));
}

export function getLastMonthDate(date) {
  return getGreenwichDate(new Date(new Date(date).setDate(32)).setDate(0));
}

export function compressTimings(timings) {
  if (timings.every && timings.every(item => item.start_time && item.end_time)) {
    timings.sort((a, b) => {
      return a.start_time - b.start_time;
    });
    let compressed = [];
    let currentIndex = 0;
    let fromIndex;
    while (currentIndex <= timings.length - 1) {
      let fromIndex = currentIndex;
      while (currentIndex + 1 <= timings.length - 1 && timings[currentIndex].end_time === timings[currentIndex + 1].start_time) {
        currentIndex++;
      }
      compressed.push({start_time: timings[fromIndex].start_time, end_time: timings[currentIndex].end_time});
      currentIndex++;
    }
    return compressed;
  }
}

export function getDatesDiff(date1, date2, abs?) {
  if (abs) {
    return Math.abs((new Date(date1).setHours(0, 0, 0, 0) - new Date(date2).setHours(0, 0, 0, 0)) / (24 * 3600 * 1000));
  } else {
    return (new Date(date1).setHours(0, 0, 0, 0) - new Date(date2).setHours(0, 0, 0, 0)) / (24 * 3600 * 1000);
  }
}

export let phoneMask = (rawValue) => {
  if (rawValue && (rawValue[0] === '+' || rawValue[0] === '7')) {
    return ['+', /7/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
  } else {
    return [/8/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, /\d/, ' ', /\d/, /\d/, ' ', /\d/, /\d/];
  }
};

export function parseApiError(e) {
  let error;
  try {
    error = JSON.parse(e._body);
    error = error.result && error.result.errors
      ? error.result.errors : null;
  } catch {
    error = e;
  }
  if (error.error) {
    error = error.error;
  }
  if (error.result) {
    error = error.result;
  }
  if (error.errors) {
    error = error.errors;
  }
  if (error.error) {
    error = error.error;
  }
  if (error.errors) {
    error = error.errors;
  }
  if (error.forEach && error.length) {
    error = error[0];
  }
  return error;
}

export function initClickOutsideLisener() {
  window.addEventListener('click', function cb(event) {
    event.currentTarget.removeEventListener(event.type, cb);
  }, {once: true});
}


export let removingEquipReasonsMap = {
  PERMANENTLY_DELETED: 'Оборудование удалено из парка',
  ADDED_BY_MISTAKE: 'Оборудование добавлено по ошибке',
  OTHER: 'Другая причина...'
};

export let removingEquipReasons = [
  {
    id: 'PERMANENTLY_DELETED',
    title: removingEquipReasonsMap['PERMANENTLY_DELETED']
  },
  {
    id: 'ADDED_BY_MISTAKE',
    title: removingEquipReasonsMap['ADDED_BY_MISTAKE']
  },
  {
    id: 'OTHER',
    title: removingEquipReasonsMap['OTHER']
  }
];

export function getCanonicalLink(url) {
  let currentLink = url.split('?')[0];
  let currentQueryParams = url.split('?')[1] || '';
  let pageIndex = currentLink.indexOf('/page');
  let result = '';
  if (pageIndex + 1) {
    result = currentLink.slice(0, pageIndex);
  } else {
    result = currentLink;
  }
  if (currentQueryParams) {
    result += '?' + currentQueryParams;
  }
  if (result[0] === '/') {
    result = result.substring(1);
  }
  return result;
}

export function addSlashToEndIfNotExist(url: string) {
  return url.endsWith('/') ? url : url + '/';
}

export function encodeBase64(str: string): string {
  // @ts-ignore
  return typeof btoa === 'function' ? window.btoa(str) : Buffer.from(str).toString('base64');
}
