import { Pipe} from '@angular/core';

@Pipe({
  name: 'firstCapital'
})
export class FirstCapitalPipe {

  transform(value: any, args?: any): any {
    if (value && value.length) {
      return value[0].toUpperCase() + value.slice(1, value.length);
    } else {
      return ''
    }
  }

}
