import { Pipe } from '@angular/core';

@Pipe({
  name: 'toFixed'
})
export class ToFixedPipe {

  transform(value: any, round): any {
    if (value && !isNaN(value)) {
      return value.toFixed(round);
    } else {
      return 0;
    }
  }

}
