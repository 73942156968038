import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { EquipmentsService } from '../../services/equipments.service';

import * as moment from 'moment';
@Component({
  selector: '[app-breakdown-list-item]',
  templateUrl: './breakdown-list-item.component.html',
  styleUrls: ['./breakdown-list-item.component.styl']
})
export class BreakdownListItemComponent implements OnInit {
  @Input() breakdown;
  @Output() updateEmitter = new EventEmitter();
  constructor(private equipmentsService: EquipmentsService) { }

  ngOnInit() {
  }

  postponeBreakage(date) {
    let postponeDate = new Date(date);
    this.equipmentsService.postponeBreakage(this.breakdown.id, moment(date).format('YYYY-MM-DD')).subscribe((date) => {
      this.updateEmitter.emit();
    })
  }

  repair() {
    this.equipmentsService.repair(this.breakdown.id).subscribe(() => {
      this.updateEmitter.emit();
    });
  }

}
