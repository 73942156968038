import { Component, OnInit, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-single-media-picker',
  templateUrl: './single-media-picker.component.html',
  styleUrls: ['./single-media-picker.component.styl']
})
export class SingleMediaPickerComponent implements OnInit {
  @Input() uploader: any;
  @Input() file: any;
  @Input() title: string;
  @Input() description: string;
  @Input() hideFieldText: boolean;
  @Input() greenColor: boolean;
  hasDropZoneOver;
  constructor() { }

  ngOnInit() {
    if (!this.uploader) {
      this.uploader = new FileUploader({})
    }
    this.uploader.onAfterAddingFile = (item:any, response:any, status:any, headers:any) => {
      this.uploader.photoInvalid = false;
      if (!(item.file.type.indexOf('image/') + 1)) {
        this.uploader.queue.pop(); // clear old file & replace it with the new one
      } else if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, 1); // clear old file & replace it with the new one
      }
      if (this.file) {
        this.file.setValue(this.uploader.queue[0]._file);
        this.file.markAsTouched();
      }
    };
  }


  public fileOver(e:any):void {
    this.hasDropZoneOver = e;
  }

  removeImage(evt) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    if (this.file) {
      this.file.setValue(null);
    }
    this.uploader.cancelAll();
    this.uploader.clearQueue();
  }
  removeOldImage(evt) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.uploader.queue.pop();
    if (this.file) {
      this.file.setValue(null);
    }
  }
}
