import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { EventsService } from '../../services/events.service';
import { CurrentEventService } from '../../services/current-event.service';
import { ActivatedRoute } from '@angular/router'

@Component({
  selector: 'app-modal-enroll-event',
  templateUrl: './modal-enroll-event.component.html',
  styleUrls: ['./modal-enroll-event.component.styl']
})
export class ModalEnrollEventComponent implements OnInit {
  @Input() eventParams;
  eventLoading;
  paymentLoading;
  error;
  errorMessage;
  constructor(private modalService: ModalService,
              private eventsService: EventsService,
              private activatedRoute: ActivatedRoute,
              private currentEventService: CurrentEventService) { }

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(params => {
        if (params.event) {
          this.eventLoading = true;
          this.eventsService.getEvent(params.event).subscribe((data) => {
            this.eventParams = data.result;
            this.eventLoading = false;
            this.errorHandler(this.eventParams);
          }, () => {this.eventLoading = false})
        }
    });
  }

  enroll(payNow) {
    let model = {
      register: true,
      payment_type: payNow ? 'ONLINE' : 'ON_THE_SPOT'
    }
    this.eventsService.register(this.eventParams.id, model).subscribe((data) => {
      this.currentEventService.enrollSubject.next(this.eventParams.id);
      this.modalService.nextPage({eventPaid: payNow, eventParams: this.eventParams}, null);
    }, () => {
      this.error = true;
      this.errorMessage = 'Ошибка';
    })
  }

  sameYear(start, end) {
    return new Date(start).getFullYear() === new Date(end).getFullYear();
  }

  errorHandler(event) {
    if (event.status === 'PASSED') {
      this.error = true;
      this.errorMessage = 'Это событие уже прошло.'
    } else if (event.registered) {
      this.error = true;
      this.errorMessage = 'Вы уже записаны на это событие.'
    } else if (!event.need_registration) {
      this.error = true;
      this.errorMessage = 'Для этого события не нужна регистрация.'
    }
  }

}
