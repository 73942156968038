import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

@Component({
  selector: 'app-comment-author',
  templateUrl: './comment-author.component.html',
  styleUrls: ['./comment-author.component.styl']
})
export class CommentAuthorComponent implements OnInit {

  @Input() user;
  @Input() time;
  @Input() isAuthor;
  today;
  @ViewChild('image') userPic: ElementRef;
  constructor() { }

  ngOnInit() {
    this.today = new Date(this.time).setHours(0,0,0,0) === new Date().setHours(0,0,0,0);
  }
}
