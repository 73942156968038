import { Pipe } from '@angular/core';

@Pipe({
  name: 'courseSearch'
})
export class CourseSearchPipe {

  transform(items: any[], filter: string, param: string) {
    if (!items || !filter) {
      return items;
    }
    if (!param) {
      param = 'client';
    }
    // filter items array, items which match and return true will be kept, false will be filtered out
    return items.filter((item) => {
      return !!(item.name.toLowerCase().indexOf(filter.toLowerCase()) + 1);
    });
  }
}
