import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentEquipmentService {
  currentEquipment;

  updateSubject;
  updateReserveSubject;

  constructor() {
    this.updateReserveSubject = new Subject();
    this.updateSubject = new Subject();
  }

  updateEmitter() {
    this.updateSubject.next();
  }

  updateReserveEmitter() {
    this.updateReserveSubject.next();
  }
}
