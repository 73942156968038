import {Injectable} from '@angular/core';
import {ApiService} from './api.service';
import {RESERVE_END_TIME, RESERVE_START_TIME, SUBSCRIPTION_DAY_DEFAULT_PRICE, SUBSCRIPTION_HOUR_DEFAULT_PRICE} from '../config';

@Injectable({
  providedIn: 'root'
})

export class SubscriptionsService {

  constructor(private api: ApiService) {
  }

  newSubscription(model) {
    return this.api.post('v1/subscriptions/new', JSON.stringify(model));
  }

  paid(subscriptionId, paid) {
    return this.api.post(`v1/subscriptions/${subscriptionId}/paid`, null, {paid});
  }

  price() {
    return this.api.get('v1/subscriptions/price');
  }

  buildCards(hourPrice = SUBSCRIPTION_HOUR_DEFAULT_PRICE, dayPrice = SUBSCRIPTION_DAY_DEFAULT_PRICE) {
    return [
      {
        price: hourPrice,
        type: 'hours',
        workTime: ''
      },
      {
        price: dayPrice,
        type: 'days',
        workTime: `(${RESERVE_START_TIME}:00-${RESERVE_END_TIME}:00)`
      }
    ];
  }
}
