import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-admin-search-result-item',
  templateUrl: './admin-search-result-item.component.html',
  styleUrls: ['./admin-search-result-item.component.styl']
})
export class AdminSearchResultItemComponent implements OnInit {
  @Input() item;
  @Input() searchQuery;
  @Input() type = 'user';
  @Output() clickEmitter = new EventEmitter();
  constructor() { }

  ngOnInit() {
  }

  getMatch(str, substr) {
    let escape = function(string) {
      return string.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&')
    };
    return str.toLowerCase().search(new RegExp(escape(substr.toLowerCase())), 'gi');
  }

  onClick() {
    this.clickEmitter.emit(this.item);
  }

}
