import {Inject, Injectable, PLATFORM_ID} from '@angular/core';
import {isPlatformBrowser} from '@angular/common';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class IsBrowserService {
  isBrowser: boolean;
  isIosDevice;
  isSafari;
  hasFocus;
  isMac;
  onFocusSubject$ = new Subject();
  windowClickSubject$ = new Subject();

  constructor(@Inject(PLATFORM_ID) platformId: string) {
    this.isBrowser = isPlatformBrowser(platformId);

    if (this.isBrowser) {
      this.isIosDevice = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
      this.isMac = !!navigator.platform && /Mac68K|MacPPC|MacIntel/.test(navigator.platform);
      this.isSafari = (/safari/.test(navigator.userAgent.toLowerCase())) && !(/chrome/.test(navigator.userAgent.toLowerCase()));
    }

    if (this.isBrowser) {
      window.addEventListener('click', (e) => {
        this.windowClickSubject$.next(e);
      });
      if (this.isIosDevice) {
        let startPos: any = {};
        window.addEventListener('touchstart', (e) => {
          console.log(e);
          let touch: any = e.targetTouches || e.changedTouches || e.touches;
          touch = touch && touch[0];
          startPos.x = touch.clientX;
          startPos.y = touch.clientY;
        });
        window.addEventListener('touchend', (e) => {
          let touch: any = e.changedTouches || e.targetTouches || e.touches;
          touch = touch && touch[0];
          if (touch) {
            if (Math.abs(touch.clientX - startPos.x) + Math.abs(touch.clientY - startPos.y) < 50) {
              this.windowClickSubject$.next(e);
            }
          }
        });
      }
    }
  }

  setFocus(state) {
    this.hasFocus = state;
    this.onFocusSubject$.next(state);
  }
}
