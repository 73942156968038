import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { MessagesService } from '../../services/messages.service';
import { FormBuilder, Validators } from '@angular/forms';
import { first } from 'rxjs/operators';
import { parseApiError } from '../../utils';

@Component({
  selector: 'app-session-control-panel',
  templateUrl: './session-control-panel.component.html',
  styleUrls: ['./session-control-panel.component.styl']
})
export class SessionControlPanelComponent implements OnInit {
  workControlLoading;
  @Input() user;
  @Input() currentUserId;
  certForm;
  setCertMode: boolean;
  cert: string;
  disableToggleSession;
  constructor(private adminService: AdminService,
              private fb: FormBuilder,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.certForm = this.fb.group({
      number: [this.user.certificate_number || null, [Validators.required]],
      duration: [0, [Validators.required]],
      durationUnit: ['HOURLY', [Validators.required]],
      single: ['']
    });
    if (this.user.certificate_number) {
      this.cert = this.user.certificate_number;
    }
  }

  setCertSave() {
    if (this.cert !== this.certForm.value.number || !this.user.session_open) {
      if (this.user.session_open) {
        this.addCertificateToSession();
      } else {
        this.cert = this.certForm.value.number;
        this.setCertMode = false;
        this.user.certificate_amount = this.certForm.value.duration
        this.user.certificate_type = this.certForm.value.durationUnit
      }
    } else {
      this.setCertMode = false;
    }
  }

  setCertOpen() {
    if (!this.setCertMode) {
      setTimeout(() => {
        this.setCertMode = true;
      });
      setTimeout(() => {
        let input: any = document.querySelector('.work-control-cert-input');
        input && input.focus();
      }, 350);
    }
  }

  addCertificateToSession() {
    let model = {
      userId: this.currentUserId,
      certificateNumber: this.certForm.value.number,
      certificateAmount: this.certForm.value.duration,
      certificateType: this.certForm.value.durationUnit,
      reusable: !this.certForm.value.single
    }
    this.adminService.addCertificateToSession(model).pipe(first()).subscribe((data) => {
      this.user = data.result;
      this.messagesService.showMessage(`Сертификат добавлен.`, false, true, true);
      this.cert = this.certForm.value.number;
      this.setCertMode = false;
    }, (e) => {
      let error = parseApiError(e);
      if (error && error.error === 'Certificate time is over') {
        this.messagesService.showMessage('Недействительный сертификат.', false, false, true);
      } else {
        this.messagesService.showMessage('Ошибка при добавлении сертфифката.', false, false, true);
      }
    })
  }

  updateHours(hours) {
    this.certForm.controls.duration.setValue(hours);
  }

  setCertClose() {
    this.setCertMode = false;
  }

  setCertReset() {
    this.certForm.reset();
    this.certForm.controls.duration.setValue(0);
    this.certForm.controls.durationUnit.setValue('HOURLY');
    this.cert = '';
    this.user.certificate_amount = null;
    this.user.certificate_type = null;
  }

  toggleSession() {
    if (!this.disableToggleSession) {
      this.disableToggleSession = true;
      this.workControlLoading = true;
      if (this.user.session_open) {
        this.adminService.finishWork(this.currentUserId).pipe(first()).subscribe((data) => {
          this.user = data.result;
          this.workControlLoading = false;
          this.setCertReset();
          this.messagesService.showMessage(`Клиент ${this.user.name || ''} ${this.user.last_name || ''} завершил работу.`, false, true, true);
          this.afterToggleSession();
        }, (e) => {
          let error = parseApiError(e);
          if (error.code) {
            error = error.code;
          }
          if (error === 'not-enough-balance') {
            this.messagesService.showMessage('Недостаточно времени на абонементе.', false, false, true);
          } else {
            this.messagesService.showMessage('Ошибка при остановке времени работы клента.', false, false, true);
          }
          this.workControlLoading = false;
          this.disableToggleSession = false;
        });
      } else {
        let model:any = {
          userId: this.currentUserId
        }
        if (this.cert) {
          model.certificateNumber = this.certForm.value.number;
          model.certificateAmount = this.certForm.value.duration;
          model.certificateType = this.certForm.value.durationUnit;
          model.reusable = !this.certForm.value.single;
        }
        this.adminService.startWork(model).pipe(first()).subscribe((data) => {
          this.user = data.result;
          this.workControlLoading = false;
          this.messagesService.showMessage(`Клиент ${this.user.name || ''} ${this.user.last_name || ''} начал работу.`, false, true, true);
          this.afterToggleSession();
        }, (e) => {
          let error = parseApiError(e);
          if (error === 'certificate-time-is-over') {
            this.messagesService.showMessage('Недействительный сертификат.', false, false, true);
          } else if (error === 'certificate-already-used') {
            this.messagesService.showMessage('Сертификат используется другим пользователем.', false, false, true);
          } else {
            this.messagesService.showMessage('Ошибка при запуске времени работы клента.', false, false, true);
          }
          this.workControlLoading = false;
          this.disableToggleSession = false;
        });
      }
    }

  }

  deleteCertificate() {
    if (this.user.session_open) {
      this.adminService.removeCertificateFromSession(this.user.id).pipe(first()).subscribe((data) => {
        this.user = data.result;
        this.setCertReset();
        this.messagesService.showMessage('Сертификат удален.', false, false, true);
      }, () => {
        this.messagesService.showMessage('Не удалось отменить сертификат.', false, false, true);
      })
    } else {
      this.setCertReset();
    }

  }

  afterToggleSession() {
    this.disableToggleSession = true;
    setTimeout(() => {
      this.disableToggleSession = false;
    }, 3000)
  }

}
