import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-storage-products-extend',
  templateUrl: './storage-products-extend.component.html',
  styleUrls: ['./storage-products-extend.component.styl']
})
export class StorageProductsExtendComponent implements OnInit {
  @Output() extendEmitter = new EventEmitter();
  isOpenBox: boolean = false;
  extend:number = 1;
  constructor() { }

  ngOnInit() {
  }

  openBox() {
    this.isOpenBox = true;
  }

  closeBox() {
    if (this.isOpenBox) this.isOpenBox = false;
  }

  changeAmount(evt) {
    this.extend = evt;
  }

  submit() {
    this.closeBox();
    this.extendEmitter.emit(this.extend);
  }

}
