import { Component, OnInit, Input } from '@angular/core';
import { FileUploader } from 'ng2-file-upload';

@Component({
  selector: 'app-logo-picker',
  templateUrl: './logo-picker.component.html',
  styleUrls: ['./logo-picker.component.styl']
})
export class LogoPickerComponent implements OnInit {
  @Input() file: any;
  @Input() title: string;
  uploader;
  hasDropZoneOver;
  constructor() { }

  ngOnInit() {
    if (this.file) {
      this.file.markAsUntouched();
    }
    this.uploader = new FileUploader({});
    this.uploader.onAfterAddingFile = (item:any, response:any, status:any, headers:any) => {
      this.uploader.photoInvalid = false;
      if (!(item.file.type.indexOf('image/') + 1)) {
        this.uploader.queue.pop(); // clear old file & replace it with the new one
      } else if (this.uploader.queue.length > 1) {
        this.uploader.queue.splice(0, 1); // clear old file & replace it with the new one
      }
      if (this.file) {
        this.file.setValue(this.uploader.queue[0]._file);
        this.file.markAsTouched();
      }
    };
  }


  public fileOver(e:any):void {
    this.hasDropZoneOver = e;
  }

  removeImage(evt) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.uploader.clearQueue();
    this.file.setValue(null);
    this.file.hasErr = false;
    this.file.loading = false;
    this.file.loaded = false;
  }
  removeOldImage(evt) {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }
    this.uploader.queue.pop();
    this.file.setValue(null);
    this.file.hasErr = false;
    this.file.loading = false;
    this.file.loaded = false;
  }

}
