import {Inject, Injectable, PLATFORM_ID } from "@angular/core";
import Stomp from "stompjs";
import { Subject } from "rxjs";
import { UserService } from "./user.service";
import { isPlatformBrowser } from '@angular/common';

@Injectable({
  providedIn: 'root'
})

export class SocketService {
  updateSocket$ = new Subject();
  updateAdminSocket$ = new Subject();
  socket;
  adminSocket;
  errorHandlerClient;
  errorHandlerAdmin;
  isBrowser;

  constructor(@Inject('SOCKET') private socketUrl: string,
              @Inject(PLATFORM_ID) platformId: string,
              @Inject('ENV_NAME') private envName: string,
              private userService: UserService) {
    this.isBrowser = isPlatformBrowser(platformId);
    let errorHandler = function (connectFn) {
      let errFn = connectFn;
      let retries = 0;
      return function () {
        console.error("Could not connect websocket. Go to retry");
        if (retries >= 4) {
          console.error("Could not connect websocket. Connection failed");
          return;
        }
        setTimeout(errFn, 2000);
        retries++;
      };
    };
    if (this.isBrowser && location) {
      this.errorHandlerAdmin = errorHandler(this.initializeAdminWebSocketConnection);
      this.errorHandlerClient = errorHandler(this.initializeClientWebSocketConnection);
    }
  }

  initializeClientWebSocketConnection() {
    if (this.isBrowser && this.userService.userId) {
      let url = '/notifications/users/' + this.userService.userId;
      let protocol = "ws";
      if (location.protocol === 'https:') {
        protocol = "wss";
      }
      let ws = new WebSocket(protocol + "://" + this.socketUrl + "/app");
      this.socket = Stomp.over(ws);
      this.socket.debug = (message) => {
        if (this.envName !== 'prod') {
          console.log(message);
        }
      };
      let that = this;
      this.socket.connect({}, function (frame) {
        that.socket.subscribe(url, (message) => {
          console.log('client message');
          that.updateSocket$.next();
        });
      }, this.errorHandlerClient);
    }
  }

  disconnectClient() {
    if (this.socket) {
      this.socket.disconnect();
    }
  }

  initializeAdminWebSocketConnection() {
    if (this.isBrowser && this.userService.userId) {
      let url = '/notifications/admins';
      let protocol = "ws";
      if (location.protocol === 'https:' || location.hostname === 'localhost') {
        protocol = "wss";
      }
      let ws = new WebSocket(protocol + "://" + this.socketUrl + "/app");
      this.adminSocket = Stomp.over(ws);
      let that = this;

      this.adminSocket.connect({}, function (frame) {
        that.adminSocket.subscribe(url, (message) => {
          console.log('admin message');
          that.updateAdminSocket$.next();
        });
      }, this.errorHandlerAdmin);
    }
  }

  disconnectAdmin() {
    if (this.adminSocket) {
      this.adminSocket.disconnect();
    }
  }


}
