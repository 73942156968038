import { Component, OnInit, ElementRef, PLATFORM_ID, Inject } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { ConsultationsService } from '../../services/consultations.service';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { CancelRegistrationService } from '../../services/cancel-registration.service';
import { CurrentCourseService } from '../../services/current-course.service';
import { IsBrowserService } from '../../services/is-browser.service';

declare var $;

@Component({
  selector: 'app-modal-client-move-consult',
  templateUrl: './modal-client-move-consult.component.html',
  styleUrls: ['./modal-client-move-consult.component.styl']
})
export class ModalClientMoveConsultComponent implements OnInit {
  maker;
  area;
  consultLoading;
  moveLoading;
  consultData;
  consultsSubject$;
  currentMonth;
  consultations;
  consultDays;
  dropTime;
  selectedDate;
  selectedDay;
  selectedTime;
  selectedConsult;
  consultationsArr;
  consultForm;
  error;
  currentConsultId;
  modalLayout;
  isBrowser;
  constructor(private activatedRoute: ActivatedRoute,
              private userService: UserService,
              private modalService: ModalService,
              private consultationsService: ConsultationsService,
              private cancelRegistrationService: CancelRegistrationService,
              private currentCourseService: CurrentCourseService,
              private isBrowserService: IsBrowserService,
              private el: ElementRef) {
                this.isBrowser = this.isBrowserService.isBrowser;
              }

  ngOnInit() {
    this.modalLayout = this.el.nativeElement.closest('.lm-modal');
    this.consultLoading = true;
    this.activatedRoute.queryParams.subscribe(params => {
      if (params.consultation) {
        this.currentConsultId = params.consultation;
        this.consultationsService.getOrderedConsultationById(params.consultation).subscribe((data) => {
          this.area = data.result.area;
          this.consultData = data.result;
          this.consultLoading = false;
        }, () => this.error = true);
      } else {
        this.error = true;
      }
    });
  }

  onTimeSelect(consult) {
    this.selectedConsult = consult;
    if (consult) {
      this.scrollToBottom();
    }
  }

  closeModal() {
    this.modalService.onClose();
  }

  submit() {
    let model = {
      new_available_consultation_id: this.selectedConsult.id
    }
    this.moveLoading = true;
    this.consultationsService.moveConsultation(this.currentConsultId, model).subscribe(() => {
      this.moveLoading = false;
      this.modalService.nextPage({consultParams: this.selectedConsult, consultPaid: this.consultData.paid}, null);
      this.currentCourseService.updateEmitter();
    }, () => this.error = true)
  }

  unregister() {
    this.closeModal();
    this.cancelRegistrationService.cancelConsult(this.consultData);
  }

  onDateSelect() {
    if (this.isBrowser && window.innerWidth <= 800) {
      setTimeout(() => {
        this.scrollToElem('select-time');
      }, 100)
    }
  }

  scrollToBottom() {
    $(this.modalLayout).animate({
      scrollTop: this.modalLayout.scrollHeight - this.modalLayout.offsetHeight
    }, 1000);
  }

  scrollToElem(id) {
    let elem = $('#' + id);
    if (elem.offset()) {
      $(this.modalLayout).animate({
        scrollTop: this.modalLayout.scrollTop + $(elem).get(0).getBoundingClientRect().top
      }, 1000);
    }
  }

}
