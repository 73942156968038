import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { CANCEL_COURSE_CAUSES_NAMES } from '../../config';
import { ActivatedRoute } from '@angular/router';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-modal-notify-cancel-course',
  templateUrl: './modal-notify-cancel-course.component.html',
  styleUrls: ['./modal-notify-cancel-course.component.styl']
})
export class ModalNotifyCancelCourseComponent implements OnInit {
  @Input() isEvent: boolean;
  notificationLoading;
  notificationParams;
  notificationId;
  error;
  causesNames;

  constructor(private modalService: ModalService,
              private activatedRoute: ActivatedRoute,
              private adminService: AdminService) { }

  ngOnInit() {
    this.causesNames = CANCEL_COURSE_CAUSES_NAMES;
    this.notificationLoading = true;
    this.notificationId = this.activatedRoute.snapshot.queryParams.notification;
    if (this.notificationId) {
      let method;
      if (this.isEvent) {
        method = this.adminService.getCancelEventNotificationInfo.bind(this.adminService);
      } else {
        method = this.adminService.getCancelCourseNotificationInfo.bind(this.adminService);
      }
      method(this.notificationId).subscribe((data) => {
        this.notificationParams = data.result;
        this.notificationLoading = false;
        this.sortWarnings();
      }, () => this.error = true)
    } else {
      this.error = true;
    }
  }

  sortWarnings() {
    this.notificationParams.client_warnings.sort((a, b) => {
      if (!a.last_name && !a.first_name) {
        return 1;
      }
      if (!b.last_name && !b.first_name) {
        return -1;
      }
      if (`${(a.last_name || '').toLowerCase()} ${(a.first_name || '').toLowerCase()}`.trim() > `${(b.last_name || '').toLowerCase()} ${(b.first_name || '').toLowerCase()}`.trim()) {
        return 1;
      }
      if (`${(a.last_name || '').toLowerCase()} ${(a.first_name || '').toLowerCase()}`.trim() < `${(b.last_name || '').toLowerCase()} ${(b.first_name || '').toLowerCase()}`.trim()) {
        return -1;
      }
      return 0;
    })
  }

  closeModal() {
    this.modalService.onClose();
  }

}
