import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { NotificationsService } from '../../services/notifications.service';
import { MessagesService } from '../../services/messages.service';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-modal-notifications-settings',
  templateUrl: './modal-notifications-settings.component.html',
  styleUrls: ['./modal-notifications-settings.component.styl']
})
export class ModalNotificationsSettingsComponent implements OnInit {
  settingsForm: FormGroup;
  settingsLoading;
  error;
  settings;
  areas;
  materials;
  updateLoading;
  expandedRow;
  modelChanged: boolean = false;
  constructor(public modalService: ModalService,
              private notificationsService: NotificationsService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.settingsLoading = true;
    this.notificationsService.getSettings().subscribe((data) => {
      this.settingsLoading = false;
      this.areas = data.result.new_courses_areas;
      this.materials = data.result.new_ideas_material_types;
      this.settings = this.parseSettings(data.result);
    }, () => {
      this.settingsLoading = false;
      this.error = true;
    })
  }

  areasChange(arr) {
    this.areas = arr;
    this.onChange();
  }

  materialsChange(arr) {
    this.materials = arr;
    this.onChange();
  }

  submit() {
    let form = this.getModel();
    this.notificationsService.setSettings(form).subscribe(data => {
      this.messagesService.showMessage('Настройки уведомлений обновлены', null, true, true);
      this.modalService.onClose();
      this.updateLoading = false;
    })
  }

  onChange() {
    this.modelChanged = true;
  }

  expandRow(index) {
    if (this.expandedRow === index) {
      this.expandedRow = null;
    } else {
      this.expandedRow = index;
    }
  }

  private getModel = function() {
    let form = this.reparseSettings(this.settings);
    if (this.areas && this.areas.length) {
      if (this.areas[0].id) {
        form.new_courses_areas = this.areas.map(item => item.id);
      } else {
        form.new_courses_areas = this.areas;
      }
    } else {
      form.new_courses_areas = [];
    }
    if (this.materials && this.materials.length) {
      if (this.materials[0].id) {
        form.new_ideas_material_types = this.materials.map(item => item.id);
      } else {
        form.new_ideas_material_types = this.materials;
      }
    } else {
      form.new_ideas_material_types = [];
    }
    return form;
  }


  private parseSettings = function(settings) {
    let result: any = {};
    for (let key in settings) {
      if (['balance', 'new_courses', 'new_events', 'new_ideas', 'postponed', 'replies'].indexOf(key) + 1) {
        result[key] = [!!(settings[key].indexOf('SITE') + 1), !!(settings[key].indexOf('EMAIL') + 1), !!(settings[key].indexOf('SMS') + 1)];
      }
    }
    return result;
  }

  private reparseSettings = function(settings) {
    let result: any = {};
    let values = ['SITE', 'EMAIL', 'SMS'];
    for (let key in settings) {
      if (['balance', 'new_courses', 'new_events', 'new_ideas', 'postponed', 'replies'].indexOf(key) + 1) {
        if (settings[key].forEach) {
          result[key] = [];
          settings[key].forEach((item, index) => {
            if (item) result[key].push(values[index]);
          })
        }
      }
    }
    return result;
  }

}
