import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-modal-confirm-change-role',
  templateUrl: './modal-confirm-change-role.component.html',
  styleUrls: ['./modal-confirm-change-role.component.styl']
})
export class ModalConfirmChangeRoleComponent implements OnInit {
  @Input() client;
  @Output() close = new EventEmitter();
  @Output() confirm = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  confirmChange() {
    this.confirm.emit();
  }

  closeModal() {
    this.close.emit();
  }

}
