import { Pipe } from '@angular/core';

@Pipe({
  name: 'sectionName'
})
export class SectionNamePipe {

  transform(value: any, args?: any): any {
    if (value === 'wood') {
      return 'Дерево'
    } else if (value === 'metal'){
      return 'Металл'
    } else if (value === '3d'){
      return '3D'
    } else if (value === 'tissue'){
      return 'Ткань'
    } else if (value === 'light') {
      return 'Свет'
    } else return null;
  }

}
