import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, HostListener, Output, EventEmitter } from '@angular/core';
import { IdeasService } from '../../services/ideas.service';
import { EventsService } from '../../services/events.service';
import { CoursesService } from '../../services/courses.service';
import { WriteCommentComponent } from '../write-comment/write-comment.component';
import { UserService } from '../../services/user.service';
import { ModalService } from '../../services/modal.service';
import { Router } from '@angular/router';
import { CommentsService } from '../../services/comments.service';
import { ClickOutsideDirective } from '../../directives/click-outside.directive';

@Component({
  selector: 'app-comment-item',
  templateUrl: './comment-item.component.html',
  styleUrls: ['./comment-item.component.styl']
})
export class CommentItemComponent implements OnInit, AfterViewInit {
  @Input() comment: any;
  @Input() notSeen;
  @Input() currentId: any;
  @Input() currentOwnerId: any;
  @Input() type: any;
  @Input() parentComment;
  @Input() canComment;
  @Input() read:boolean;
  @Output() updateFeedEmitter = new EventEmitter();
  @Output() showImageEmitter = new EventEmitter();
  @ViewChild('text') textBlock: ElementRef;
  @ViewChild('firststring') firstString: ElementRef;
  @ViewChild('shortText') shortText: ElementRef;
  @ViewChild(WriteCommentComponent) writeComment;
  @ViewChild(ClickOutsideDirective) clickOutside: ClickOutsideDirective;
  textHidden: boolean = false;
  answerOpened: boolean = false;
  finalString1: string = '';
  canDelete;
  isError;
  readError;
  readCheck;
  readLoad;
  deleteLoading;
  service;
  meAdmin;
  // @HostListener('window:resize') onResize() {
  //   if (this.textHidden) {
  //     // this.splitByStrings(this.comment.text);
  //   }
  // }

  constructor(private ideasService: IdeasService,
              private eventsService: EventsService,
              private coursesService: CoursesService,
              private userService: UserService,
              private router: Router,
              private commentService: CommentsService,
              private modalService: ModalService) { }

  ngOnInit() {
    if (this.type === 'IDEA') {
      this.service = this.ideasService;
    } else if (this.type === 'EVENT') {
      this.service = this.eventsService;
    } else if (this.type === 'COURSE') {
      this.service = this.coursesService;
    }
    this.canDelete = +this.userService.userId === +this.comment.author_id;
    if (this.userService.userData) {
      this.meAdmin = !!(['ADMIN', 'SUPER_ADMIN'].indexOf(this.userService.userData.role) + 1);
    }
    this.userService.updateSubject.subscribe(() => {
      this.canDelete = +this.userService.userId === +this.comment.author_id;
    })
  }
  ngAfterViewInit() {
    if (this.textBlock.nativeElement.offsetHeight > 50) {
      setTimeout(() => {
        this.textHidden = true;
      }, 0)
    }
  }


  openAnswer() {
    this.answerOpened = true;
    this.clickOutside.enable();
  }

  closeAnswer() {
    this.answerOpened = false;
    this.clickOutside.disable();
  }

  readMore() {
    this.textHidden = false;
    this.shortText.nativeElement.style.display = 'none';
  }

  dontCloseWriter(state) {
    if (state) {
      this.clickOutside.disable();
    } else {
      this.clickOutside.enable();
    }
  }

  writeReply(evt) {
    this.service.sendReply(this.currentId, this.comment.parent_id || this.comment.id, evt).subscribe((data) => {
      this.writeComment.onSuccess('Комментарий отправлен');
      this.closeAnswer();
      this.updateFeed();
    }, () => {
      this.writeComment.onError('Ошибка при отправке комментария');
    })
  }

  readComment() {
    this.readLoad = true;
    this.commentService.readComment(this.comment.id, !this.readCheck).subscribe((data) => {
      this.readLoad = false;
      this.readCheck = !this.readCheck;
      this.comment.read = this.readCheck;
      this.updateFeed();
    }, (err) => {
      this.readError = true;
    });
  }

  deleteComment() {
    this.isError = false;
    this.deleteLoading = true;
    this.service.deleteComment(this.currentId, this.comment.id).subscribe((data) => {
      this.deleteLoading = false;
      this.updateFeed();
    }, () => {
      this.deleteLoading = false;
      this.isError = true;
    })
  }

  // private splitByStrings = function(text) {
  //   let arr = text.split(' ');
  //   this.string1 = '';
  //   let previousVal;
  //   let stringHeight;
  //   let stop = false;
  //   arr.forEach((item, index) => {
  //     if (!stop) {
  //       previousVal = this.string1;
  //       this.string1 += item + ' ';
  //       this.firstString.nativeElement.innerText = this.string1;
  //     }
  //     if (index === 0) {
  //       stringHeight = this.firstString.nativeElement.offsetHeight;
  //     }
  //     if (!stop && (stringHeight < this.firstString.nativeElement.offsetHeight)) {
  //       stop = true;
  //       this.string1 = previousVal;
  //       if (this.string1 != this.finalString1) {
  //         this.finalString1 = " " + this.string1;
  //         this.firstString.nativeElement.innerText = previousVal;
  //         let arr2 = arr.slice(index, arr.length);
  //         setTimeout(() => {
  //           this.string2 = arr2.join(' ');
  //         }, 0)
  //       }
  //     }
  //   })
  // }

  updateFeed() {
    this.updateFeedEmitter.emit();
  }

  toggleLike(evt) {
    evt.event.stopPropagation();
    evt.event.preventDefault();
    if (this.userService.userId) {
      this.service.likeComment(this.currentId, this.comment.id, !this.comment.is_liked).subscribe((data) => {
        this.comment.likes_count = data.result.likes_count;
        this.comment.is_liked = data.result.is_liked;
        if (data.result.is_liked) {
          evt.elem.playLikeAnimation();
        } else {
          evt.elem.playUnlikeAnimation();
        }
      })
    } else {
      this.router.navigate([this.router.url.split('?')[0]], {queryParams: {popup: 'login'}});
      let like = function() {
        this.service.likeComment(this.currentId, this.comment.id, true).subscribe((data) => {
          this.comment.likes_count = data.result.likes_count;
          this.comment.is_liked = data.result.is_liked;
        })
      }.bind(this);
      setTimeout(() => {
        this.modalService.setPageOptions({signInTitleText: 'Войдите, чтобы отметить комментарий как понравившийся'});
        this.modalService.setPageOptions({signUpTitleText: 'Зарегистрируйтесть, чтобы отметить комментарий как понравившийся'});
        this.modalService.setGlobalOptions({afterSuccess: like});
      }, 0)
    }

  }

  showImage(url) {
    this.showImageEmitter.emit(url);
  }
}
