import { NgModule } from '@angular/core';
import { SortablejsModule } from 'angular-sortablejs';
import { GlobalModule } from '../global/global.module';


import { HeaderComponent } from '../../shared/header/header.component';
import { HeaderMobileNavigationComponent } from '../../shared/header-mobile-navigation/header-mobile-navigation.component';
import { UserNotificationsComponent } from '../../shared/user-notifications/user-notifications.component';
import { PolicyFooterComponent } from '../../shared/policy-footer/policy-footer.component';
import { LmRulesComponent } from '../../shared/lm-rules/lm-rules.component';
import { ModalComponent } from '../../shared/modal/modal.component';
import { SigninComponent } from '../../shared/signin/signin.component';
import { SignupComponent } from '../../shared/signup/signup.component';
import { ModalSubscribeComponent } from '../../shared/modal-subscribe/modal-subscribe.component';
import { ModalPaymentOptionsComponent } from '../../shared/modal-payment-options/modal-payment-options.component';
import { ModalPaymentSuccessComponent } from '../../shared/modal-payment-success/modal-payment-success.component';
import { ModalActionProgressComponent } from '../../shared/modal-action-progress/modal-action-progress.component';;
import { ModalOrderConsultationComponent } from '../../shared/modal-order-consultation/modal-order-consultation.component';
import { ModalConsultPayComponent } from '../../shared/modal-consult-pay/modal-consult-pay.component';

import { EventCreationComponent } from '../../shared/event-creation/event-creation.component';
import { LogoPickerComponent } from '../../shared/logo-picker/logo-picker.component';
import { DatePickerComponent } from '../../shared/date-picker/date-picker.component';
import { SocialPickerComponent } from '../../shared/social-picker/social-picker.component';
import { AddSocialItemComponent } from '../../shared/add-social-item/add-social-item.component';
import { ModalEnrollEventComponent } from '../../shared/modal-enroll-event/modal-enroll-event.component';
import { CourseCreationComponent } from '../../shared/course-creation/course-creation.component';
import { MasterSelectComponent } from '../../shared/master-select/master-select.component';
import { ModalSetBirthdayComponent } from '../../shared/modal-set-birthday/modal-set-birthday.component';
import { ModalEnrollCourseComponent } from '../../shared/modal-enroll-course/modal-enroll-course.component';
import { EquipmentCreationComponent } from '../../shared/equipment-creation/equipment-creation.component';
import { ModalReserveInstrumentComponent } from '../../shared/modal-reserve-instrument/modal-reserve-instrument.component';
import { ModalExtendSubscriptionComponent } from '../../shared/modal-extend-subscription/modal-extend-subscription.component';
import { ModalCancelMcComponent } from '../../shared/modal-cancel-mc/modal-cancel-mc.component';

import { ModalNotificationsSettingsComponent } from '../../shared/modal-notifications-settings/modal-notifications-settings.component';
import { ModalUploadSheludeOfMastersComponent } from '../../shared/modal-upload-shelude-of-masters/modal-upload-shelude-of-masters.component';
import { ModalClientPopupComponent } from '../../shared/modal-client-popup/modal-client-popup.component';
import { ModalConsultationComponent } from '../../shared/modal-consultation/modal-consultation.component';
import { ModalCourseUsersComponent } from '../../shared/modal-course-users/modal-course-users.component';
import { ModalEventUsersComponent } from '../../shared/modal-event-users/modal-event-users.component';

import { ModalMultistepItemComponent } from '../../shared/modal-multistep-item/modal-multistep-item.component';

import { ModalMultistepReserveInstrumentComponent } from '../../shared/modal-multistep-reserve-instrument/modal-multistep-reserve-instrument.component';
import { ModalMultistepReserveInstrumentSuccessComponent } from '../../shared/modal-multistep-reserve-instrument-success/modal-multistep-reserve-instrument-success.component';
import { ModalCancelCourseComponent } from '../../shared/modal-cancel-course/modal-cancel-course.component';
import { ModalCancelEventComponent } from '../../shared/modal-cancel-event/modal-cancel-event.component';
import { ModalMoveConsultComponent } from '../../shared/modal-move-consult/modal-move-consult.component';
import { ModalMoveConsultSuccessComponent } from '../../shared/modal-move-consult-success/modal-move-consult-success.component';
import { ModalClientMoveConsultComponent } from '../../shared/modal-client-move-consult/modal-client-move-consult.component';
import { ModalCancelConsultComponent } from '../../shared/modal-cancel-consult/modal-cancel-consult.component';
import { ModalReserveEquipForClientComponent } from '../../shared/modal-reserve-equip-for-client/modal-reserve-equip-for-client.component';
import { ModalBreakdownEquipmentComponent } from '../../shared/modal-breakdown-equipment/modal-breakdown-equipment.component';
import { ModalDescriptionBreakdownComponent } from '../../shared/modal-description-breakdown/modal-description-breakdown.component';
import { ModalNotifyAboutBreakdown } from '../../shared/modal-notify-about-breakdown/modal-notify-about-breakdown.component';
import { ModalNotifyCancelCourseComponent } from '../../shared/modal-notify-cancel-course/modal-notify-cancel-course.component';
import { ModalQuantityOfBreakdownComponent } from '../../shared/modal-quantity-of-breakdown/modal-quantity-of-breakdown.component';
import { ModalAppointmentForCourseComponent } from '../../shared/modal-appointment-for-course/modal-appointment-for-course.component';
import { CoursesModalFilterComponent } from '../../shared/courses-modal-filter/courses-modal-filter.component';
import { ModalAppointmentForEventComponent } from '../../shared/modal-appointment-for-event/modal-appointment-for-event.component';
import { EventsModalFilterComponent } from '../../shared/events-modal-filter/events-modal-filter.component';
import { ModalNotifyMoveCourseComponent } from '../../shared/modal-notify-move-course/modal-notify-move-course.component';
import { ModalReserveInstrumentSubscriptionComponent } from '../../shared/modal-reserve-instrument-subscription/modal-reserve-instrument-subscription.component';
import { ModalReserveInstrumentLoadingComponent } from '../../shared/modal-reserve-instrument-loading/modal-reserve-instrument-loading.component';
import { ModalAppointmentForConsultComponent } from '../../shared/modal-appointment-for-consult/modal-appointment-for-consult.component';
import { ModalStorageCellReservationComponent } from '../../shared/modal-storage-cell-reservation/modal-storage-cell-reservation.component';
import { ModalStorageProductsComponent } from '../../shared/modal-storage-products/modal-storage-products.component';
import { ModalProlongateStoragePeriodComponent } from '../../shared/modal-prolongate-storage-period/modal-prolongate-storage-period.component';

import { ModalReserveEquipmentForConsultComponent } from '../../shared/modal-reserve-equipment-for-consult/modal-reserve-equipment-for-consult.component';
import { ModalClientStorageProlongateComponent } from '../../shared/modal-client-storage-prolongate/modal-client-storage-prolongate.component';
import { ModalRecoveryPasswordEmailComponent } from '../../shared/modal-recovery-password-email/modal-recovery-password-email.component';
import { ModalSetPhoneComponent } from '../../shared/modal-set-phone/modal-set-phone.component';
import { ModalLoaderComponent } from '../../shared/modal-loader/modal-loader.component';

import { SingleMediaPickerComponent } from '../../shared/single-media-picker/single-media-picker.component';
import { InstrumentSelectComponent } from '../../shared/instrument-select/instrument-select.component';
import { DateSelectComponent } from '../../shared/date-select/date-select.component';
import { NotificationsSettingsSelectSpacesComponent } from '../../shared/notifications-settings-select-spaces/notifications-settings-select-spaces.component';
import { SchedulePickerComponent } from '../../shared/schedule-picker/schedule-picker.component';
import { EquipmentSearchComponent } from '../../shared/equipment-search/equipment-search.component';
import { EquipmentSearchResultItemComponent } from '../../shared/equipment-search-result-item/equipment-search-result-item.component';
import { EquipmentReservationPopupComponent } from '../../shared/equipment-reservation-popup/equipment-reservation-popup.component';
import { ClientCallResultComponent } from '../../shared/client-call-result/client-call-result.component';
import { BreakdownListItemComponent } from '../../shared/breakdown-list-item/breakdown-list-item.component';
import { CoursesSearchComponent } from '../../shared/courses-search/courses-search.component';
import { CoursesSearchResultItemComponent } from '../../shared/courses-search-result-item/courses-search-result-item.component';
import { EventsSearchComponent } from '../../shared/events-search/events-search.component';
import { EventsSearchResultItemComponent } from '../../shared/events-search-result-item/events-search-result-item.component';
import { SelectConsultComponent } from '../../shared/select-consult/select-consult.component';
import { ShelfStorageComponent } from '../../shared/shelf-storage/shelf-storage.component';
import { ProlongateStoragePeriodCellComponent } from '../../shared/prolongate-storage-period-cell/prolongate-storage-period-cell.component';
import { InputListSearchComponent } from '../../shared/input-list-search/input-list-search.component';
import { IdeaCreationComponent } from '../../shared/idea-creation/idea-creation.component';
import { FilePickerComponent } from '../../shared/file-picker/file-picker.component';
import { IdeaMediaPickerComponent } from '../../shared/idea-media-picker/idea-media-picker.component';
import { CreateMasterComponent } from '../../shared/create-master/create-master.component';
import { CreateAccountComponent } from '../../shared/create-account/create-account.component';
import { ModalClientCertsComponent } from '../../shared/modal-client-certs/modal-client-certs.component';
import { ModalConfirmChangeRoleComponent } from '../../shared/modal-confirm-change-role/modal-confirm-change-role.component';
import { ModalNotifyRemoveEquipmentComponent } from '../../shared/modal-notify-remove-equipment/modal-notify-remove-equipment.component';
import { ModalRemoveEquipmentComponent } from '../../shared/modal-remove-equipment/modal-remove-equipment.component';
import { ModalNotifyPassedStorageComponent } from '../../shared/modal-notify-passed-storage/modal-notify-passed-storage.component';
import { ModalConfirmPhoneComponent } from '../../shared/modal-confirm-phone/modal-confirm-phone.component';
import { EquipmentSearchConsultComponent } from '../../shared/equipment-search-consult/equipment-search-consult.component';
import { EquipmentSearchConsultItemComponent } from '../../shared/equipment-search-consult-item/equipment-search-consult-item.component';
import { ModalRemoveMasterAfterComponent } from '../../shared/modal-remove-master-after/modal-remove-master-after.component';
import { UserNotificationsItemComponent } from '../../shared/user-notifications-item/user-notifications-item.component';
import { ModalConfirmPaidComponent } from '../../shared/modal-confirm-paid/modal-confirm-paid.component';

const sharedModules = [
  GlobalModule,
  SortablejsModule.forRoot({ animation: 150 })
]

const sharedComponents = [
  HeaderComponent,
  HeaderMobileNavigationComponent,
  UserNotificationsComponent,
  PolicyFooterComponent,
  LmRulesComponent,
  ModalComponent,
  SigninComponent,
  SignupComponent,
  ModalSubscribeComponent,
  ModalPaymentOptionsComponent,
  ModalPaymentSuccessComponent,
  ModalActionProgressComponent,
  ModalOrderConsultationComponent,
  ModalConsultPayComponent,
  EventCreationComponent,
  LogoPickerComponent,
  DatePickerComponent,
  SocialPickerComponent,
  AddSocialItemComponent,
  ModalEnrollEventComponent,
  CourseCreationComponent,
  MasterSelectComponent,
  ModalSetBirthdayComponent,
  ModalEnrollCourseComponent,
  EquipmentCreationComponent,
  ModalReserveInstrumentComponent,
  ModalExtendSubscriptionComponent,
  ModalCancelMcComponent,
  ModalNotificationsSettingsComponent,
  ModalNotificationsSettingsComponent,
  ModalUploadSheludeOfMastersComponent,
  ModalClientPopupComponent,
  ModalConsultationComponent,
  ModalCourseUsersComponent,
  ModalEventUsersComponent,
  ModalMultistepItemComponent,
  ModalMultistepReserveInstrumentComponent,
  ModalMultistepReserveInstrumentSuccessComponent,
  ModalCancelCourseComponent,
  ModalCancelEventComponent,
  ModalMoveConsultComponent,
  ModalMoveConsultSuccessComponent,
  ModalClientMoveConsultComponent,
  ModalCancelConsultComponent,
  ModalReserveEquipForClientComponent,
  ModalBreakdownEquipmentComponent,
  ModalDescriptionBreakdownComponent,
  ModalNotifyAboutBreakdown,
  ModalNotifyCancelCourseComponent,
  ModalQuantityOfBreakdownComponent,
  ModalAppointmentForCourseComponent,
  CoursesModalFilterComponent,
  ModalAppointmentForEventComponent,
  EventsModalFilterComponent,
  ModalNotifyCancelCourseComponent,
  ModalNotifyMoveCourseComponent,
  ModalReserveInstrumentSubscriptionComponent,
  ModalReserveInstrumentLoadingComponent,
  ModalAppointmentForConsultComponent,
  ModalStorageCellReservationComponent,
  ModalStorageProductsComponent,
  ModalProlongateStoragePeriodComponent,
  ModalReserveEquipmentForConsultComponent,
  ModalClientStorageProlongateComponent,
  ModalRecoveryPasswordEmailComponent,
  ModalSetPhoneComponent,
  ModalLoaderComponent,
  SingleMediaPickerComponent,
  InstrumentSelectComponent,
  DateSelectComponent,
  NotificationsSettingsSelectSpacesComponent,
  SchedulePickerComponent,
  EquipmentSearchComponent,
  EquipmentSearchResultItemComponent,
  EquipmentReservationPopupComponent,
  ClientCallResultComponent,
  BreakdownListItemComponent,
  CoursesSearchComponent,
  CoursesSearchResultItemComponent,
  EventsSearchComponent,
  EventsSearchResultItemComponent,
  SelectConsultComponent,
  ShelfStorageComponent,
  ProlongateStoragePeriodCellComponent,
  InputListSearchComponent,
  IdeaCreationComponent,
  FilePickerComponent,
  IdeaMediaPickerComponent,
  CreateMasterComponent,
  CreateAccountComponent,
  ModalClientCertsComponent,
  ModalConfirmChangeRoleComponent,
  ModalNotifyRemoveEquipmentComponent,
  ModalRemoveEquipmentComponent,
  ModalNotifyPassedStorageComponent,
  ModalConfirmPhoneComponent,
  EquipmentSearchConsultComponent,
  EquipmentSearchConsultItemComponent,
  UserNotificationsItemComponent,
  ModalRemoveMasterAfterComponent,
  ModalConfirmPaidComponent
]

@NgModule({
  declarations: [
    ...sharedComponents
  ],
  imports: [
  ...sharedModules
    // NgtUniversalModule,

  ],
  exports: [ ...sharedComponents ]
})
export class ModalModule { }
