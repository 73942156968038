import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { materialTypes, areaTypes } from '../../utils'
@Component({
  selector: 'app-notifications-settings-select-spaces',
  templateUrl: './notifications-settings-select-spaces.component.html',
  styleUrls: ['./notifications-settings-select-spaces.component.styl']
})
export class NotificationsSettingsSelectSpacesComponent implements OnInit {
  @Input() type;
  @Input() val;
  @Input() control;
  @Output() changeEmitter = new EventEmitter();
  selectOpened;
  viewSelectors = [];
  selectors: any[] = [];


  constructor() { }

  ngOnInit() {
    if (this.type === 'spaces') {
      this.selectors = areaTypes.map((item, index) => {
        return {
          title: item.title,
          id: item.value,
          index: index
        }
      });
    } else {
      this.selectors = materialTypes.map((item, index) => {
        return {
          title: item.name,
          id: item.id,
          index: index
        }
      })
    }
    if (this.val) {
      this.val.forEach(item => {
        this.selectItem(item);
      })
    }
  }

  toggleSelector() {
    if (this.selectOpened) {
      this.selectClose();
    } else {
      this.selectOpen();
    }
  }

  selectOpen() {
    this.selectOpened = true;
  }

  selectClose() {
    this.selectOpened = false;
  }

  selectItem(id, emit?) {
    let elemIndex;
    this.selectors.forEach((item, index) => {
      if (item.id === id) {
        elemIndex = index;
      }
    })
    if (elemIndex || elemIndex === 0) {
      this.viewSelectors.push(this.selectors.splice(elemIndex, 1)[0]);
    }
    this.val = this.viewSelectors;
    if (emit) {
      this.changeEmitter.emit(this.viewSelectors);
    }
  }

  removeItem(id) {
    let elemIndex;
    this.viewSelectors.forEach((item, index) => {
      if (item.id === id) {
        elemIndex = index;
      }
    })
    if (elemIndex || elemIndex === 0) {
      this.selectors.push(this.viewSelectors.splice(elemIndex, 1)[0]);
      this.sortSelectors();
    }
    this.val = this.viewSelectors;
    this.changeEmitter.emit(this.viewSelectors);
  }

  private sortSelectors() {
    if (this.selectors[0] && (this.selectors[0].index || this.selectors[0].index === 0)) {
      this.selectors.sort((a, b) => a.index - b.index);
    }
  }

}
