import { Component, OnInit, Input } from '@angular/core';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-client-call-result',
  templateUrl: './client-call-result.component.html',
  styleUrls: ['./client-call-result.component.styl']
})
export class ClientCallResultComponent implements OnInit {
  @Input() warning;
  @Input() params;
  @Input() notificationId;
  @Input() entityType;
  @Input() entityChangeType;
  cost;
  type;
  justDenied;
  constructor(private adminService: AdminService) { }

  ngOnInit() {
    this.cost = this.params.cost || this.params.entity_cost;
    this.type = this.params.entity_change_type + this.params.entity_type;
  }

  setCallStatus(status) {
    let method;
    if (this.entityType === 'COURSE' && this.entityChangeType === 'CANCEL') {
      method = this.adminService.cancelCourseCallResult.bind(this.adminService);
    } else if (this.entityType === 'COURSE' && this.entityChangeType === 'MOVE') {
      method = this.adminService.moveCourseCallResult.bind(this.adminService);
    } else if (this.entityType === 'EVENT' && this.entityChangeType === 'CANCEL') {
      method = this.adminService.cancelEventCallResult.bind(this.adminService);
    } else if (this.entityType === 'EVENT' && this.entityChangeType === 'MOVE') {
      method = this.adminService.moveEventCallResult.bind(this.adminService);
    }
    let selectedWarning;
    this.params.client_warnings.forEach(item => {
      if (item.client_id == this.warning.client_id) {
        selectedWarning = item;
      }
    })
    selectedWarning.call_result = status;
    let model:any = {
      client_warnings: this.params.client_warnings,
      notification_id: this.notificationId
    }
    if (status === 'NOT_ANSWERED') {
      selectedWarning.no_answer_date_time = new Date().toISOString();
      this.justDenied = true;
    }
    model.client_warnings = model.client_warnings.map(item => {
      let result: any = {};
      if (item.client_id) result.client_id = item.client_id;
      if (item.no_answer_date_time) result.no_answer_date_time = item.no_answer_date_time;
      if (item.call_result) result.call_result = item.call_result;
      return result;
    })
    method(model).subscribe(() => {
      this.warning.call_result = status;
      if (status === 'NOT_ANSWERED') {
        this.warning.no_answer_date_time = new Date().toISOString();
      }
    })
  }
}
