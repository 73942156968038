import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CurrentIdeaService {
  currentIdea;
  updateEmitter;
  updateObs;
  updateSubject;
  constructor() {
    this.updateSubject = new Subject();
    this.updateEmitter = Observable.create((obs) => this.updateObs = obs);
  }

  update() {
    this.updateSubject.next();
  }

}
