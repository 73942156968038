import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class StorageService {
  updateSubject;
  constructor(private api: ApiService) {
    this.updateSubject = new Subject();
  }

  startStorageSession(userId, slotIdentifier, days, paid) {
    return this.api.post(`v1/storage/start`, null, { userId, slotIdentifier, days, paid });
  }

  getStorageList() {
    return this.api.get(`v1/storage/list`);
  }

  prolongSession(slots, userId) {
    return this.api.post(`v1/storage/prolong`, { slots }, { userId });
  }

  pauseSession(sessionId) {
    return this.api.post(`v1/storage/pause`, null, { sessionId });
  }

  restartSession(sessionId, slotIdentifier, days) {
    return this.api.post(`v1/storage/restart`, null, { sessionId, slotIdentifier, days });
  }

  paid(sessionId, paid) {
    return this.api.post(`v1/storage/${sessionId}/paid`, null, { paid });
  }

  phoned(phoned, slotIdentifier, result) {
    return this.api.post(`v1/storage/phoned`, null, { phoned, slotIdentifier, result });
  }

  finishSession(sessionId) {
    return this.api.post(`v1/storage/finish`, null, { sessionId });
  }

  getUsersStorageSessions(ownerId) {
    return this.api.get(`v1/storage/running`, { ownerId });
  }

  updateEmitter() {
    this.updateSubject.next();
  }

  getOverdueSessionsByNotificationId(notificationId) {
    return this.api.get('v1/admin/warnings/storage/overdue/info', { notificationId });
  }

}
