import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { EventsService } from '../../services/events.service';
import { MessagesService } from '../../services/messages.service';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-modal-event-users',
  templateUrl: './modal-event-users.component.html',
  styleUrls: ['./modal-event-users.component.styl']
})
export class ModalEventUsersComponent implements OnInit {
  eventLoading;
  paymentLoading;
  selectedTimeId;
  eventParams;
  eventTiming;
  users;
  registeredevent;
  error;
  filter;
  currentRoute;
  constructor(private modalService: ModalService,
              private eventsService: EventsService,
              private activatedRoute: ActivatedRoute,
              private router: Router,
              private messagesService: MessagesService) { }

  ngOnInit() {
    this.currentRoute = this.router.url.split('?')[0];
    if (this.activatedRoute.snapshot.queryParams.event) {
      this.eventLoading = true;
      forkJoin([this.eventsService.getEvent(this.activatedRoute.snapshot.queryParams.event), this.eventsService.getRegisteredUsers(this.activatedRoute.snapshot.queryParams.event)]).subscribe(data => {
        this.eventParams = data[0].result;
        this.users = data[1].result.sort(this.sortCallback);
        this.eventLoading = false;
      }, () => {
        this.eventLoading = false;
        this.error = true;
      });
    } else {
      this.error = true;
    }
  }

  togglePaid(user) {
    this.eventsService.paid(this.eventParams.id, user.user.id, !user.paid).subscribe((data) => {
      user.paid = data.result;
    }, () => {
      this.messagesService.showMessage('Произошла ошибка при смене статуса оплаты.')
    })
  }

  toggleCame(user) {
    this.eventsService.attend(this.eventParams.id, user.user.id, !user.attended).subscribe((data) => {
      user.attended = data.result;
    })
  }

  onSearchInput(evt) {
    this.filter = evt;
  }

  onSearchClose() {
    this.filter = '';
  }

  back() {
    this.modalService.back();
  }

  private sortCallback = function(a, b) {
    if (`${(a.user.last_name || '').toLowerCase()} ${(a.user.first_name || '').toLowerCase()}`.trim() > `${(b.user.last_name || '').toLowerCase()} ${(b.user.first_name || '').toLowerCase()}`.trim()) {
      return 1;
    }
    if (`${(a.user.last_name || '').toLowerCase()} ${(a.user.first_name || '').toLowerCase()}`.trim() < `${(b.user.last_name || '').toLowerCase()} ${(b.user.first_name || '').toLowerCase()}`.trim()) {
      return -1;
    }
    return 0;
  }

}
