import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { ActivatedRoute } from '@angular/router';
import { StorageService } from '../../services/storage.service';
import { forkJoin } from "rxjs";

@Component({
  selector: 'app-modal-client-storage-prolongate',
  templateUrl: './modal-client-storage-prolongate.component.html',
  styleUrls: ['./modal-client-storage-prolongate.component.styl']
})
export class ModalClientStorageProlongateComponent implements OnInit {
  currentStep = 0;
  user;
  isLoadUser;
  cell: any;
  cellsList;
  error;
  state: any;
  finalStep = 1;
  isLoading;

  constructor(private fb: FormBuilder,
              private userService: UserService,
              private activatedRoute: ActivatedRoute,
              private modalService: ModalService,
              private storageService: StorageService) { }

  ngOnInit() {
    this.isLoadUser = true;
    let userId = this.activatedRoute.snapshot.queryParams.user;
    if (userId) {
      forkJoin(this.userService.getUserData(userId), this.storageService.getUsersStorageSessions(userId)).subscribe(data => {
        this.user = data[0].result;
        this.cellsList = data[1].result;
        this.cell = {
          start_date: data[1].result[0].start_date,
          end_date: data[1].result[0].end_date,
          slot_identifier: data[1].result[0].slot_identifier,
          id: data[1].result[0].id,
          not_paid: data[1].result[0].no_paid_sum
        }
        this.initForm();
        this.isLoadUser = false;
      }, () => {this.error = true;});
    } else {
      this.storageService.getUsersStorageSessions(userId).subscribe(data => {
        this.cellsList = data.result;
        this.isLoadUser = false;
      }, () => {this.error = true;});
    }
  }

  nextPage(paid) {
    this.modalService.nextPage({
      clientProlongateStoragePeriodData: {
        endDate: this.cell.control.value.new_end_date,
        cost: this.cell.control.value.duration * 100
      },
      storageCellPaid: paid
    }, null);
  }

  nextStep(state) {
    this.currentStep++;
    this.state = {
      ...this.state,
      ...state
    };
    if (this.currentStep > this.finalStep) {
      this.submit();
    }
  }

  submit() {
    this.isLoading = true;
    let pay;
    if (this.state.paid) {
      pay = this.storageService.paid(this.state.cell.id, true);
    }
    this.storageService.prolongSession([{
      slot_identifier: this.state.cell.slot_identifier,
      days: this.state.cell.control.value.duration,
    }], this.user.id).subscribe(() => {
      if (this.state.paid) {
        this.storageService.paid(this.state.cell.id, true).subscribe((data) => {
          this.nextPage(data.result);
        }, () => {this.error = true; this.isLoading = false})
      } else {
        this.nextPage(false);
      }
      this.storageService.updateEmitter();
    }, () => {this.error = true; this.isLoading = false})
  }

  initForm() {
    this.cell.control = this.fb.group({
          index: [this.cell.slot_identifier, Validators.required],
          duration: [0, Validators.required],
          new_end_date: [null]
      });
  }

  updateValues() {

  }

  back() {
    this.modalService.back();
  }

}
