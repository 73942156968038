import { Injectable } from '@angular/core';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})

export class RefundService {

  constructor(private api: ApiService) { }

  getRefunds(userId) {
    return this.api.get(`refunds/${userId}`);
  }

  setReturned(refundId, returned) {
    return this.api.post(`refunds/${refundId}/returned`, null, { returned });
  }

}
