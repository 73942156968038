import {Component, OnInit} from '@angular/core';
import {SubscriptionsService} from '../../services/subscriptions.service';

@Component({
  selector: 'app-subscription-cards',
  templateUrl: './subscription-cards.component.html',
  styleUrls: ['./subscription-cards.component.styl']
})
export class SubscriptionCardsComponent implements OnInit {
  selectedCard = 'hours';
  cardsParams = [{
    title: 'почасовой',
    value: 'hours'
  }, {
    title: 'суточный',
    value: 'days'
  }];
  cards: any[] = this.subscriptionsService.buildCards();

  constructor(private subscriptionsService: SubscriptionsService) {
  }

  ngOnInit() {
    this.subscriptionsService.price().subscribe((data) => {
      this.cards = this.subscriptionsService.buildCards(data.result.hour_price, data.result.day_price);
    }, (error) => {
      console.log('subscription price failed ', error);
    });
  }

  onCardTypeChange(val) {
    this.selectedCard = val;
  }
}
