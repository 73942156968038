import { Pipe } from '@angular/core';

@Pipe({
  name: 'msTime'
})
export class MsTimePipe {

  transform(value: any, args?: any): any {
    if (+value) {
      let hours = Math.floor(value / (3600 * 1000));
      let minutes = Math.floor((value - (hours * 3600 * 1000)) / 1000 / 60);
      if (minutes > 0) {
        return `${hours} ч ${minutes} мин`;
      } else {
        return `${hours} ч`;
      }
    }
  }

}
