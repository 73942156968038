import { Component, OnInit, OnDestroy, AfterViewInit, PLATFORM_ID, Inject, ChangeDetectorRef, Input, ViewChild, ElementRef } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { isPlatformBrowser } from '@angular/common';
import { FileUploadService } from '../../services/file-upload.service';
import { IdeasService } from '../../services/ideas.service';
import { Router, ActivatedRoute } from '@angular/router';
import { CurrentIdeaService } from '../../services/current-idea.service';
import { ModalService } from '../../services/modal.service';
import { CreationService } from '../../services/creation.service';
import { EquipmentsService } from '../../services/equipments.service';
import { Subject } from 'rxjs';
import { tap, switchMap, debounceTime, map, first } from 'rxjs/operators';
import { materialTypes } from '../../utils';
import { TranslitService } from '../../services/translit.service';
import { IsBrowserService } from '../../services/is-browser.service';
import { HistoryService } from '../../services/history.service';
import { RoutesDataService } from '../../services/routes-data.service';

const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

declare var $;

@Component({
  selector: 'app-idea-creation',
  templateUrl: './idea-creation.component.html',
  styleUrls: ['./idea-creation.component.styl']
})
export class IdeaCreationComponent implements OnInit {
  @Input() editionMode;
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('mainPhotoPicker') mainPhotoPicker: ElementRef;
  @ViewChild('materialsWrapper') materialsWrapper: ElementRef;
  @ViewChild('skillsWrapper') skillsWrapper: ElementRef;
  public creationForm: any;
  materialTypesArr = materialTypes;
  materials: any[] = [{
    name: '',
    price: '',
    inFabric: false,
    inLeroy: false,
    left: false
  }];
  instruments: any[] = [{
    name: '',
    equipment_id: null,
  }];
  skills: any[] = [];
  steps: any[] = [];
  mainUploader:any = new FileUploader({url: URL});
  filesUploader:any = new FileUploader({url: URL});
  modules = {
    toolbar: [
      ['bold', 'italic'],
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link']
    ],
    clipboard: {
      matchVisual: false,
    }
  };
  isBrowser;
  w;
  stepsCounter = 0;
  mainPicUrl;
  mainPicture;
  loadingState;
  errorMessage;
  deleteLoading;
  deleted;
  confirmType;
  ideaLoading;
  isEdition;
  instrumentTypeahead = new Subject<string>();
  instrumentSuggests = {};
  instrumentSuggestsLoading = {};

  constructor(private fb: FormBuilder,
              private _ref: ChangeDetectorRef,
              private fileUploadService: FileUploadService,
              private ideasService: IdeasService,
              private currentIdeaService: CurrentIdeaService,
              private modalService: ModalService,
              private creationService: CreationService,
              private router: Router,
              private activatedRoute: ActivatedRoute,
              private equipmentsService: EquipmentsService,
              private isBrowserService: IsBrowserService,
              private translitService: TranslitService,
              private historyService: HistoryService,
              private routesDataService: RoutesDataService,
              @Inject('HOSTNAME') private hostName: string) {
    this.w = window;
    this.isBrowser = this.isBrowserService.isBrowser;
  }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.editidea) {
      this.ideaLoading = true;
      this.isEdition = true;
      this.ideasService.getIdea(this.activatedRoute.snapshot.queryParams.editidea).subscribe((data) => {
        this.ideaLoading = false;
        this.editionMode = data.result;
        this.initIdeaEdition();
        this.wrapper.nativeElement.scrollTop = 0;
      })
    } else if (this.activatedRoute.snapshot.queryParams.creation && this.activatedRoute.snapshot.queryParams.creation === 'idea') {
      this.initIdeaCreation();
    }

    this.instrumentTypeahead.pipe(debounceTime(500), tap((index) => {
      this.instrumentSuggestsLoading[index] = true;
    }), switchMap(index =>
      this.equipmentsService.getEquipmentsList({ query_str: this.creationForm.controls.instruments.controls[index].controls.name.value })
        .pipe(map(({ result }) => [index, result]))
    )).subscribe(([index, result]) => {
        this.creationForm.controls.instruments.controls[index].setValue({
          ...this.creationForm.controls.instruments.controls[index].value,
          equipment_id: null,
        });
        this.instrumentSuggestsLoading[index] = false;
        this.instrumentSuggests[index] = result.data;
      });
  }

  ngAfterViewInit() {
    this.wrapper.nativeElement.scrollTop = 0;
  }

  initIdeaEdition() {
    this.creationForm = this.fb.group({
      title: [this.editionMode.name || '', [Validators.required]],
      description: [this.encode(this.editionMode.description) || '', [Validators.required]],
      skills: [this.editionMode.need_skills],
      duration: [this.parseDuration(this.editionMode.duration).value, [Validators.required, this.numberValidator]],
      durationUnit: [this.parseDuration(this.editionMode.duration).unit, [Validators.required]],
      complexity: [this.editionMode.difficulty || 'EASY', [Validators.required]],
      instruments: this.fb.array([]),
      materials: this.fb.array([]),
      materialTypes: this.fb.array([
        this.fb.control(this.editionMode.material_types ? !!(this.editionMode.material_types.indexOf(this.materialTypesArr[0].id) + 1) : false),
        this.fb.control(this.editionMode.material_types ? !!(this.editionMode.material_types.indexOf(this.materialTypesArr[1].id) + 1) : false),
        this.fb.control(this.editionMode.material_types ? !!(this.editionMode.material_types.indexOf(this.materialTypesArr[2].id) + 1) : false),
        this.fb.control(this.editionMode.material_types ? !!(this.editionMode.material_types.indexOf(this.materialTypesArr[3].id) + 1) : false),
        this.fb.control(this.editionMode.material_types ? !!(this.editionMode.material_types.indexOf(this.materialTypesArr[4].id) + 1) : false)])
    });
    this.parseFiles();
    if (this.editionMode.need_skills) {
      if (this.editionMode.skills) {
        this.skills = this.editionMode.skills.map(item => {
          return {
            name: item
          }
        })
      }
      this.addSkill();
    }
    this.editionMode.materials.forEach(item => {
      this.addMaterial(item);
    })
    if (this.editionMode.instruction_steps && this.editionMode.instruction_steps.length) {
      this.editionMode.instruction_steps.forEach(item => {
        this.addStep(item);
      })
    }
    if (this.editionMode.instruments && this.editionMode.instruments.length) {
      this.editionMode.instruments.forEach((item, index) => {
        this.instrumentSuggests[index] = [{
          name: item.name,
          id: item.equipment.id,
        }];

        this.addInstrument({
          name: item.name,
          equipment_id: item.equipment.id,
        });
      })
    }
    this.addMaterial();
    this.addInstrument();
  }

  private parseDuration = function(duration) {
    let hours = duration / (3600 * 1000);
    if (hours >= 24) {
      return {
        value: Math.floor(hours / 24),
        unit: 'days'
      }
    } else {
      return {
        value: hours,
        unit: 'hours'
      }
    }
  }

  private parseFiles = function() {
    this.mainUploader.queue[0] = {};
    this.mainUploader.queue[0].fileUrl = this.editionMode.photo.url;
    this.mainUploader.queue[0].fileId = this.editionMode.photo.id;
    this.mainUploader.queue[0].old = true;
    this.editionMode.attachments.forEach((item) => {
      this.filesUploader.queue.push({
        file: {
          type: 'FILE',
          name: item.name,
          file_size: item.file_size,
          url: '',
          id: item.id
        },
        old: true
      })
    })
  }

  initIdeaCreation() {
    let materialTypesValidator: ValidatorFn = function(control: FormControl) {
      console.log(control)
      return control.value.some(item => item) ? null : {
        materialTypesValidator: {
          valid: false
        }
      }
    }

    this.creationForm = this.fb.group({
      title: ['', [Validators.required]],
      description: ['', [Validators.required]],
      skills: [''],
      duration: ['', [this.numberValidator, Validators.required]],
      durationUnit: ['hours', [Validators.required]],
      complexity: ['EASY', [Validators.required]],
      materials: this.fb.array([]),
      instruments: this.fb.array([]),
      materialTypes: this.fb.array([
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false),
        this.fb.control(false)], materialTypesValidator)
    });
    this.addStep();
    this.addInstrument();
    this.addMaterial();
    this.loadingState = 0;
  }

  addMaterial(material:any = {}) {
    let group = this.fb.group({
      name: [material.name],
      price: [material.price],
      in_leroy: [material.exists_in_leroy],
      in_fabric: [material.exists_in_fabric]
    })
    this.creationForm.controls.materials.push(group);
    this.initMaterialsValidators();
  }

  removeMaterial(index) {
    this.creationForm.controls.materials.controls.splice(index, 1);
    if (!this.creationForm.controls.materials.controls.length) {
      this.addMaterial();
    }
    this.initMaterialsValidators();
  }

  initMaterialsValidators() {
    if (this.creationForm.controls.materials.controls.length > 1) {
      this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.name.setValidators();
      this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.price.setValidators();
      this.safeReset(this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.name);
      this.safeReset(this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.price);
      for (let i = 0; i < this.creationForm.controls.materials.controls.length - 1; i++) {
        this.creationForm.controls.materials.controls[i].controls.name.setValidators(Validators.required);
        this.creationForm.controls.materials.controls[i].controls.price.setValidators([Validators.pattern(/^[\d]{1,}$/)]);
        this.safeReset(this.creationForm.controls.materials.controls[i].controls.name);
        this.safeReset(this.creationForm.controls.materials.controls[i].controls.price);
      }
    } else if (this.creationForm.controls.materials.controls.length === 1) {
      this.creationForm.controls.materials.controls[0].controls.name.setValidators(Validators.required);
      this.creationForm.controls.materials.controls[0].controls.price.setValidators();
      this.safeReset(this.creationForm.controls.materials.controls[0].controls.name);
      this.safeReset(this.creationForm.controls.materials.controls[0].controls.price);
    }
  }

  onMaterialInput(index) {
    if (index === this.creationForm.controls.materials.controls.length - 1) {
      if (this.creationForm.controls.materials.controls[index].controls.name.value) {
        this.addMaterial();
      }
    } else {
      if (!this.creationForm.controls.materials.controls[index].controls.name.value && !this.creationForm.controls.materials.controls[index].controls.price.value && !this.creationForm.controls.materials.controls[index].controls.in_fabric.value && !this.creationForm.controls.materials.controls[index].controls.in_leroy.value) {
        this.removeMaterial(index);
      }
    }
  }

  onMaterialBlur(index) {
    if (index < this.creationForm.controls.materials.controls.length - 1) {
      this.creationForm.controls.materials.controls[index].controls.price.markAsTouched();
    }
  }

  addInstrument(instrument: any = {}) {
    this.creationForm.controls.instruments.push(this.fb.group({
      name: [instrument.name ? instrument.name : ''],
      equipment_id: [instrument.equipment_id ? instrument.equipment_id : null]
    }));
    this.initInstrumentValidators();
  }

  removeInstrument(index) {
    this.creationForm.controls.instruments.removeAt(index);
    if (!this.creationForm.controls.instruments.length) {
      this.addInstrument();
    }
    this.initInstrumentValidators();
  }

  onInstrumentTitleInput(index) {
    if (index === this.creationForm.controls.instruments.length - 1) {
      if (this.creationForm.controls.instruments.controls[index].controls.name.value) {
        this.addInstrument();
      }
    }
    if (index === this.creationForm.controls.instruments.length - 2) {
      if (this.isInstrumentEmpty(this.creationForm.controls.instruments.controls[index])) {
        this.removeInstrument(this.creationForm.controls.instruments.length - 1);
      }
    }
    this.instrumentTypeahead.next(index);
  }

  onInstrumentFieldBlur(index) {
    if (index < this.creationForm.controls.instruments.length - 1) {
      this.creationForm.controls.instruments.controls[index].patchValue({
        left: true
      });
    }
    if (this.isInstrumentEmpty(this.creationForm.controls.instruments.controls[index]) && index < this.creationForm.controls.instruments.length - 1) {
      this.removeInstrument(index);
    }
  }

  initInstrumentValidators() {
    if (this.creationForm.controls.instruments.controls.length > 1) {
      this.creationForm.controls.instruments.controls[this.creationForm.controls.instruments.controls.length - 1].controls.name.setValidators();
      this.creationForm.controls.instruments.controls[this.creationForm.controls.instruments.controls.length - 1].controls.equipment_id.setValidators();
      this.safeReset(this.creationForm.controls.instruments.controls[this.creationForm.controls.instruments.controls.length - 1].controls.name);
      this.safeReset(this.creationForm.controls.instruments.controls[this.creationForm.controls.instruments.controls.length - 1].controls.equipment_id);
      for (let i = 0; i < this.creationForm.controls.instruments.controls.length - 1; i++) {
        this.creationForm.controls.instruments.controls[i].controls.name.setValidators(Validators.required);
        this.creationForm.controls.instruments.controls[i].controls.equipment_id.setValidators(Validators.required);
        this.safeReset(this.creationForm.controls.instruments.controls[i].controls.name);
        this.safeReset(this.creationForm.controls.instruments.controls[i].controls.equipment_id);
      }
    } else if (this.creationForm.controls.materials.controls.length === 1) {
      this.creationForm.controls.instruments.controls[0].controls.name.setValidators();
      this.creationForm.controls.instruments.controls[0].controls.equipment_id.setValidators();
      this.safeReset(this.creationForm.controls.instruments.controls[0].controls.name);
      this.safeReset(this.creationForm.controls.instruments.controls[0].controls.equipment_id);
    }
  }

  isInstrumentEmpty(instrument) {
    return !instrument.controls.name.value
  }

  addStep(stepData?, index?) {
    this.stepsCounter = this.stepsCounter + 1;
    let editorId = 'editor-' + this.stepsCounter;
    let stepTitleId = 'step-title-' + this.stepsCounter;
    let uploader: any = new FileUploader({url: URL});
    let step: any = {
      uploader: uploader,
      title: '',
      instruction: '',
      editorId: editorId,
      stepTitleId: stepTitleId,
      attachments: []
    }
    if (stepData) {
      step.title = stepData.name || '';
      if (stepData.attachments) {
        step.attachments = stepData.attachments.map(item => {
          return {
            type: item.file_item_type,
            old: true,
            fileUrl: item.url,
            fileId: item.id
          }
        })
      }
      if (stepData.id) {
        step.id = stepData.id;
      }
    }
    if (!index && index !== 0) {
      this.steps.push(step);
    } else {
      this.steps.splice(index, 0, step);
    }
    this._ref.detectChanges();
    var Block = this.w.Quill.import('blots/block');
    Block.tagName = 'P';
    this.w.Quill.register(Block, true)
    let quill = new this.w.Quill('#' + editorId, {
      modules: this.modules,
      placeholder: 'Введите инструкции',
      theme: 'snow'
    });
    if (stepData) {
      if (stepData.instruction) {
        step.instruction = stepData.instruction || '';
        quill.pasteHTML('');
        quill.pasteHTML(0,stepData.instruction, 'api');
      }
    }
    let that = this;
    quill.on('editor-change', function(...args) {
      if (quill.editor.scroll.domNode.innerText.trim()) {
        step.instruction = quill.editor.scroll.domNode.innerHTML
      } else {
        step.instruction = '';
      }
      if (that.isBrowser) {
        if (args[1]) {
          document.getElementById(editorId).classList.add('text-editor-focused');
          document.getElementById(editorId).classList.remove('text-editor-invalid', 'ng-invalid');
        } else {
          document.getElementById(editorId).classList.remove('text-editor-focused');
          if (!quill.editor.scroll.domNode.innerText.trim()) {
            document.getElementById(editorId).classList.add('text-editor-invalid', 'ng-touched', 'ng-invalid');
          }
        }
      }
    })
  }

  removeStep(index) {
    this.steps.splice(index, 1);
    if (this.steps.length === 0) {
      this.addStep();
    }
  }

  onInstrunctionChange(evt, index) {
    this.steps[index].instruction = evt.html;
  }

  addSkill() {
    this.skills.push({name: ''});
  }

  removeSkill(index) {
    this.skills.splice(index, 1);
    if (this.skills.length === 0) {
      this.addSkill();
    }
    this.checkSkills();
  }

  clearSkills() {
    this.skills = [];
  }

  onSkillTitleInput(index) {
    if (index === this.skills.length - 1) {
      if (this.skills[index].name) {
        this.addSkill();
        this.checkSkills();
      }
    }
    if (index === this.skills.length - 2) {
      if (this.isSkillEmpty(this.skills[index])) {
        this.removeSkill(this.skills.length - 1);
      }
    }
  }

  onSkillFieldBlur(index) {
    if (index < this.skills.length - 1) {
      this.skills[index].left = true;
    }
    if (this.isSkillEmpty(this.skills[index]) && index < this.skills.length - 1) {
      this.removeSkill(index);
    }
    this.checkSkills();
  }

  isSkillEmpty(skill) {
    return !skill.name;
  }

  onSkillsCheckboxChange() {
    if (this.creationForm.value.skills) {
      this.addSkill();
    } else {
      this.clearSkills();
      this.checkSkills();
    }
  }

  allSkillsValid() {
    if (this.creationForm.value.skills) {
      return !!this.skills.filter(item => item.name).length;
    }
    return true;
  }

  skillsEmpty() {
    return !this.skills.filter(item => item.name).length;
  }

  checkSkills() {
    if (this.skillsEmpty() && this.creationForm.value.skills) {
      this.skillsWrapper.nativeElement.classList.add('ng-invalid');
    } else {
      this.skillsWrapper.nativeElement.classList.remove('ng-invalid');
    }
  }

  allStepsValid() {
    return !!this.steps.every(item => item.title && item.instruction);
  }


  uploadFiles(ideaName?) {
    this.errorMessage = '';
    this.loadingState = 1;
    let that = this;
    function* generator() {
      if (that.mainUploader.queue[0]) {
        yield 'main'
      }
      for (let n = 0; n < that.filesUploader.queue.length; n++) {
        yield n;
      }
      for (let i = 0; i < that.steps.length; i++) {
        let filesGenerator: any = function* () {
          for (let k = 0; k < that.steps[i].attachments.length; k++) {
            yield k
          }
        }
        yield* filesGenerator()
      }
      return that.submitForm();
    }
    let gen = generator();
    gen.next();
    this.filesUploader.queue.forEach((item) => {
      if (item.old) {
        item.fileId = item.file.id;
        item.fileUrl = item.file.url;
        item.loaded = true;
        gen.next();
      } else if (item.loaded) {
        gen.next();
      } else {
        item.loading = true;
        item.hasError = false;
        this.fileUploadService.uploadFile(item._file, 'IDEAS', ideaName).pipe(first()).subscribe((data) => {
          item.fileUrl = data.result.url;
          item.fileId = data.result.id;
          item.loaded = true;
          item.loading = false;
          gen.next();
        }, () => {
          item.loading = false;
          item.hasError = true;
          this.loadingState = 'error'
          that.errorMessage = 'Ошибка загрузки файла';
        });
      }
    })
    if (this.mainUploader.queue[0]) {
      if (this.mainUploader.queue[0].old) {
        this.mainUploader.queue[0].loaded = true;
        gen.next();
      } else if (this.mainUploader.queue[0].loaded) {
        gen.next();
      } else {
        this.mainUploader.queue[0].loading = true;
        this.mainUploader.queue[0].hasError = false;
        this.fileUploadService.uploadFile(this.mainUploader.queue[0]._file, 'IDEAS', ideaName).pipe(first()).subscribe((data) => {
          this.mainUploader.queue[0].fileUrl = data.result.url;
          this.mainUploader.queue[0].fileId = data.result.id;
          this.mainUploader.queue[0].loaded = true;
          this.mainUploader.queue[0].loading = false;
          gen.next();
        }, () => {
          this.mainUploader.queue[0].hasError = true;
          this.mainUploader.queue[0].loading = false;
          this.loadingState = 'error'
          that.errorMessage = 'Ошибка загрузки файла';
        });
      }
    }
    this.steps.forEach((item) => {
      item.attachments.forEach((file) => {
        if (file.old) {
          file.type = file.type
          file.fileId = file.fileId;
          file.fileUrl = file.fileUrl;
          file.loaded = true;
          gen.next();
        } else if (file.loaded) {
          gen.next();
        } else {
          file.loading = true;
          file.hasError = false;
          if (file.type === 'URL') {
            file.loaded = true;
            file.fileUrl = file.url;
            file.loading = false;
            gen.next();
          } else if (file.type === 'IMAGE' || file.type === 'VIDEO') {
            this.fileUploadService.uploadFile(file.file, 'IDEAS', ideaName).pipe(first()).subscribe((data) => {
              file.loaded = true;
              file.fileUrl = data.result.url;
              file.fileId = data.result.id;
              file.loading = false;
              gen.next();
            }, () => {
              file.hasError = true;
              file.loading = false;
              this.loadingState = 'error'
              that.errorMessage = 'Ошибка загрузки файла';
            });
          }
        }
      })
    })
  }

  private getIdeaCreationForm = function() {
    let form:any = {};
    form.name = this.creationForm.value.title;
    form.description = this.escape(this.creationForm.value.description);
    form.difficulty = this.creationForm.value.complexity;
    form.need_skills = !!this.creationForm.value.skills;
    if (!!this.creationForm.value.skills) {
      form.skills = this.skills.filter(item => item.name).map(item => item.name);
    }
    form.material_types = this.materialTypesArr.filter((item, index) => this.creationForm.value.materialTypes[index]).map(item => item.id);
    form.cost = 0;
    form.materials = this.creationForm.value.materials.filter(item => (item.name)).map(item => {
      let result = {
        exists_in_fabric: !!item.in_fabric,
        exists_in_leroy: !!item.in_leroy,
        name: item.name,
        price: item.price ? item.price : 0
      }
      return result;
    })
    form.instruments = this.creationForm.value.instruments.map(instrument => {
      if (instrument.equipment_id) {
        return {
          name: instrument.name,
          equipment_id: instrument.equipment_id,
        }
      }
    }).filter(Boolean);
    console.log(this.steps);
    form.instruction_steps = this.steps.map((step, stepIndex) => {
      let result:any = {
        attachments: step.attachments.length ? step.attachments.filter(item => item.fileUrl).map((item, index) => {
          if (item.type === 'IMAGE' || item.type === 'VIDEO') {
            return {
              file_item_type: item.type,
              file_id: item.fileId,
              order: index
            }
          } else if (item.type === 'URL') {
            return {
              file_item_type: item.type,
              attachment_url: item.fileUrl,
              order: index
            }
          }
          return null;
        }).filter(item => item != null) : [],
        instruction: step.instruction,
        name: step.title,
        step_number: stepIndex + 1
      }
      if (step.id) {
        result.id = step.id;
      }
      return result;
    })
    if (this.filesUploader.queue && this.filesUploader.queue.length) {
      form.attachments = this.filesUploader.queue.map((item, index) => {
        return {
          file_item_type: 'FILE',
          file_id: item.fileId,
          order: index
        }
      });
    }
    if (this.creationForm.value.durationUnit === 'hours') {
      form.duration = this.creationForm.value.duration * 3600 * 1000;
    } else {
      form.duration = this.creationForm.value.duration * 24 * 3600 * 1000;
    }
    if (this.mainUploader.queue[0] && this.mainUploader.queue[0].fileUrl) {
      form.photo_id = this.mainUploader.queue[0].fileId;
    }
    return form;
  }

  submitForm() {
    console.log(this.getIdeaCreationForm());
    this.loadingState = 2;
    if (this.editionMode) {
      this.ideasService.edit(this.editionMode.id, this.getIdeaCreationForm()).subscribe((data) => {
        this.loadingState = 3;
        // this.closeModal();
        if (this.router.url.indexOf('idei/') + 1) {
          let queryParams = this.router.url.split('?')[1];
          if (queryParams) {
            queryParams = '?' + queryParams;
          }
          let redirectUrl = '/idei/' + this.translitService.getHumanReadableId(data.result.name, data.result.id);
          try {
            this.historyService.redirect404 = true;
            this.historyService.currentState = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url].previous.url;
            let pageData:any = {};
            pageData[redirectUrl] = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url]
            this.historyService.addPageData('idea', pageData);
          } catch {
            this.historyService.redirect404 = false;
          }
          this.router.navigate([redirectUrl]);
        } else {
          this.closeModal();
        }
        // this.closeModal();
        setTimeout(() => {
          this.currentIdeaService.updateObs.next();
          this.currentIdeaService.update();
        })
      }, (e) => {
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка публикации';
      });
    } else {
      this.ideasService.create(this.getIdeaCreationForm()).subscribe((data) => {
        this.loadingState = 3;
        this.closeModal();
        this.currentIdeaService.updateObs.next();
        this.currentIdeaService.update();
      }, (e) => {
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка публикации';
      });
    }
  }

  closeModal() {
    this.creationService.closeCreationModal();
    // this.modalService.onClose();
    // if (this.editionMode) {
    //   this.router.navigate([`./ideas/idea/${this.editionMode.id}`]);
    // } else {
    //   this.router.navigate([`./ideas`]);
    // }
  }

  deleteIdea() {
    this.confirmType = '';
    this.loadingState = 0;
    if (this.editionMode.id) {
      this.deleteLoading = true;
      this.ideasService.deleteIdea(this.editionMode.id).subscribe((data) => {
        this.deleteLoading = false;
        this.deleted = true;
        if (this.router.url.indexOf('idei/') + 1) {
          this.router.navigate(['/idei']);
        } else {
          this.closeModal();
        }
      }, () => {
        this.deleteLoading = false;
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка удаления';
      })
    }
  }

  openConfirmDeletion() {
    this.confirmType = 'delete';
    this.wrapper.nativeElement.style.overflow = 'hidden';
  }

  closeConfirm() {
    this.confirmType = '';
    this.wrapper.nativeElement.style.overflow = 'auto';
  }

  onSubmit() {
    if (this.creationForm.invalid || !this.allStepsValid() || !this.allSkillsValid() || !this.mainUploader.queue[0]) {
      console.log(this.creationForm)
      if (this.isBrowser) {
        this.markInvalidFields();
        this._ref.detectChanges();
        this.scrollToInvalidField();
      }
    } else {
      let ideaName = this.creationForm.value.title;
      console.log(ideaName);
      this.uploadFiles(ideaName);
    }
  }

  onSelectInstrument(index) {
    console.log(index);
    if (index === this.creationForm.controls.instruments.length - 1) {
      this.addInstrument();
    }
  }

  stepUp(index) {
    console.log(index);
    console.log(this.steps);
    if (index >= 1) {
      this.changeStepsOrder(index, index - 1);
      // this.steps = this.moveElementInArray(this.steps, index, index - 1);
    }
  }

  stepDown(index) {
    console.log(index);
    console.log(this.steps);
    if (index < this.steps.length - 1) {
      this.changeStepsOrder(index, index + 1);
      // this.steps = this.moveElementInArray(this.steps, index, index + 1);
    }
  }

  public handleSearchEquip = function(query) {
    return this.equipmentsService.getEquipmentsList({ query_str: query, hide_broken: true }).pipe(map((data:any) => {
      return {
        result: data.result.data
      }
    }))
  }.bind(this);

  private markInvalidFields = function() {
    const controls = this.creationForm.controls;
    Object.keys(controls)
    .forEach(controlName => controls[controlName].markAsTouched());

    controls.instruments.controls.forEach((_, index) => controls.instruments.controls[index].markAsTouched());
    this.creationForm.controls.materials.controls.forEach(control => {
      Object.keys(control.controls)
      .forEach(controlName => {
        control.controls[controlName].markAsTouched();
      });
    });
    this.steps.forEach((item) => {
      $('#' + item.stepTitleId).addClass('ng-touched');
    })

    if (!this.allSkillsValid()) {
      this.skillsInvalid = true;
    }
    if(!this.mainUploader.queue[0]) {
      this.mainUploader.photoInvalid = true;
    }

    this.steps.forEach((item) => {
      if (!item.instruction) {
        document.getElementById(item.editorId).classList.add('text-editor-invalid', 'ng-touched', 'ng-invalid')
      }
    })
    this.checkSkills();
  }

  private scrollToInvalidField = function() {
    let elem;
    if (this.mainUploader.photoInvalid) {
      console.log(1)
      let elem = this.mainPhotoPicker.nativeElement;
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
    } else {

      if ($('.ng-invalid')[0].tagName === 'FORM' && $('.ng-invalid')[1]) {
        console.log(2)
        elem = $('.ng-invalid')[1].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-invalid')[1]
        }
      } else {
        console.log(3)
        elem = $('.ng-invalid')[0].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-invalid')[0]
        }
      }
      console.log(elem);
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
      // console.log(elem,elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop);

    }
  }

  private minValValidator: ValidatorFn = function(control: FormControl) {
    console.log(control)
    return +control.value > 0 ? null : {
      minValValidator: {
        valid: false
      }
    }
  }

  private numberValidator: ValidatorFn = function(control: FormControl) {
    return +control.value > 0 || (control.value === null || control.value === '') ? null : {
      incorrect: 'true'
    }
  }.bind(this)

  getDurationErrorText() {
    if (this.creationForm.controls.duration.errors && this.creationForm.controls.duration.errors.required) {
      return 'Пожалуйста, заполните это поле.'
    } else  {
      return 'Некорректное значение.'
    }
  }

  private escape = function (string) {
    let escaped = $("<div>").text(string).html();
    return escaped;
  }

  private encode = function (string) {
    let encoded = $("<div>").html(string).text();
    return encoded;
  }

  private safeReset(control) {
    let val = control.value;
    control.reset();
    control.setValue(val);
  }

  private changeStepsOrder(oldIndex, newIndex) {
    let oldElem = document.getElementsByClassName('creation-step-item')[oldIndex];
    let oldScrollOffset = oldElem.getBoundingClientRect().top;
    let result = [];
    this.steps.forEach((item, index) => {
      if (index === oldIndex) {
        result.push(this.steps[newIndex]);
      } else if (index === newIndex) {
        result.push(this.steps[oldIndex]);
      } else {
        result.push(item);
      }
    })
    this.steps = result;
    this._ref.detectChanges();
    let elem = document.getElementsByClassName('creation-step-item')[newIndex];
    console.log(elem);
    let scrollPos = elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - oldScrollOffset;
    console.log(scrollPos);
    this.wrapper.nativeElement.scrollTo(0, scrollPos);
  }

}
