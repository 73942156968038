import { Injectable } from '@angular/core';
import { ModalService } from './modal.service';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CreationService {
  isCreationModalOpened;
  creationSubject;
  constructor(private modalService: ModalService, private userService: UserService) { }

  openCreationModal(subject) {
    if (this.userService.userId && (this.userService.userData.role === 'ADMIN' || this.userService.userData.role === 'SUPER_ADMIN')) {
      this.modalService.freezBody();
      this.isCreationModalOpened = true;
      this.creationSubject = subject;
    } else {
      this.modalService.onClose();
    }
  }

  closeCreationModal(noRedirects?) {
    this.modalService.unfreezBody();
    this.isCreationModalOpened = false;
    this.creationSubject = null;
    if (!noRedirects) {
      this.modalService.onClose();
    } else {
      this.isCreationModalOpened = false;
    }
  }
}
