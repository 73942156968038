import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'certificatesSort'
})
export class CertificatesSortPipe implements PipeTransform {

  transform(items: any[], param: string, direction: boolean) {
    let callback;
   

    function coursesSortCallback(a, b) {
      if (`${a.course_name.toLowerCase()}`.trim() > `${b.course_name.toLowerCase()}`.trim()) {
        return direction ? 1 : -1;
      }
      if (`${a.course_name.toLowerCase()}`.trim() < `${b.course_name.toLowerCase()}`.trim()) {
        return direction ? -1 : 1;
      }
      return 0;
    }

    function paramsSortCallback(param, a, b) {
      let result = a[param] - b[param];
      return direction ? result : -result;
    }

    function defaultSortCallback(a, b) {
      return 0
    }

    if (!items) {
      return items;
    }

    switch(param) {
      case 'date':
        callback = paramsSortCallback.bind(null, 'date_ms');
        break;
      case 'cert':
        callback = paramsSortCallback.bind(null, 'certificate_number');
        break;
      case 'hours':
        callback = paramsSortCallback.bind(null, 'hours');
        break;
      case 'course':
        callback = coursesSortCallback;
        break;
      default:
        callback = defaultSortCallback;
        break;
      }

    return items.sort(callback);
  }

}
