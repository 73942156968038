import { Component, EventEmitter, OnInit, Input, Output } from '@angular/core';

@Component({
  selector: 'app-instrument-select',
  templateUrl: './instrument-select.component.html',
  styleUrls: ['./instrument-select.component.styl']
})
export class InstrumentSelectComponent implements OnInit {
  @Input() control;
  @Input() instruments: any[];
  @Input() searchMethod;
  @Input() exclude;
  @Output() onSelectEmitter = new EventEmitter();
  @Output() onSearch = new EventEmitter();

  pickerOpened;

  constructor() { }

  ngOnInit() {
  }

  selectInstrument(instrument) {
    setTimeout(() => {
      this.closePicker();
    }, 0);
    if (this.control) {
      this.control.setValue({
        ...this.control.value,
        name: instrument ? instrument.name : null,
        equipment_id: instrument ? instrument.id : null
      });
      this.control.markAsTouched();
      this.onSelectEmitter.emit(this.control.value);
    }
  }

  openPicker() {
    this.pickerOpened = true;
  }

  closePicker() {
    if (this.pickerOpened) {
      this.pickerOpened = false;
      if (this.control) {
        this.control.markAsTouched();
      }
    }
  }

  onBlur() {
    this.control.markAsTouched();
  }

  equipFilter(item) {
    return !item.free_access;
  }
}
