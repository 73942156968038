import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-subsriptions-card',
  templateUrl: './subsriptions-card.component.html',
  styleUrls: ['./subsriptions-card.component.styl']
})
export class SubsriptionsCardComponent implements OnInit {

  @Input() card: any;
  amount:number = 1;

  constructor(public modalService: ModalService) { }

  ngOnInit() {
    if (this.card.minHours) {
      this.amount = this.card.minHours;
    }
  }
  updateAmount(e:number) {
    this.amount = e;
  }

}
