import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin } from 'rxjs';

import { ModalService } from '../../services/modal.service';
import { UserService } from '../../services/user.service';
import { ReserveType, Session } from '../../interfaces';
import { EquipmentsService } from '../../services/equipments.service';
import { first } from 'rxjs/operators';

const initialCalendarDisplay = {
  courses: [],
  consultations: [],
  disabled: [],
};

@Component({
  selector: 'app-modal-reserve-instrument',
  templateUrl: './modal-reserve-instrument.component.html',
  styleUrls: ['./modal-reserve-instrument.component.styl']
})
export class ModalReserveInstrumentComponent implements OnInit {
  equipmentId: number;
  isAdmin;
  isMaster;
  isMe;
  reserveForMaster;
  reserveLoading = true;
  currentUserId: number;
  equipment;
  reserveParams;
  moveTiming;
  error;
  userId;
  user;

  constructor(
    private activatedRoute: ActivatedRoute,
    private modalService: ModalService,
    private userService: UserService,
    private equipmentsService: EquipmentsService,
  ) {}

  ngOnInit() {
    this.isAdmin = this.userService.userData.role === 'ADMIN' || this.userService.userData.role === 'SUPER_ADMIN';
    if (this.activatedRoute.snapshot.queryParams.user && this.isAdmin) {
      this.currentUserId = this.activatedRoute.snapshot.queryParams.user;
    } else {
      this.currentUserId = +this.userService.userId;

    }
    this.isMaster = this.userService.userData.role === 'ADMIN';
    if (this.activatedRoute.snapshot.queryParams.instrument) {
      this.equipmentId = this.activatedRoute.snapshot.queryParams.instrument;
      this.reserveLoading = true;
      forkJoin(this.equipmentsService.getEquipment(this.equipmentId), this.userService.getUserData(this.currentUserId)).pipe(first()).subscribe((data) => {
        this.equipment = data[0].result;
        this.user = data[1].result;
        this.isMe = this.user.id == this.userService.userId;
        this.reserveForMaster = this.user.role === 'ADMIN';
        this.reserveLoading = false;
      }, () => {this.reserveLoading = false; this.error = false})
    }
    if (this.activatedRoute.snapshot.queryParams.moving) {
      this.moveTiming = this.activatedRoute.snapshot.queryParams.moving;
    }
  }

  onSubmit(data) {
    this.modalService.nextPage(null, {...data, moveTiming: this.moveTiming, userId: this.currentUserId, isMe: this.isMe});
  }

  handleClose() {
    this.modalService.onClose();
  }
}
