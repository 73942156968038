import { Component, OnInit, OnDestroy, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl, FormBuilder, Validators, FormArray, ValidatorFn } from '@angular/forms';
import { EquipmentsService } from '../../services/equipments.service';
import { CurrentEquipmentService } from '../../services/current-equipment.service';
import { CreationService } from '../../services/creation.service';
import { phoneMask, uses, areaTypesEquipments, materialTypesEquipments, parseApiError } from '../../utils';
import { FileUploadService } from '../../services/file-upload.service';
import { first } from 'rxjs/operators';
import { TranslitService } from '../../services/translit.service';
import { HistoryService } from '../../services/history.service';
import { RoutesDataService } from '../../services/routes-data.service';

declare var $;

function indexOf(collection, pred) {
  let findIndex;

  collection.forEach((val, index) => {
    if (pred(val) === true) {
      findIndex = index;
    }
  });
  return findIndex;
}

@Component({
  selector: 'app-equipment-creation',
  templateUrl: './equipment-creation.component.html',
  styleUrls: ['./equipment-creation.component.styl']
})
export class EquipmentCreationComponent implements OnInit {
  @ViewChild('wrapper') wrapper: ElementRef;
  @ViewChild('mainPhotoPicker') mainPhotoPicker: ElementRef;

  editionMode;
  loadingState;
  errorMessage;
  deleteLoading;
  deleted;
  confirmType;
  creationForm;
  sortableOptions;
  equipLoading;
  isEdition;
  masters = [];
  currentRoute;
  removeQueryParams;
  uses = uses.map(item => {
    return {
      name: item.title,
      value: item.value,
      checked: false
    }
  });
  areas = areaTypesEquipments.map(item => {
    return {
      name: item.title,
      value: item.value,
      checked: false
    }
  })

  material_types = materialTypesEquipments.map(item => {
    return {
      name: item.name,
      value: item.id,
      checked: false
    }
  });

  phoneMask = phoneMask;
  isOtherUseActive;

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private _ref: ChangeDetectorRef,
    private equipmentsService: EquipmentsService,
    private currentEquipmentService: CurrentEquipmentService,
    private creationService: CreationService,
    private activatedRoute: ActivatedRoute,
    private fileUploadService: FileUploadService,
    private translitService: TranslitService,
    private historyService: HistoryService,
    private routesDataService: RoutesDataService
  ) { }

  ngOnInit() {
    if (this.activatedRoute.snapshot.queryParams.editequipment) {
      this.equipLoading = true;
      this.isEdition = true;
      this.equipmentsService.getEquipment(this.activatedRoute.snapshot.queryParams.editequipment).pipe(first()).subscribe((data) => {
        this.equipLoading = false;
        this.editionMode = data.result;
        this.editionMode.areas = this.validateAreas(this.editionMode.areas);
        this.editionMode.material_types = this.validateMaterials(this.editionMode.material_types);
        this.initEdition();
        this.wrapper.nativeElement.scrollTop = 0;
      })
    } else if (this.activatedRoute.snapshot.queryParams.creation && this.activatedRoute.snapshot.queryParams.creation === 'equipment') {
      this.initCreation();
    }
    this.sortableOptions = {
      onUpdate: (event: any) => {
        this.creationForm.controls.characteristics.controls.forEach((control, index) => {
          this.creationForm.controls.characteristics.controls[index].patchValue({
            order: index
          });
        });
      },
      handle: '.charact-list-handle-icon'
    };
    this.currentRoute = this.router.url.split('?')[0];
    if (this.isEdition) {
      this.removeQueryParams = { popup: 'remove-equipment', equipment: this.activatedRoute.snapshot.queryParams.editequipment };
    }
  }

  initCreation() {
    this.creationForm = this.fb.group({
      title: ['', [Validators.required]],
      mainImage: [null, [Validators.required]],
      areas: this.fb.array([], Validators.compose([Validators.required, Validators.minLength(1)])),
      material_types: this.fb.array([]),
      uses: this.fb.array([], Validators.compose([Validators.required, Validators.minLength(1)])),
      // use: ['SAW', [Validators.required]],
      other_use: [''],
      description: ['', [Validators.required]],
      characteristics: this.fb.array([]),
      mini_courses: this.fb.array([]),
      materials: this.fb.array([]),
      need_reserve: [false],
      amount: ['', [Validators.required, this.numberValidator]],
      provider_name: ['', [Validators.required]],
      provider_contact: ['', [Validators.required, Validators.minLength(11)]],
    });

    this.addMaterial();
  }

  initEdition() {
    this.creationForm = this.fb.group({
      title: [this.editionMode.name, [Validators.required]],
      mainImage: [this.editionMode.photo ? {
        old: true,
        fileId: this.editionMode.photo.id,
        fileUrl: this.editionMode.photo.url
      } : '', [Validators.required]],
      areas: this.fb.array([], Validators.compose([Validators.required, Validators.minLength(1)])),
      material_types: this.fb.array([]),
      uses: this.fb.array([], Validators.compose([Validators.required, Validators.minLength(1)])),
      // use: [this.editionMode.use || 'SAW', [Validators.required]],
      other_use: [this.editionMode.other_use || ''],
      description: [this.editionMode.description, [Validators.required]],
      characteristics: this.fb.array([]),
      mini_courses: this.fb.array([]),
      materials: this.fb.array([]),
      need_reserve: [!this.editionMode.free_access],
      amount: [this.editionMode.amount, [Validators.required, this.numberValidator]],
      provider_name: [this.editionMode.provider_name, [Validators.required]],
      provider_contact: [this.editionMode.provider_contact, [Validators.required, Validators.minLength(11)]],
    });

    this.editionMode.areas.forEach(item => {
      this.onSelectCheck('areas', item, true);
    });

    this.editionMode.material_types.forEach(item => {
      this.onSelectCheck('material_types', item, true);
    });

    if (this.editionMode.uses) {
      this.editionMode.uses.forEach(item => {
        this.onSelectCheck('uses', item, true);
      });
    }


    this.editionMode.characteristics.forEach(item => {
      this.addCharact(item);
    });

    this.editionMode.mini_courses.forEach(item => {
      this.addMiniCourse(item);
    });

    this.editionMode.materials.forEach(item => {
      this.addMaterial(item);
    });

    this.addMaterial();
  }

  onCheckChange(type, event) {
    this.onSelectCheck(type, event.target.value, event.target.checked);
  }

  onSelectCheck(type, value, checked) {
    const formArray: FormArray = this.creationForm.get(type) as FormArray;
    formArray.markAsTouched();

    if (checked){
      formArray.push(new FormControl(value));
    } else {
      let i: number = 0;
      formArray.controls.forEach((ctrl: FormControl) => {
        if (ctrl.value == value) {
          formArray.removeAt(i);
          return;
        }
        i++;
      });
    }

    if (type === 'areas') {
      const index = indexOf(this.areas, (item) => {
        return item.value === value;
      });
      if (index >=0) {
        this.areas[index].checked = checked;
      }
    }

    if (type === 'material_types') {
      const index = indexOf(this.material_types, (item) => {
        return item.value === value;
      });
      if (index >=0) {
        this.material_types[index].checked = checked;
      }
    }
    if (type === 'uses') {
      const index = indexOf(this.uses, (item) => {
        return item.value === value;
      });
      if (index >=0) {
        this.uses[index].checked = checked;
        if (this.uses[index].value === 'OTHER') {
          this.isOtherUseActive = checked;
          if (this.isOtherUseActive) {
            this.safeReset(this.creationForm.controls.other_use);
            this.creationForm.controls.other_use.setValidators([Validators.required]);
          } else {
            this.safeReset(this.creationForm.controls.other_use);
            this.creationForm.controls.other_use.setErrors(null);
            this.creationForm.controls.other_use.setValidators();
          }
        }
      }
    }
  }

  onChangeUse(event) {
    if (event.target.value === 'OTHER') {
      this.safeReset(this.creationForm.controls.other_use);
      this.creationForm.controls.other_use.setValidators([Validators.required]);
    } else {
      this.safeReset(this.creationForm.controls.other_use);
      this.creationForm.controls.other_use.setErrors(null);
      this.creationForm.controls.other_use.setValidators();
    }
  }

  addCharact(item: any = {}) {
    let group = this.fb.group({
      name: [item.name],
      value: [item.value],
      order: item.order ? item.order : this.creationForm.controls.characteristics.controls.length
    });

    this.creationForm.controls.characteristics.push(group);
    this.initCharactValidators();
  }

  removeCharact(index) {
    this.creationForm.controls.characteristics.removeAt(index);

    this.initCharactValidators();
  }

  addMiniCourse(item: any = {}) {
    let group = this.fb.group({
      name: [item.name],
      duration: [item.duration],
      id: [item.id]
    });

    this.creationForm.controls.mini_courses.push(group);
    this.initMiniCourseValidators();
  }

  removeMiniCourse(index) {
    this.creationForm.controls.mini_courses.removeAt(index);
    this.initMiniCourseValidators();
  }

  addMaterial(material:any = {}) {
    let group = this.fb.group({
      name: [material.name],
      price: [material.price],
      in_leroy: [material.exists_in_leroy],
      in_fabric: [material.exists_in_fabric]
    });

    this.creationForm.controls.materials.push(group);
    this.initMaterialsValidators();
  }

  removeMaterial(index) {
    this.creationForm.controls.materials.removeAt(index);
    if (!this.creationForm.controls.materials.controls.length) {
      this.addMaterial();
    }

    this.initMaterialsValidators();
  }

  initCharactValidators() {
    for (let i = 0; i < this.creationForm.controls.characteristics.controls.length; i++) {
      this.creationForm.controls.characteristics.controls[i].controls.name.setValidators(Validators.required);
      this.creationForm.controls.characteristics.controls[i].controls.value.setValidators(Validators.required);
      this.safeReset(this.creationForm.controls.characteristics.controls[i].controls.name);
      this.safeReset(this.creationForm.controls.characteristics.controls[i].controls.value);
    }
  }

  initMiniCourseValidators() {
    for (let i = 0; i < this.creationForm.controls.mini_courses.controls.length; i++) {
      this.creationForm.controls.mini_courses.controls[i].controls.name.setValidators(Validators.required);
      this.creationForm.controls.mini_courses.controls[i].controls.duration.setValidators([this.numberValidator, Validators.required]);
      this.safeReset(this.creationForm.controls.mini_courses.controls[i].controls.name);
      this.safeReset(this.creationForm.controls.mini_courses.controls[i].controls.duration);
    }
  }

  initMaterialsValidators() {
    if (this.creationForm.controls.materials.controls.length > 1) {
      this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.name.setValidators();
      this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.price.setValidators();
      this.safeReset(this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.name);
      this.safeReset(this.creationForm.controls.materials.controls[this.creationForm.controls.materials.controls.length - 1].controls.price);
      for (let i = 0; i < this.creationForm.controls.materials.controls.length - 1; i++) {
        this.creationForm.controls.materials.controls[i].controls.name.setValidators(Validators.required);
        this.creationForm.controls.materials.controls[i].controls.price.setValidators([this.numberValidator, Validators.required]);
        this.safeReset(this.creationForm.controls.materials.controls[i].controls.name);
        this.safeReset(this.creationForm.controls.materials.controls[i].controls.price);
      }
    } else if (this.creationForm.controls.materials.controls.length === 1) {
      this.creationForm.controls.materials.controls[0].controls.name.setValidators();
      this.creationForm.controls.materials.controls[0].controls.price.setValidators();
      this.safeReset(this.creationForm.controls.materials.controls[0].controls.name);
      this.safeReset(this.creationForm.controls.materials.controls[0].controls.price);
    }
  }

  onMaterialInput(index) {
    if (index === this.creationForm.controls.materials.controls.length - 1) {
      if (this.creationForm.controls.materials.controls[index].controls.name.value) {
        this.addMaterial();
      }
    } else {
      if (!this.creationForm.controls.materials.controls[index].controls.name.value && !this.creationForm.controls.materials.controls[index].controls.price.value && !this.creationForm.controls.materials.controls[index].controls.in_fabric.value && !this.creationForm.controls.materials.controls[index].controls.in_leroy.value) {
        this.removeMaterial(index);
      }
    }
  }

  onMaterialBlur(index) {
    if (index < this.creationForm.controls.materials.controls.length - 1) {
      this.creationForm.controls.materials.controls[index].controls.price.markAsTouched();
    }
  }

  uploadFiles() {
    this.errorMessage = '';
    this.loadingState = 1;
    let that = this;
    function* generator() {
      if (that.creationForm.controls.mainImage.value) {
        yield 'main'
      }
      return that.submitForm();
    }
    let gen = generator();
    gen.next();
    let equipmentName = this.creationForm.value.title;
    this.uploadImage(that.creationForm.controls.mainImage, gen, equipmentName);
  }

  private uploadImage = function(file, gen, equipmentName?) {
    if (file.value) {
      if (file.value.old) {
        file.loaded = true;
        gen.next();
      } else if (file.loaded) {
        gen.next();
      } else {
        file.loading = true;
        file.hasErr = false;
        this.fileUploadService.uploadFile(file.value, 'INSTRUMENTS', equipmentName).subscribe((data) => {
          file.value.fileUrl = data.result.url;
          file.value.fileId = data.result.id;
          file.loaded = true;
          file.loading = false;
          gen.next();
        }, () => {
          file.hasErr = true;
          file.loading = false;
          this.loadingState = 'error'
          this.errorMessage = 'Ошибка загрузки файла';
        });
      }
    }
  }

  closeModal() {
    this.creationService.closeCreationModal();
  }

  submitForm() {
    this.loadingState = 2;
    if (this.editionMode) {
      this.equipmentsService.edit(this.editionMode.id, this.getEquipmentCreationForm()).subscribe((data) => {
        this.loadingState = 3;
        if (this.router.url.indexOf('oborudovanie/') + 1) {
          let redirectUrl = '/oborudovanie/' + this.translitService.getHumanReadableId(data.result.name, data.result.id);
          try {
            this.historyService.redirect404 = true;
            this.historyService.currentState = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url].previous.url;
            let pageData:any = {};
            pageData[redirectUrl] = this.historyService.pagesData[this.routesDataService.currentData.pageName][this.routesDataService.currentData.url]
            this.historyService.addPageData('equipment', pageData);
          } catch {
            this.historyService.redirect404 = false;
          }
          this.router.navigate([redirectUrl]);
        } else {
          this.closeModal();
        }
        this.currentEquipmentService.updateEmitter();
      }, (e) => {
        let error = parseApiError(e);
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка публикации';
        if (error && (error.code === "equipment-amount-less-than-breakages" || error.code === 'equipment-amount-less-than-reservations')) {
          this.errorMessage = 'Невозможно уменьшить количество экзмепляров оборудования';
          this.creationForm.controls.amount.setErrors({incorrect: true});
          setTimeout(() => {
            this.scrollToInvalidField();
            let input: any = document.querySelector('.equip-amount input');
            input && input.onblur();
          });
        }
      });
    } else {
      this.equipmentsService.create(this.getEquipmentCreationForm()).subscribe((data) => {
        this.loadingState = 3;
        this.closeModal();
        this.currentEquipmentService.updateEmitter();
      }, (e) => {
        this.loadingState = 'error';
        this.errorMessage = 'Ошибка публикации';
      });
    }
  }

  onSubmit() {
    this.validProviderPhoneNumber();
    if (this.creationForm.invalid) {
      this.markInvalidFields();
      this._ref.detectChanges();
      this.scrollToInvalidField();
    } else {
      this.uploadFiles();
    }
  }

  markInvalidFields() {
    const controls = this.creationForm.controls;
    Object.keys(controls).forEach(controlName => controls[controlName].markAsTouched());
    ['characteristics', 'mini_courses', 'materials'].forEach(controlName => {
      this.creationForm.controls[controlName].controls.forEach(control => {
        Object.keys(control.controls).forEach(controlName => {
          control.controls[controlName].markAsTouched();
        });
      });
    });
    this.creationForm.controls.mainImage.markAsTouched();
  }

  openConfirmDeletion() {
    this.confirmType = 'delete';
    this.wrapper.nativeElement.style.overflow = 'hidden';
  }

  closeConfirm() {
    this.confirmType = '';
    this.wrapper.nativeElement.style.overflow = 'auto';
  }


  deleteEquip() {
    this.confirmType = '';
    this.loadingState = 0;
    if (this.editionMode.id) {
      this.deleteLoading = true;
      // this.equipmentsService.deleteEquipment(this.editionMode.id).subscribe((data) => {
      //   this.deleteLoading = false;
      //   this.deleted = true;
      //   this.closeModal();
      //   if (this.router.url.indexOf('equipments/equipment/') + 1) {
      //     this.router.navigate(['/equipments']);
      //   }
      // }, () => {
      //   this.deleteLoading = false;
      //   this.loadingState = 'error';
      //   this.errorMessage = 'Ошибка удаления';
      //   this.closeConfirm();
      // })
    }
  }

  private getEquipmentCreationForm = function() {
    let form:any = {};
    form.name = this.creationForm.value.title;
    form.description = this.escape(this.creationForm.value.description);
    form.photo_id = this.creationForm.value.mainImage.fileId;
    form.areas = this.creationForm.value.areas;

    form.characteristics = this.creationForm.value.characteristics.filter(item => (item.name && item.value)).map(item => {
      return {
        name: item.name,
        value: item.value,
        order: item.order
      }
    });

    form.mini_courses = this.creationForm.value.mini_courses.filter(item => (item.name && item.duration)).map(item => {
      if (item.id) {
        return {
          name: item.name,
          duration: item.duration,
          id: item.id
        }
      } else {
        return {
          name: item.name,
          duration: item.duration,
        }
      }
    });

    form.materials = this.creationForm.value.materials.filter(item => (item.name && item.price)).map(item => {
      return {
        exists_in_fabric: !!item.in_fabric,
        exists_in_leroy: !!item.in_leroy,
        name: item.name,
        price: item.price
      }
    });

    form.material_types = this.creationForm.value.material_types;
    // form.use = this.creationForm.value.use;
    form.uses = this.creationForm.value.uses;

    form.other_use = this.creationForm.value.other_use;
    form.free_access = !this.creationForm.value.need_reserve;
    form.amount = this.creationForm.value.amount;
    form.provider_name = this.creationForm.value.provider_name;
    form.provider_contact = this.getProviderPhoneNumber();

    return form;
  }

  private validProviderPhoneNumber() {
    let phoneNumber = this.getProviderPhoneNumber();

    if (phoneNumber.length < 11) {
      this.creationForm.controls.provider_contact.setErrors({ incorrect: 'true' })
    }
  }

  private getProviderPhoneNumber(): string {
    if (this.creationForm.value.provider_contact) {
      return this.creationForm.value.provider_contact.replace(/\D+/g, '');
    } else {
      return '';
    }
  }

  private scrollToInvalidField = function() {
    let elem;
    if (!this.creationForm.value.mainImage) {
      let elem = this.mainPhotoPicker.nativeElement;
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
    } else {
      if ($('.ng-invalid')[0].tagName === 'FORM' && $('.ng-invalid')[1]) {
        elem = $('.ng-invalid')[1].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-touched.ng-invalid')[1]
        }
      } else {
        elem = $('.ng-invalid')[0].closest('.creation-input-anchor');
        if (!elem) {
          elem = $('.ng-invalid')[0]
        }
      }
      $(this.wrapper.nativeElement).animate({
        scrollTop: elem.getBoundingClientRect().top + this.wrapper.nativeElement.scrollTop - 16
      }, 1000);
    }
  }

  private numberValidator: ValidatorFn = function(control: FormControl) {
    return +control.value > 0 || (control.value === null || control.value === '') ? null : {
      incorrect: 'true'
    }
  }.bind(this)

  private safeReset(control) {
    let val = control.value;
    control.reset();
    control.setValue(val);
  }

  private escape = function (string) {
    let escaped = $("<div>").text(string).html();
    return escaped;
  }

  private encode = function (string) {
    let encoded = $("<div>").html(string).text();
    return encoded;
  }

  private validateAreas(areas) {
    let existingAreas = this.areas.map(item => item.value);
    let result = existingAreas.filter((item) => {
      return areas.indexOf(item) + 1;
    })
    return result;
  }

  private validateMaterials(materials) {
    let existingMaterials = this.material_types.map(item => item.value);
    let result = existingMaterials.filter((item) => {
      return materials.indexOf(item) + 1;
    })
    return result;
  }
}
