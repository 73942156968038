import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-lm-rules',
  templateUrl: './lm-rules.component.html',
  styleUrls: ['./lm-rules.component.styl']
})
export class LmRulesComponent implements OnInit {

  constructor(public modalService: ModalService) { }

  ngOnInit() {
  }

  back() {
    if (this.modalService.rulesShown) {
      this.modalService.hideRules();
    } else {
      this.modalService.back();
    }
  }

}
