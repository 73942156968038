import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../services/modal.service';

@Component({
  selector: 'app-modal-action-progress',
  templateUrl: './modal-action-progress.component.html',
  styleUrls: ['./modal-action-progress.component.styl']
})
export class ModalActionProgressComponent implements OnInit {
  constructor(public modalService: ModalService) { }

  ngOnInit() {
  }

}
