import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormControl, FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-sorting-filter',
  templateUrl: './sorting-filter.component.html',
  styleUrls: ['./sorting-filter.component.styl']
})
export class SortingFilterComponent implements OnInit {
  @Input() title: string;
  @Input() params: any[];
  @Input() small;
  @Input() noArrow;
  @Input() right;
  @Input() notAdaptive;
  @Input() defaultValue;
  @Output() onChangeEmitter = new EventEmitter();
  currentValue;
  popupOpened;
  public sortingFilterForm: FormGroup;
  constructor(private fb: FormBuilder) {
    this.sortingFilterForm = this.fb.group({
      param: ['', [Validators.required]]
    });
  }

  ngOnInit() {
    this.setValue(this.params[0].value);
  }

  onChange(evt) {
    let param = this.params.filter(item => item.value === this.sortingFilterForm.value.param)[0];
    if (param) {
      this.currentValue = param.title;
      this.onChangeEmitter.emit(param.value);
    }
    this.closePopup();
  }

  openPopup() {
    this.popupOpened = true;
  }

  closePopup() {
    this.popupOpened = false;
  }

  togglePopup() {
    if (this.popupOpened) {
      this.closePopup();
    } else {
      this.openPopup();
    }
  }

  setValue(value) {
    let param;
    if (this.params) {
      param = this.params.filter(item => item.value === value);
      if (param && param[0]) {
        param = param[0];
      }
      if (param) {
        this.sortingFilterForm.controls.param.setValue(param.value);
        this.currentValue = param.title;
      } else {
        this.sortingFilterForm.controls.param.setValue(this.params[0].value);
        this.currentValue = this.params[0].title;
      }
    }

  }

}
