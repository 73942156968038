import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { compareMonth } from '../../utils';

@Component({
  selector: 'app-events-search-result-item',
  templateUrl: './events-search-result-item.component.html',
  styleUrls: ['./events-search-result-item.component.styl']
})
export class EventsSearchResultItemComponent implements OnInit {
  @Input() event;
  @Output() appointEvent = new EventEmitter();
  placesLeft;
  oneDate;
  oneMonth;
  constructor() { }

  ngOnInit() {
    this.placesLeft = this.event.places_amount - this.event.places_filled;
    this.oneDate = new Date(this.event.start_date).setHours(0,0,0,0) === new Date(this.event.end_date).setHours(0,0,0,0);
    this.oneMonth = compareMonth(this.event.start_date, this.event.end_date) === 0;
  }

  appoint() {
    this.appointEvent.emit(this.event);
  }

}
