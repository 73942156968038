import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {
  updateSubject;
  constructor(private api: ApiService) {
    this.updateSubject = new Subject();
  }

  getUserCalendar(model) {
    return this.api.post('v1/calendar/list', JSON.stringify(model));
  }

  updateEmitter() {
    this.updateSubject.next();
  }

}
