import { Injectable, Inject } from '@angular/core';
import { ApiService } from './api.service';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class NotificationsService {
  updateSubject$;
  doc: any;
  constructor(private api: ApiService, @Inject('SOCKET') private socketUrl: string) {
    this.updateSubject$ = new Subject();
    // this.doc = document;
  }

  getSettings() {
    return this.api.get(`v1/notifications/settings`);
  }

  setSettings(form) {
    return this.api.post(`v1/notifications/settings`, JSON.stringify(form));
  }

  getAdminNotificationsList() {
    return this.api.get(`v1/notifications/list/admin`);
  }

  getClientNotificationsList() {
    return this.api.get(`v1/notifications/list`);
  }

  markAsRead(notificationId) {
    return this.api.post(`v1/notifications/${notificationId}/read`, null);
  }

  updateEmitter() {
    this.updateSubject$.next();
  }

  clearAllClientNotifications() {
    return this.api.post(`v1/notifications/delete`, null);
  }

  getUnreadNotificationsCount() {
    return this.api.get(`v1/notifications/count`);
  }

  markAllNotificationsSeen() {
    return this.api.post(`v1/notifications/seen`, null);
  }
}
