import { Component, OnInit, Input } from '@angular/core';
import { ModalService } from '../../services/modal.service';
import { ActivatedRoute } from '@angular/router';
import { IsBrowserService } from '../../services/is-browser.service';
import { EquipmentsService } from '../../services/equipments.service';
import { forkJoin } from 'rxjs';
import { first } from 'rxjs/operators';
import { combineDates, removingEquipReasonsMap, parseApiError } from '../../utils';
import { MessagesService } from '../../services/messages.service';

@Component({
  selector: 'app-modal-notify-remove-equipment',
  templateUrl: './modal-notify-remove-equipment.component.html',
  styleUrls: ['./modal-notify-remove-equipment.component.styl']
})
export class ModalNotifyRemoveEquipmentComponent implements OnInit {
  @Input() options;
  currentNotificationId;
  currentNotification;
  clientWarnings;
  isLoading;
  isError;
  altEquipment;
  removingEquipReasonsMap = removingEquipReasonsMap;
  constructor(private modalService: ModalService,
              private isBrowserService: IsBrowserService,
              private activatedRoute: ActivatedRoute,
              private equipmentsService: EquipmentsService,
              private messagesService: MessagesService) { }

  ngOnInit() {
    if (this.isBrowserService.isBrowser) {
      this.currentNotificationId = this.activatedRoute.snapshot.queryParams.notification;
      if (this.currentNotificationId) {
        this.updateNotifications();
      } else {
        this.isLoading = false;
        this.isError = true;
      }
    }
  }

  // getNotificationInfo() {
  //   this.equipmentsService.getDeletionInfo(this.currentNotificationId).pipe(first()).subscribe((data) => {
  //     this.currentNotification = data.result;
  //     this.clientWarnings = this.currentNotification.client_warnings;
  //     console.log(this.currentNotification);
  //     this.isLoading = false;
  //   }, () => {
  //     this.isLoading = false;
  //     this.isError = false;
  //   });
  // }

  closeModal() {
    this.modalService.onClose();
  }

  updateNotifications(disableLoading?) {
    if (!disableLoading) {
      this.isLoading = true;
    }
    this.equipmentsService.getDeletionInfo(this.currentNotificationId).pipe(first()).subscribe((data) => {
      this.currentNotification = data.result
      this.currentNotification.client_warnings.sort(this.sortCallback);
      this.clientWarnings = this.currentNotification.client_warnings;
      if (!this.currentNotification.deleted_equipment_info) {
        this.isError = true;
        this.isLoading = false;
      } else {
        if (this.currentNotification.replacing_equipment_id) {
          this.equipmentsService.getEquipment(this.currentNotification.replacing_equipment_id).pipe(first()).subscribe((data) => {
            this.altEquipment = data.result;
            this.isLoading = false
          }, () => this.isError = true, () => this.isLoading = false)
        } else {
          this.isLoading = false
        }
      }
    }, () => this.isError = true)
  }

  postpone(warning, params) {
    let cancel = this.equipmentsService.cancelReservation(warning.old_timing_ids, warning.client_id);
    let reservationParams;
    if (params.reserveParams) {
      reservationParams = {
        ...params.reserveParams,
        timings: params.reserveSettings.parsedSessionsTime.map(sessionTime => ({
          date: sessionTime.date,
          start_time: sessionTime.start_time,
          end_time: sessionTime.end_time,
          consultation_id: sessionTime.consultation_id,
          course_timing_id: sessionTime.course_timing_id
       }))
      }
    } else {
      reservationParams = params;
    }
    let reserve = this.equipmentsService.reserveEquip(this.currentNotification.replacing_equipment_id, reservationParams);
    warning.loading = true;
    reserve.pipe(first()).subscribe((reserveData) => {
      cancel.pipe(first()).subscribe((cancelData) => {
        let changeCallModel = {
          client_warnings: [{
            call_result: 'POSTPONED',
            client_id: warning.client_id,
            timing_ids: reserveData.result.map(item => item.timing_id),
            warning_id: warning.warning_id
          }],
          notification_id: this.currentNotificationId
        }
        this.equipmentsService.changeDeletionCallResult(changeCallModel).pipe(first()).subscribe((data) => {
          this.updateNotifications(true);
        }, () => {warning.loading = false})
      }, () => {warning.loading = false})
    }, (e) => {
      warning.loading = false;
      let error = parseApiError(e);
      if (error === 'not_enough') {
        this.messagesService.showMessage('На это время нет свободного оборудования.', false, false, true);
      } else {
        this.messagesService.showMessage('Ошибка при бронировании оборудования.', false, false, true);
      }
    })
    // forkJoin(cancel, reserve).pipe(first()).subscribe((data) => {
    //   let changeCallModel = {
    //     client_warnings: [{
    //       call_result: 'POSTPONED',
    //       client_id: warning.client_id,
    //       timing_ids: data[1].result.map(item => item.timing_id),
    //       warning_id: warning.warning_id
    //     }],
    //     notification_id: this.currentNotificationId
    //   }
    //   this.equipmentsService.changeDeletionCallResult(changeCallModel).pipe(first()).subscribe((data) => {
    //     this.updateNotifications(true);
    //   }, () => {warning.loading = false})
    // }, () => {warning.loading = false})
  }

  cancel(warning) {
    if (warning.old_timing_ids && warning.old_timing_ids.length) {
      let changeCallModel = {
        client_warnings: [{
          call_result: 'CANCELED',
          client_id: warning.client_id,
          timing_ids: warning.old_timing_ids,
          warning_id: warning.warning_id
        }],
        notification_id: this.currentNotificationId
      }
      let cancel = this.equipmentsService.cancelReservation(warning.old_timing_ids, warning.client_id);
      let call = this.equipmentsService.changeDeletionCallResult(changeCallModel);
      warning.loading = true;
      cancel.pipe(first()).subscribe(() => {
        call.pipe(first()).subscribe(() => {
          warning.loading = false;
          this.updateNotifications(true);
        }, () => {warning.loading = false})
      }, () => {warning.loading = false})
    }
  }

  private sortCallback = function(a, b) {
    if (!a.last_name && !a.first_name) {
      return 1;
    }
    if (!b.last_name && !b.first_name) {
      return -1;
    }
    if (`${(a.last_name || '').toLowerCase()} ${(a.first_name || '').toLowerCase()}`.trim() > `${(b.last_name || '').toLowerCase()} ${(b.first_name || '').toLowerCase()}`.trim()) {
      return 1;
    }
    if (`${(a.last_name || '').toLowerCase()} ${(a.first_name || '').toLowerCase()}`.trim() < `${(b.last_name || '').toLowerCase()} ${(b.first_name || '').toLowerCase()}`.trim()) {
      return -1;
    }
    return new Date(combineDates(a.reservation_date, a.old_reservation_start_time)).getTime() -  new Date(combineDates(b.reservation_date, b.old_reservation_start_time)).getTime();
  }
}
