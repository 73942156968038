import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
const URL = 'https://evening-anchorage-3159.herokuapp.com/api/';

@Component({
  selector: 'app-file-picker',
  templateUrl: './file-picker.component.html',
  styleUrls: ['./file-picker.component.styl']
})
export class FilePickerComponent implements OnInit {
  constructor() { }
  @Input() uploader;
  @Input() limit;
  public hasDropZoneOver:boolean = false;
  public fileOver(e:any):void {
    this.hasDropZoneOver = e;
  }
  ngOnInit() {
    this.uploader.onAfterAddingFile = (item:any, response:any, status:any, headers:any) => {
      if (this.limit && this.uploader.queue.length > this.limit) {
        this.uploader.queue.pop();
      }
    };
  }

  removeFile(index) {
    this.uploader.queue.splice(index, 1);
  }

}
