import { Pipe } from '@angular/core';

@Pipe({
  name: 'msDuration'
})
export class MsDurationPipe {
  declOfNum(number, titles) {
    let cases = [2, 0, 1, 1, 1, 2];
    if (+Math.abs(number) < 1) {
      return titles[1];
    }
    number = Math.round(Math.abs(number));
    return titles[ (number%100>4 && number%100<20)? 2 : cases[(number%10<5)?number%10:5] ];
  }
  transform(value: any, args?: any): any {
    if (+value) {
      let hours = value / (3600 * 1000);
      if (hours >= 24) {
        let days = Math.floor(hours / 24);
        if (args === 'crop') {
          return days + ' д';
        } else {
          return days + ' ' + this.declOfNum(days, ['день', 'дня', 'дней']);
        }
      } else {
        if (args === 'crop') {
          return hours + ' ч';
        } else {
          return hours + ' ' + this.declOfNum(hours, ['час', 'часа', 'часов']);
        }
      }
    }
  }

}
