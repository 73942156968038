import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalService } from '../../services/modal.service';
import { phoneMask } from '../../utils';
import { MessagesService } from '../../services/messages.service';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'app-modal-confirm-phone',
  templateUrl: './modal-confirm-phone.component.html',
  styleUrls: ['./modal-confirm-phone.component.styl']
})
export class ModalConfirmPhoneComponent implements OnInit {
  phoneForm: FormGroup;
  titleText: string;
  isReg: boolean;
  phoneMask = phoneMask;
  setCode: boolean;
  error:boolean ;
  constructor(private modalService: ModalService,
              private messagesService: MessagesService,
              private fb: FormBuilder,
              private userService: UserService) { }

  ngOnInit() {
  
  }

  onPhoneChange() {
    this.userService.update();
    this.modalService.onClose();
    this.messagesService.showMessage('Телефон успешно изменен.', false, true, true);
  }

}
