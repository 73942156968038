import { Injectable } from '@angular/core';
import { CurrentCourseService } from './current-course.service';
import { CoursesService } from './courses.service';
import { EventsService } from './events.service';
import { CurrentEventService } from './current-event.service';
import { ConsultationsService } from './consultations.service';
import { MessagesService } from './messages.service';
import { ModalService } from './modal.service';
import { EquipmentsService } from './equipments.service';
import { CurrentEquipmentService } from './current-equipment.service';
import { getApiTime } from '../utils';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class CancelRegistrationService {
  cancelModalOpened;
  currentCourse;
  currentCourseId;
  currentTimingId;
  currentEvent;
  currentConsult;
  constructor(private currentCourseService: CurrentCourseService,
              private coursesService: CoursesService,
              private eventsService: EventsService,
              private currentEventService: CurrentEventService,
              private consultationsService: ConsultationsService,
              private messagesService: MessagesService,
              private modalService: ModalService,
              private equipmentsService: EquipmentsService,
              private currentEquipmentService: CurrentEquipmentService) { }

  cancelCourse(course) {
    if (course.cost && course.paid) {
      this.openCancelCourseConfirm(course);
    } else {
      this.cancelFreeCourse(course);
    }
  }

  cancelEvent(event) {
    if (event.cost && event.paid) {
      this.openCancelEventConfirm(event);
    } else {
      this.cancelFreeEvent(event);
    }
  }

  cancelConsult(consult) {
    if (consult.paid) {
      this.openCancelConsultConfirm(consult);
    } else {
      this.cancelFreeConsult(consult);
    }
  }

  openCancelCourseConfirm(course) {
    this.cancelModalOpened = true;
    this.currentCourse = course;
    this.currentCourseId = course.entity_second_id || course.id;
    this.currentTimingId = course.entity_id || this.getRegisteredTiming(course);
    this.modalService.freezBody(true);
  }

  cancelPaidCourse() {
    let courseName = "" + this.currentCourse.name;
    this.coursesService.cancel(this.currentCourseId).subscribe(() => {
      this.messagesService.showMessage(`Ваша запись на курс «${courseName}» отменена.`, null, false, true);
      this.currentCourseService.updateEmitter();
    })
    this.closeConfirmPopup();
  }

  cancelFreeCourse(course) {
    let currentCourse = course;
    let currentCourseId = course.entity_second_id || course.id;
    let registredId = course.entity_id || this.getRegisteredTiming(course);
    let certificate = course.by_certificate;
    let enrollAgain = function () {
      this.coursesService.register(currentCourseId, {
        course_timing_id: registredId,
        payment_type: certificate ? 'CERTIFICATE' : 'ON_THE_SPOT'
      }).subscribe(() => {
        this.messagesService.showMessage(`Ваша запись на курс «${currentCourse.name}» восстановлена.`, null, true, true);
        this.currentCourseService.updateEmitter();
      })
    }.bind(this);
    this.coursesService.cancel(currentCourseId).subscribe(() => {
      this.currentCourseService.updateEmitter();
      this.messagesService.showMessage(`Ваша запись на курс «${currentCourse.name}» отменена.`, {
        text: 'ВЕРНУТЬ ЗАПИСЬ',
        func: enrollAgain
      }, false, false)
    })
  }

  getRegisteredTiming(course) {
    return course.course_timings.filter(item => item.registered)[0].id;
  }

  openCancelEventConfirm(event) {
    this.currentEvent = event;
    this.cancelModalOpened = true;
    this.modalService.freezBody(true);
  }

  cancelPaidEvent() {
    let eventName = "" + this.currentEvent.name;
    this.eventsService.register(this.currentEvent.entity_id || this.currentEvent.id, { register: false, payment_type: 'ON_THE_SPOT' }).subscribe(() => {
      this.messagesService.showMessage(`Ваша запись на событие «${eventName}» отменена.`, null, false, true);
      this.currentEventService.updateEmitter();
    })
    this.closeConfirmPopup();
  }

  cancelFreeEvent(event) {
    let currentEvent = event;
    let currentEventId = event.entity_id || event.id;
    this.eventsService.register(currentEventId, { register: false, payment_type: 'ON_THE_SPOT' }).subscribe(() => {
      this.currentEventService.updateEmitter();
      let enrollAgain = function () {
        this.eventsService.register(currentEventId, { register: true, payment_type: 'ON_THE_SPOT' }).subscribe(() => {
          this.messagesService.showMessage(`Ваша запись на событие «${currentEvent.name}» восстановлена.`, null, true, true);
          this.currentEventService.updateEmitter();
        })
      }.bind(this);
      this.messagesService.showMessage(`Ваша запись на событие «${currentEvent.name}» отменена.`, {
        text: 'ВЕРНУТЬ ЗАПИСЬ',
        func: enrollAgain
      }, false, false)
    })
  }

  openCancelConsultConfirm(consult) {
    this.currentConsult = consult;
    this.cancelModalOpened = true;
    this.modalService.freezBody(true);
  }

  cancelFreeConsult(consult) {
    let currentConsult = consult;
    let currentConsultId = consult.entity_second_id || consult.id;
    let enrollAgain = function () {
      this.consultationsService.order(currentConsultId, 'ON_THE_SPOT').subscribe(() => {
        this.messagesService.showMessage(`Ваша запись на консультацию восстановлена.`, null, true, true);
        this.currentCourseService.updateEmitter();
      })
    }.bind(this);
    this.consultationsService.cancelConsultation(consult.entity_id || consult.id).subscribe(() => {
      this.currentCourseService.updateEmitter();
      this.messagesService.showMessage(`Ваша запись на консультацию отменена.`, {
        text: 'ВЕРНУТЬ ЗАПИСЬ',
        func: enrollAgain
      }, false, false)
    }, () => {
      this.messagesService.showMessage(`Ошибка отмены записи.`, null, false, true)
    })
  }

  cancelPaidConsult() {
    this.consultationsService.cancelConsultation(this.currentConsult.id || this.currentConsult.entity_id).subscribe(() => {
      this.messagesService.showMessage(`Ваша запись на консультацию отменена.`, null, false, true);
      this.currentCourseService.updateEmitter();
    }, () => {
      this.messagesService.showMessage(`Ошибка отмены записи.`, null, false, true)
    })
    this.closeConfirmPopup();
  }

  closeConfirmPopup() {
    this.currentCourse = null;
    this.currentCourseId = null;
    this.currentTimingId = null;
    this.currentEvent = null;
    this.currentConsult = null;
    this.modalService.unfreezBody();
    this.cancelModalOpened = false;
  }

  cancelReservation(equipmentId, equipmentTimingIds, userId, params) {
    let reserveAgain = function(equipmentId, params, userId) {
      let model = {
        amount: 1,
        reservation_type: params.reservation_type,
        timings: [{
          date: moment(params.start_date).format('YYYY-MM-DD'),
          start_time: getApiTime(moment(params.start_time).format('HH:mm')),
          end_time: getApiTime(moment(params.end_time).format('HH:mm')),
          course_timing_id: params.purpose_entity_id,
          consultation_id: params.purpose_entity_id
        }],
        user_id: userId
      }
      this.equipmentsService.reserveEquip(equipmentId, model).subscribe((data) => {
        this.messagesService.showMessage(`Резервирование «${params.name}» восстановлено.`, null, true, true);
        this.currentEquipmentService.updateEmitter();
      })
    }.bind(this, equipmentId, params, userId);
    this.equipmentsService.cancelReservation(equipmentTimingIds, userId).subscribe((data) => {
      this.currentEquipmentService.updateEmitter();
      this.messagesService.showMessage(`Резервирование «${params.name}» отменено.`, {
        text: 'ВЕРНУТЬ РЕЗЕРВИРОВАНИЕ',
        func: reserveAgain
      }, false, true)
    })
  }

}
