import { Pipe } from '@angular/core';

@Pipe({
  name: 'rusDay'
})
export class RusDayPipe {

  transform(value: any, type): any {
    let days = ['воскресенье','понедельник', 'вторник', 'среда', 'четверг', 'пятница', 'суббота']
    let shortDays = ['вс', 'пн', 'вт', 'ср', 'чт', 'пт', 'сб']
    if (type === 'short') {
      days = shortDays;
    }
    if (type === 'today') {
      if (new Date(value).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
        return 'сегодня';
      }
      if (new Date(value).setHours(0,0,0,0) === new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0)) {
        return 'завтра';
      }
    }
    if (type === 'todayshort') {
      days = shortDays;
      if (new Date(value).setHours(0,0,0,0) === new Date().setHours(0,0,0,0)) {
        return 'сегодня';
      }
      if (new Date(value).setHours(0,0,0,0) === new Date(new Date().setDate(new Date().getDate() + 1)).setHours(0,0,0,0)) {
        return 'завтра';
      }
      if (new Date(value).setHours(0,0,0,0) === new Date(new Date().setDate(new Date().getDate() - 1)).setHours(0,0,0,0)) {
        return 'вчера';
      }
    }
    return days[new Date(value).getDay()];
  }

}
