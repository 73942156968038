import {Component, OnInit} from '@angular/core';
import {SubscriptionsService} from '../../services/subscriptions.service';
import {ServicesService} from '../../services/services.service';

interface IItem {
  type: string;
  priceType: string;
  price: number;

  workTime?: string;
}
@Component({
  selector: 'app-subscription-services-prices',
  templateUrl: './subscription-services-prices.component.html',
  styleUrls: ['./subscription-services-prices.component.styl']
})
export class SubscriptionServicesPricesComponent implements OnInit {

  subscriptionsItems: IItem[] = this.subscriptionsService.buildCards().map(this.convertSubscriptionToItem);
  servicesItems: IItem[] = this.servicesService.get().map(this.convertServiceToItem);
  items: IItem[] = [...this.subscriptionsItems, ...this.servicesItems];

  constructor(
    private subscriptionsService: SubscriptionsService,
    private servicesService: ServicesService) {
  }

  convertSubscriptionToItem({ price, type, workTime }) {
    return {
      type: `subscription`,
      priceType: type,
      price,
      workTime,
    }
  }

  convertServiceToItem({ type, price, priceType }) {
    return {
      type,
      priceType,
      price,
    }
  }

  ngOnInit() {
    this.subscriptionsService.price().subscribe((data) => {
      const subscriptions = this.subscriptionsService.buildCards(data.result.hour_price, data.result.day_price);

      this.items = [...subscriptions.map(this.convertSubscriptionToItem), ...this.servicesItems];

    }, (error) => {
      console.log('subscription price failed ', error);
    });
  }
}
