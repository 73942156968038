import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-storage-products-comment',
  templateUrl: './storage-products-comment.component.html',
  styleUrls: ['./storage-products-comment.component.styl']
})
export class StorageProductsCommentComponent implements OnInit {
  @Input() storage;
  @Output() commentEmitter = new EventEmitter();
  openBox = false;
  comment:string = '';
  today = new Date();

  constructor() { }

  ngOnInit() {
  }

  openComment() {
    this.openBox = true;
  }

  closeComment() {
    this.openBox = false;
    if (this.comment) {
      this.submit();
    }
  }

  submit() {
    if (this.comment) {
      this.commentEmitter.emit(this.comment);
    }
  }

  keyDownFunction(event) {
    if(event.keyCode == 13 && this.comment) {

      this.closeComment();
      // rest of your code
    }
  }

}
