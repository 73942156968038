import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-file-link',
  templateUrl: './file-link.component.html',
  styleUrls: ['./file-link.component.styl']
})
export class FileLinkComponent implements OnInit {
  @Input() file: any;
  @Input() hideSize: boolean;
  size;
  type;
  isLoading;
  loaded;
  total;
  constructor() { }

  ngOnInit() {
    if (this.file.url) {
      let symb;
      if (this.file.url.indexOf('?') + 1) {
        symb = '&';
      } else {
        symb = '?';
      }
    }
  }

  parseFileData() {
    let typeArr = this.file.type.split('/');
    this.type = typeArr[typeArr.length - 1].toUpperCase();
  }

  saveAs(uri, filename) {
    let self = this;
    var link = document.createElement('a');
    if (typeof link.download === 'string') {
      this.isLoading = true;
      var xhr = new XMLHttpRequest();
      xhr.open('GET', uri);
      xhr.responseType = 'blob';
      xhr.onprogress = function(event) {
        self.loaded = event.loaded;
        self.total = event.total;
      }
      xhr.onload = function(e) {
        let that: any = this;
        self.isLoading = false;
        if (that.status == 200) {
          var myBlob = that.response;
          let url = window.URL.createObjectURL(myBlob);
          document.body.appendChild(link);
          link.setAttribute('download', filename);
          link.setAttribute('target', '_blank');
          link.href = url;
          link.click();
          document.body.removeChild(link);
        }
      };
      xhr.send();
    } else {
      location.replace(uri);
    }
  }

}
